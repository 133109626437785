import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MEMBER_ROLES } from "../../shared/constants";

const useStyles = makeStyles((theme) => ({
	root: {},
	formControl: {
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

export default function RolePicker({ roleId, changeHandler }) {
	const [selectedRoleId, setSelectedRoleId] = useState(roleId);
	const classes = useStyles();

	const handleChange = (e) => {
		changeHandler(e.target.value);
		setSelectedRoleId(e.target.value);
	};

	// Used to remove hierarchy styling from the selected item
	const renderSelectedItem = (value) => {
		return MEMBER_ROLES[value].label;
	};

	return (
		<div className={classes.root}>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={selectedRoleId}
					onChange={handleChange}
					renderValue={renderSelectedItem}
				>
					{Object.values(MEMBER_ROLES).map((role, index) => {
						return (
							<MenuItem key={index} value={role.id}>
								{role.label}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
}
