import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MembersTabs from "./MembersTabs";
import MembersTabPanel from "./MembersTabPanel";
import PeopleTable from "./PeopleTable";
import { PEOPLE_TABLE_TYPE } from "../../shared/constants";

const useStyles = makeStyles({
	tabRoot: {
		flex: 1,
		display: "flex",
		flexFlow: "column nowrap",
	},
	bob: {
		display: "flex",
		justifyContent: "space-between",
	},
});

function createRolesString(roles) {
	if (!roles) return "";
	const keys = Object.keys(roles);
	if (keys.length === 0) return "";

	let result = keys.reduce((result, key) => {
		result += `${key}, `;
		return result;
	}, "");

	return result.slice(0, -2);
}

export default function MembersTabControl({ members, pending, emails }) {
	const classes = useStyles();
	const [activeTabId, setActiveTabId] = React.useState(0);

	const handleTabChange = (event, newValue) => {
		setActiveTabId(newValue);
	};

	return (
		<div className={classes.tabRoot}>
			<MembersTabs
				members={members}
				pending={pending}
				emails={emails}
				activeTabId={activeTabId}
				onChange={handleTabChange}
			/>
			<MembersTabPanel activeTabId={activeTabId} tabPanelId={0}>
				<PeopleTable
					tableType={PEOPLE_TABLE_TYPE.MEMBER}
					data={members.map((item) => {
						return {
							name: `${item.firstName} ${item.lastName}`,
							email: item.email,
							role: createRolesString(item.subscription.roles),
							userId: item.SK,
							subscription: item.subscription,
							status: item.status,
							PK: item.PK,
						};
					})}
				/>
			</MembersTabPanel>
			<MembersTabPanel activeTabId={activeTabId} tabPanelId={1}>
				<PeopleTable
					tableType={PEOPLE_TABLE_TYPE.PENDING}
					data={pending.map((item) => {
						return {
							email: item.email,
							role: createRolesString(item.subscription.roles),
							createdAt: item.createdAt,
							userId: item.SK,
							subscription: item.subscription,
							status: item.status,
							PK: item.PK,
						};
					})}
				/>
			</MembersTabPanel>
		</div>
	);
}
