module.exports = {
	channels: {
		REMINDERS: "reminders",
		NOTIFICATION: "notification",
		REFLECTION: "reflection",
		UPDATE: "update",
		APPCONTEXT: "appcontext",
		RESTART: "restart",
		GET_VERSION: "get-version",
		ANALYTICS_EVENT: "analyticsEvent",
		POWER_EVENT: "powerEvent",
	},
	messages: {
		CLOSE_NOTIFICATION: "close-notification",
		MORALE_REFLECTION: "morale-reflection",
		GRATITUDE_REFLECTION: "gratitude-reflection",
		AFFIRMATIONS_REFLECTION: "affirmations-reflection",
		CLOSE_REFLECTION: "close-reflection",
		REQUEST_REFLECTION_INFO: "request-reflection-info",
		RESPONSE_REFLECTION_INFO: "response-reflection-info",
		NOTIFICATION_REFLECTION: "notification-reflection",
		REFLECTION_CLOSED: "reflection-closed",
		REQUEST_USER_INFO: "request-userinfo",
		RESPONSE_USER_INFO: "response-userinfo",
		SET_USER_SIGNED_IN: "set-user-signed-in",
		SET_USER_SIGNED_OUT: "set-user-signed-out",
		SETUP_MODULE_REMINDERS: "setup-module-reminders",
		UPDATE_SETUP_INFO: "update-setup-info",
	},
	guids: {
		SETUP_GOAL_0: "G_0",
		SETUP_GOAL_1: "G_1",
		SETUP_GOAL_2: "G_2",
		SETUP_GOAL_3: "G_3",
		SETUP_GOAL_4: "G_4",
		SETUP_STATEMENT_0: "S_0",
		SETUP_STATEMENT_1: "S_1",
		SETUP_STATEMENT_2: "S_2",
		SETUP_STATEMENT_3: "S_3",
		SETUP_STATEMENT_4: "S_4",
		SETUP_STATEMENT_5: "S_5",
	},

	faStatus: {
		NOT_SUPPORTED: "NOT_SUPPORTED",
		FAILED: "FAILED",
		OFF: "OFF",
		PRIORITY_ONLY: "PRIORITY_ONLY",
		ALARMS_ONLY: "ALARMS_ONLY",
	},

	faPriority: {
		NOT_SUPPORTED: "NOT_SUPPORTED",
		FAILED: "FAILED",
		NO: "NO",
		YES: "YES",
	},

	winQUNS: {
		QUNS_NOT_PRESENT: "QUNS_NOT_PRESENT",
		QUNS_BUSY: "QUNS_BUSY",
		QUNS_RUNNING_D3D_FULL_SCREEN: "QUNS_RUNNING_D3D_FULL_SCREEN",
		QUNS_PRESENTATION_MODE: "QUNS_PRESENTATION_MODE",
		QUNS_ACCEPTS_NOTIFICATIONS: "QUNS_ACCEPTS_NOTIFICATIONS",
		QUNS_QUIET_TIME: "QUNS_QUIET_TIME",
		QUNS_APP: "QUNS_APP",
	},

	dateRange: {
		ONE_WEEK: "ONE_WEEK",
		ONE_MONTH: "ONE_MONTH",
		SIX_MONTHS: "SIX_MONTHS",
		ONE_YEAR: "ONE_YEAR",
		MAX: "MAX",
	},

	dataType: {
		MOOD: "moodIndexData",
		EMOTION: "emotionWordData",
		STIMULUS: "stimulusData",
		TABLE: "tableData",
		KUDOS: "kudosData",
	},

	MY_INSIGHTS: "my-insights",
	MY_INSIGHTS_TEXT: "My Insights",
	MIN_GROUP_PARTICIPATION: 3,

	kudosVisibility: {
		ONLY_ME: "ONLY_ME",
		COMPANY: "COMPANY",
	},

	defaults: {
		REMINDERS: [
			{
				days: [false, true, true, true, true, true, false],
				time: "09:00",
				enabled: true,
				moraleCheckIn: true,
			},
			{
				days: [false, true, true, true, true, true, false],
				time: "17:00",
				enabled: true,
				moraleCheckIn: true,
			},
		],

		SETUPINFO: { goals: [], statements: [], completedSetup: false },
		GROUPS: [],
		MANAGED_GROUPS: [],
	},

	API_FLAG: { OLD: "OLD", OLDNEW: "OLDNEW", NEW: "NEW" },

	GROUP_TREE_ID: "GROUP_TREE",

	QUIMBY_AWS_PATHS: {
		S3_BASE_PATH: "https://quimby-releases.s3.us-east-2.amazonaws.com/",
		BETA_YAML_WINDOWS: "https://quimby-releases.s3.us-east-2.amazonaws.com/beta.yml",
		BETA_YAML_MAC: "https://quimby-releases.s3.us-east-2.amazonaws.com/beta-mac.yml",
		DOWNLOAD_ENDPOINT:
			"https://a1queyjen4.execute-api.us-east-2.amazonaws.com/prod/v2/download",
	},

	OS: {
		WINDOWS: "win",
		MAC: "mac",
		LINUX: "linux",
		UNIX: "unix",
		UNKNOWN: "unknown",
	},

	PEOPLE_TABLE_TYPE: {
		MEMBER: "MEMBER",
		PENDING: "PENDING",
	},

	BUTTON_STYLES: {
		SOLID: "contained",
		OUTLINED: "outlined",
	},
	MEMBER_STATUS: {
		MEMBER: "MEMBER",
		PENDING: "PENDING",
		NEEDS_APPROVAL: "NEEDS_APPROVAL",
		REMOVED: "REMOVED",
	},
	MEMBER_ROLES: {
		none: { id: "none", label: "None" },
		admin: { id: "admin", label: "Admin" },
		// GROUP_MANAGER: { id: "groupManager", label: "Group Manager" },
	},
	KILL_EDIT_MODE_ACTION: {
		SAVE: "SAVE",
		DONTSAVE: "DONTSAVE",
		CLEAR: "CLEAR",
	},
	DRAWER_WIDTH: 250,
	UPDATE_SUBSCRIPTION_ACTION: {
		KEY: "UPDATE_SUBSCRIPTION_ACTION",
		ADD_MEMBERS: "ADD_MEMBERS",
		REMOVE_MEMBER: "REMOVE_MEMBER",
		SET_BILLING_TYPE: "SET_BILLING_TYPE",
		UPDATE_ROLES: "UPDATE_ROLES",
	},
	EDIT_RECORD_KEY: {
		NAME: "name",
		MANAGERS: "managers",
		MEMBERS: "members",
		PARENT: "parentGroupId",
	},
	PASSWORD_REQUIREMENTS:
		"Password requires 8 characters minimum, a lowercase letter, an uppercase letter, a number and a special character",

	NAV_DIRECTION: {
		BACK: "BACK",
		FORWARD: "FORWARD",
	},

	SIGNUP_ACTION: {
		TEAM_MANAGER: "TEAM_MANAGER",
		TEAM_MEMBER: "TEAM_MEMBER",
		INDIVIDUAL: "INDIVIDUAL",
	},

	NAV_ITEMS: {
		PROFILE: "PROFILE",
		BILLING: "BILLING",
		MEMBERS: "MEMBERS",
		MANAGE_GROUPS: "MANAGE_GROUPS",
		DOWNLOAD: "DOWNLOAD",
		SUBSCRIPTIONS: "SUBSCRIPTIONS",
	},
	ERROR_ACTION: {
		SET: "SET",
		CLEAR: "CLEAR",
	},

	FIELD_DEFAULT: {
		value: "",
		isTouched: false,
	},

	FIELD_ERROR_DEFAULT: {
		errorText: "",
		error: false,
	},
	VIDEOS: {
		BOX_BREATHING: "https://quimby-assets.s3.us-east-2.amazonaws.com/videos/box_breathing.mp4",
		BRAIN_BEING_PRESENT:
			"https://quimby-assets.s3.us-east-2.amazonaws.com/videos/brain_being_present.mp4",
		BRAIN_WORKOUT: "https://quimby-assets.s3.us-east-2.amazonaws.com/videos/brain_workout.mp4",
		BRAIN_EMOTION_THOUGHT:
			"https://quimby-assets.s3.us-east-2.amazonaws.com/videos/brain_emotion_thoughts.mp4",
	},
};
