import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	ThemeProvider,
	Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import SmallArrow from "../components/common/SmallArrows";
import { NavBarState } from "../components/home/NavBar";
import { apiGetReflections } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { MY_INSIGHTS } from "../shared/constants";

const MyValues = () => {
	const [valuesData, setValuesData] = React.useState();
	const [expanded, setExpanded] = React.useState("");
	const {
		appState: { reflectionCache },
	} = useAppContext();
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();

	const handleChange = (panel) => (event, newExpanded) => {
		if (newExpanded) {
			logger.telemetry({
				action: "click",
				event: "my_values_expand_value",
				eventData: {
					value: panel,
				},
			});
		}
		setExpanded(newExpanded ? panel : false);
	};
	React.useEffect(() => {
		//TODO: get this from the app context to avoid multiple api calls
		const fetchReflectionData = async () => {
			const data = await apiGetReflections(MY_INSIGHTS);
			const filteredData = data.filter(
				(reflection) => reflection.value && reflection.value.length > 1,
			);
			const groupedData = groupBy(filteredData, "value") ?? [];
			setValuesData(
				groupedData.sort(function (a, b) {
					return b.length - a.length;
				}),
			);
		};
		void fetchReflectionData();
		// TODO: get reflectionData
	}, [reflectionCache]);

	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.pageBase : classes.pageBaseMobile
				}
			>
				<Stack spacing="20px">
					<Typography variant="h1">My Values</Typography>
					<Paper style={styles.mainContainer}>
						{valuesData &&
							valuesData.map((valueArr) => {
								const valueName = valueArr[0].value;
								let positives = 0;
								// eslint-disable-next-line
								valueArr.map((reflection) => {
									if (
										(reflection.valueWeight === undefined &&
											reflection.moodIndex > 5) ||
										reflection.valueWeight === 1
									) {
										positives++;
									}
								});
								const stimuliArr =
									valueArr.filter(
										(reflection) =>
											!!reflection.stimulus &&
											reflection.stimulus.length >= 1,
									) ?? [];
								const average = positives / valueArr.length;
								const groupedStimuliData = groupBy(stimuliArr, "stimulus") ?? [];
								const stimuliData = groupedStimuliData.sort(function (a, b) {
									return b.length - a.length;
								});
								return (
									<Accordion
										key={valueArr[0].timestamp}
										expanded={expanded === valueName}
										onChange={handleChange(valueName)}
										style={styles.itemContainer}
									>
										<AccordionSummary>
											<div style={styles.itemSummaryTitle}>
												<SmallArrow flip={expanded === valueName} />
												<Typography style={{ textAlign: "right" }}>
													{valueName}
												</Typography>
											</div>
											<div style={styles.itemSummaryBar}>
												<div
													style={{
														...styles.itemSummaryBall,
														marginLeft: `${100 * average}%`,
													}}
												></div>
											</div>
										</AccordionSummary>
										<AccordionDetails style={styles.itemDetails}>
											<Table size="small">
												<TableHead>
													<TableRow>
														<TableCell sx={{ maxWidth: "70px" }}>
															Stimulus
														</TableCell>
														<TableCell sx={{ maxWidth: "70px" }}>
															Responses
														</TableCell>
														<TableCell>Distribution</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{stimuliData &&
														stimuliData.map((reflectionsArr) => (
															<ValueDetails
																reflections={reflectionsArr}
																key={reflectionsArr[0].stimulus}
															/>
														))}
												</TableBody>
											</Table>
										</AccordionDetails>
									</Accordion>
								);
							})}
					</Paper>
					{valuesData && valuesData.length === 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								textAlign: "center",
								paddingRight: "150px",
								paddingLeft: "150px",
								paddingTop: "30px",
							}}
						>
							<Typography variant={"body1"}>
								Data is not available because you have not practiced the
								&quot;Decode value&quot; exercise. You can find it under mindful
								resilience recommendations during your check-in.
							</Typography>
						</div>
					)}
				</Stack>
			</div>
		</ThemeProvider>
	);
};
MyValues.propTypes = {};
const ValueDetails = ({ reflections }) => {
	let stimValueList = new Map();
	for (let item of reflections) {
		if (typeof item.stimulus === "string") {
			let stimPositive = 0;
			if ((item.valueWeight === undefined && item.moodIndex > 5) || item.valueWeight === 1)
				stimPositive = 1;
			if (!stimValueList.has(item.stimulus)) {
				stimValueList.set(item.stimulus, { count: 1, positive: stimPositive });
			} else {
				stimValueList.get(item.stimulus).count++;
			}
		} else {
			for (var stimuli of item.stimulus) {
				let stimPositive = 0;
				if (
					(item.valueWeight === undefined && item.moodIndex > 5) ||
					item.valueWeight === 1
				)
					stimPositive = 1;
				if (!stimValueList.has(stimuli)) {
					stimValueList.set(stimuli, { count: 1, positive: stimPositive });
				} else {
					stimValueList.get(stimuli).count++;
					if (stimPositive == 1) {
						stimValueList.get(stimuli).positive++;
					}
				}
			}
		}
	}

	let detailsdiv = [];

	for (let stimValue of stimValueList) {
		let averagePositive = stimValue[1].positive / stimValue[1].count;
		detailsdiv.push(
			<TableRow>
				<TableCell sx={{ maxWidth: "70px" }}>
					<span style={{ textTransform: "capitalize" }}>{stimValue[0]}</span>
				</TableCell>
				<TableCell sx={{ maxWidth: "70px", paddingLeft: "40px" }}>
					{stimValue[1].count}
				</TableCell>
				<TableCell>
					<div style={styles.itemDetailsBar}>
						<div
							style={{
								...styles.itemDetailsGreenBar,
								width: `${averagePositive * 100}%`,
							}}
						>
							<span style={styles.itemDetailsBarText}>{`+ ${Math.round(
								averagePositive * 100,
							)}%`}</span>
						</div>
						<div style={{ textAlign: "center", flex: 1 }}>
							{averagePositive < 0.9 && (
								<span style={styles.itemDetailsBarText}>{`- ${Math.round(
									(1 - averagePositive) * 100,
								)}%`}</span>
							)}
						</div>
					</div>
				</TableCell>
			</TableRow>,
		);
	}
	return detailsdiv;
};
ValueDetails.propTypes = {
	reflections: PropTypes.array.isRequired,
};

const groupBy = (arr, key) => {
	return Object.values(
		arr.reduce((acc, item) => {
			acc[item[key]] = [...(acc[item[key]] || []), item];
			return acc;
		}, {}),
	);
};
groupBy.propTypes = {
	arr: PropTypes.array.isRequired,
	key: PropTypes.string.isRequired,
};

const styles = {
	mainContainer: {
		backgroundColor: "white",
		borderRadius: 15,
		overflow: "hidden",
	},
	itemContainer: {
		border: "none",
		boxShadow: "none",
		"& :before": {
			backgroundColor: "transparent !important",
		},
	},
	itemSummaryTitle: {
		width: 200,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingRight: 15,
	},
	itemSummaryBar: {
		borderRadius: 20,
		height: 40,
		width: "100%",
		background:
			"linear-gradient(90deg, rgba(251,115,115,1) 0%, rgba(255,231,100,1) 50%, rgba(138,210,126,1) 100%)",
		padding: 5,
		paddingRight: 35,
	},
	itemSummaryBall: {
		marginRight: 30,
		backgroundColor: "black",
		width: 30,
		height: 30,
		borderRadius: 15,
	},
	itemDetails: {
		paddingLeft: 20,
		paddingRight: 20,
	},
	itemDetailsBar: {
		minWidth: 60,
		height: 30,
		backgroundColor: "#fb7573",
		flexDirection: "row",
		textAlign: "center",
		display: "flex",
		borderRadius: 5,
		overflow: "hidden",
		flex: 1,
	},
	itemDetailsGreenBar: {
		backgroundColor: "#8ed37d",
		height: 30,
		textAlign: "center",
		overflow: "hidden",
	},
	itemDetailsBarText: {
		color: "white",
		lineHeight: "30px",
	},
};

export default MyValues;
