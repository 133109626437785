import { pushNotificationPublicKey } from "../index";
import { apiSendTestWebPushNotification, apiSetWebPushSubscription } from "./apiLib";
import { logger } from "./logLib";
import { QuimbyServiceWorker } from "./quimbyServiceWorkerLib";

export enum WebPushNotificationsState {
	DISABLED = "disabled",
	ENABLED = "enabled",
	ERROR = "error",
}

export interface WebPushNotificationsStatus {
	state: WebPushNotificationsState;
	message: string;
}

export class WebPushNotifications {
	#error: string | undefined;
	#pushSubscriptionState: WebPushNotificationsState;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	#subscription: PushSubscription | undefined;

	constructor() {
		this.#pushSubscriptionState = WebPushNotificationsState.DISABLED;
		this.#subscription = undefined;
	}

	getStatus() {
		const output: WebPushNotificationsStatus = {
			state: WebPushNotificationsState.ERROR,
			message: "Browser notification error. Email support at support@quimby.app for help",
		};
		switch (this.#pushSubscriptionState) {
			case WebPushNotificationsState.ENABLED:
				output.state = WebPushNotificationsState.ENABLED;
				output.message = "";
				break;
			case WebPushNotificationsState.DISABLED:
				output.state = WebPushNotificationsState.DISABLED;
				if (this.#error) {
					output.message = this.#error;
				} else {
					output.message = "Browser push notifications disabled";
				}
				break;
			default: //WebPushNotificationState.Error
				if (this.#error) {
					output.message = this.#error;
				}
				break;
		}
		return output;
	}
	async initializePushNotifications(quimbyServiceWorker: QuimbyServiceWorker) {
		const permission = Notification.permission;
		return await this.#processPermission(quimbyServiceWorker, permission);
	}

	async requestNotificationPermission(quimbyServiceWorker: QuimbyServiceWorker) {
		const permission = await Notification.requestPermission();
		const process = await this.#processPermission(quimbyServiceWorker, permission);
		return process;
	}

	async subscribeToPushNotifications(quimbyServiceWorker: QuimbyServiceWorker) {
		const registration = quimbyServiceWorker.getRegistration();
		if (registration) {
			const subscribe = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlB64ToUint8Array(pushNotificationPublicKey),
			});
			if (subscribe) {
				try {
					await apiSetWebPushSubscription(subscribe);
					this.#pushSubscriptionState = WebPushNotificationsState.ENABLED;
					this.#subscription = subscribe;
					return true;
				} catch (e) {
					logger.error(e);
					this.#pushSubscriptionState = WebPushNotificationsState.ERROR;
					this.#subscription = undefined;
					this.#error = "Failed to sync subscription with server";
					return false;
				}
			} else {
				this.#pushSubscriptionState = WebPushNotificationsState.ERROR;
				this.#error = "Failed to subscribe to browser push notifications";
				this.#subscription = undefined;
				return false;
			}
		} else {
			this.#pushSubscriptionState = WebPushNotificationsState.ERROR;
			this.#error = quimbyServiceWorker.getStatus().message;
			this.#subscription = undefined;
			return false;
		}
	}

	async sendTestNotification(userId: string) {
		try {
			await apiSendTestWebPushNotification(userId);
		} catch (e) {
			logger.error(e);
		}
	}

	async #processPermission(
		quimbyServiceWorker: QuimbyServiceWorker,
		permission: NotificationPermission,
	) {
		const registration = quimbyServiceWorker.getRegistration();

		switch (permission) {
			case "denied":
				this.#pushSubscriptionState = WebPushNotificationsState.ERROR;
				this.#error = "You need to unblock notifications to receive Quimby reminders";
				return false;
			case "granted":
				if (registration) {
					const subscription = await registration.pushManager.getSubscription();
					if (subscription) {
						this.#subscription = subscription;
						this.#pushSubscriptionState = WebPushNotificationsState.ENABLED;
						this.#error = "";
						return true;
					} else {
						return await this.subscribeToPushNotifications(quimbyServiceWorker);
					}
				}
				this.#pushSubscriptionState = WebPushNotificationsState.ERROR;
				this.#error = quimbyServiceWorker.getStatus().message;
				return false;
			default:
				return false;
		}
	}
}

const urlB64ToUint8Array = (base64String: string) => {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};
