import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
import MaterialUiAppBar from "@mui/material/AppBar";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "../../Theme";
import { logger } from "../../libs/logLib";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { findPageTitleByPath } from "../../model/pages.Model";
import { TelemetryAction } from "../../model/telemetry";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";

const AppBar: FC = () => {
	const {
		UIContext: { navBarVisible, setNavBarVisible },
	} = useTypedAppContext();
	const classes = modulesStyles();
	const location = useLocation();
	const { pathname } = location;
	const title = findPageTitleByPath(pathname);

	return (
		<ThemeProvider theme={modulesTheme}>
			{!navBarVisible && (
				<MaterialUiAppBar
					elevation={0} // removes shadow
				>
					<Toolbar className={classes.appBar}>
						<IconButton
							edge="start"
							aria-label="open drawer"
							onClick={() => {
								setNavBarVisible(true);
								logger.telemetry({
									action: TelemetryAction.click,
									event: "navbar_expand",
								});
							}}
							size="large"
						>
							<MenuIcon style={{ color: colors.white }} />
						</IconButton>
						<Typography style={{ fontSize: "x-large" }}>{title}</Typography>
					</Toolbar>
				</MaterialUiAppBar>
			)}
		</ThemeProvider>
	);
};

export default AppBar;
