import { Container, Grid, ThemeProvider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import Theme, { colors } from "../Theme";
import FilterDataControl from "../components/FilterDataControl";
import { modulesTheme } from "../components/Modules/modulesTheme";
import { emotionWordGroups } from "../components/ReflectionSlides/SharedComponents/EmotionWordList";
import QButton from "../components/common/QButton";
import { MemoizedMyInsightsLineChart } from "../components/visualize/MyInsightsLineChart";
import StimulusChart from "../components/visualize/MyInsightsStimulus";
import MyInsightsTable from "../components/visualize/MyInsightsTable";
import { MemoizedMyWordcloud } from "../components/visualize/MyInsightsWordCloud";
import { apiGetReflections, getCsvFileString } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import {
	MY_INSIGHTS,
	// channels,
	dataType,
	dateRange,
} from "../shared/constants";
import {
	getData,
	getTimeStamp,
	hasDataChanged,
	mapData,
	updateCache,
} from "../utility/dashboardUserHelpers";
const SECONDARY_COLOR = Theme.palette.words.customStimulus;

// Used to clear selection
const CLEARZONE0 = "clearzone0";
const CLEARZONE1 = "clearzone1";
const CLEARZONE2 = "clearzone2";
const CLEARZONE3 = "clearzone3";
const CLEARZONE4 = "clearzone4";
const CLEARZONEALL = "clearzoneall";

const UNSELECTED_WORD = { text: undefined };

const useStyles = makeStyles((theme) => ({
	mainUser: {
		display: "flex",
		flex: 1,
		background: colors.lightGray,
		paddingTop: "40px",
	},
	root: {
		marginTop: theme.spacing(2),
	},
	tilesArea: {
		paddingTop: theme.spacing(4),
		width: "100%",
	},
	title: {
		marginBottom: theme.spacing(1),
		pointerEvents: "none",
	},
	userControl: {
		display: "grid",
		width: "100%",
		justifyContent: "end",
	},
	groupPicker: {
		gridColumn: "1/2",
		justifySelf: "left",
	},
	filterControl: {
		gridColumn: "2/3",
		margin: "auto",
	},
	downloadLinkContainer: {
		textAlign: "center",
	},
	"@media (max-width: 600px)": {
		downloadLinkContainer: {
			marginTop: "1rem",
		},
		userControl: {
			justifyContent: "center",
		},
	},
}));

const DashboardUser = () => {
	const {
		appState: { reflectionCache, refreshReflectionData },
		appDispatch,
	} = useAppContext();

	const [selectedWord, setSelectedWord] = useState([]);
	const [filteredTableData, setSelectedTableData] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [domain, setDomain] = useState(dateRange.ONE_MONTH);

	// These two bits of state are used to help coordinate how
	// the Morale Chart and Wordcloud interact when selecting data
	const [dotClicked, setDotClicked] = useState(false);
	const [refreshSelectedWord, setRefreshSelectedWord] = useState(false);

	const [forceWordCloudRender, setForceWordCloudRender] = useState(Math.random());
	const classes = useStyles();

	const updateReflections = useCallback(
		async (forceUpdate = false) => {
			// Fetch data from AWS
			const fetchedData = await apiGetReflections(MY_INSIGHTS);

			// Check timestamp to see if data is new
			const dataHasChanged = hasDataChanged(MY_INSIGHTS, fetchedData, reflectionCache);

			// If data is new (or we're forcing an update) map data and update cache
			if (dataHasChanged || forceUpdate) {
				// Get timestamp
				const timestamp = getTimeStamp(MY_INSIGHTS, fetchedData);

				// Map data
				const mappedData = mapData(MY_INSIGHTS, fetchedData);

				// Update cache
				const updatedCache = updateCache(
					MY_INSIGHTS,
					{ timestamp: timestamp, data: mappedData },
					reflectionCache,
				);

				appDispatch({
					type: "setReflectionCache",
					reflectionCache: updatedCache,
				});

				// Used to force rerender of wordcloud
				setForceWordCloudRender(Math.random());
			}
		},
		[reflectionCache, appDispatch],
	);

	useEffect(() => {
		void updateReflections();
		appDispatch({ type: "refreshReflectionData", refreshReflectionData: false });
	}, [reflectionCache, updateReflections, refreshReflectionData]);

	const onWordClick = useCallback((word) => {
		if (word.text !== undefined) {
			logger.telemetry({
				action: "click",
				event: "my_insights_wordcloud_word",
				eventData: { value: word.text },
			});
		}
		setSelectedWord(word.text ? [word.text] : []);
		setRefreshSelectedWord(true);
	}, []);

	useEffect(() => {
		const selectDataByEmotion = (emotionList) => {
			let tableData;
			let selectedData;

			if (emotionList.length === 0) {
				tableData = getData(MY_INSIGHTS, reflectionCache, dataType.TABLE, domain);

				selectedData = getData(
					MY_INSIGHTS,
					reflectionCache,
					dataType.MOOD,
					// dateRange.MAX
					domain,
				).map((data) => {
					return { date: data.date, feeling: Number.NaN };
				});
			} else {
				const fullData = getData(MY_INSIGHTS, reflectionCache, dataType.TABLE, domain);
				tableData = fullData.filter((data) => {
					const found = emotionList.find((element) => element === data.emotion);
					return found;
				});

				const timestamps = tableData.map((data) => {
					return data.multiEmotionIndex
						? (data.timestamp - data.multiEmotionIndex).toString()
						: data.timestamp.toString();
				});

				selectedData = getData(
					MY_INSIGHTS,
					reflectionCache,
					dataType.MOOD,
					// dateRange.MAX
					domain,
				).map((data) => {
					if (timestamps.includes(data.date)) {
						return data;
					} else {
						return { date: data.date, feeling: Number.NaN };
					}
				});
			}

			setSelectedTableData(tableData);
			setSelectedData(selectedData);
		};

		if (dotClicked) {
			setDotClicked(false);
		} else {
			selectDataByEmotion(selectedWord);
		}

		setRefreshSelectedWord(false);
	}, [selectedWord, refreshSelectedWord, domain, reflectionCache]);

	useEffect(() => {
		onWordClick(UNSELECTED_WORD);
	}, [reflectionCache, domain, onWordClick]);

	const getWordColor = useCallback(
		(word) => {
			const foundWord = emotionWordGroups.find((list) =>
				list.list.find(
					(wrd) => wrd.word.toLocaleLowerCase() === word.text.toLocaleLowerCase(),
				),
			);
			if (selectedWord.length === 0) {
				if (foundWord) {
					return foundWord.baseColor;
				}
				return SECONDARY_COLOR;
			}

			const foundIndex = selectedWord.findIndex((element) => element === word.text);
			if (foundIndex >= 0) {
				if (foundWord) {
					return foundWord.baseColor;
				}
				return SECONDARY_COLOR;
			}

			return "grey";
		},
		[selectedWord, emotionWordGroups],
	);

	function handleDotClick(event) {
		if (event) {
			selectThoughtsByDate(event.activePayload[0].payload.date);
			logger.telemetry({
				action: "click",
				event: "my insights morale graph dot",
				eventData: {
					value: new Date(+event.activePayload[0].payload.date),
				},
			});
		}
	}

	function selectThoughtsByDate(timestamp) {
		const tableData = getData(MY_INSIGHTS, reflectionCache, dataType.TABLE, domain);

		const entry = tableData.find((element) => element.timestamp === timestamp);
		let selectedTableData = [];
		if (entry.multiEmotion) {
			// find mutli emotion stuff
			selectedTableData = tableData.filter(
				(element) => element.date === entry.date && element.multiEmotion === true,
			);
		} else {
			selectedTableData = [entry];
		}
		const selectedData = getData(
			MY_INSIGHTS,
			reflectionCache,
			dataType.MOOD,
			// dateRange.MAX
			domain,
		).map((data) => {
			if (data.date === timestamp) {
				return data;
			} else {
				return { date: data.date, feeling: Number.NaN };
			}
		});
		setDotClicked(true);
		setSelectedTableData(selectedTableData);
		setSelectedData(selectedData);
		setSelectedWord(
			selectedTableData.map((element) => {
				return element.emotion;
			}),
		);
	}

	function handleClick(e) {
		let id = e.target.id;
		if (id?.includes("clearzone")) {
			setSelectedWord([]);
			onWordClick(UNSELECTED_WORD);
		}
	}

	const downloadFile = async () => {
		logger.telemetry({
			action: "click",
			event: "my_insights_download_insights",
		});
		appDispatch({
			type: "setErrorText",
			toastErrorText: `Generating file...`,
		});
		const string = await getCsvFileString();
		if (!string.csvFile) {
			//if the api fails for some reason
			appDispatch({
				type: "setErrorText",
				toastErrorText: `And error has occurred`,
			});
		}
		const blob = new Blob([string.csvFile], {
			type: "text/csv;charset=utf-8;",
		});
		// eslint-disable-next-line no-undef
		let link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "Quimby Insights Report");
			link.style.visibility = "hidden";
			// eslint-disable-next-line no-undef
			document.body.appendChild(link);
			link.click();
			// eslint-disable-next-line no-undef
			document.body.removeChild(link);
		}
	};

	const {
		UIContext: { homeHidden },
	} = useTypedAppContext();

	return (
		<ThemeProvider theme={modulesTheme}>
			<div className={classes.mainUser} onClick={handleClick} id={CLEARZONE0}>
				<Container maxWidth={false} className={classes.root}>
					<Grid
						container
						justifyContent="center"
						wrap="wrap"
						alignItems="center"
						id={CLEARZONE1}
					>
						<Grid item mobile={12} tablet={8}>
							<div className={classes.userControl}>
								<div className={classes.filterControl}>
									<FilterDataControl
										domain={domain}
										setDomain={(dm) => {
											setDomain(dm);
											logger.telemetry({
												action: "click",
												event: "my_morale_date_range",
												eventData: {
													value: dm,
												},
											});
										}}
									/>
								</div>
							</div>
						</Grid>

						<Grid item mobile={12} tablet={4}>
							<div onClick={downloadFile} className={classes.downloadLinkContainer}>
								<QButton
									text="Download Insights"
									variant="text"
									onClick={handleClick}
								/>
							</div>
						</Grid>

						<Grid
							container
							spacing={6}
							justifyContent="space-evenly"
							alignItems="center"
							wrap="wrap"
							className={classes.tilesArea}
							id={CLEARZONEALL}
						>
							<Grid item mobile={12} tablet={6} id={CLEARZONE2}>
								<Typography variant="h2" className={classes.title}>
									{"My Morale"}
								</Typography>
								<MemoizedMyInsightsLineChart
									data={getData(
										MY_INSIGHTS,
										reflectionCache,
										dataType.MOOD,
										// dateRange.MAX
										domain,
									)}
									selectedData={selectedData}
									handleDotClick={handleDotClick}
								/>
							</Grid>
							<Grid item mobile={12} tablet={6} id={CLEARZONE3}>
								<Typography variant="h2" className={classes.title}>
									Emotions Word Cloud
								</Typography>
								{!homeHidden && (
									<MemoizedMyWordcloud
										words={getData(
											MY_INSIGHTS,
											reflectionCache,
											dataType.EMOTION,
											domain,
										)}
										dataSource={MY_INSIGHTS}
										onWordClick={onWordClick}
										getWordColor={getWordColor}
										key={forceWordCloudRender}
									/>
								)}
							</Grid>
							<Grid
								item
								mobile={12}
								tablet={6}
								id={CLEARZONE4}
								style={{ alignSelf: "flex-start" }}
							>
								<Typography variant="h2" className={classes.title}>
									Stimulus
								</Typography>
								<StimulusChart
									data={filteredTableData}
									key={forceWordCloudRender}
								/>
							</Grid>
							<Grid
								item
								mobile={12}
								tablet={6}
								id={CLEARZONE4}
								style={{ alignSelf: "flex-start" }}
							>
								<Typography variant="h2" className={classes.title}>
									Thoughts
								</Typography>
								<MyInsightsTable
									data={filteredTableData.filter((element) => element.thoughts)}
									key={forceWordCloudRender}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>
		</ThemeProvider>
	);
};

export default DashboardUser;
