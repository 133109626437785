import React from "react";
import Button from "@mui/material/Button";
import CreateIcon from "@mui/icons-material/Create";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "./EditButtons.styles";

export default function EditButtons({
	iconSize = "2.5rem",
	saveAction = () => {},
	cancelAction = () => {},
	showEditIcon,
	isEditMode,
	isModified,
	isLoading,
	children,
}) {
	const classes = useStyles();

	function handleSave() {
		saveAction();
	}

	function handleCancel() {
		cancelAction();
	}

	return (
		<div className={classes.root}>
			{children}
			{isEditMode ? (
				<>
					<div className={classes.loaderButtonWrapper}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.editButton}
							onClick={handleSave}
							disabled={isLoading || !isModified}
						>
							Save Changes
						</Button>
						{isLoading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={handleCancel}
						className={classes.editButton}
					>
						Cancel
					</Button>
				</>
			) : (
				showEditIcon && <CreateIcon style={{ fontSize: iconSize }} />
			)}
		</div>
	);
}
