import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import { modulesTheme } from "../Modules/modulesTheme";
import ChipInput from "../common/ChipInput";
import QChip from "../common/QChip";
import {
	EmailChip,
	parseChipString,
	removeDuplicateChips,
	validateChips,
} from "./EmailInviteControl.utils";

const ERROR_MESSAGE = "Check highlighted emails for errors.";

interface EmailInviteControlProps {
	emailChips: EmailChip[];
	setEmailChips: React.Dispatch<React.SetStateAction<EmailChip[]>>;
	error: boolean;
	setError: React.Dispatch<React.SetStateAction<boolean>>;
	info?: string;
	emails?: string[];
}
const EmailInviteControl: FC<EmailInviteControlProps> = ({
	emailChips,
	setEmailChips,
	error,
	setError,
	emails = [] as string[],
	info = "",
}) => {
	const [totalValidEmails, setTotalValidEmails] = useState(0);
	const hackRef = useRef();
	let chipKey = 0;

	useEffect(() => {
		const validEmails = emailChips.filter((chip) => chip.isValid);
		setTotalValidEmails(validEmails.length);

		// There is an error if the number of valid emails is different than the number of all emails
		setError(emailChips.length !== validEmails.length);
	}, [emailChips, setError]);

	function handleAddChip(chipString: string) {
		const parsedChips = parseChipString(chipString);
		const newChips = removeDuplicateChips(parsedChips, emailChips, emails);
		const validatedChips = validateChips(newChips);

		setEmailChips([...emailChips, ...validatedChips]);
	}

	function handleDeleteChip(_chip: string, index: number) {
		const newArray = emailChips.filter((_chip, chipIndex) => {
			return chipIndex !== index;
		});

		setEmailChips(newArray);
	}

	// Give focus to the input element when the box is clicked
	function handleChipBoxClick() {
		//@ts-expect-error not checking html element
		const theChild = hackRef.current.input.current.children[0];
		theChild?.focus();
	}

	// Commit any entered values when TAB is pressed or button is clicked
	function handleBlur() {
		//@ts-expect-error not checking html element
		const theChild = hackRef.current.input.current.children[0];
		if (theChild?.value) {
			handleAddChip(theChild.value);
		}
	}

	interface ChipRendererProps {
		value: EmailChip;
		isFocused: boolean;
		handleClick: MouseEventHandler;
		handleDelete: (event: unknown) => void;
	}

	const chipRenderer: FC<ChipRendererProps> = ({
		value,
		isFocused,
		handleClick,
		handleDelete,
	}) => {
		return (
			<Box sx={{ padding: modulesTheme.spacing(0.5) }}>
				<QChip
					key={chipKey++}
					label={value.text}
					clickable
					onClick={handleClick}
					onDelete={handleDelete}
					variant={isFocused ? "contained" : value.isValid ? "outlined" : "contained"}
					color={value.isValid ? "primary" : "secondary"}
				/>
			</Box>
		);
	};

	return (
		<Stack width="100%">
			<Box
				sx={{
					padding: modulesTheme.spacing(2),
					backgroundColor: "white",
					border: `2px solid ${modulesTheme.palette.primary.main}`,
					height: "100%",
					minHeight: "200px",
					width: "100%",
					overflow: "auto",
					cursor: "text",
					borderRadius: "4px",
					flex: "1 0 100%",
				}}
				onClick={handleChipBoxClick}
			>
				<ChipInput
					value={emailChips}
					disableUnderline={true}
					onAdd={(chip: string) => handleAddChip(chip)}
					onDelete={(chip: string, index: number) => handleDeleteChip(chip, index)}
					InputProps={{ autoFocus: true }}
					ref={hackRef}
					alwaysShowPlaceholder={true}
					fullWidthInput={true}
					fullWidth={true}
					chipRenderer={chipRenderer}
					onBlur={handleBlur}
				/>
			</Box>
			<Grid container justifyContent="space-between" width="100%" paddingTop={""}>
				<Grid item>
					<Typography sx={{ color: error ? "red" : "auto" }} variant="info1">
						{error ? ERROR_MESSAGE : info}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="info1">{`Total Invites: ${totalValidEmails}`}</Typography>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default EmailInviteControl;
