import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { ModulesActivitySummaryPage } from "../../../model/modules/modules.Model";
import MilestoneStaticList from "../Shared/MilestoneStaticList";
import ModulesNav from "../Shared/ModulesNav";

interface ActivitySummaryTemplateProps {
	page: ModulesActivitySummaryPage;
}

const ActivitySummaryTemplate: FC<ActivitySummaryTemplateProps> = ({ page }) => {
	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container spacing="20px" justifyContent="center">
				<Grid item mobile={12}>
					<Typography variant="h3">{page.title}</Typography>
				</Grid>
				{page.description && (
					<Grid item mobile={12}>
						<Typography
							variant="body"
							dangerouslySetInnerHTML={{ __html: page.description }}
						/>
					</Grid>
				)}
				{page.subtitle && (
					<Grid item mobile={12}>
						<Typography variant="h5">{page.subtitle}</Typography>
					</Grid>
				)}
				<Grid item mobile={12}>
					<MilestoneStaticList milestones={page.milestones} moduleId="" />
				</Grid>
				{page.info && (
					<Grid item mobile={12}>
						<Typography variant="info1">{page.info}</Typography>
					</Grid>
				)}
			</Grid>
		</ModulesNav>
	);
};

export default ActivitySummaryTemplate;
