import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { modulesTheme } from "../Modules/modulesTheme";

const DataDisclaimer: FC = () => {
	return (
		<Stack direction="row" alignItems="center" spacing={modulesTheme.spacing(1)}>
			<InfoOutlinedIcon color="primary" />
			<Typography variant="info1">
				We only share data in aggregates. We do not share your individual data with any
				other user in your company.
			</Typography>
		</Stack>
	);
};

export default DataDisclaimer;
