import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import QButton from "src/components/common/QButton";
import Theme from "../../../Theme";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

interface PositiveMindsetIntroSlideProps {
	page: ModulesLegacyPage;
}

const PositiveMindsetIntroSlide: FC<PositiveMindsetIntroSlideProps> = ({ page }) => {
	const { saveOnClick, goForward } = useReflectionModelContext();
	const classes = modulesStyles();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5" textAlign="center">
						Check out Quimby’s new positive mindset habits!
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography
						variant="h5"
						style={{ color: Theme.palette.primary.main, textAlign: "center" }}
					>
						Gratitude & Affirmations
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1" textAlign="center">
						Adding a regular practice of gratitude to your check-ins can increase
						well-being and emotional resilience. Affirmations can reinforce desired
						beliefs, help overcome negative thoughts and emotions, build
						self-confidence, and improve mental outlook.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Stack direction="column" spacing={2}>
						<div className={classes.modulesItemCentered}>
							<QButton
								text="Try Gratitude & Affirmations"
								onClick={() => {
									goForward();
								}}
							/>
						</div>
						<div className={classes.modulesItemCentered}>
							<QButton
								text="Save & Close"
								onClick={() => {
									saveOnClick();
								}}
								variant="text"
							/>
						</div>
					</Stack>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default PositiveMindsetIntroSlide;
