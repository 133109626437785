import React from "react";
import { BUTTON_STYLES } from "../../shared/constants";
import ActionButton from "../common/ActionButton";
import DeleteGroupDialog from "./DeleteGroupDialog";

export default function DeleteGroupButton({ deleteGroup, groupName, isDisabled }) {
	const [open, setOpen] = React.useState(false);

	function action() {
		setOpen(true);
	}

	function handleCancel() {
		setOpen(false);
	}

	function handleDelete() {
		setOpen(false);
		deleteGroup();
	}

	return (
		<>
			<ActionButton
				label="Delete Group"
				action={action}
				isDisabled={isDisabled}
				style={BUTTON_STYLES.OUTLINED}
			/>
			<DeleteGroupDialog
				open={open}
				handleCancel={handleCancel}
				handleDelete={handleDelete}
				groupName={groupName}
			/>
		</>
	);
}
