import React from "react";
import SignInTemplate from "./SignInTemplate";
import ChangePasswordConfirmComponent from "./ChangePasswordConfirmComponent";

export default function SignIn() {
	return (
		<SignInTemplate>
			<ChangePasswordConfirmComponent />
		</SignInTemplate>
	);
}
