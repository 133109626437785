import DeleteIcon from "@mui/icons-material/Delete";
import DeleteMemberDialog from "./DeleteMemberDialog";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { apiRemoveMemberFromSubscription } from "../../libs/apiLib";
import makeStyles from "@mui/styles/makeStyles";
import { useAppContext } from "../../libs/contextLib";
import { refreshSubscriptionInfo } from "../../utility/App.util";

const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
	},
	icon: {
		color: theme.palette.primary.main,
	},
}));

export default function DeleteMemberButton({ userId, name, clearHoverRow }) {
	const {
		appState: { selectedSubscriptionId },
		appDispatch,
	} = useAppContext();
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();

	function handleClick() {
		setOpen(true);
	}

	function handleCancel() {
		resetState();
	}

	async function handleDelete() {
		await apiRemoveMemberFromSubscription(selectedSubscriptionId, userId);
		resetState();
		await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
	}

	function resetState() {
		setOpen(false);
		clearHoverRow();
	}

	return (
		<>
			<Tooltip title="Remove member from subscription">
				<IconButton className={classes.button} onClick={handleClick} size="large">
					<DeleteIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			<DeleteMemberDialog
				open={open}
				handleCancel={handleCancel}
				handleDelete={handleDelete}
				name={name}
			/>
		</>
	);
}
