import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import Sense_All from "../../../../images/interventions/intervention_sense_all.png";
import Sense_Hear from "../../../../images/interventions/intervention_sense_hear.png";
import Sense_See from "../../../../images/interventions/intervention_sense_see.png";
import Sense_Touch from "../../../../images/interventions/intervention_sense_touch.png";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ClickableLink from "../../../common/ClickableLink";
import ProgressBar from "../../../common/ProgressBar";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const promptList = [
	{
		title: "Notice three things you can see...",
		body: (
			<div>
				Look around you and become aware of your environment. Try to pick out something that
				you don’t usually notice.
			</div>
		),
		image: Sense_See,
		imageAlt: "Sense see",
	},
	{
		title: "Notice two things you can feel...",
		body: (
			<div>
				Bring attention to the things that you’re currently feeling, such as the texture of
				your clothing or the smooth surface of the table you’re resting your hands on.
			</div>
		),
		image: Sense_Touch,
		imageAlt: "Sense touch",
	},
	{
		title: "Notice one thing you can hear...",
		body: (
			<div>
				Listen for and notice things in the background that you don’t normally notice. It
				could be the birds chirping outside or an appliance humming in the next room.
			</div>
		),
		image: Sense_Hear,
		imageAlt: "Sense Hear",
	},
	{
		title: "",
		body: (
			<div>
				Notice how you feel compared to the start of the mindfulness practice.
				<br />
				<br />
				Be open to any reaction you may notice.
			</div>
		),
		image: Sense_All,
		imageAlt: "Sense See, Touch, and Hear",
	},
];

interface SenseItOutInterventionSlideProps {
	page: ModulesLegacyPage;
}

const SenseItOutInterventionSlide: FC<SenseItOutInterventionSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const [step, setStep] = React.useState(0);

	const nextStep = () => {
		setStep(step + 1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt={promptList[step].imageAlt}
							src={promptList[step].image}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					{promptList[step].title === "" ? (
						""
					) : (
						<Typography variant="h5" paddingBottom="20px">
							{promptList[step].title}
						</Typography>
					)}
					<Typography variant="body1">{promptList[step].body}</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={` ${classes.modulesItemCentered} ${
							promptList.length === 1 ? classes.hidden : ""
						}`}
					>
						<ProgressBar totalSteps={promptList.length} step={step} />
					</div>
					<div className={classes.modulesItemCentered}>
						{step >= promptList.length - 1 ? (
							""
						) : (
							<ClickableLink text="Continue" onClick={nextStep} />
						)}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default SenseItOutInterventionSlide;
