import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import WebPushNotification from "src/components/webPushNotifications/WebPushNotificationsStatus";
import { ModulesNotificationSetupPage } from "src/model/modules/modules.Model";
import ModulesNav from "../Shared/ModulesNav";

interface NotificationSetupTemplateProps {
	page: ModulesNotificationSetupPage;
}

const NotificationSetupTemplate: FC<NotificationSetupTemplateProps> = ({ page }) => {
	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container width="100%" spacing="10px" justifyContent="center">
				<Grid item mobile={12}>
					<Typography variant="h5">{page.title}</Typography>
				</Grid>
				<Grid item mobile={12}>
					{page.description && (
						<Typography
							variant="body1"
							dangerouslySetInnerHTML={{ __html: page.description }}
						/>
					)}
				</Grid>
				<Grid item mobile={12} navBar={10}>
					<div style={{ paddingTop: "12px", paddingBottom: "12px" }}>
						<WebPushNotification />
					</div>
				</Grid>
				<Grid item mobile={12}>
					{page.info && (
						<Typography
							variant="body"
							dangerouslySetInnerHTML={{ __html: page.info }}
						/>
					)}
				</Grid>
				{page.info && (
					<Typography variant="body" dangerouslySetInnerHTML={{ __html: page.info }} />
				)}
			</Grid>
		</ModulesNav>
	);
};

export default NotificationSetupTemplate;
