import React, { FC } from "react";

interface SmallArrowProps {
	flip?: boolean;
}

const SmallArrow: FC<SmallArrowProps> = ({ flip = false }) => {
	return (
		<div style={{ cursor: "pointer" }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="15" height="27" viewBox="0 0 22 27">
				<path
					id="Polygon_9"
					data-name="Polygon 9"
					d="M10.943,4.167a3,3,0,0,1,5.114,0L24.2,17.431A3,3,0,0,1,21.639,22H5.361A3,3,0,0,1,2.8,17.431Z"
					transform={`translate(25,${flip ? 25 : -3}) rotate(${flip ? 180 : 90})`}
					fill="#909090"
				/>
			</svg>
		</div>
	);
};

export default SmallArrow;
