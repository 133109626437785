import { deepOrange, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { createStyles } from "@mui/styles";

export const colors = {
	teal: "#009688",
	midTeal: "#61b5aa",
	lightTeal: "#d0ebe8",
	black: "#000000",
	white: "#ffffff",
	gray: "#707070",
	midGray: "#ccc",
	lightGray: "#eeeeee",
	orange: "#f09c89",
	darkerGray: "#ababab",
	darkGray: "#333",
};
//TODO clean this up:
const Theme = createTheme({
	palette: {
		default: "black",
		defaultMid: "#757575",
		primary: {
			main: colors.teal,
		},
		words: {
			customStimulus: colors.midTeal,
		},
		secondary: {
			light: "#edc5bb",
			main: "#f09c89",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#ffffff",
		},
		canvasColor: "#ffffff",
		primary1Color: colors.teal,
		reflectionBackgroundColor: "#ffffff",
		primary2Color: deepOrange.A100,
		accent1Color: red[300],
		group: {
			main: "#596b86",
			light: "#7b94ba",
			dark: "#384354",
		},
		text: {
			light: "rgba(255, 255, 255, 0.87)",
			mid: "rgba(0, 0, 0, 0.60)",
			dark: "rgba(0, 0, 0, 0.87)",
		},

		canvasGrey: colors.gray,
		canvasWhite: "white",
		memberStatus: { pending: "#FFCF56", needsApproval: "#704e2e" },
		emailChip: {
			valid: {
				main: colors.teal,
				light: colors.teal,
				text: "white",
				button: "white",
			},
			invalid: {
				main: red[500],
				light: red[50],
				text: red[500],
				button: red[500],
			},
		},
		...colors,
	},
	props: {
		MuiTextField: {
			variant: "outlined",
			margin: "normal",
			fullWidth: true,
		},
	},
	typography: {
		fontFamily: `"Nunito", "Helvetica", "Arial", sans-serif !important`,
		h1: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		h2: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		h3: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		h4: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		h5: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		h6: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
		},
		// subtitle1: { fontSize: 23 },
		// subtitle2: { fontSize: 19 },
		body1: { fontSize: 16 },
		// body2: { fontSize: 16 }
	},
	inputBorderWidth: "1px",
});

export default Theme;

export const textStyles = {
	title: {
		color: "black",
		fontSize: 20,
		fontWeight: "bold",
	},
	subTitle: {
		color: "black",
		fontWeight: 500,
		fontSize: 20,
	},
	text: {
		color: "black",
		fontWeight: 400,
		fontSize: 14,
	},
	subText: {
		color: "black",
		fontWeight: 400,
		fontSize: 12,
	},
	checkBoxLabel: {
		color: "black",
		fontWeight: 400,
		fontSize: 16,
	},
};

/**
 * @param {import("@mui/styles").DefaultTheme} theme
 */

export const defaultStyles = (theme) =>
	createStyles({
		container: {
			display: "flex",
			flexFlow: "column nowrap",
			alignItems: "center",
			justifyContent: "center",
		},
		mainContainer: {
			backgroundColor: theme.palette.lightGray,
			width: "100%",
		},
		interventionContainer: {
			width: "100%",
			alignSelf: "center",
		},
		interventionContainerCentered: {
			display: "flex",
			justifyContent: "center",
			paddingBottom: 40,
		},
		interventionContainerRight: {
			display: "flex",
			justifyContent: "right",
			paddingBottom: 40,
		},
		interventionTitle: {
			paddingBottom: 40,
		},
		interventionTitleCentered: {
			paddingBottom: 40,
			textAlign: "center",
		},
		interventionBodyIndent1: {
			paddingRight: 50,
			paddingLeft: 50,
			paddingBottom: 40,
		},
		interventionBodyIndent2: {
			paddingRight: 100,
			paddingLeft: 100,
			paddingBottom: 40,
		},
		interventionBodyIndent3: {
			display: "flex",
			flexDirection: "row",
			paddingRight: 150,
			paddingLeft: 150,
			paddingBottom: 40,
		},
		interventionBody: {
			paddingBottom: 40,
		},
		interventionImage: {
			height: 200,
			maxWidth: "100%",
			paddingBottom: 40,
		},
		interventionBox: {
			borderColor: theme.palette.primary.main,
			borderStyle: "solid",
			borderRadius: "6px",
			borderWidth: theme.inputBorderWidth,
			display: "flex",
		},
		title: {
			margin: theme.spacing(1),
			color: theme.palette.primary.main,
			fontSize: 40,
		},
		subTitle: {
			fontWeight: 500,
			fontSize: 20,
		},
		text: {
			color: theme.palette.default,
			fontWeight: 400,
			fontSize: 14,
		},
		subText: {
			color: theme.palette.default,
			fontWeight: 400,
			fontSize: 12,
		},
		hidden: {
			visibility: "hidden",
		},
	});
