import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	warning: {
		color: theme.palette.error.main,
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(4),
	},
	warningIcon: {
		verticalAlign: "middle",
		fontSize: "3em",
		marginRight: theme.spacing(1),
	},
}));

export default function DeleteMemberDialog({ open, handleCancel, handleDelete, name }) {
	const classes = useStyles();

	return (
		<Dialog open={open} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Remove member</DialogTitle>
			<DialogContent>
				<DialogContentText component="div">
					<div>
						Are you sure you want to remove{" "}
						<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>{name}</span> from
						the subscription?
						<div className={classes.warning}>
							<ErrorOutlineIcon className={classes.warningIcon} />
							<em>
								Their insights will not be aggregated with the rest of the teams’
								insights. Once you confirm, we will send them a notification of the
								change.
							</em>
						</div>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleDelete();
						handleCancel();
					}}
					color="primary"
				>
					Remove
				</Button>
			</DialogActions>
		</Dialog>
	);
}
