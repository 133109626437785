import { TreeItem } from "@mui/x-tree-view";
import React from "react";
import { isEmptyObject } from "../../libs/utilityLib";
import { useStyles } from "./TreeItems.styles";

export default function TreeItems({ groupsTree }) {
	if (!groupsTree) {
		return null;
	}
	const classes = useStyles();

	function hasChildren(group) {
		return group.children && group.children.length;
	}

	return isEmptyObject(groupsTree[0])
		? null
		: groupsTree.map((group) => {
				return (
					<TreeItem
						key={group.SK}
						nodeId={group.SK}
						label={group.name}
						classes={{
							root: classes.root,
							label: classes.label,
						}}
					>
						{hasChildren(group) ? <TreeItems groupsTree={group.children} /> : null}
					</TreeItem>
				);
			});
}
