import { QUIMBY_AWS_PATHS } from "../shared/constants";
import React from "react";
import { getOS } from "../libs/utilityLib";
import { logger } from "../libs/logLib";
import { makeStyles } from "@mui/styles";
import { defaultStyles } from "../Theme";
import { Typography } from "@mui/material";
import QuimbyLogo from "../images/quimbyLogo512.png";

const detectedOS = getOS();

const useStyles = makeStyles((theme) => defaultStyles(theme));

export default function DownloadApp() {
	const classes = useStyles();
	const [downloaded, setDownloaded] = React.useState(false);
	React.useEffect(() => {
		try {
			void fetch(`${QUIMBY_AWS_PATHS.DOWNLOAD_ENDPOINT}/${detectedOS}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					// eslint-disable-next-line no-undef
					window.location.href = data.url;
					setTimeout(() => {
						setDownloaded(true);
					}, 4000);
				});
		} catch (e) {
			logger.log(e);
		}
	}, []);
	return (
		<div className={classes.container}>
			<Typography variant="h3" paddingBottom={4}>
				{!downloaded ? "Generating download file...." : "Thanks for downloading Quimby!"}
			</Typography>
			<img src={QuimbyLogo} alt="Quimby logo" height="250px" />
		</div>
	);
}
