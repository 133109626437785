// Solution from https://github.com/recharts/recharts/issues/275

import { addLeadingZero, amOrPm, convertTo12Hour } from "../../utility/HelperFunctions";

import PropTypes from "prop-types";
import React from "react";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";

// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways
//TODO Create a custom component, importing non public apis may lead to undesired results

function formatTooltipTimestamp(rawTime) {
	const date = new Date(+rawTime);
	const hours = convertTo12Hour(date.getHours());
	const minutes = addLeadingZero(date.getMinutes());
	const meridiem = amOrPm(date.getHours());

	return `${hours}:${minutes}${meridiem}`;
}

const MyInsightsLineChartTooltip = (props) => {
	// we don't need to check payload[0] as there's a better prop for this purpose
	if (!props.active) {
		// I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
		return null;
	}

	// Payload is null when the graph has no data
	if (props?.payload === null) {
		return null;
	}

	// mutating props directly is against react's conventions
	// so we create a new payload with the name and value fields set to what we want
	const newPayload = [
		{
			name: "Time",
			// all your data which created the tooltip is located in the .payload property
			value: formatTooltipTimestamp(props?.payload[0]?.payload?.date),
			// you can also add "unit" here if you need it
		},
		//* Commenting this out for now because I don't want Y-axis values to show
		// ...props.payload,
	];

	// we render the default, but with our overridden payload
	return <DefaultTooltipContent {...props} payload={newPayload} />;
};

export default MyInsightsLineChartTooltip;

MyInsightsLineChartTooltip.propTypes = {
	active: PropTypes.bool,
	payload: PropTypes.array,
};
