import { validateEmail } from "../../libs/formValidationLib";

export interface EmailChip {
	text: string;
	isValid: boolean;
}

// White space and commas are delimiters
export function parseChipString(chipString: string) {
	const chips = chipString
		.toLowerCase()
		.replaceAll(",", " ")
		.trim()
		.split(/\s+/)
		.filter((item) => item !== "");
	return chips;
}

export function removeDuplicateChips(
	chipArray: string[],
	existingChipArray: EmailChip[],
	existingEmails: string[],
) {
	// Remove duplicates from chipArray first
	const uniqueChips = [...new Set(chipArray)];

	// Remove any chips already present
	return uniqueChips.filter((chip) => {
		// Don't allow emails that already exist in the subscription
		if (existingEmails.includes(chip)) return false;

		const isChipNew = !existingChipArray.some((existingChip) => {
			return existingChip.text === chip;
		});

		return isChipNew;
	});
}

export function validateChips(chipArray: string[]) {
	return chipArray.map((value) => {
		return { text: value, isValid: !validateEmail(value).error };
	});
}
