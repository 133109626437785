import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import QButton from "src/components/common/QButton";
import { NavBarState } from "src/components/home/NavBar";
import { useReflectionModelContext, useTypedAppContext } from "../../../libs/typedContextLib";
import { ModulesLegacyPage } from "../../../model/modules/modules.Model";
import ClickableLink from "../../common/ClickableLink";
import TileButton from "../../common/TileButton";
import ModulesNav from "../Shared/ModulesNav";

interface InterventionSelectionSlideProps {
	page: ModulesLegacyPage;
}

const InterventionSelectionSlide: FC<InterventionSelectionSlideProps> = ({ page }) => {
	const { interventionSlideList, setIntervention } = useReflectionModelContext();
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const [showMore, setShowMore] = React.useState(false);

	const tileOnClick = (id: string) => {
		setIntervention(id);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container spacing="20px" justifyContent="center">
				<Grid item mobile={12}>
					<Typography variant="h5">Practice mindful resilience</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1">
						Even though emotions are temporary, they can have a big impact. You always
						have the option of accepting, amplifying, or changing how you feel. Build a
						more resilient mindset by practicing a skill.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="h6">We recommend:</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{navBarState === NavBarState.FULL ? (
							<QButton
								text={interventionSlideList[0].title}
								onClick={() => tileOnClick(interventionSlideList[0].id)}
								width="100%"
							/>
						) : (
							<TileButton
								text={interventionSlideList[0].title}
								onClick={() => tileOnClick(interventionSlideList[0].id)}
							></TileButton>
						)}
					</div>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="h6">Or one of these may interest you:</Typography>
				</Grid>

				{interventionSlideList.map((value, index) => {
					if (index !== 0 && interventionSlideList.length) {
						if (value.hidden || (!showMore && index > 4)) {
							return;
						}
						return (
							<Grid item mobile={12} tablet={3}>
								{navBarState === NavBarState.FULL ? (
									<QButton
										key={value.id}
										text={value.title}
										onClick={() => {
											tileOnClick(value.id);
										}}
										width="100%"
									/>
								) : (
									<TileButton
										key={value.id}
										text={value.title}
										onClick={() => {
											tileOnClick(value.id);
										}}
										small={true}
									></TileButton>
								)}
							</Grid>
						);
					}
				})}

				<Grid item mobile={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<ClickableLink
							text={showMore ? "show less" : "show more"}
							onClick={() => setShowMore(!showMore)}
						/>
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default InterventionSelectionSlide;
