// Keep this in sync with webapp
export enum TelemetryType {
	EVENT = "event",
	SESSION = "session",
}

// Keep this in sync with webapp
export enum TelemetryAction {
	click = "click", // user clicks a UI element
	hover = "hover", // user hover over UI element
	inputfield = "inputfield", // user enters information into a input field
	log = "log", // application logs something
	pageview = "pageview", // page loads
}

// Keep this in sync with webapp
export enum TelemetrySource {
	slackapp = "slackapp",
	website = "website",
	desktopapp = "desktopapp",
	webportal = "webportal",
	webapp = "webapp",
}

// Keep this in sync with webapp
export interface TelemetryEventData {
	action: TelemetryAction;
	event: string; // e.g. home.myinsights.datefilter
	eventData?: Record<string, string | boolean | number>;
}

// Keep this in sync with webapp
export interface TelemetryEvent extends TelemetryEventData {
	sessionId: string; //SK
	timestamp: string;
}

// Keep this in sync with webapp
export interface TelemetrySessionData {
	userId: string;
	source: TelemetrySource;
	version: string;
	sessionData?: Record<string, string | boolean | number>;
}

// Keep this in sync with webapp
export interface TelemetrySession extends TelemetrySessionData {
	timestamp: string;
	sessionId: string; //SK
}

export interface TelemetryLog {
	(data: TelemetryEventData | TelemetrySessionData): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTelemetryEventData(obj: any): obj is TelemetryEventData {
	return "action" in obj && "event" in obj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTelemetrySessionData(obj: any): obj is TelemetrySessionData {
	return "userId" in obj && "source" in obj && "version" in obj;
}
