import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import React from "react";
import { createRoot } from "react-dom/client";
import Theme from "./Theme";
import config from "./config";
import App from "./pages/App";

export const getAppStage = () => {
	return process.env.REACT_APP_STAGE === "local"
		? "local"
		: process.env.REACT_APP_STAGE === "dev"
			? "dev"
			: "prod";
};

const configData =
	process.env.REACT_APP_STAGE === "local"
		? config.local
		: process.env.REACT_APP_STAGE === "dev"
			? config.dev
			: config.prod;

export const base_url = configData.apiGateway.URL;
export const portalUrl = configData.portal;
export const pushNotificationPublicKey = configData.pushNotificationPublicKey;
export const appUrl = configData.appUrl;

Amplify.configure({
	Auth: {
		mandatorySignId: true,
		region: configData.cognito.REGION,
		userPoolId: configData.cognito.USER_POOL_ID,
		identityPoolId: configData.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: configData.cognito.APP_CLIENT_ID,
	},
	API: {
		endpoints: [
			{
				name: "quimby",
				endpoint: configData.apiGateway.URL,
				region: configData.apiGateway.REGION,
			},
		],
	},
});

// eslint-disable-next-line no-undef
const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={Theme}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>,
);
