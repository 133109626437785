import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	warning: {
		color: theme.palette.error.main,
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(4),
	},
	warningIcon: {
		verticalAlign: "middle",
		fontSize: "3em",
		marginRight: theme.spacing(1),
	},
}));

export default function DeleteGroupDialog({ open, handleCancel, handleDelete, groupName }) {
	const classes = useStyles();

	return (
		<Dialog open={open} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Delete group</DialogTitle>
			<DialogContent>
				<DialogContentText component="div">
					<div>
						Are you sure you want to delete{" "}
						<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>{groupName}</span>{" "}
						and all of its subgroups?
						<div className={classes.warning}>
							<ErrorOutlineIcon className={classes.warningIcon} />
							<em>
								When you delete a group, you will lose all previous history of the
								group's aggregated data.
							</em>
						</div>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button variant="contained" onClick={handleDelete} color="primary">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}
