import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { ThemeProvider, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { WebPushNotifications } from "../../libs/pushNotificationsLib";
import { QuimbyServiceWorker } from "../../libs/quimbyServiceWorkerLib";
import LinkButton from "../Modules/Shared/LinkButton";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";

const WebPushNotification: FC = () => {
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		appState,
	} = useAppContext();
	const webPushNotifications: WebPushNotifications = appState.webPushNotifications;
	const quimbyServiceWorker: QuimbyServiceWorker = appState.quimbyServiceWorker;
	const userId = appState.authenticatedUserInfo.SK;
	const classes = modulesStyles();
	const [status, setStatus] = useState(webPushNotifications.getStatus());

	const enableNotifications = async () => {
		const process =
			await webPushNotifications.requestNotificationPermission(quimbyServiceWorker);
		if (process) {
			await webPushNotifications.initializePushNotifications(quimbyServiceWorker);
		}
		setStatus(webPushNotifications.getStatus());
	};

	const testNotifications = () => {
		void webPushNotifications.sendTestNotification(userId as string);
	};

	return (
		<ThemeProvider theme={modulesTheme}>
			<div className={classes.webPushNotificationStatusBox}>
				<Typography variant="info">Browser Notification Status</Typography>
				{status.state === "enabled" && (
					<div>
						<CheckCircleOutline
							className={classes.webPushNotificationStatusEnabledIcon}
						/>
						<Typography variant="body">Enabled</Typography>
						<div>
							<LinkButton text="Send test notification" onClick={testNotifications} />
						</div>
					</div>
				)}
				{status.state === "disabled" && (
					<div>
						<ErrorOutline className={classes.webPushNotificationStatusDisabledIcon} />
						<Typography variant="body">Disabled</Typography>
						<div>
							<LinkButton
								text="Allow notifications from Quimby"
								onClick={enableNotifications}
							/>
						</div>
					</div>
				)}
				{status.state === "error" && (
					<div>
						<ErrorOutline className={classes.webPushNotificationStatusErrorIcon} />
						<Typography variant="body">Notifications are blocked</Typography>
						<Typography variant="body2">{status.message}</Typography>
						<div>
							<LinkButton
								text="Unblock notifications from this site"
								onClick={() => {
									window
										.open(
											"https://www.quimbyapp.com/browser-notification-faq",
											"_blank",
										)
										?.focus();
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</ThemeProvider>
	);
};

export default WebPushNotification;
