import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import StepAway from "../../../../images/interventions/intervention_step_away.png";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ClickableLink from "../../../common/ClickableLink";
import ProgressBar from "../../../common/ProgressBar";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const promptList = [
	{
		title: "Reward yourself",
		body: (
			<div>
				Take a moment of mindfulness to decompress. <br />
				<br /> For five minutes, don’t look at your phone or any screens. Consider a 5
				minute walk outside, or moving to a different room to stretch.
			</div>
		),
		image: StepAway,
		imageAlt: "Step Away",
	},
];

interface StepAwayInterventionSlideProps {
	page: ModulesLegacyPage;
}

const StepAwayInterventionSlide: FC<StepAwayInterventionSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const [step, setStep] = React.useState(0);

	const nextStep = () => {
		setStep(step + 1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt={promptList[step].imageAlt}
							src={promptList[step].image}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					{promptList[step].title === "" ? (
						""
					) : (
						<Typography variant="h5" paddingBottom="20px">
							{promptList[step].title}
						</Typography>
					)}
					<Typography variant="body1">{promptList[step].body}</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={` ${classes.modulesItemCentered} ${
							promptList.length === 1 ? classes.hidden : ""
						}`}
					>
						<ProgressBar totalSteps={promptList.length} step={step} />
					</div>
					<div className={classes.modulesItemCentered}>
						{step >= promptList.length - 1 ? (
							""
						) : (
							<ClickableLink text="Continue" onClick={nextStep} />
						)}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default StepAwayInterventionSlide;
