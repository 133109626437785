import DayPicker from "./DayPicker";
import PropTypes from "prop-types";
import React from "react";
import TimePicker from "./TimePicker";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		padding: "1em",
		"& > *": {
			padding: "1em",
		},
	},
}));

const ReminderEditControl = (props) => {
	const classes = useStyles();
	const { days, setDays, time, setTime } = props;

	return (
		<div className={classes.root}>
			<DayPicker days={days} setDays={setDays} />
			<TimePicker defaultTime={time} time={time} setTime={setTime} />
		</div>
	);
};

export default ReminderEditControl;
ReminderEditControl.propTypes = {
	days: PropTypes.array.isRequired,
	setDays: PropTypes.func.isRequired,
	time: PropTypes.string.isRequired,
	setTime: PropTypes.func.isRequired,
};
