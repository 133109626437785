export interface ReflectionModules {
	moraleCheckIn?: boolean;
	affirmations?: boolean;
	gratitude?: boolean;
	modules?: boolean;
	activity?: string;
	toolkit?: boolean;
}

export const isMoraleCheckInReflection = (reflectionModules: object) => {
	return (
		reflectionModules &&
		"moraleCheckIn" in reflectionModules &&
		reflectionModules.moraleCheckIn === true
	);
};

export const isModulesReflection = (reflectionModules: object) => {
	return (
		reflectionModules && "modules" in reflectionModules && reflectionModules.modules === true
	);
};

export const isModulesOrToolkitReflection = (reflectionModules: object) => {
	return (
		reflectionModules &&
		(("modules" in reflectionModules && reflectionModules.modules === true) ||
			("toolkit" in reflectionModules && reflectionModules.toolkit === true))
	);
};

export interface EmotionStimulusCombo {
	emotion: string;
	stimulus: string;
	pleasant: boolean | undefined;
}

export interface EmotionOnly {
	emotion: string;
	pleasant: boolean | undefined;
}

export interface EmotionStimulusData {
	emotion: string;
	shareStimuli: boolean;
	stimuli: Array<{ inputValue: string; title: string; new: boolean }>;
}

export interface StimuliData {
	inputValue: string;
	title: string;
}

export interface AffirmationData {
	prompt: string;
	text: string;
}

export interface ModulesReminderConfigData {
	module: string;
	config: { all?: true; time?: string; days?: boolean[] };
}

export interface EmotionWordGroup {
	list: { word: string; bgOpacity: number; pleasant: boolean }[];
	baseColor: string;
}
