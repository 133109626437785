import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.primary.main,
		fontSize: 18,
		pointerEvents: "auto",
		cursor: "pointer",
		textAlign: "center",
		padding: 2,
	},
	gray: {
		color: theme.palette.darkerGray,
	},
	underline: {
		textDecoration: "underline",
	},
	bold: {
		fontWeight: "bold",
	},
	textAlign: {
		float: "right",
	},
}));

export default function ClickableLink({
	gray,
	text,
	onClick,
	toggle,
	altText,
	underline = false,
	bold = false,
	textAlign,
}) {
	const classes = useStyles();
	const [toggleState, setToggleState] = useState(false);
	return (
		<span
			className={`${classes.link} ${gray && classes.gray} ${underline && classes.underline} ${
				bold && classes.bold
			} ${textAlign === "right" && classes.textAlign}`}
			onClick={() => {
				setToggleState(!toggleState);
				onClick();
			}}
		>
			{toggleState && toggle ? altText : text}
		</span>
	);
}
ClickableLink.propTypes = {
	gray: PropTypes.bool,
	text: PropTypes.string.isRequired,
	toggle: PropTypes.bool,
	altText: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	underline: PropTypes.bool,
	bold: PropTypes.bool,
	textAlign: PropTypes.string,
};
