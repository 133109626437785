export function createDevSubscriptionFlatGroupTree(allTheGroupInfos) {
	const groupInfosICareAboutObject = allTheGroupInfos.reduce((result, item) => {
		result[item.SK] = item;
		return result;
	}, {});

	const groupTreeICareAbout = createGroupTree(groupInfosICareAboutObject);
	let flatTree = flattenTree(groupTreeICareAbout);
	return flatTree;
}

// Main function used to generate a flatGroupTree
export function createFlatGroupTreeNEW(
	memberGroups,
	managedGroups,
	allTheGroupInfos,
	entireGroupTree,
) {
	const groupIdsICareAbout = getGroupIdsICareAbout(memberGroups, managedGroups, entireGroupTree);

	const groupInfosICareAbout = allTheGroupInfos.filter((item) =>
		groupIdsICareAbout.has(item.SK ?? "NOSK"),
	);

	const groupInfosICareAboutObject = groupInfosICareAbout.reduce((result, item) => {
		result[item.SK ?? "NOSK"] = item;
		return result;
	}, {});

	const groupTreeICareAbout = createGroupTree(groupInfosICareAboutObject);

	let flatTree = flattenTree(groupTreeICareAbout);

	return flatTree;
}

function getGroupIdsICareAbout(memberGroups, managedGroups, entireGroupTree) {
	const groupIdsICareAbout = new Set();
	memberGroups.forEach((group) => {
		const groupId = group.SK;
		let path = [];
		getRootToNodePath(entireGroupTree[0], groupId, path);
		path.forEach((item) => groupIdsICareAbout.add(item));
	});
	const manGroups = managedGroups ?? [];
	manGroups.forEach((groupId) => {
		let path = [];
		getRootToNodePathAndDescendents(entireGroupTree[0], groupId.SK, path);
		path.forEach((item) => groupIdsICareAbout.add(item));
	});

	return groupIdsICareAbout;
}

// Finds the path from root to the target node
export function getRootToNodePath(node, groupId, path = []) {
	path.push(stuffICareAbout(node));

	if (!!node.SK && node.SK === groupId) {
		return true;
	}

	if (node.children) {
		let result = false;

		for (const child of node.children) {
			result |= getRootToNodePath(child, groupId, path);
		}

		if (!result) path.pop();

		return result;
	}

	path.pop();
	return false;
}

// Used to generate groupList
// Finds the path from root to the target node and
// then all the descendents of the target node
function getRootToNodePathAndDescendents(node, groupId, path = []) {
	path.push(stuffICareAbout(node));

	if (node.SK && node.SK === groupId) {
		getDescendentNodes(node, path, true);
		return true;
	}

	if (node.children) {
		let result = false;

		for (const child of node.children) {
			// eslint-disable-next-line no-unused-vars
			result |= getRootToNodePathAndDescendents(child, groupId, path);
		}

		if (!result) path.pop();

		return result;
	}

	path.pop();
	return false;
}

function getDescendentNodes(node, descendents = [], firstCall = false) {
	if (!firstCall) {
		descendents.push(stuffICareAbout(node));
	}

	if (node.children) {
		for (const child of node.children) {
			getDescendentNodes(child, descendents);
		}
	}

	return descendents;
}

function stuffICareAbout(node) {
	return node.SK ?? false;
}

// Returns an object tree representation of the groupsList
function createGroupTree(gtList) {
	// Cheap deep copy
	const groupTreeList = JSON.parse(JSON.stringify(gtList));
	const idMapping = Object.keys(groupTreeList).reduce((acc, groupId, i) => {
		acc[groupId] = i;
		return acc;
	}, {});

	const keys = Object.keys(groupTreeList);

	// this allows for multiple roots
	let roots = {};
	keys.forEach((id) => {
		let el = groupTreeList[id];

		// Handle the root element
		if (el.parentGroupId === "") {
			roots[id] = el;
			return;
		}

		// Use our mapping to locate the parent element in our data array
		let parentGroupIdKey = keys[idMapping[el.parentGroupId]];
		const parentEl = groupTreeList[parentGroupIdKey];

		// Add our current el to its parent's `children` array
		parentEl.children = [...(parentEl.children || []), el];
	});

	return roots;
}

// Returns an array of objects that is the flattened version of tree
function flattenTree(tree) {
	let result = [];

	Object.keys(tree).forEach((rootId) => {
		result = [...result, ...flattenTreeToArray(tree[rootId])];
	});

	return result;
}

function flattenTreeToArray(node, level = 0, result = []) {
	result.push({
		groupId: node.SK ?? "NOSK",
		name: node.name,
		level: level,
		isGOG: !!node.SK && node.SK.startsWith("GG:"),
	});

	level++;

	if (node["children"]) {
		for (const child of node.children) {
			flattenTreeToArray(child, level, result);
		}
	}

	return result;
}
