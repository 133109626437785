import React, { FC } from "react";
import SignInComponent from "../components/signIn/SignInComponent";
import SignInTemplate from "../components/signIn/SignInTemplate";

const SignIn: FC = () => {
	return (
		<SignInTemplate>
			<SignInComponent />
		</SignInTemplate>
	);
};

export default SignIn;
