import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DialogTitle from "@mui/material/DialogTitle";
import EmailInviteControlMembers from "../email/EmailInviteControlMembers";
import GroupsTreeControl from "../group/GroupsTreeControl";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Dialog } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginLeft: 0,
		marginRight: 0,
	},
	button: {
		textTransform: "none",
	},
}));

export default function AddMembersDialog({
	open,
	handleCancel,
	handleCreate,
	emails,
	error,
	setError,
	groupsTree,
	selectedGroupId,
	availableSeats,
}) {
	const [emailChips, setEmailChips] = useState([]);
	const [localSelectedGroupId, setLocalSelectedGroupId] = useState(selectedGroupId);
	const [addToGroup, setAddToGroup] = useState(false);
	const classes = useStyles();

	function handleSwitchChange(e) {
		setAddToGroup(e.target.checked);
	}

	function enteringHandler() {
		setAddToGroup(false);
		setLocalSelectedGroupId(selectedGroupId);
	}

	function exitHandler() {
		setEmailChips([]);
	}

	return (
		<Dialog
			fullWidth={true}
			open={open}
			TransitionProps={{
				onEntering: enteringHandler,
				onExit: exitHandler,
			}}
		>
			<DialogTitle>Add members</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Add the email addresses for team members you would like to invite to Quimby.
					Press Enter to separate different emails.
				</DialogContentText>
				<EmailInviteControlMembers
					emailChips={emailChips}
					setEmailChips={setEmailChips}
					error={error}
					setError={setError}
					emails={emails}
					availableSeats={availableSeats}
				/>
				<FormControlLabel
					className={classes.root}
					control={
						<Switch
							checked={addToGroup}
							onChange={handleSwitchChange}
							color="primary"
						/>
					}
					label="Assign to a group"
					labelPlacement="start"
				/>
				{addToGroup && (
					<div>
						<DialogContentText style={{ marginTop: "24px" }}>
							Select a group:
						</DialogContentText>
						<GroupsTreeControl
							groupsTree={groupsTree}
							selectedGroupId={localSelectedGroupId}
							setSelectedGroupId={setLocalSelectedGroupId}
							disableToggle={true}
						/>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button className={classes.button} onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button
					className={classes.button}
					variant="contained"
					disabled={error.error || emailChips.length === 0}
					onClick={() =>
						handleCreate(emailChips, addToGroup ? localSelectedGroupId : null)
					}
					color="primary"
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
