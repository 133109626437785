import React from "react";
import EmotionStimulusSlide from "../../components/Modules/LegacySlides/EmotionStimulusSlide";
import ModulesLastSlide from "../../components/Modules/LegacySlides/ModulesLastSlide";
import MoraleSlide from "../../components/Modules/LegacySlides/MoraleSlide";
import ThoughtsSlide from "../../components/Modules/LegacySlides/ThoughtsSlide";
import ActivitySummaryTemplate from "../../components/Modules/ModuleTemplates/ActivitySummaryTemplate";
import ExerciseTemplate from "../../components/Modules/ModuleTemplates/ExerciseTemplate";
import InputTemplate from "../../components/Modules/ModuleTemplates/InputTemplate";
import NotificationSetupTemplate from "../../components/Modules/ModuleTemplates/NotificationSetupTemplate";
import ReminderSetupTemplate from "../../components/Modules/ModuleTemplates/ReminderSetupTemplate";
import StaticContentTemplate from "../../components/Modules/ModuleTemplates/StaticContentTemplate";
import {
	apiCreateModulesData,
	apiGetModulesCheckInPages,
	apiGetModulesPageData,
	apiGetToolkitPages,
	apiUpdateModulesUserConfigStep,
	apiUpdateModulesUserConfigStepBySession,
} from "../../libs/apiLib";
import { ModulesData, ModulesPageData } from "../modulesData/modulesData.Model";
import { ReflectionSlide } from "../reflection/reflectionSlides.Model";
import {
	ModulesLegacyPageType,
	isModulesActivitySummaryPage,
	isModulesExercisePage,
	isModulesInputRadioPage,
	isModulesInputSurveyPage,
	isModulesInputTextPage,
	isModulesLegacyPage,
	isModulesNotificationSetupPage,
	isModulesReminderSetupPage,
} from "./modules.Model";

export const getModuleSlides = async (reflectionActivity: string, isToolkit = false) => {
	let pages;
	if (!isToolkit) {
		pages = await apiGetModulesCheckInPages(reflectionActivity);
	} else {
		pages = await apiGetToolkitPages(reflectionActivity);
	}
	const slides = pages.map((page): ReflectionSlide => {
		let slide: JSX.Element = <></>;
		const hideBackArrow = true;
		const hideNextArrow = true;
		let isLegacy = false;
		if (isModulesActivitySummaryPage(page)) {
			slide = <ActivitySummaryTemplate page={page} />;
		} else if (
			isModulesInputTextPage(page) ||
			isModulesInputRadioPage(page) ||
			isModulesInputSurveyPage(page)
		) {
			slide = <InputTemplate page={page} />;
		} else if (isModulesReminderSetupPage(page)) {
			slide = <ReminderSetupTemplate page={page} />;
		} else if (isModulesExercisePage(page)) {
			slide = <ExerciseTemplate page={page} />;
		} else if (isModulesNotificationSetupPage(page)) {
			slide = <NotificationSetupTemplate page={page} />;
		} else if (isModulesLegacyPage(page)) {
			isLegacy = true;
			switch (page.legacyPageType) {
				case ModulesLegacyPageType.MORALE:
					slide = <MoraleSlide page={page} />;
					break;
				case ModulesLegacyPageType.EMOTION_STIMULUS:
					slide = <EmotionStimulusSlide page={page} />;
					break;
				case ModulesLegacyPageType.JOURNAL:
					slide = <ThoughtsSlide page={page} />;
					break;
				case ModulesLegacyPageType.LAST_SLIDE:
					slide = <ModulesLastSlide page={page} />;
					break;
			}
		} else {
			//default is Static Content Template
			slide = <StaticContentTemplate page={page} />;
		}

		const slideObject: ReflectionSlide = {
			slide: () => slide,
			id: page.id,
			title: page.pageTitle,
			hideBackArrow: hideBackArrow,
			hideNextArrow: hideNextArrow,
			isLegacy: isLegacy,
		};
		return slideObject;
	});
	return slides;
};

export const saveModulesData = async (
	modulesData: ModulesData,
	activity: string | undefined,
	increment: boolean,
) => {
	if (modulesData.getData().length) {
		try {
			await apiCreateModulesData(modulesData.getData());
		} catch (e) {
			console.error("Error saving Module Data:", e);
		}
	}
	try {
		if (activity) {
			if (increment) {
				await apiUpdateModulesUserConfigStepBySession(activity);
			}
		} else {
			await apiUpdateModulesUserConfigStep();
		}
	} catch (e) {
		console.error("Error updating user config step:", e);
	}
};

export const getModulesPageData = async (arrId: Array<string>): Promise<ModulesPageData[]> => {
	const modulesPageData = await Promise.all(
		arrId.map(async (id) => {
			const y = await apiGetModulesPageData(id);
			return y[0];
		}),
	);
	const filteredModulesPageData = modulesPageData.filter((data) => data !== undefined);
	return filteredModulesPageData;
};
