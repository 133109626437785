import { GROUP_ID_PREFIX, MEMBER_STATUS } from "../../shared/constants";

// Returns an object tree representation of the groupsList
export function createGroupTree(gtList) {
	// Cheap deep copy
	const groupTreeList = JSON.parse(JSON.stringify(gtList));
	const idMapping = Object.keys(groupTreeList).reduce((acc, groupId, i) => {
		acc[groupId] = i;
		return acc;
	}, {});

	const keys = Object.keys(groupTreeList);

	// Only allod one root for now. This is different than what's in the app.
	let root = {};
	keys.forEach((id) => {
		let el = groupTreeList[id];

		// Handle the root element
		if (el.parentGroupId === "") {
			root = el;
			return;
		}

		// Use our mapping to locate the parent element in our data array
		let parentGroupIdKey = keys[idMapping[el.parentGroupId]];
		const parentEl = groupTreeList[parentGroupIdKey];

		// Add our current el to its parent's `children` array
		parentEl.children = [...(parentEl.children || []), el];
	});

	return [root];
}

export function findNode(node, target) {
	if (node.SK === target) {
		return node;
	}

	if (node.children) {
		for (const child of node.children) {
			// eslint-disable-next-line no-unused-vars
			const result = findNode(child, target);

			if (result) {
				return result;
			}
		}
	}
}

export function getDescendentIds(node, descendents = []) {
	if (!node) {
		return descendents;
	}
	descendents.push(node.SK);

	if (node.children) {
		for (const child of node.children) {
			// eslint-disable-next-line no-unused-vars
			getDescendentIds(child, descendents);
		}
	}

	return descendents;
}

export function isGroup(id) {
	if (!id) return false;
	return id.startsWith(GROUP_ID_PREFIX);
}

// It is HERE that we create the full groupInfo object used by GroupDetails
export function generateSelectedGroupInfo(selectedGroupId, groups, subscriptionMembers) {
	if (selectedGroupId === "" || !groups) {
		return {
			id: "",
			name: "",
			managers: [],
			members: [],
			// The Root Group has no parent is not deletable
			isDeletable: false,
		};
	}

	const group = groups[selectedGroupId];
	if (!group) {
		return {
			id: "",
			name: "",
			managers: [],
			members: [],
			// The Root Group has no parent is not deletable
			isDeletable: false,
		};
	}
	let groupInfo = {
		id: group?.SK ?? undefined,
		name: group.name,
		managers: [],
		members: [],
		parentGroupId: group.parentGroupId,
		// The Root Group has no parent is not deletable
		isDeletable: group.parentGroupId !== "",
	};

	group.managers.forEach((id) => {
		groupInfo.managers.push(subscriptionMembers[id]);
	});

	// this sorting works because alphabetically G: is before U:
	group.members
		.sort((a, b) => {
			if (b > a) return 1;

			if (b < a) return -1;

			return 0;
		})
		.forEach((id) => {
			let info = isGroup(id)
				? {
						SK: id,
						name: groups[id].name,
						groupManagers: getGroupManagerNames(groups[id], subscriptionMembers),
						groupMembers: getGroupMemberNames(groups[id], groups, subscriptionMembers),
					}
				: subscriptionMembers[id];
			groupInfo.members.push(info);
		});

	return groupInfo;
}

function getGroupMemberNames(group, groups, subscriptionMembers) {
	// First sort managers and members into separate arrays.
	// Then alphabetize each one and combine back into single array
	const subscriptionMembersArr = Object.values(subscriptionMembers);
	return group.members //this is an array of strings
		.reduce(
			(result, id) => {
				isGroup(id) //just check if the id start with a D instead of an U
					? result[0].push(`[${groups[id]?.name ?? "Group"}]`)
					: result[1].push(formatUserString(subscriptionMembersArr, id));
				return result;
			},
			[[], []],
		)
		.reduce((finalResult, array) => {
			return [
				...finalResult,
				...array.sort((a, b) => {
					return a.toLowerCase() <= b.toLowerCase() ? -1 : 1;
				}),
			];
		}, []);
}

//takes and array of users and the user id, a string and returns a string
export const formatUserString = (arr, id) => {
	if (!id) return "-";
	let member = arr.find((member) => {
		member.SK === id;
	});
	if (!member) {
		member = arr.find((member) => {
			return id.toLowerCase().includes(member.email.toLowerCase());
		});
	}
	if (member) {
		if (!!member.status && member.status === MEMBER_STATUS.MEMBER) {
			return `${member.firstName ?? "-"} ${member.lastName ?? "-"}`;
		} else {
			return member.email ?? "@";
		}
	}
	return id;
};

// TODO: Need to make this not break
function getGroupManagerNames(group, subscriptionMembers) {
	const subscriptionMembersArr = Object.values(subscriptionMembers);

	return group.managers.map((id) => {
		return formatUserString(subscriptionMembersArr, id);
	});
}

export function getCompanyIdFromWorkspaceId(workspaceId) {
	let tokens = workspaceId.split(":");
	return tokens.length === 3 ? tokens[1] : "";
}

export function parentNodeCandidateList(node, prunedNodeId, result = []) {
	if (node.SK === prunedNodeId) return [];

	result.push({ id: node.SK, name: node.name });

	node.children?.forEach((child) => {
		parentNodeCandidateList(child, prunedNodeId, result);
	});
	return result;
}

// TODO: A bit of a hack until I finalize the shape of this structure
export function mapArrayToObjectHACK(array) {
	return array.reduce((result, item) => {
		result[item.SK] = item;
		return result;
	}, {});
}
