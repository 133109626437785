import React from "react";
import TableCell from "@mui/material/TableCell";
import RolePicker from "./RolePicker";
import { MEMBER_ROLES } from "../../shared/constants";

export default function PeopleTableCellRoles({ row, editRowId, roleChangeHandler, mappedRoleId }) {
	// TODO: HACK UNTIL I FIX THIS ON THE BACKEND

	return (
		<TableCell align="left">
			{editRowId === row.userId ? (
				<RolePicker roleId={mappedRoleId} changeHandler={roleChangeHandler} />
			) : (
				MEMBER_ROLES[mappedRoleId].label
			)}
		</TableCell>
	);
}
