import { ModulesData } from "@model/modulesData/modulesData.Model";
import {
	AffirmationData,
	EmotionStimulusData,
	ModulesReminderConfigData,
	StimuliData,
} from "@model/reflection/reflection.Model";
import { ReflectionSlide } from "@model/reflection/reflectionSlides.Model";
import { SetupGoal, SetupStatement } from "@model/setup.Model";
import { CognitoUser } from "amazon-cognito-identity-js";
import React, { ReactNode, createContext, useContext, useState } from "react";
import { NavBarState } from "../components/home/NavBar";

interface TypedAppContext {
	UIContext: UIContext;
	Auth: Auth;
}

interface UIContext {
	navBarVisible: boolean;
	setNavBarVisible: React.Dispatch<React.SetStateAction<boolean>>;
	navBarState: NavBarState;
	setNavBarState: React.Dispatch<React.SetStateAction<NavBarState>>;
	appWidth: number;
	setAppWidth: React.Dispatch<React.SetStateAction<number>>;
	appHeight: number;
	setAppHeight: React.Dispatch<React.SetStateAction<number>>;
	homeHidden: boolean;
	setHomeHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Auth {
	cognitoUser: CognitoUser | null;
	setCognitoUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
}

const TypedAppContext = createContext<TypedAppContext | undefined>(undefined);

export const TypedAppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [navBarVisible, setNavBarVisible] = useState<boolean>(true);
	const [navBarState, setNavBarState] = useState<NavBarState>(NavBarState.SHIFT);
	const [appHeight, setAppHeight] = useState<number>(window.innerHeight);
	const [appWidth, setAppWidth] = useState<number>(window.innerWidth);
	const [homeHidden, setHomeHidden] = useState<boolean>(false);
	const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

	const contextValue: TypedAppContext = {
		UIContext: {
			navBarVisible,
			setNavBarVisible,
			navBarState,
			setNavBarState,
			appHeight,
			setAppHeight,
			appWidth,
			setAppWidth,
			homeHidden,
			setHomeHidden,
		},
		Auth: {
			cognitoUser,
			setCognitoUser,
		},
	};

	return <TypedAppContext.Provider value={contextValue}>{children}</TypedAppContext.Provider>;
};

export const useTypedAppContext = () => {
	const context = useContext(TypedAppContext);
	if (!context) {
		throw new Error("useTypedAppContext must be used within a TypedAppContextProvider");
	}
	return context;
};

interface ReflectionModalContext {
	goForward: () => void;
	goBackward: () => void;
	modulesData: ModulesData;
	feeling: number;
	setFeeling: React.Dispatch<React.SetStateAction<number>>;
	userName: string;
	emotionStimList: EmotionStimulusData[];
	setEmotionStimList: React.Dispatch<React.SetStateAction<EmotionStimulusData[]>>;
	stimuli: StimuliData[];
	thoughts: string;
	setThoughts: React.Dispatch<React.SetStateAction<string>>;
	saveOnClick: () => void;
	reflectionsCount: number;
	affirmation: AffirmationData;
	gratitude: string;
	setGratitude: React.Dispatch<React.SetStateAction<string>>;
	setModuleIntrosComplete: (moduleKeyList: string[]) => void;
	setModuleReminders: (remindersConfig: ModulesReminderConfigData) => void;
	prompt: string;
	setPrompt: React.Dispatch<React.SetStateAction<string>>;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	weight: number | undefined;
	setWeight: React.Dispatch<React.SetStateAction<number | undefined>>;
	interventionSlideList: ReflectionSlide[];
	setIntervention: React.Dispatch<React.SetStateAction<string>>;
	//TODO Remove
	setHideNextArrow: () => void;
	saving: boolean;
}

export const ReflectionModalContext = createContext<ReflectionModalContext | undefined>(undefined);

export const useReflectionModelContext = () => {
	const context = useContext(ReflectionModalContext);
	if (!context) {
		throw new Error(
			"ReflectionModalContext must be used within a ReflectionModalContext.Provider",
		);
	}
	return context;
};

export interface SetupContext {
	setupStatements: SetupStatement[];
	setSetupStatements: React.Dispatch<React.SetStateAction<SetupStatement[]>>;
	setupGoals: SetupGoal[];
	setSetupGoals: React.Dispatch<React.SetStateAction<SetupGoal[]>>;
	setupComplete: boolean;
	setSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SetupContext = createContext<SetupContext | undefined>(undefined);

export const useSetupContext = () => {
	const context = useContext(SetupContext);
	if (!context) {
		throw new Error("SetupContext must be used within a SetupContext.Provider");
	}
	return context;
};

export interface CarouselContext {
	goForward: () => void;
}

export const CarouselContext = createContext<CarouselContext | undefined>(undefined);

export const useCarouselContext = () => {
	const context = useContext(CarouselContext);
	if (!context) {
		throw new Error("CarouselContext must be used within a CarouselContext.Provider");
	}
	return context;
};
