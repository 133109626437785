import { EDIT_RECORD_KEY, KILL_EDIT_MODE_ACTION } from "../../shared/constants";
import React, { useEffect, useState } from "react";

import AutoCompleteTextField from "./AutoCompleteTextField";
import ChipGrid from "./ChipGrid";
import EditButtons from "./EditButtons";
import LonelyIndicator from "./LonelyIndicator";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	peopleGroupRoot: {
		display: "grid",
		gridTemplateRows: "auto 1fr",
	},
	title: {
		width: "fit-content",
		color: theme.palette.primary.main,
		marginBottom: "4px",
	},
	personAreaBorder: {
		display: "grid",
		gridTemplateRows: "auto auto 1fr",

		border: "2px solid lightgray",
		background: theme.palette.lightGray,
		borderRadius: "4px",
		padding: theme.spacing(2),
	},
	personAreaHover: {
		border: `2px solid ${theme.palette.primary.main}`,
	},
	personAreaEditMode: {
		border: `2px solid ${theme.palette.primary.main}`,
		background: "rgb(0 150 136 / 20%)",
		gap: theme.spacing(1),
		paddingTop: 0,
	},
	editHover: {
		cursor: "pointer",
	},
	row: {
		flexFlow: "row wrap",
	},
	column: {
		flexFlow: "column wrap",
	},
}));

export default function PersonGroup({
	title,
	editLabel,
	people,
	setPeople,
	updateGroupEditRecord,
	lonelyMessage,
	killEditMode,
	canEditGroupsSection,
}) {
	const [showEditIcon, setShowEditIcon] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isModified, setIsModified] = useState(false);
	const [peopleCopy, setPeopleCopy] = useState([...people]);
	const [addedPeople, setAddedPeople] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (killEditMode) {
			killEditMode === KILL_EDIT_MODE_ACTION.SAVE ? void saveAction() : cancelAction();
		}
	}, [killEditMode]);

	useEffect(() => {
		updateGroupEditRecord({
			key: title === "Managers" ? EDIT_RECORD_KEY.MANAGERS : EDIT_RECORD_KEY.MEMBERS,
			isModified: isModified,
		});
	}, [isModified, peopleCopy]);

	useEffect(() => {
		setPeopleCopy([...people]);
	}, [people]);

	function handleMouseEnter() {
		if (canEditGroupsSection) setShowEditIcon(true);
	}

	function handleMouseLeave() {
		if (canEditGroupsSection) setShowEditIcon(false);
	}

	function handleClick() {
		if (canEditGroupsSection) {
			if (!isEditMode) {
				setIsEditMode(!isEditMode);
			}
		}
	}

	function resetState() {
		setAddedPeople([]);
		setIsEditMode(false);
		setIsModified(false);
	}

	async function saveAction() {
		let peopleIdArray = peopleCopy.map((person) => person?.SK ?? undefined);

		let newPeopleIdArray = addedPeople.reduce((result, person) => {
			if (!peopleIdArray.includes(person)) {
				result.push(person);
			}
			return result;
		}, []);

		let finalPeople = [...peopleIdArray, ...newPeopleIdArray];

		setIsLoading(true);

		//* This just needs IDs only
		await setPeople(finalPeople);
		resetState();

		setIsLoading(false);
	}

	function cancelAction() {
		setPeopleCopy([...people]);
		resetState();
	}

	function onChipDelete(id) {
		let changedPeople = peopleCopy.filter((person) => !!person && person.SK !== id);

		setPeopleCopy(changedPeople);
		setIsModified(true);
	}

	function onTagsChange(event, tags) {
		setAddedPeople(tags.map((tag) => tag.SK));
		setIsModified(true);
	}

	return (
		<div className={classes.peopleGroupRoot}>
			<div
				className={clsx(classes.title, showEditIcon && !isEditMode && classes.editHover)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleClick}
			>
				<EditButtons
					iconSize="1.25rem"
					saveAction={saveAction}
					cancelAction={cancelAction}
					showEditIcon={showEditIcon}
					setShowEditIcon={setShowEditIcon}
					isEditMode={isEditMode}
					setIsEditMode={setIsEditMode}
					isModified={isModified}
					isLoading={isLoading}
				>
					<Typography variant="h5">{title}</Typography>
				</EditButtons>
			</div>
			<div
				className={clsx(
					classes.personAreaBorder,
					showEditIcon && !isEditMode && [classes.editHover, classes.personAreaHover],
					isEditMode && classes.personAreaEditMode,
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleClick}
			>
				{isEditMode && (
					<AutoCompleteTextField editLabel={editLabel} onTagsChange={onTagsChange} />
				)}
				{people.length === 0 ? (
					<LonelyIndicator lonelyMessage={lonelyMessage} />
				) : (
					<ChipGrid people={peopleCopy} onDelete={onChipDelete} isEditMode={isEditMode} />
				)}
			</div>
		</div>
	);
}
