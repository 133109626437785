import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import Sensation from "../../../../images/interventions/intervention_sensation.png";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ClickableLink from "../../../common/ClickableLink";
import ProgressBar from "../../../common/ProgressBar";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const promptList = [
	{
		title: "Focus on sensations in your body",
		body: (
			<div>
				Scan from the top of your head to the tip of your toes. Observe the physical
				sensations and how the emotion manifests in your body. <br />
				<br /> Sit with it for 30 seconds.
			</div>
		),
		image: Sensation,
		imageAlt: "Sensations",
	},
];

interface SensationsInterventionSlideProps {
	page: ModulesLegacyPage;
}

const SensationsInterventionSlide: FC<SensationsInterventionSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const [step, setStep] = React.useState(0);

	const nextStep = () => {
		setStep(step + 1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt={promptList[step].imageAlt}
							src={promptList[step].image}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					{promptList[step].title === "" ? (
						""
					) : (
						<Typography variant="h5" paddingBottom="20px">
							{promptList[step].title}
						</Typography>
					)}
					<Typography variant="body1">{promptList[step].body}</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={` ${classes.modulesItemCentered} ${
							promptList.length === 1 ? classes.hidden : ""
						}`}
					>
						<ProgressBar totalSteps={promptList.length} step={step} />
					</div>
					<div className={classes.modulesItemCentered}>
						{step >= promptList.length - 1 ? (
							""
						) : (
							<ClickableLink text="Continue" onClick={nextStep} />
						)}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default SensationsInterventionSlide;
