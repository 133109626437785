import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import PassItForward from "../../../../images/interventions/intervention_pass_it_forward.png";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ClickableLink from "../../../common/ClickableLink";
import ProgressBar from "../../../common/ProgressBar";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const promptList = [
	{
		title: "Pass it forward",
		body: (
			<div>
				Engage in an activity that would benefit someone else and also bring you joy. For
				example, connecting with a friend you haven’t spoken with in a while, playing with
				your pet, giving praise to your colleague for something they’ve done well, or
				lending a helpful ear to a colleague who’s having a hard day.
			</div>
		),
		image: PassItForward,
		imageAlt: "Pass it forward",
	},
];

interface PassItForwardInterventionSlideProps {
	page: ModulesLegacyPage;
}

const PassItForwardInterventionSlide: FC<PassItForwardInterventionSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const [step, setStep] = React.useState(0);

	const nextStep = () => {
		setStep(step + 1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt={promptList[step].imageAlt}
							src={promptList[step].image}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					{promptList[step].title === "" ? (
						""
					) : (
						<Typography variant="h5" paddingBottom="20px">
							{promptList[step].title}
						</Typography>
					)}
					<Typography variant="body1">{promptList[step].body}</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={` ${classes.modulesItemCentered} ${
							promptList.length === 1 ? classes.hidden : ""
						}`}
					>
						<ProgressBar totalSteps={promptList.length} step={step} />
					</div>
					<div className={classes.modulesItemCentered}>
						{step >= promptList.length - 1 ? (
							""
						) : (
							<ClickableLink text="Continue" onClick={nextStep} />
						)}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default PassItForwardInterventionSlide;
