import { UserNotificationTargetConfig } from "@model/user/user.model";
import React, { FC, useEffect, useState } from "react";
import {
	apiGetSignInWithGoogleUrl,
	apiGetUserNotificationTargetConfig,
	apiRevokeGoogleAuth,
} from "../../libs/apiLib";
import { logger } from "../../libs/logLib";
import GoogleButton from "../common/GoogleButton";
import NotificationTargetItem from "./NotificationTargetItem";

export interface GoogleCalendarNTIProps {
	returnPath: string;
}

const GoogleCalendarNTI: FC<GoogleCalendarNTIProps> = ({ returnPath }) => {
	const [targetConfig, setTargetConfig] = useState({} as UserNotificationTargetConfig);
	const signInWithGoogle = async () => {
		try {
			const url = await apiGetSignInWithGoogleUrl(returnPath);
			if (url) {
				window.location.replace(url);
			}
		} catch (e) {
			logger.error(e);
		}
	};

	const disconnectGoogle = async () => {
		try {
			await apiRevokeGoogleAuth();
			void loadUserNotificationInfo();
		} catch (e) {
			console.log(e);
		}
	};
	const loadUserNotificationInfo = async () => {
		const config = await apiGetUserNotificationTargetConfig();
		setTargetConfig(config);
	};
	useEffect(() => {
		void loadUserNotificationInfo();
	}, []);

	return (
		<NotificationTargetItem
			enabled={targetConfig.googleCalendar}
			statusText={targetConfig.googleCalendar ? "connected: " + targetConfig.googleEmail : ""}
			notificationTargetName="Google Calendar"
			info="Push reminders to your google calendar"
		>
			{!targetConfig.googleCalendar ? (
				<GoogleButton onClick={signInWithGoogle} text="Connect to Google Calendar" />
			) : (
				<GoogleButton onClick={disconnectGoogle} text="Disconnect Google Calendar" />
			)}
		</NotificationTargetItem>
	);
};

export default GoogleCalendarNTI;
