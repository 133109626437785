import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	member: {
		background: "none",
		color: theme.palette.group.dark,
		border: `1px solid ${theme.palette.group.dark}`,
	},
	pending: {
		background: theme.palette.memberStatus.pending,
		color: theme.palette.text.dark,
		border: "none",
	},
	needsApproval: {
		background: theme.palette.memberStatus.needsApproval,
		color: theme.palette.text.light,
		border: "none",
	},
	editModeChip: {
		border: "1px solid gray",
		background: theme.palette.secondary.main,
		color: "white",
		"&:hover": {
			background: theme.palette.secondary.dark,
		},
		"& .MuiChip-deleteIcon": {
			color: "white",
			opacity: 0.6,
		},
		"& .MuiChip-deleteIcon:hover": {
			opacity: 1.0,
		},
	},
}));
