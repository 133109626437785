import { List, Typography } from "@mui/material";
import React, { FC } from "react";
import { ModulesReminderGroup } from "../../../model/modules/modules.Model";
import MilestoneReminderListItem from "./ModulesReminderListItem";
import { ModulesData } from "src/model/modulesData/modulesData.Model";

export interface MilestoneReminderListProps {
	reminderGroup: ModulesReminderGroup;
	pageId: string;
	modulesData: ModulesData;
}

const MilestoneReminderList: FC<MilestoneReminderListProps> = ({
	reminderGroup,
	modulesData,
	pageId,
}) => {
	return (
		<>
			<Typography variant="h5">{reminderGroup.groupTitle}</Typography>
			<List>
				{reminderGroup.reminders.map((reminder) => (
					<MilestoneReminderListItem
						key={reminder.id}
						modulesData={modulesData}
						pageId={pageId}
						reminderId={reminder.id}
					/>
				))}
			</List>
		</>
	);
};

export default MilestoneReminderList;
