import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	group: {
		// TODO: Option 1 - No Fll
		background: "none",
		color: theme.palette.group.dark,
		border: `1px solid ${theme.palette.group.dark}`,

		// TODO: Option 2 - Solid Fill
		// background: theme.palette.group.main,
		// color: theme.palette.text.light,

		paddingLeft: "8px",
		"& .MuiChip-deleteIcon": {
			color: "white",
			opacity: 0.6,
		},
		"& .MuiChip-deleteIcon:hover": {
			opacity: 1.0,
		},
	},
	groupIcon: {
		// TODO: Option 1 - No Fill
		color: theme.palette.group.dark,

		// TODO: Option 2 - Solid Fill
		// color: theme.palette.text.light,
	},
	clickable: {
		"&:focus": {
			background: theme.palette.group.light,
		},
		"&:hover": {
			background: theme.palette.group.light,
		},
	},
}));
