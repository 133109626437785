import React, { useState } from "react";
import { useErrorFields, useFormFields } from "../../libs/hooksLib";

import { Auth } from "aws-amplify";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LoaderButton from "./LoaderButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../libs/formValidationLib";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		color: theme.palette.primary.main,
	},
	padding: {
		marginBottom: "64px",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	link: {
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	inputField: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderWidth: `${theme.inputBorderWidth} !important`,
		},
	},
}));

export default function ForgotPasswordComponent() {
	const history = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [fields, handleFieldChange] = useFormFields({ email: "" });
	const [errors, setError] = useErrorFields({
		email: { errorText: "", error: false },
	});

	const classes = useStyles();

	function validateForm() {
		return !errors.email.error;
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);

		// AWS Cognito integration here
		try {
			await Auth.forgotPassword(fields.email);
			history("/forgot_password_verification");
		} catch (error) {
			console.log(error);
		}
	}

	function handleLinkClick(e) {
		e.preventDefault();
		history("/");
	}

	function handleFocus(e) {
		setError(e.target.id, "");
	}

	function handleBlurValidation(e) {
		const { id, value } = e.target;

		switch (id) {
			case "email": {
				setError(id, validateEmail(value));
				break;
			}
			default:
				break;
		}
	}

	function handleKeyUp(e) {
		e.preventDefault();

		// ENTER Key
		if (e.keyCode === 13) {
			e.target.blur();
		}
	}

	return (
		<Container component="main" maxWidth="sm" className={classes.container}>
			<Typography paragraph variant="h5" className={classes.title}>
				Forgot Password?
			</Typography>
			<Container maxWidth="xs">
				<Typography variant={"body1"} className={classes.padding}>
					Please enter the email address associated with your account and we&apos;ll email
					you a password reset confirmation code.
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit} noValidate>
					<div style={{ height: 100 }}>
						<TextField
							autoFocus
							variant="outlined"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							className={classes.inputField}
							type="email"
							value={fields.email}
							onChange={handleFieldChange}
							onBlur={handleBlurValidation}
							onFocus={handleFocus}
							onKeyUp={handleKeyUp}
							error={errors.email.error}
							helperText={errors.email.errorText}
						/>
					</div>
					<LoaderButton
						buttonText="Submit"
						isLoading={isLoading}
						isValidated={validateForm()}
					/>
					<Grid container>
						<Grid item xs></Grid>

						<Grid item>
							<Link
								id="signIn"
								href="#"
								variant="body2"
								className={classes.link}
								onClick={handleLinkClick}
								underline="hover"
							>
								Return to sign in
							</Link>
						</Grid>
					</Grid>
				</form>
			</Container>
		</Container>
	);
}
