import ForgotPasswordComponent from "./ForgotPasswordComponent";
import React from "react";
import SignInTemplate from "./SignInTemplate";

export default function SignIn() {
	return (
		<SignInTemplate>
			<ForgotPasswordComponent />
		</SignInTemplate>
	);
}
