import { StoreKey } from "../model/entities";
import {
	TelemetryEvent,
	TelemetryEventData,
	TelemetryLog,
	TelemetrySession,
	TelemetrySessionData,
	isTelemetryEventData,
	isTelemetrySessionData,
} from "../model/telemetry";
import { getLocalStorage, getSessionStorage } from "../utility/App.util";
import { apiPostTelemetryEvent, apiPostTelemetrySession } from "./apiLib";
/** Signature of a logging function */
interface LogFn {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
interface Logger {
	log: LogFn;
	warn: LogFn;
	error: LogFn;
	telemetry: TelemetryLog;
}

/** Log levels */
export enum LogLevel {
	log = "log",
	warn = "warn",
	error = "error",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const NO_OP: LogFn = (_message?: any, ..._optionalParams: any[]) => {};
const handleTelemetryEventPrint = async (data: TelemetryEventData | TelemetrySessionData) => {
	if (isTelemetryEventData(data)) {
		await recordTelemetryEvent(data as TelemetryEventData);
		console.info(
			"[Telemetry] ACTION: ",
			data.action,
			" EVENT: ",
			data.event,
			"EVENT DATA:",
			data.eventData,
		);
	}
	if (isTelemetrySessionData(data)) {
		await recordTelemetrySession(data as TelemetrySessionData);
		console.info(
			"[Telemetry] SOURCE: ",
			data.source,
			" VERSION: ",
			data.version,
			"SESSION DATA",
			data.sessionData,
		);
	}
};

const handleTelemetryEventSilent = async (data: TelemetryEventData | TelemetrySessionData) => {
	if (isTelemetryEventData(data)) {
		await recordTelemetryEvent(data as TelemetryEventData);
	}
	if (isTelemetrySessionData(data)) {
		await recordTelemetrySession(data as TelemetrySessionData);
	}
};

const recordTelemetryEvent = async (data: TelemetryEventData) => {
	const sessionId = getSessionStorage(StoreKey.SESSION_ID);
	const response = await apiPostTelemetryEvent({
		...data,
		sessionId: sessionId ? sessionId : "",
		timestamp: new Date().toISOString(),
	} as TelemetryEvent);
	if (response.error) {
		logger.error(response);
	}
};

const recordTelemetrySession = async (data: TelemetrySessionData) => {
	const sessionId = getSessionStorage(StoreKey.SESSION_ID);
	const response = await apiPostTelemetrySession({
		...data,
		sessionId: sessionId ? sessionId : "",
		timestamp: new Date().toISOString(),
	} as TelemetrySession);
	if (response.error) {
		logger.error(response);
	}
};

/** Logger which outputs to the browser console */
class ConsoleLogger implements Logger {
	readonly log: LogFn;
	readonly warn: LogFn;
	readonly error: LogFn;
	readonly telemetry: TelemetryLog;

	constructor(options?: { level?: LogLevel }) {
		const { level } = options || {};

		this.error = console.error.bind(console);

		if (level === "error") {
			this.warn = NO_OP;
			this.log = NO_OP;
			this.telemetry = handleTelemetryEventSilent;

			return;
		}

		this.warn = console.warn.bind(console);

		if (level === "warn") {
			this.log = NO_OP;
			this.telemetry = handleTelemetryEventSilent;

			return;
		}
		this.log = console.log.bind(console);
		this.telemetry = handleTelemetryEventPrint;
	}
}
const storedLevel = getLocalStorage("LOG_LEVEL");

const level = !!storedLevel && storedLevel in LogLevel ? (storedLevel as LogLevel) : LogLevel.error;

export const logger = new ConsoleLogger({
	level: level,
});
