import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";
import MemberTableRow from "./MemberTableRow";
import PendingTableRow from "./PendingTableRow";
import { apiUpdateUser, apiUpgradeSubscriptionRoles } from "../../libs/apiLib";
import { useAppContext } from "../../libs/contextLib";
import { MEMBER_ROLES, PEOPLE_TABLE_TYPE } from "../../shared/constants";
import { refreshSubscriptionInfo } from "../../utility/App.util";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function hackyCreateSubscriptionRolesObject(roleId) {
	if (roleId === MEMBER_ROLES.none.id) return { roles: {} };

	return { roles: { [`${roleId}`]: true } };
}

export default function PeopleTableBody({ tableType, order, orderBy, data }) {
	const {
		appDispatch,
		appState: { selectedSubscriptionId, subscriptionMembers },
	} = useAppContext();
	const [hoverRowId, setHoverRowId] = useState("");
	const [editRowId, setEditRowId] = useState("");
	const [editRowInfo, setEditRowInfo] = useState({});

	function handleMouseEnter(e) {
		if (editRowId === "") {
			setHoverRowId(e.target.parentNode.dataset.userId);
		} else {
			setHoverRowId("");
		}
	}

	function handleMouseLeave() {
		setHoverRowId("");
	}
	const companySubsId = selectedSubscriptionId;
	//roleId: "admin" | "none"
	const updateSubscription = async (roleId) => {
		const members = Object.values(subscriptionMembers);

		//Returns and array of objects: [{id:"U:...", role:"admin" | "none"}]
		const formattedMembers = members.reduce((res, memb) => {
			const memberSubs =
				memb.subscription && Array.isArray(memb.subscription)
					? memb.subscription
					: [memb.subscription];
			if (!memberSubs || memberSubs.length < 1) {
				return res;
			} else {
				const role = memberSubs.find(
					(sub) => sub.subscriptionId && sub.subscriptionId === companySubsId,
				);
				const memObj = {
					id: memb.SK,
					role: !!role && role.roles && role.roles.admin ? "admin" : "none",
				};
				return [...res, memObj];
			}
		}, []);

		//I'm not sure if this is reliable
		//let adminArrays = appState.subscription?.roles?.admins ?? [];

		//So just in case this gets the admins ids from the members array above
		let adminArrays = formattedMembers
			.filter((member) => member.role === "admin")
			.reduce((arr, itm) => [...arr, itm.id], []);

		if (roleId === "admin") {
			//adding a new admin
			adminArrays.push(editRowInfo.userId);
		} else {
			//removing an admin
			adminArrays = adminArrays.filter((admin) => admin !== editRowInfo.userId);
		}

		await apiUpgradeSubscriptionRoles(companySubsId, adminArrays);
	};

	async function saveAction(roleId) {
		// call api to UpdateMember record with modified role
		const updatedRoles = hackyCreateSubscriptionRolesObject(roleId);

		let subscriptionField = undefined;
		//if the user info's subscription field is an array:
		if (Array.isArray(editRowInfo.subscription)) {
			//get the object of the current subscription
			const currentSubs = editRowInfo.subscription.find(
				(sub) => sub.subscriptionId && sub.subscriptionId === companySubsId,
			);

			//create a new subscriptions array
			subscriptionField = [
				//spread the subscriptions array without the current one
				...editRowInfo.subscription.filter(
					(sub) => sub.subscriptionId && sub.subscriptionId !== companySubsId,
				),
				{
					//spread the current subscriptions
					...currentSubs,
					//new role
					...updatedRoles,
					//over kill overwirite the subscriptionId in case the updatedRoles object does something shady
					subscriptionId: currentSubs.subscriptionId,
				},
			];
		} else {
			//if the subscription field is just an object (should not be anymore) just update the object
			subscriptionField = {
				...subscriptionField,
				...updatedRoles,
			};
		}
		await updateSubscription(roleId);

		await apiUpdateUser(editRowInfo.PK, editRowInfo.userId, {
			subscription: subscriptionField,
		});

		setEditRowId("");
		setEditRowInfo({});
		await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
	}

	function cancelAction() {
		setEditRowId("");
		setEditRowInfo({});
	}

	return (
		<TableBody>
			{stableSort(data, getComparator(order, orderBy)).map((row, index) => {
				if (tableType === PEOPLE_TABLE_TYPE.MEMBER) {
					return (
						<MemberTableRow
							key={index}
							row={row}
							handleMouseEnter={handleMouseEnter}
							handleMouseLeave={handleMouseLeave}
							hoverRowId={hoverRowId}
							setHoverRowId={setHoverRowId}
							editRowId={editRowId}
							setEditRowId={setEditRowId}
							saveAction={saveAction}
							cancelAction={cancelAction}
							setEditRowInfo={setEditRowInfo}
						/>
					);
				}

				return (
					<PendingTableRow
						key={index}
						row={row}
						handleMouseEnter={handleMouseEnter}
						handleMouseLeave={handleMouseLeave}
						hoverRowId={hoverRowId}
						setHoverRowId={setHoverRowId}
						editRowId={editRowId}
						setEditRowId={setEditRowId}
						saveAction={saveAction}
						cancelAction={cancelAction}
						setEditRowInfo={setEditRowInfo}
					/>
				);
			})}
		</TableBody>
	);
}
