export const PENDING_TABLE_HEAD_CELLS = [
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "Email",
		width: "25%",
	},
	{
		id: "roles",
		numeric: true,
		disablePadding: false,
		label: "Roles",
		width: "25%",
	},
	{
		id: "createdAt",
		numeric: true,
		disablePadding: false,
		label: "Invitation Date",
		width: "50%",
	},
];

export const MEMBER_TABLE_HEAD_CELLS = [
	{
		id: "name",
		numeric: false,
		disablePadding: false,
		label: "Name",
		width: "25%",
	},
	{
		id: "roles",
		numeric: true,
		disablePadding: false,
		label: "Roles",
		width: "25%",
	},
	{
		id: "email",
		numeric: true,
		disablePadding: false,
		label: "Email",
		width: "50%",
	},
];
