import { Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { modulesTheme } from "src/components/Modules/modulesTheme";
import FullPageWrapper from "src/components/common/FullPageWrapper";
import QButton from "src/components/common/QButton";

const RedirectPage: FC = () => {
	const [render, setRender] = useState<boolean>(true);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const isSignUp = location.pathname.includes("signup");
		if (isSignUp) {
			navigate("/register?signUpType=individual");
		}
		const isTeamSignUp = location.pathname.includes("team-signup");
		if (isTeamSignUp) {
			navigate("/register?signUpType=team");
		}

		setRender(true);
	}, []);

	return (
		<>
			{render && (
				<FullPageWrapper>
					<Stack
						width="100%"
						alignItems="center"
						justifyContent="center"
						spacing={modulesTheme.spacing(3)}
					>
						<Typography variant="h5">Automatic Redirect</Typography>
						<Typography variant="body">
							If you haven't been automatically redirected choose from one of the
							following options
						</Typography>
						<QButton
							text="Individual sign up"
							onClick={() => {
								navigate("/register?signUpType=individual");
							}}
							variant="outlined"
						/>
						<QButton
							text="Team manager sign up"
							onClick={() => {
								navigate("/register?signUpType=team");
							}}
							variant="outlined"
						/>
						<QButton
							text="Home"
							onClick={() => {
								navigate("/");
							}}
							variant="outlined"
						/>
					</Stack>
				</FullPageWrapper>
			)}
		</>
	);
};

export default RedirectPage;
