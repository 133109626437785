import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { VIDEOS } from "../../../shared/constants";
import ModulesNav from "../Shared/ModulesNav";

interface EmotionalAwarenessIntroSlideProps {
	page: ModulesLegacyPage;
}

const EmotionalAwarenessIntroSlide: FC<EmotionalAwarenessIntroSlideProps> = ({ page }) => {
	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={5} tablet={4} desktop={4}>
					<video
						id="background-video"
						autoPlay
						loop
						muted
						style={{ maxWidth: 360, width: "100%", clipPath: "inset(0px)" }}
					>
						<source src={VIDEOS.BRAIN_WORKOUT} type="video/mp4" />
					</video>
				</Grid>
				<Grid
					item
					container
					direction="column"
					mobile={12}
					tablet={12}
					desktop={8}
					spacing="20px"
					justifyContent="center"
				>
					<Grid item padding="0px">
						<Typography variant="h5">Emotional awareness</Typography>
					</Grid>
					<Grid item padding="0px">
						<Typography variant="body1">
							Studies show that we spend 47% of our conscious hours in a passive state
							of mind wandering - an automatic process associated with feelings of
							unhappiness, stress, and boredom. Pausing and reflecting on how we are
							feeling increases our emotional awareness and helps to cultivate
							mindfulness of the moment - the antidote to mind wandering. It also
							allows us to shift from a passive, reactive state, to a more active,
							responsive state.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default EmotionalAwarenessIntroSlide;
