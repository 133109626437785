import { Grid } from "@mui/material";
import React, { useState } from "react";
import { apiUpdateReminders } from "../../libs/apiLib";
import { useAppContext } from "../../libs/contextLib";
import RoutineList from "../Routines/RoutineList";
import RemindersListSubheader from "./RemindersListSubheader";

// eslint-disable-next-line
const RemindersList = React.forwardRef((props, ref) => {
	const {
		appState: { reminders },
		appDispatch,
	} = useAppContext();
	const [editMode, setEditMode] = useState(false);
	const [deleteFlags, setDeleteFlags] = useState([]);

	function sortByTime(a, b) {
		if (a.time < b.time) {
			return -1;
		}

		if (a.time > b.time) {
			return 1;
		}

		return 0;
	}

	function addReminder(reminder) {
		let sortedReminders = [reminder, ...reminders];
		sortedReminders.sort(sortByTime);
		setAndPersistReminders(sortedReminders);
	}

	function editReminder(reminder, index) {
		let remindersCopy = [...reminders];
		remindersCopy.splice(index, 1, reminder);
		remindersCopy.sort(sortByTime);
		setAndPersistReminders(remindersCopy);
	}

	function deleteReminders() {
		let remindersCopy = [...reminders];
		// sort deleteFlags into ascending order
		let deleteFlagsSorted = [...deleteFlags];
		deleteFlagsSorted.sort();

		for (let i = deleteFlagsSorted.length - 1; i >= 0; i--) {
			remindersCopy.splice(deleteFlagsSorted[i], 1);
		}

		setAndPersistReminders(remindersCopy);

		// Clears deleteFlags when done
		setDeleteFlags([]);
	}

	function deleteReminder(reminderIndex) {
		let remindersCopy = [...reminders];
		remindersCopy.splice(reminderIndex, 1);

		// Clears deleteFlags when done
		setAndPersistReminders(remindersCopy);
	}

	function setAndPersistReminders(reminders) {
		//TODO add web based reminders
		appDispatch({ type: "setReminders", reminders });
		void apiUpdateReminders(reminders);
	}

	return (
		<>
			<RemindersListSubheader
				editMode={editMode}
				setEditMode={setEditMode}
				reminders={reminders}
				deleteFlags={deleteFlags}
				addReminder={addReminder}
				deleteReminders={deleteReminders}
			/>
			<Grid container>
				<Grid item style={{ width: "100%" }}>
					{reminders?.map((reminder, index) => {
						return (
							<RoutineList
								key={index}
								index={index}
								reminder={reminder}
								editReminder={editReminder}
								deleteReminder={deleteReminder}
							/>
						);
					})}
				</Grid>
			</Grid>
		</>
	);
});
RemindersList.displayName = "RemindersList";
export default RemindersList;
