import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";

const useStyles = makeStyles(() => ({
	root: {
		padding: "2em",
	},
}));

const Copyright: FC = () => {
	const classes = useStyles();

	return (
		<Typography variant="body2" color="textSecondary" align="center" className={classes.root}>
			{"© "}
			{new Date().getFullYear()} QuimbyApp, Inc
		</Typography>
	);
};
export default Copyright;
