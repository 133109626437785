import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	lonely: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.text.mid,
	},
}));

export default function LonelyIndicator({ lonelyMessage }) {
	const classes = useStyles();

	return (
		<div className={classes.lonely}>
			<Typography>{lonelyMessage}</Typography>
		</div>
	);
}
