import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import React, {
	ChangeEvent,
	FocusEvent,
	FormEvent,
	KeyboardEvent,
	MouseEvent,
	useEffect,
	useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAppStage } from "src";
import { setLocalStorage } from "src/utility/App.util";
import { useAppContext } from "../../libs/contextLib";
import { validateEmail, validatePassword } from "../../libs/formValidationLib";
import { useErrorFields, useFormFields } from "../../libs/hooksLib";
import { logger } from "../../libs/logLib";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { StoreKey, User } from "../../model/entities";
import { TelemetryAction } from "../../model/telemetry";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";

const useStyles = makeStyles(() => ({
	container: {
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
		maxWidth: "400px",
	},
	title: {
		color: modulesTheme.palette.primary.main,
	},
	avatar: {
		margin: modulesTheme.spacing(1),
		backgroundColor: modulesTheme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: modulesTheme.spacing(1),
	},
	smallLink: {
		justifyContent: "flex-end",
	},
	submit: {
		margin: modulesTheme.spacing(3, 0, 2),
	},
	link: {
		"&:hover": {
			color: modulesTheme.palette.primary.main,
		},
	},
	inputField: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderWidth: `1px !important`,
		},
	},
}));

export default function SignInComponent() {
	const navigate = useNavigate();
	const location = useLocation();
	const [rememberMe, setRememberMe] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [signInError, setSignInError] = useState("");
	const {
		//@ts-expect-error no types
		appState: { authenticatedUserInfo },
		//@ts-expect-error no types
		getAuthenticatedUserInfo,
	} = useAppContext();
	const {
		Auth: { cognitoUser, setCognitoUser },
	} = useTypedAppContext();
	const [fields, setFields] = useFormFields({
		email: "",
		password: "",
	});

	const [errors, setError] = useErrorFields({
		email: { errorText: "", error: false },
		password: { errorText: "", error: false },
	});

	// If already authenticated
	useEffect(() => {
		if (cognitoUser) {
			if (authenticatedUserInfo?.setupInfo?.completedSetup) {
				//If user exists and completed setup reroute
				location.pathname !== "/"
					? navigate(location.pathname)
					: navigate("/home/dashboard_user");
			} else {
				//Otherwise setup the user
				navigate("/setup");
			}
		} else {
			navigate("/");
		}
	}, []);

	function validateForm() {
		return !errors.email.error && !errors.password.error;
	}

	async function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setIsLoading(true);

		try {
			const cognitoAuthenticatedUser = await Auth.signIn(fields.email, fields.password);
			try {
				const quimbyAuthenticatedUser: User = await getAuthenticatedUserInfo();
				setLocalStorage(StoreKey.REMEMBER_ME, rememberMe);
				setCognitoUser(cognitoAuthenticatedUser);
				if (quimbyAuthenticatedUser.setupInfo.completedSetup) {
					location.pathname !== "/"
						? navigate(location.pathname)
						: navigate("/home/dashboard_user");
				} else {
					navigate("/setup");
				}
			} catch (e) {
				if (e instanceof Error) {
					if (e.message === "User account not configured") {
						logger.warn("user account not configured");
						setLocalStorage(StoreKey.REMEMBER_ME, rememberMe);
						setCognitoUser(cognitoAuthenticatedUser);
						navigate("/setup");
					} else if (e.message === "User account disabled") {
						logger.warn("User account disabled");
						setSignInError(
							"Your account has been disabled. To enable your account email support@quimby.app",
						);
					} else {
						logger.error(e);
						setSignInError(e.message);
					}
				} else {
					logger.error(e);
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				if (e.message === "User is not confirmed.") {
					navigate(`/verify?email=${fields.email}`);
					logger.warn(e.message);
				} else {
					setSignInError(e.message);
					logger.warn(e.message);
				}
			} else {
				setSignInError("unknown error");
				logger.error(e);
			}
		}
	}

	const classes = useStyles();

	function handleFieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		e.preventDefault();

		const { id, value } = e.target;

		switch (id) {
			case "email": {
				setError(id, validateEmail(value));
				break;
			}
			case "password": {
				setError(id, validatePassword(value));
				break;
			}
			default:
				break;
		}

		setFields(e);
	}

	function handleLinkClick(e: MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();
		const target = e.target as HTMLAnchorElement;
		switch (target.id) {
			case "signUp":
				navigate("/register");
				logger.telemetry({
					action: TelemetryAction.click,
					event: "signup",
				});
				break;
			case "forgotPassword":
				navigate("/forgot_password");
				logger.telemetry({
					action: TelemetryAction.click,
					event: "forgot_password",
				});
				break;
			default:
				break;
		}
	}

	function handleFocus(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
		e.preventDefault();
		const { id } = e.target;

		if (id !== "contactPermission" && id !== "termsOfUse") {
			setError(e.target.id, { error: false, errorText: "" });
		}
	}

	function handleBlurValidation(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
		e.preventDefault();

		const { id, value } = e.target;

		switch (id) {
			case "email": {
				setError(id, validateEmail(value));
				break;
			}
			case "password": {
				setError(id, validatePassword(value));
				break;
			}
			default:
				break;
		}
	}

	function handleKeyUp(e: KeyboardEvent<HTMLDivElement>) {
		e.preventDefault();

		// ENTER Key
		if (e.key === "Enter") {
			e.currentTarget.blur();
		}
	}

	function rememberMeChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		setRememberMe(e.currentTarget.checked);
	}

	return (
		<Container component="main" className={classes.container}>
			<Typography paragraph variant="h5" className={classes.title}>
				Sign In
			</Typography>
			<Typography color="error">
				{getAppStage() === "local" || getAppStage() === "dev"
					? "[" + getAppStage().toUpperCase() + "]"
					: ""}
			</Typography>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<form className={classes.form} onSubmit={handleSubmit} noValidate>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email"
					name="email"
					type="email"
					autoComplete="email"
					autoFocus
					className={classes.inputField}
					onChange={handleFieldChange}
					onBlur={handleBlurValidation}
					onFocus={handleFocus}
					onKeyUp={handleKeyUp}
					error={errors.email.error || signInError.length > 0}
					helperText={errors.email.errorText}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					className={classes.inputField}
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={handleFieldChange}
					onBlur={handleBlurValidation}
					onFocus={handleFocus}
					onKeyUp={handleKeyUp}
					error={errors.password.error || signInError.length > 0}
					helperText={errors.password.errorText}
				/>
				<Typography variant="body2" style={{ color: "red" }}>
					{signInError}
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							value="remember"
							color="primary"
							checked={rememberMe}
							onChange={rememberMeChangeHandler}
						/>
					}
					label="Remember me"
				/>
				<Grid container className={classes.smallLink}>
					<Grid item>
						<Link
							id="forgotPassword"
							href="#"
							variant="body2"
							className={classes.link}
							onClick={handleLinkClick}
							underline="hover"
						>
							Forgot password?
						</Link>
					</Grid>
				</Grid>
				<QButton
					text="Sign in"
					loading={isLoading}
					disabled={!validateForm()}
					width="100%"
					type="submit"
				/>
				<Grid container className={classes.smallLink}>
					<Grid item>
						<Link
							id="signUp"
							href="#"
							variant="body2"
							className={classes.link}
							onClick={handleLinkClick}
							underline="hover"
						>
							Don&apos;t have an account? Sign Up!
						</Link>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
}
