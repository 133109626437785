import React, { FC } from "react";
import { modulesStyles } from "../modulesStyles";
import { Info } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export interface InfoBoxProps {
	text: string;
}

const InfoBox: FC<InfoBoxProps> = ({ text }) => {
	const classes = modulesStyles();
	return (
		<div className={classes.infoBox}>
			<Stack direction="row" spacing="8px">
				<Info color="primary"></Info>
				<Typography variant="body">{text}</Typography>
			</Stack>
		</div>
	);
};

export default InfoBox;
