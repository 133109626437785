export const interventionESPairingList = [
	{ emotion: "Anxious", stimulus: "Career", interventionId: "Journal_Career" },
	{
		emotion: "Anxious",
		stimulus: "Meeting",
		interventionId: "Journal_Meeting",
	},
	{ emotion: "Anxious", stimulus: "Project", interventionId: "OppositeAction" },
	{ emotion: "Anxious", stimulus: "Workload", interventionId: "ZoomOut" },
	{
		emotion: "Anxious",
		stimulus: "To-do list",
		interventionId: "OppositeAction",
	},
	{
		emotion: "Confident",
		stimulus: "Workload",
		interventionId: "Journal_Gratitude",
	},
	{
		emotion: "Confident",
		stimulus: "Co-workers",
		interventionId: "Journal_Gratitude",
	},
	{
		emotion: "Content",
		stimulus: "Career",
		interventionId: "Journal_Gratitude",
	},
	{
		emotion: "Content",
		stimulus: "Co-worker",
		interventionId: "Journal_Gratitude",
	},
	{
		emotion: "Content",
		stimulus: "Mental health",
		interventionId: "Journal_MentalHealth",
	},
	{
		emotion: "Excited",
		stimulus: "Career",
		interventionId: "Journal_Gratitude",
	},
	{
		emotion: "Excited",
		stimulus: "Project",
		interventionId: "Journal_Share",
	},
	{
		emotion: "Excited",
		stimulus: "Recreation",
		interventionId: "DecodeValues",
	},
	{
		emotion: "Excited",
		stimulus: "Co-workers",
		interventionId: "Journal_Gratitude",
	},
	{ emotion: "Happy", stimulus: "Family", interventionId: "Journal_Gratitude" },
	{ emotion: "Happy", stimulus: "Career", interventionId: "Journal_Reward" },
	{ emotion: "Happy", stimulus: "Home", interventionId: "Journal_Gratitude" },
	{ emotion: "Happy", stimulus: "Friends", interventionId: "Journal_Share" },
	{ emotion: "Happy", stimulus: "Children", interventionId: "Sensations" },
	{ emotion: "Happy", stimulus: "Co-workers", interventionId: "Journal_Share" },
	{
		emotion: "Optimistic",
		stimulus: "Workload",
		interventionId: "Journal_Reward",
	},

	{ emotion: "Overwhelmed", stimulus: "Project", interventionId: "ZoomOut" },
	{ emotion: "Overwhelmed", stimulus: "Workload", interventionId: "ZoomOut" },
	{ emotion: "Overwhelmed", stimulus: "To-do list", interventionId: "ZoomOut" },
	{ emotion: "Stress", stimulus: "Career", interventionId: "Journal_Share" },
	{ emotion: "Stress", stimulus: "Project", interventionId: "Breathe" },
	{
		emotion: "Stress",
		stimulus: "To-do list",
		interventionId: "OppositeAction",
	},
	{
		emotion: "Stress",
		stimulus: "Workload",
		interventionId: "Journal_SupportingOthers",
	},
	{ emotion: "Tired", stimulus: "Career", interventionId: "SenseItOut" },
	{
		emotion: "Tired",
		stimulus: "Physical health",
		interventionId: "SenseItOut",
	},
	{
		emotion: "Tired",
		stimulus: "To-do list",
		interventionId: "OppositeAction",
	},
	{ emotion: "Tired", stimulus: "Workload", interventionId: "Breathe" },
];
//{ emotion: "", stimulus: "", interventionId: "" },

export const interventionEPairingList = [
	{ emotion: "Anxious", interventionId: "Breathe" },
	{ emotion: "Confident", interventionId: "Sensations" },
	{ emotion: "Content", interventionId: "Sensations" },
	{ emotion: "Energetic", interventionId: "Sensations" },
	{ emotion: "Excited", interventionId: "Journal_Reward" },
	{ emotion: "Happy", interventionId: "Journal_Gratitude" },
	{ emotion: "Hopeful", interventionId: "Journal_Share" },
	{ emotion: "Inspired", interventionId: "Journal_Share" },
	{ emotion: "Optimistic", interventionId: "Journal_Share" },
	{ emotion: "Overwhelmed", interventionId: "Breathe" },
	{ emotion: "Stress", interventionId: "SenseItOut" },
	{ emotion: "Tired", interventionId: "StepAway" },
];
//{ emotion: "", interventionId: "" },
