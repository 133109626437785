import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import React from "react";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useAppContext } from "../../libs/contextLib";

const useStyles = makeStyles((theme) => ({
	chipRoot: {
		border: "1px solid gray",
		background: theme.palette.text.light,
		color: theme.palette.text.mid,
		"&:hover": {
			background: "#eeeeee",
			color: theme.palette.text.dark,
		},
		"& .MuiChip-deleteIcon": {
			color: theme.palette.secondary.main,
		},
		"& .MuiChip-deleteIcon:hover": {
			color: theme.palette.secondary.dark,
		},
	},
	bob: {
		"&.MuiAutocomplete-inputRoot[class*='MuiInput-root']": {
			paddingTop: "4px",
			paddingBottom: "8px",
		},
	},
}));

export default function AutoCompleteTextField({ editLabel, onTagsChange }) {
	const {
		appState: { subscriptionMembers },
	} = useAppContext();
	const classes = useStyles();
	function getName(option) {
		let returnValue;
		// eslint-disable-next-line no-extra-boolean-cast
		if (!!option.firstName) {
			returnValue = `${option.firstName} ${option.lastName}`;
		}
		// eslint-disable-next-line no-extra-boolean-cast
		else if (!!option.email) {
			returnValue = option.email;
		} else {
			returnValue = option.key;
		}
		return returnValue ? returnValue : "User";
	}

	return (
		<Autocomplete
			classes={{ inputRoot: classes.bob }}
			onChange={onTagsChange}
			multiple
			disablePortal
			id="tags-filled"
			options={Object.values(subscriptionMembers)}
			renderOption={(props, option, { inputValue }) => {
				const word = getName(option);
				const matches = match(word, inputValue);
				const parts = parse(word, matches);
				return (
					<li {...props}>
						<div>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400,
									}}
								>
									{part.text}
								</span>
							))}
						</div>
					</li>
				);
			}}
			getOptionLabel={(option) => getName(option)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						key={index}
						variant="outlined"
						label={getName(option)}
						classes={{
							root: classes.chipRoot,
						}}
						{...getTagProps({ index })}
					/>
				))
			}
			renderInput={(params) => <TextField {...params} variant="standard" label={editLabel} />}
		/>
	);
}
