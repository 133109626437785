import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddMembersButton from "./AddMembersButton";

const useStyles = makeStyles({
	root: {
		display: "flex",
		justifyContent: "space-between",
		textTransform: "none",
	},
	tab: {
		textTransform: "none",
	},
});

export default function MembersTabs({ members, pending, emails, activeTabId, onChange }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Tabs value={activeTabId} onChange={onChange}>
				<Tab className={classes.tab} label={`Members (${members.length})`} />
				<Tab className={classes.tab} label={`Pending (${pending.length})`} />
			</Tabs>
			<AddMembersButton emails={emails} />
		</div>
	);
}
