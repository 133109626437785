import React from "react";
import { BUTTON_STYLES } from "../../shared/constants";
import ActionButton from "../common/ActionButton";
import CreateGroupDialog from "./CreateGroupDialog";

export default function CreateGroupButton({
	selectedGroupId,
	groupsTree,
	createNewGroup,
	canEditGroupsSection,
}) {
	const [open, setOpen] = React.useState(false);

	function action() {
		setOpen(true);
	}

	function handleCancel() {
		setOpen(false);
	}

	function handleCreate(groupName, parentGroupId) {
		setOpen(false);
		createNewGroup(groupName, parentGroupId);
	}

	return (
		<>
			<ActionButton
				label="Create Group"
				action={action}
				style={BUTTON_STYLES.SOLID}
				isDisabled={!canEditGroupsSection}
			/>
			<CreateGroupDialog
				open={open}
				handleCancel={handleCancel}
				handleCreate={handleCreate}
				groupsTree={groupsTree}
				selectedGroupId={selectedGroupId}
			/>
		</>
	);
}
