import { UserSetupInfo } from "@model/entities";
import { ReflectionSlide } from "@model/reflection/reflectionSlides.Model";
import React from "react";
import { ModulesLegacyPageType } from "../../model/modules/modules.Model";
import {
	EmotionOnly,
	EmotionStimulusCombo,
	EmotionStimulusData,
	ReflectionModules,
	isMoraleCheckInReflection,
} from "../../model/reflection/reflection.Model";
import AffirmationSlide from "../Modules/LegacySlides/AffirmationSlide";
import EmotionStimulusSlide from "../Modules/LegacySlides/EmotionStimulusSlide";
import EmotionalAwarenessIntroSlide from "../Modules/LegacySlides/EmotionalAwarenessIntroSlide";
import GratitudeAffirmationsIntroSlide from "../Modules/LegacySlides/GratitudeAffirmationsIntroSlide";
import GratitudeSlide from "../Modules/LegacySlides/GratitudeSlide";
import InterventionSelectionSlide from "../Modules/LegacySlides/InterventionSelectionSlide";
import BoxBreathingSlide from "../Modules/LegacySlides/Interventions/BoxBreathingSlide";
import {
	interventionEPairingList,
	interventionESPairingList,
} from "../Modules/LegacySlides/Interventions/InterventionPairingList";
import JournalInterventionSlide, {
	PROMPTLIST,
} from "../Modules/LegacySlides/Interventions/JournalInterventionSlide";
import OppositeActionInterventionSlide from "../Modules/LegacySlides/Interventions/OppositeActionInterventionSlide";
import PassItForwardInterventionSlide from "../Modules/LegacySlides/Interventions/PassItForwardInterventionSlide";
import RewardInterventionSlide from "../Modules/LegacySlides/Interventions/RewardInterventionSlide";
import SensationsInterventionSlide from "../Modules/LegacySlides/Interventions/SensationsInterventionSlide";
import SenseItOutInterventionSlide from "../Modules/LegacySlides/Interventions/SenseItOutInterventionSlide";
import StepAwayInterventionSlide from "../Modules/LegacySlides/Interventions/StepAwayInterventionSlide";
import ValuesSlide from "../Modules/LegacySlides/Interventions/ValuesSlide";
import ZoomOutInterventionSlide from "../Modules/LegacySlides/Interventions/ZoomOutInterventionSlide";
import ModulesLastSlide from "../Modules/LegacySlides/ModulesLastSlide";
import MoraleSlide from "../Modules/LegacySlides/MoraleSlide";
import PositiveMindsetConfirmationSlide from "../Modules/LegacySlides/PositiveMindsetConfirmationSlide";
import PositiveMindsetIntroSlide from "../Modules/LegacySlides/PositiveMindsetIntroSlide";
import ThoughtsSlide from "../Modules/LegacySlides/ThoughtsSlide";
import { emotionWordListFull } from "./SharedComponents/EmotionWordList";

const pageTitle = "Morale check-in";

const moraleIntroSlides = [
	{
		slide: () => (
			<EmotionalAwarenessIntroSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.EA_INTRO,
					id: "",
					title: "",
					buttonConfig: {
						backButton: false,
					},
				}}
			/>
		),
		id: "Emotional_Awareness_Intro",
		title: "Emotional Awareness Intro",
	},
	{
		slide: () => (
			<MoraleSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.MORALE,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Morale_Check_In",
		title: "Morale Check In",
	},
	{
		slide: () => (
			<EmotionStimulusSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.EMOTION_STIMULUS,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Emotion_Stimulus_Check_In",
		title: "Emotion Stimulus Check In",
	},
	{
		slide: () => (
			<ThoughtsSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.JOURNAL,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Journal_Check_In",
		title: "Journal Check In",
	},
	{
		slide: () => (
			<InterventionSelectionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "InterventionSelect",
		title: "Intervention Selection",
	},
];

const moraleCoreSlides = [
	{
		slide: () => (
			<MoraleSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.MORALE,
					id: "",
					title: "",
					buttonConfig: {
						backButton: false,
					},
				}}
			/>
		),
		id: "Morale_Check_In",
		title: "Morale Check In",
	},
	{
		slide: () => (
			<EmotionStimulusSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.EMOTION_STIMULUS,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Emotion_Stimulus_Check_In",
		title: "Emotion Stimulus Check In",
	},
	{
		slide: () => (
			<ThoughtsSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.JOURNAL,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Journal_Check_In",
		title: "Journal Check In",
	},
	{
		slide: () => (
			<InterventionSelectionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "InterventionSelect",
		title: "Intervention Selection",
	},
];

const reflectionLastSlides = [
	{
		slide: () => (
			<ModulesLastSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.LAST_SLIDE,
					id: "",
					title: "",
					overrideDescription: true,
					buttonConfig: {
						forwardButton: false,
					},
				}}
			/>
		),
		id: "Last_Slide",
		title: "Last Slide",
	},
];

const gratitudeSlides = [
	{
		slide: () => (
			<GratitudeSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Gratitude",
		title: "Practice Gratitude",
	},
];

const gratitudeSlidesSolo = [
	{
		slide: () => (
			<GratitudeSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
					buttonConfig: {
						backButton: false,
					},
				}}
			/>
		),
		id: "Gratitude",
		title: "Practice Gratitude",
	},
];

const affirmationsSlides = [
	{
		slide: () => (
			<AffirmationSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Affirmations",
		title: "Daily Affirmation",
	},
];

const affirmationsSlidesSolo = [
	{
		slide: () => (
			<AffirmationSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
					buttonConfig: {
						backButton: false,
					},
				}}
			/>
		),
		id: "Affirmations",
		title: "Daily Affirmation",
	},
];

const positiveMindsetSlides = [
	{
		slide: () => (
			<PositiveMindsetIntroSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
					buttonConfig: {
						backButton: false,
						forwardButton: false,
					},
				}}
			/>
		),
		id: "PositiveMindsetIntroSlide",
		title: "Positive Mindset Introduction",
		hideNextArrow: true,
	},
	{
		slide: () => (
			<GratitudeAffirmationsIntroSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "GratitudeIntro",
		title: "Gratitude Introduction",
	},
	...gratitudeSlides,
	...affirmationsSlides,
	{
		slide: () => (
			<PositiveMindsetConfirmationSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
					buttonConfig: {
						forwardButton: false,
					},
				}}
			/>
		),
		id: "PositiveMindsetConfirmationSlide",
		title: "Positive Mindset Confirmation Slide",
		hideNextArrow: true,
	},
];
export const interventionSlides = [
	{
		slide: () => (
			<BoxBreathingSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Breathe",
		title: "Breathe",
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt="If someone I cared about felt the same way, what might I say to express support?"
			/>
		),
		id: "Journal",
		title: "Journal",
	},
	{
		slide: () => (
			<ValuesSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "DecodeValues",
		title: "Decode values",
	},
	{
		slide: () => (
			<SenseItOutInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "SenseItOut",
		title: "Be present",
	},
	{
		slide: () => (
			<StepAwayInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "StepAway",
		title: "Step away",
	},
	{
		slide: () => (
			<ZoomOutInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "ZoomOut",
		title: "Zoom out",
	},
	{
		slide: () => (
			<OppositeActionInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "OppositeAction",
		title: "Opposite action",
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt={PROMPTLIST.MEETING}
			/>
		),
		id: "Journal_Meeting",
		title: "Meeting reflection",
		hidden: true,
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt={PROMPTLIST.CAREER}
			/>
		),
		id: "Journal_Career",
		title: "Career reflection",
		hidden: true,
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt={PROMPTLIST.MENTAL_HEALTH}
			/>
		),
		id: "Journal_MentalHealth",
		title: "Mental health reflection",
		hidden: true,
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt={PROMPTLIST.SUPPORTING_OTHERS}
			/>
		),
		id: "Journal_SupportingOthers",
		title: "Emotion reflection",
		hidden: true,
	},
	{
		slide: () => (
			<JournalInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
				initialPrompt={PROMPTLIST.SHARE}
			/>
		),
		id: "Journal_Share",
		title: "Practice vulnerability",
	},
	{
		slide: () => (
			<RewardInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Reward",
		title: "Reward yourself",
	},
	{
		slide: () => (
			<SensationsInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "Sensations",
		title: "Focus on sensations",
	},
	{
		slide: () => (
			<PassItForwardInterventionSlide
				page={{
					pageTitle: pageTitle,
					legacyPageType: ModulesLegacyPageType.INTERVENTION,
					id: "",
					title: "",
				}}
			/>
		),
		id: "PassItForward",
		title: "Pass it forward",
	},
];

export const configureReflectionSlides = (
	reflectionModules: ReflectionModules,
	setupInfo: UserSetupInfo,
	selectedInterventionList: string,
	selectedInterventionIndex: number,
	interventionSlideList: ReflectionSlide[],
) => {
	const slides: ReflectionSlide[] = [] as ReflectionSlide[];
	//add morale if selected
	if (isMoraleCheckInReflection(reflectionModules)) {
		if (!setupInfo.completedSetup) {
			slides.push(...moraleIntroSlides);
		} else {
			slides.push(...moraleCoreSlides);
		}
		const found = interventionSlideList.findIndex(
			(x) => x.id === selectedInterventionList[selectedInterventionIndex],
		);

		if (found >= 0) slides.push(interventionSlideList[found]);
	}
	//add gratitude if selected
	if (reflectionModules?.gratitude) {
		if (!setupInfo.moduleSetup?.gratitude) {
			slides.push(...positiveMindsetSlides);
		} else {
			if (slides.length === 0) {
				slides.push(...gratitudeSlidesSolo);
			} else {
				slides.push(...gratitudeSlides);
			}
		}
	}
	//add affirmation if selected
	if (reflectionModules?.affirmations) {
		if (!setupInfo.moduleSetup?.affirmations) {
			slides.push(...positiveMindsetSlides);
		} else {
			if (slides.length === 0) {
				slides.push(...affirmationsSlidesSolo);
			} else {
				slides.push(...affirmationsSlides);
			}
		}
	}
	slides.push(...reflectionLastSlides);

	return slides;
};

export const createInterventionDeck = (
	emotionStimList: EmotionStimulusData[],
	recommendationSuggestion: string,
	setRecommendationSuggestion: (input: string) => void,
) => {
	let recommendedIntervention = "";
	let interventionPleasant = false;
	let pairingScore = 0;
	if (emotionStimList.length > 0) {
		// we have emotions create lists of emotions stim combos and emotions only
		let emotionStimCombos = [] as EmotionStimulusCombo[];
		let emotionOnlys = [] as EmotionOnly[];
		emotionStimList.forEach((emotion) => {
			const foundEmotion = emotionWordListFull.find(
				(x) => x.word.toLocaleLowerCase() === emotion.emotion.toLocaleLowerCase(),
			);
			if (emotion.stimuli && emotion.stimuli.length) {
				emotion.stimuli.forEach((stimuli) => {
					emotionStimCombos.push({
						emotion: emotion.emotion,
						stimulus: stimuli.inputValue,
						pleasant: foundEmotion ? foundEmotion.pleasant : undefined,
					});
				});
			}
			emotionOnlys.push({
				emotion: emotion.emotion,
				pleasant: foundEmotion ? foundEmotion.pleasant : undefined,
			});

			if (foundEmotion && foundEmotion.pleasant !== undefined) {
				pairingScore = foundEmotion.pleasant ? pairingScore + 1 : pairingScore - 1;
			}
		});
		// check for emotion stim database matches
		let esPairingFound = false;
		let esPairingFirstUnpleasantIntervention = "";
		let esPairingFirstPleasantIntervention = "";
		emotionStimCombos = emotionStimCombos.map((combo) => {
			const pairingFound = interventionESPairingList.find(
				(entry) =>
					entry.emotion.toLocaleLowerCase() === combo.emotion.toLocaleLowerCase() &&
					entry.stimulus.toLocaleLowerCase() === combo.stimulus.toLocaleLowerCase(),
			);
			if (pairingFound) {
				esPairingFound = true;
				if (combo.pleasant === true) {
					if (esPairingFirstPleasantIntervention === "") {
						esPairingFirstPleasantIntervention = pairingFound.interventionId;
					}
				}
				if (combo.pleasant === false) {
					if (esPairingFirstUnpleasantIntervention === "") {
						esPairingFirstUnpleasantIntervention = pairingFound.interventionId;
					}
				} else {
					if (esPairingFirstUnpleasantIntervention === "") {
						esPairingFirstUnpleasantIntervention = pairingFound.interventionId;
						esPairingFirstPleasantIntervention = pairingFound.interventionId;
					}
				}
			}
			return {
				...combo,
				paring: pairingFound ? true : false,
				interventionId: pairingFound ? pairingFound.interventionId : "",
			};
		});
		if (esPairingFound) {
			if (pairingScore > 0) {
				const found = interventionSlides.find(
					(slide) => slide.id === esPairingFirstPleasantIntervention,
				);
				if (found) {
					recommendedIntervention = esPairingFirstPleasantIntervention;
					interventionPleasant = true;
				}
			} else {
				const found = interventionSlides.find(
					(slide) => slide.id === esPairingFirstUnpleasantIntervention,
				);
				if (found) {
					recommendedIntervention = esPairingFirstUnpleasantIntervention;
					interventionPleasant = false;
				}
			}
		}
		if (recommendedIntervention === "") {
			//check for emotion pairing matches if no emotion-stim parings match
			let ePairingFound = false;
			let ePairingFirstUnpleasantIntervention = "";
			let ePairingFirstPleasantIntervention = "";
			emotionOnlys = emotionOnlys.map((only) => {
				const pairingFound = interventionEPairingList.find(
					(entry) =>
						entry.emotion.toLocaleLowerCase() === only.emotion.toLocaleLowerCase(),
				);
				if (pairingFound) {
					ePairingFound = true;
					if (only.pleasant === true) {
						if (ePairingFirstPleasantIntervention === "") {
							ePairingFirstPleasantIntervention = pairingFound.interventionId;
						}
					}
					if (only.pleasant === false) {
						if (ePairingFirstUnpleasantIntervention === "") {
							ePairingFirstUnpleasantIntervention = pairingFound.interventionId;
						}
					} else {
						if (ePairingFirstUnpleasantIntervention === "") {
							ePairingFirstUnpleasantIntervention = pairingFound.interventionId;
							ePairingFirstPleasantIntervention = pairingFound.interventionId;
						}
					}
				}
				return {
					...only,
					paring: pairingFound ? true : false,
					interventionId: pairingFound ? pairingFound.interventionId : "",
				};
			});
			if (ePairingFound) {
				if (pairingScore > 0) {
					const found = interventionSlides.find(
						(slide) => slide.id === ePairingFirstPleasantIntervention,
					);
					if (found) {
						recommendedIntervention = ePairingFirstPleasantIntervention;
						interventionPleasant = true;
					}
				} else {
					const found = interventionSlides.find(
						(slide) => slide.id === ePairingFirstUnpleasantIntervention,
					);
					if (found) {
						recommendedIntervention = ePairingFirstUnpleasantIntervention;
						interventionPleasant = false;
					}
				}
			}
		}
	}
	// create deck
	const foundIndex = interventionSlides.findIndex(
		(slide) => slide.id === recommendedIntervention,
	);
	if (foundIndex >= 0) {
		let interventionDeck = [] as ReflectionSlide[];
		if (recommendationSuggestion !== recommendedIntervention) {
			setRecommendationSuggestion(recommendedIntervention);
		}
		interventionDeck = interventionDeck.concat(interventionSlides[foundIndex]);
		const splice = [...interventionSlides];
		splice.splice(foundIndex, 1);

		const finalDeck = interventionPleasant ? [...splice.reverse()] : [...splice];

		interventionDeck = interventionDeck.concat(...finalDeck);

		return interventionDeck;
	} else {
		return pairingScore > 0 ? interventionSlides.reverse() : interventionSlides;
	}
};
