import { Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { WebPushNotifications } from "../../libs/pushNotificationsLib";
import { QuimbyServiceWorker } from "../../libs/quimbyServiceWorkerLib";
import LinkButton from "../Modules/Shared/LinkButton";
import QButton from "../common/QButton";
import NotificationTargetItem from "./NotificationTargetItem";

const BrowserNotificationNTI: FC = () => {
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		appState,
	} = useAppContext();
	const webPushNotifications: WebPushNotifications = appState.webPushNotifications;
	const quimbyServiceWorker: QuimbyServiceWorker = appState.quimbyServiceWorker;
	const userId = appState.authenticatedUserInfo.SK;
	// const classes = modulesStyles();
	const [status, setStatus] = useState(webPushNotifications.getStatus());

	const enableNotifications = async () => {
		const process =
			await webPushNotifications.requestNotificationPermission(quimbyServiceWorker);
		if (process) {
			await webPushNotifications.initializePushNotifications(quimbyServiceWorker);
		}
		setStatus(webPushNotifications.getStatus());
	};

	const testNotifications = () => {
		void webPushNotifications.sendTestNotification(userId as string);
	};

	return (
		<NotificationTargetItem
			enabled={status.state === "enabled"}
			statusText={
				status.state === "enabled"
					? "connected"
					: status.state === "disabled"
						? ""
						: "disabled"
			}
			notificationTargetName="Browser Notifications"
			info="Receive reminders via your browser"
		>
			{status.state === "enabled" ? (
				<>
					<QButton
						text="Send test notification"
						onClick={testNotifications}
						variant="outlined"
					/>
				</>
			) : (
				<>
					{status.state === "error" ? (
						<Stack style={{ maxWidth: "300px" }} alignItems="flex-start">
							<Typography>{status.message}</Typography>
							<LinkButton
								text="Unblock notifications from this site"
								onClick={() => {
									window
										.open(
											"https://www.quimbyapp.com/browser-notification-faq",
											"_blank",
										)
										?.focus();
								}}
							/>
						</Stack>
					) : (
						<QButton
							text="Allow browser notifications"
							onClick={enableNotifications}
							width="256px"
						/>
					)}
				</>
			)}
		</NotificationTargetItem>
	);
};

export default BrowserNotificationNTI;
