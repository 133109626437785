import { Stack, Typography } from "@mui/material";
import React, { FC, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { useSetupContext } from "../../libs/typedContextLib";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";

const SetupConclusion: FC = () => {
	//@ts-expect-error app context isn't typed
	const { appDispatch } = useAppContext();
	const { setSetupComplete } = useSetupContext();
	const history = useNavigate();
	function handleClick(e: MouseEvent) {
		e.preventDefault();
		history("/home/dashboard_user");
	}
	useEffect(() => {
		setSetupComplete(true);
		appDispatch({ type: "setIsSetupComplete", isSetupComplete: true });
	}, []);

	return (
		<Stack spacing={modulesTheme.spacing(2)} justifyContent="center" alignItems="center">
			<Typography variant="h5">You’re all set up!</Typography>
			<Typography variant="body1" paragraph>
				Great! You’re ready to get started on building your emotional resilience.
			</Typography>
			<Typography variant="body1" paragraph>
				To successfully receive notifications, you’ll need to keep your browser open in the
				background. You can open the Quimby app at any time to submit new reflections and
				view your data insights.
			</Typography>
			<QButton onClick={handleClick} text="Continue to Quimby" />
		</Stack>
	);
};

export default SetupConclusion;
