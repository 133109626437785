import PropTypes from "prop-types";
import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { dateRange } from "../shared/constants";

export default function ToggleButtons({ domain, setDomain }) {
	const onChangeHandler = (event, newDateRange) => {
		if (newDateRange !== null) {
			setDomain(newDateRange);
		}
	};

	return (
		<ToggleButtonGroup
			value={domain}
			exclusive
			onChange={onChangeHandler}
			aria-label="text alignment"
		>
			<ToggleButton value={dateRange.ONE_WEEK} aria-label="1 week">
				1 week
			</ToggleButton>
			<ToggleButton value={dateRange.ONE_MONTH} aria-label="1 month">
				1 month
			</ToggleButton>
			<ToggleButton value={dateRange.SIX_MONTHS} aria-label="6 months">
				6 months
			</ToggleButton>
			<ToggleButton value={dateRange.ONE_YEAR} aria-label="1 year">
				1 year
			</ToggleButton>
			<ToggleButton value={dateRange.MAX} aria-label="max">
				max
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

ToggleButtons.propTypes = {
	domain: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
	setDomain: PropTypes.func.isRequired,
};
