import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MEMBER_STATUS } from "../../shared/constants";
import clsx from "clsx";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "0.25em 0.5em",
		borderRadius: "1rem",
	},
	member: { background: "none", color: theme.palette.text.mid },
	pending: {
		background: theme.palette.memberStatus.pending,
		color: theme.palette.text.dark,
	},
	needsApproval: {
		background: theme.palette.memberStatus.needsApproval,
		color: theme.palette.text.light,
	},
}));

const statusText = {
	[MEMBER_STATUS.MEMBER]: "Members",
	[MEMBER_STATUS.PENDING]: "Pending",
	[MEMBER_STATUS.NEEDS_APPROVAL]: "Awaiting Approval",
};

export default function SubscriptionStatusIndicator({ status, quantity, showEmpty = false }) {
	const classes = useStyles();

	const statusColor = {
		[MEMBER_STATUS.MEMBER]: classes.member,
		[MEMBER_STATUS.PENDING]: classes.pending,
		[MEMBER_STATUS.NEEDS_APPROVAL]: classes.needsApproval,
	};

	return showEmpty || quantity > 0 ? (
		<Typography
			variant="caption"
			className={clsx(classes.root, statusColor[status])}
		>{`${quantity} ${statusText[status]}`}</Typography>
	) : null;
}
