import { CircularProgress, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { FC, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { modulesTheme } from "src/components/Modules/modulesTheme";
import FullPageWrapper from "src/components/common/FullPageWrapper";
import QButton from "src/components/common/QButton";
import { logger } from "../libs/logLib";

type VerifyState =
	| "verifying-email"
	| "check-your-email"
	| "verification-complete"
	| "verification-error"
	| "verification-exceeded"
	| "already-verified";

const VerifyPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const verifyCode = queryParams.get("verifyCode") ?? "";
	const email = queryParams.get("email") ?? "";
	const [verifyState, setVerifyState] = useState<VerifyState>(
		verifyCode !== "" && email !== "" ? "verifying-email" : "check-your-email",
	);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const verify = async () => {
			if (verifyCode !== "" && email !== "") {
				try {
					await Auth.confirmSignUp(email, verifyCode);
					setVerifyState("verification-complete");
				} catch (e) {
					logger.error(e);
					if (e instanceof Error) {
						if (e.message === "Attempt limit exceeded, please try after some time.") {
							setVerifyState("verification-exceeded");
						} else if (
							e.message === "User cannot be confirmed. Current status is CONFIRMED"
						) {
							setVerifyState("already-verified");
						} else {
							setVerifyState("verification-error");
						}
					} else {
						setVerifyState("verification-error");
					}
				}
			}
		};
		void verify();
	}, []);

	const resendVerificationLink = async () => {
		setLoading(true);
		try {
			await Auth.resendSignUp(email);
			setVerifyState("check-your-email");
		} catch (e) {
			logger.error(e);
		}
		setLoading(false);
	};

	return (
		<FullPageWrapper>
			<Stack
				width="100%"
				maxWidth="800px"
				justifyContent="center"
				alignItems="center"
				spacing={modulesTheme.spacing(3)}
			>
				{verifyState === "check-your-email" && (
					<>
						<Typography variant="h5">Verification email sent to:</Typography>
						<Typography variant="body">{email}</Typography>
						<Typography variant="info">
							Click the link in your email to verify your email address and continue
							setting up your Quimby account. If you didn't receive an email, verify
							your email address above is correct and check your spam folder.
						</Typography>
						<QButton
							text="Send a new verification link"
							onClick={() => {
								void resendVerificationLink();
							}}
							variant="outlined"
							loading={loading}
						/>
						<QButton
							text="Return to sign up"
							onClick={() => {
								navigate("/register");
							}}
							variant="outlined"
						/>
					</>
				)}
				{verifyState === "verifying-email" && (
					<>
						<Typography variant="h5">We're verifying your email</Typography>
						<CircularProgress />
					</>
				)}
				{verifyState === "verification-complete" && (
					<>
						<Typography variant="h5">Email verification complete</Typography>
						<Typography variant="body">
							Sign in to finish setting up your account.
						</Typography>
						<QButton
							text="Sign in"
							onClick={() => {
								navigate("/setup");
							}}
						/>
					</>
				)}
				{verifyState === "verification-error" && (
					<>
						<Typography variant="h5">Email verification error</Typography>
						<Typography variant="body">
							There was an error validating your email address. Click below to receive
							a new link.
						</Typography>
						<QButton
							text="Resend email verification link"
							loading={loading}
							onClick={() => {
								void resendVerificationLink();
							}}
						/>
						<QButton
							text="Sign in"
							onClick={() => {
								navigate("/setup");
							}}
							variant="outlined"
						/>
					</>
				)}
				{verifyState === "verification-exceeded" && (
					<>
						<Typography variant="h5">Email verification error</Typography>
						<Typography variant="body">
							Email verification attempt limit exceeded, please try after some time.
						</Typography>
					</>
				)}
				{verifyState === "already-verified" && (
					<>
						<Typography variant="h5">Your email is already verified</Typography>
						<Typography variant="body">
							To sign in <RouterLink to="/setup">Click Here</RouterLink>.
						</Typography>
					</>
				)}
			</Stack>
		</FullPageWrapper>
	);
};

export default VerifyPage;
