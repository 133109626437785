import { NotificationsOffOutlined } from "@mui/icons-material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesColors, modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";
import QChip from "../common/QChip";
import QModal from "../common/QModal";
import { formatDays, formatTime } from "../reminder/reminderHelperFunctions";
import ConfigureReminder from "./ConfigureReminder";

const DEFAULT_TIME = "09:00";
const DEFAULT_DAYS = [false, true, true, true, true, true, false];

export default function RoutineList(props) {
	const { index, addReminder, reminder, editReminder, deleteReminder } = props;

	const [newDays, setNewDays] = useState([false, true, true, true, true, true, false]);
	const [newTime, setNewTime] = useState("09:00");
	const [activity, setActivity] = useState({});
	const [open, setOpen] = useState(false);

	const classes = modulesStyles();

	useEffect(() => {
		setNewDays(reminder.days);
		setNewTime(reminder.time);
		if (activity) {
			setActivity({
				affirmations: reminder.affirmations,
				modules: reminder.modules,
				check_in: reminder.moraleCheckIn,
				gratitude: reminder.gratitude,
			});
		}
	}, []);

	const handleDelete = () => {
		deleteReminder(index);
		setOpen(false);
	};

	const handleMute = () => {
		const newReminder = {
			...reminder,
			enabled: !reminder.enabled,
		};

		editReminder(newReminder, index);
	};

	const handleSave = () => {
		if (activity) {
			const newReminder = {
				days: [...newDays],
				time: newTime,
				modules: activity.modules,
				moraleCheckIn: activity.check_in,
				affirmations: activity.affirmations,
				gratitude: activity.gratitude,
				enabled: activity.enabled,
				id: reminder.id,
			};
			editReminder(newReminder, index);
			setOpen(false);
		} else {
			const newReminder = {
				days: [...newDays],
				time: newTime,
				enabled: true,
				modules: activity.modules,
				moraleCheckIn: activity.check_in,
				affirmations: activity.affirmations,
				gratitude: activity.gratitude,
				id: uuidv4(),
			};

			addReminder(newReminder);
			setOpen(false);
			resetDefaultTimes();
			setActivity({});
		}
	};

	function resetDefaultTimes() {
		setNewTime(DEFAULT_TIME);
		setNewDays(DEFAULT_DAYS);
	}
	return (
		<>
			<Paper className={classes.notificationStatusBox} style={{ marginTop: "1rem" }}>
				<Grid container>
					<Grid
						item
						container
						justifyContent="space-between"
						style={{
							borderBottom: "1px solid rgba(224, 224, 224, 1)",
							paddingBottom: ".5rem",
						}}
					>
						<Grid item mobile={12} tablet={8}>
							<Stack
								direction="row"
								spacing={modulesTheme.spacing(2)}
								alignItems="center"
							>
								<Typography variant="h1" style={{ fontSize: "36px" }}>
									{formatTime(reminder.time)}
								</Typography>
								{!reminder.enabled && (
									<NotificationsOffOutlined
										sx={{
											color: modulesColors.neutrals._700,
											fontSize: "32px",
										}}
									/>
								)}
							</Stack>
						</Grid>
						<Grid
							item
							style={{
								display: "flex",
								alignItems: "flex-end",
								marginBottom: "-.5rem",
								justifyContent: "flex-end",
							}}
							mobile={12}
							tablet={4}
						>
							<QButton
								text={reminder.enabled ? "Mute" : "Unmute"}
								onClick={handleMute}
								variant="text"
							/>
							<QButton onClick={() => setOpen(true)} text="Edit" variant="text" />
							<QButton text="Delete" onClick={handleDelete} variant="text" />
						</Grid>
					</Grid>
					<Grid item style={{ paddingTop: ".7rem" }}>
						<Typography component="span" variant="body2" color="textPrimary">
							{formatDays(reminder.days)}
						</Typography>
					</Grid>
					<Grid item container style={{ paddingTop: ".7rem" }}>
						{reminder.affirmations && (
							<div style={{ marginRight: ".5rem", paddingTop: ".3rem" }}>
								<QChip label="Affirmations" variant="readOnly" />
							</div>
						)}
						{reminder.gratitude && (
							<div style={{ marginRight: ".5rem", paddingTop: ".3rem" }}>
								<QChip label="Gratitude" variant="readOnly" />
							</div>
						)}
						{reminder.moraleCheckIn && (
							<div style={{ marginRight: ".5rem", paddingTop: ".3rem" }}>
								<QChip label="Morale Check-in" variant="readOnly" />
							</div>
						)}
						{reminder.modules && (
							<div style={{ marginRight: ".5rem", paddingTop: ".3rem" }}>
								<QChip label="Intentions" variant="readOnly" />
							</div>
						)}
					</Grid>
				</Grid>
			</Paper>
			<QModal
				open={open}
				setOpen={setOpen}
				disableCancelClick
				saveButton
				saveButtonText="Save"
				onSave={handleSave}
				cancelButton
				cancelButtonText="Cancel"
				width="320px"
			>
				<ConfigureReminder
					days={newDays}
					setDays={setNewDays}
					time={newTime}
					setTime={setNewTime}
					activity={activity}
					setActivity={setActivity}
				/>
			</QModal>
		</>
	);
}
