import { Stack, ThemeProvider, Typography } from "@mui/material";
import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { appUrl } from "src";
import ClickableLink from "src/components/common/ClickableLink";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import SubscriptionCard, {
	SubscriptionCardProps,
} from "../components/subscription/SubscriptionCard";
import { apiGetStripePortalUrl } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { Account, BillingType } from "../model/account/account.Model";

export interface SubscriptionsProps {}

export type Period = "monthly" | "yearly";

const Subscriptions: FC<SubscriptionsProps> = () => {
	const classes = modulesStyles();
	const [subscriptionCards, setSubscriptionCards] = useState<SubscriptionCardProps[]>([]);
	const [period, setPeriod] = useState<"yearly" | "monthly">("monthly");
	const [status, setStatus] = useState("");
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const { appState } = useAppContext();
	const selectedSubscriptionInfo: Account = appState.selectedSubscriptionInfo;

	const togglePeriod = (period: "yearly" | "monthly") => {
		setPeriod(period);
	};
	useEffect(() => {
		if (selectedSubscriptionInfo.billingType === BillingType.Monthly) {
			setPeriod("monthly");
		}
		if (selectedSubscriptionInfo.billingType === BillingType.Yearly) {
			setPeriod("yearly");
		}
	}, [selectedSubscriptionInfo]);

	useEffect(() => {
		const isSelectedSubscription = (type: BillingType) => {
			if (!selectedSubscriptionInfo.billingType) {
				return false;
			}
			switch (selectedSubscriptionInfo.billingType) {
				case BillingType.None:
					switch (type) {
						case BillingType.None:
							return true;
						default:
							return false;
					}
				case BillingType.Monthly:
					switch (type) {
						case BillingType.Monthly:
							return true;
						default:
							return false;
					}
				case BillingType.Yearly:
					switch (type) {
						case BillingType.Yearly:
							return true;
						default:
							return false;
					}
				case BillingType.Enterprise:
					switch (type) {
						case BillingType.Enterprise:
							return true;
						default:
							return false;
					}
			}
			return false;
		};
		const coreSubs: SubscriptionCardProps[] = [
			{
				title: "Free",
				description:
					"Best for individuals who are looking to start growing emotional awareness & tracking morale.",
				priceYearly: "Free",
				priceMonthly: "Free",
				selected: isSelectedSubscription(BillingType.None),
				period: period,
				bullets: [
					"Individual check-ins",
					"Track your morale ",
					"Personalized insights ",
					"Tips from a psychologist",
					"Customizable reminders",
					"1 year data history",
				],
				togglePeriod: togglePeriod,
			},
			{
				title: "Plus",
				description:
					"Best for small groups and individuals looking to grow in emotional intelligence and resilience.",
				priceMonthly: 4.99,
				priceYearly: 58.99,
				selected: isSelectedSubscription(
					period === "monthly" ? BillingType.Monthly : BillingType.Yearly,
				),
				period: period,
				bullets: [
					"Everything from Free plan",
					"Aggregated team data",
					"Manager Insights",
					"3 year data history",
					"14 day free trial available",
				],
				togglePeriod: togglePeriod,
			},
			{
				title: "Enterprise",
				description:
					"Best for bigger organizations who are looking for more hands on and custom support for their employees.",
				priceYearly: "Contact Sales",
				priceMonthly: "Contact Sales",
				selected: isSelectedSubscription(BillingType.Enterprise),
				period: period,
				bullets: [
					"Everything from Plus plan",
					"Manager + Employee trainings",
					"Dedicated Account Manager",
					"5 year data history",
				],
				togglePeriod: togglePeriod,
			},
		];
		setSubscriptionCards(coreSubs);
		switch (selectedSubscriptionInfo.status) {
			case "FREE_TRIAL":
				setStatus(`FREE TRIAL `);
				if (
					selectedSubscriptionInfo.trialPeriod &&
					selectedSubscriptionInfo.trialPeriod.trialEndDate
				) {
					const today = new Date();
					const trialEnd = new Date(selectedSubscriptionInfo.trialPeriod.trialEndDate);
					const daysLeft = (trialEnd.getTime() - today.getTime()) / (1000 * 3600 * 24);
					setStatus(`FREE TRIAL ` + `- ${Math.round(daysLeft)} days left. `);
				}
				break;
			case "FREE_TRIAL_ENDED":
				setStatus("FREE TRIAL ENDED ");
				break;
			case "ACTIVE":
				setStatus("ACTIVE ");
				break;
			case "BILLING_PROBLEMS":
				setStatus("BILLING PROBLEMS ");
				break;
			case "DISABLED":
				setStatus("DISABLED ");
				break;
			default:
				break;
		}
	}, [selectedSubscriptionInfo, period]);

	const manageSubscription = async () => {
		const url = await apiGetStripePortalUrl(
			selectedSubscriptionInfo.SK ?? "S:NO_SUBSCRIPTION",
			appUrl + "/home/admin/subscription",
		);
		if (url) {
			document.location.href = url;
		}
	};

	return (
		<ThemeProvider theme={modulesTheme}>
			<div className={classes.pageBase}>
				<Stack spacing="30px">
					<Stack direction="row" spacing="20px" alignItems="flex-end">
						<Typography variant="h1">Subscription</Typography>
						<Typography variant="h6">{status}</Typography>
					</Stack>
					<div>
						<ClickableLink
							text="Manage Subscription"
							onClick={manageSubscription}
							underline
							bold
						/>
						<Typography variant="body1" paddingLeft="10px">
							Update your payment method, view invoices, and change subscription
						</Typography>
					</div>
					<SubscriptionProvider>
						<Stack direction="row" spacing="20px">
							{subscriptionCards.map((card, index) => {
								return <SubscriptionCard key={index} {...card} />;
							})}
						</Stack>
					</SubscriptionProvider>
				</Stack>
			</div>
		</ThemeProvider>
	);
};

export default Subscriptions;

interface SubscriptionContextType {
	descriptionHeight: number;
	priceHeight: number;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

interface SubscriptionProviderProps {
	children: ReactNode;
}

const SubscriptionProvider: FC<SubscriptionProviderProps> = ({ children }) => {
	const [descriptionHeight, setDescriptionHeight] = useState<number>(24);
	const [priceHeight, setPriceHeight] = useState<number>(24);

	useEffect(() => {
		const descriptionElements = document.getElementsByClassName("subscriptionDescription");
		Array.from(descriptionElements).forEach((element) => {
			const divElement = element as HTMLDivElement;
			const height = divElement.offsetHeight;
			if (height > descriptionHeight) {
				setDescriptionHeight(height);
			}
		});
		const priceElements = document.getElementsByClassName("subscriptionPrice");
		Array.from(priceElements).forEach((element) => {
			const divElement = element as HTMLDivElement;
			const height = divElement.offsetHeight;
			if (height > priceHeight) {
				setPriceHeight(height);
			}
		});
	}, [children]);

	return (
		<SubscriptionContext.Provider value={{ descriptionHeight, priceHeight }}>
			{children}
		</SubscriptionContext.Provider>
	);
};

export const useSubscriptionContext = () => {
	const context = useContext(SubscriptionContext);
	if (!context) {
		throw new Error("useSubscriptionContext must be used within a SubscriptionProvider");
	}
	return context;
};
