import React from "react";
import { makeStyles } from "@mui/styles";
import { defaultStyles } from "../Theme";
import { Typography } from "@mui/material";
import ClickableLink from "../components/common/ClickableLink";
import { useNavigate } from "react-router-dom";
import StepAway from "../images/interventions/intervention_step_away.png";

const useStyles = makeStyles((theme) => defaultStyles(theme));

const NotFound = () => {
	const classes = useStyles();
	const history = useNavigate();
	return (
		<div className={classes.container}>
			<Typography variant="h2" style={{ paddingBottom: "40px" }}>
				{" "}
				Oops!
			</Typography>
			<Typography variant="h5"> we couldn't find that page</Typography>
			<img src={String(StepAway)} alt="Quimby Logo" />
			<ClickableLink
				gray={true}
				onClick={() => {
					history("/home/dashboard_user");
				}}
				text="Click here to go back to our home page."
			/>
		</div>
	);
};

export default NotFound;
