import { Stack } from "@mui/material";
import React, { FC } from "react";
import { modulesTheme } from "../Modules/modulesTheme";
import ReminderButton from "../common/QRoundButton";

interface DayPickerProps {
	days: boolean[];
	setDays: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const DayPicker: FC<DayPickerProps> = ({ days, setDays }) => {
	const handleChange = (currentValue: number) => {
		const daysCopy = [...days];
		daysCopy[currentValue] = !daysCopy[currentValue];
		setDays(daysCopy);
	};
	const isChecked = (index: number) => {
		return days[index];
	};

	return (
		<Stack direction="row" spacing={modulesTheme.spacing(2)} justifyContent="center">
			<ReminderButton
				onClick={() => handleChange(0)}
				variant={isChecked(0) ? "contained" : "outlined"}
				text="S"
			/>
			<ReminderButton
				onClick={() => handleChange(1)}
				variant={isChecked(1) ? "contained" : "outlined"}
				text="M"
			/>
			<ReminderButton
				onClick={() => handleChange(2)}
				variant={isChecked(2) ? "contained" : "outlined"}
				text="T"
			/>
			<ReminderButton
				onClick={() => handleChange(3)}
				variant={isChecked(3) ? "contained" : "outlined"}
				text="W"
			/>
			<ReminderButton
				onClick={() => handleChange(4)}
				variant={isChecked(4) ? "contained" : "outlined"}
				text="T"
			/>
			<ReminderButton
				onClick={() => handleChange(5)}
				variant={isChecked(5) ? "contained" : "outlined"}
				text="F"
			/>
			<ReminderButton
				onClick={() => handleChange(6)}
				variant={isChecked(6) ? "contained" : "outlined"}
				text="S"
			/>
		</Stack>
	);
};

export default DayPicker;
