import { Stack, ThemeProvider, Typography } from "@mui/material";
import React, { FC } from "react";
import Theme from "../../Theme";
import { modulesTheme } from "../Modules/modulesTheme";
import RemindersList from "../reminder/RemindersList";

const SetupReminder: FC = () => {
	return (
		<Stack spacing={modulesTheme.spacing(2)} alignItems="center" justifyContent="center">
			<Typography variant="h5">Set your reminders</Typography>
			<Typography variant="body1">
				We recommend that you submit a reflection three times a day, once when you start
				work, once mid day and once when you finish work. Set up the time(s) you would like
				to be notified to take a minute for your daily check-ins.
			</Typography>
			<ThemeProvider theme={Theme}>
				<RemindersList />
			</ThemeProvider>
			<Typography variant="info1">
				If you realize these times don&apos;t work for you, you can always adjust them in
				your profile settings.
			</Typography>
		</Stack>
	);
};

export default SetupReminder;
