import { Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { modulesStyles } from "src/components/Modules/modulesStyles";
import { modulesTheme } from "src/components/Modules/modulesTheme";
import { NavBarState } from "src/components/home/NavBar";
import CheckMark from "../components/common/Check";
import QButton from "../components/common/QButton";
import InviteToTeam from "../components/sharedInsightsUpgrade/InviteToTeam";
import { apiGetGroupTree, apiUpdateSubscription, apiUpgradeSubscription } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { BillingType } from "../model/account/account.Model";
import { UPDATE_SUBSCRIPTION_ACTION } from "../shared/constants";

export const AccountUpgrade = () => {
	const {
		appState: { authenticatedUserInfo },
		getAuthenticatedUserInfo,
		appDispatch,
	} = useAppContext();
	const history = useNavigate();
	const [step, setStep] = React.useState(0);
	const [invites, setInvites] = React.useState([]);
	const upgradeAccount = React.useCallback(
		async (skip) => {
			const subscriptions = Array.isArray(authenticatedUserInfo.subscription)
				? authenticatedUserInfo.subscription
				: [authenticatedUserInfo.subscription];
			const subscription = subscriptions.find(
				(subs) => !!subs.subscriptionId && subs.subscriptionId === "S:NO_SUBSCRIPTION",
			);
			// First we upgrade the account
			const companyName =
				subscription.company ??
				`${authenticatedUserInfo.firstName}-${authenticatedUserInfo.lastName}`;
			const response = await apiUpgradeSubscription(
				companyName,
				BillingType.Monthly,
				invites.length + 1,
			);
			if ("status" in response) {
				if (response.status != "ERROR") {
					const subscriptionId = response.data.SK;
					//Next: if everything went fine either invite users or skip
					if (skip || invites.length === 0) {
						setStep(2);
					} else {
						let data = {};
						try {
							const groupTree = await apiGetGroupTree(response.data.SK);
							data = {
								[UPDATE_SUBSCRIPTION_ACTION.KEY]:
									UPDATE_SUBSCRIPTION_ACTION.ADD_MEMBERS,
								company: companyName,
								newMembers: invites,
								managerName: {
									...authenticatedUserInfo,
								},
								groupId: groupTree.groupTree[0].children[0].SK,
							};
						} catch (e) {
							logger.error("Get Group Tree Failed:", e);
							data = {
								[UPDATE_SUBSCRIPTION_ACTION.KEY]:
									UPDATE_SUBSCRIPTION_ACTION.ADD_MEMBERS,
								company: companyName,
								newMembers: invites,
								managerName: {
									...authenticatedUserInfo,
								},
							};
						}
						const updateMembersResponse = await apiUpdateSubscription(
							subscriptionId,
							data,
						);
						if ("status" in updateMembersResponse) {
							if (updateMembersResponse.status != "ERROR") {
								setStep(2);
							} else {
								appDispatch({
									type: "setErrorText",
									toastErrorText:
										updateMembersResponse.error ?? "An error has occurred",
								});
							}
						} else {
							appDispatch({
								type: "setErrorText",
								toastErrorText: "An error has occurred",
							});
						}
					}

					// And refresh the user data.
					await getAuthenticatedUserInfo(appDispatch);
				} else {
					appDispatch({
						type: "setErrorText",
						toastErrorText: response.error ?? "An error has occurred",
					});
				}
			} else {
				appDispatch({
					type: "setErrorText",
					toastErrorText: "An error has occurred",
				});
			}
		},
		[invites, authenticatedUserInfo],
	);
	return (
		<ThemeProvider theme={modulesTheme}>
			<>
				{(() => {
					switch (step) {
						case 1:
							return (
								<InviteToTeam
									close={() => setStep(0)}
									setStep={(skip) => upgradeAccount(skip)}
									setInvites={setInvites}
								/>
							);
						case 2:
							return (
								<UpgradeFinish
									setStep={async () => {
										history("/home/shared_insights");
									}}
									invites={invites}
								/>
							);
						default:
							return <SharedInsights setStep={() => setStep(1)} />;
					}
				})()}
			</>
		</ThemeProvider>
	);
};

const SharedInsights = ({ setStep }) => {
	const classes = modulesStyles();
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	return (
		<div
			className={navBarState === NavBarState.FULL ? classes.pageBaseMobile : classes.pageBase}
		>
			<Stack
				spacing="20px"
				alignContent="center"
				justifyContent="center"
				height="100%"
				maxWidth="800px"
				alignSelf="center"
			>
				<Typography variant="h5">
					Upgrading lets you invite friends or co-workers & gives you access to safely
					shared insights!
				</Typography>
				<Grid container>
					<Grid item mobile={12} tablet={6} spacing="8px">
						<Stack direction="row" spacing="8px" alignItems="center">
							<CheckMark />
							<Typography variant="body1">
								Drive meaningful conversations with your team.
							</Typography>
						</Stack>
						<Stack direction="row" spacing="8px" alignItems="center">
							<CheckMark />
							<Typography variant="body1">
								Create a safe space with others.
							</Typography>
						</Stack>
						<Stack direction="row" spacing="8px" alignItems="center">
							<CheckMark />
							<Typography variant="body1">Personal info is never shared.</Typography>
						</Stack>
						<Stack direction="row" spacing="8px" alignItems="center">
							<CheckMark />
							<Typography variant="body1">
								Free for 14 days. No credit card required.
							</Typography>
						</Stack>
					</Grid>
					<Grid item mobile={12} tablet={6}>
						<img
							className="image"
							src={require("../images/shared.jpg")}
							style={{ maxWidth: 450, width: "100%" }}
						/>
					</Grid>
					<Grid item mobile={12}>
						<div className={classes.modulesItemCentered}>
							<QButton text={"Start a 14 day trial"} onClick={setStep} />
						</div>
					</Grid>
					<Grid item mobile={12}>
						<div className={classes.modulesItemCentered}>
							<Typography variant="info">No credit card required.</Typography>
						</div>
					</Grid>
				</Grid>
			</Stack>
		</div>
	);
};

SharedInsights.propTypes = {
	setStep: PropTypes.func.isRequired,
};

const UpgradeFinish = ({ setStep }) => {
	const classes = modulesStyles();
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	return (
		<div
			className={navBarState === NavBarState.FULL ? classes.pageBaseMobile : classes.pageBase}
		>
			<Stack
				spacing="20px"
				maxWidth="600px"
				height="100%"
				justifyContent="center"
				alignItems="center"
				alignSelf="center"
			>
				<Typography variant="h5">Congratulations!</Typography>

				<Typography variant="h6">
					You have been upgraded to a Team account & shared insights have been unlocked.
				</Typography>

				<Typography variant="body1">
					To start collection shared insights with your invited members, visit Team Admin
					and configure your first group. The Team Admin can also be used to view
					invitation status, invite additional members, and manage your subscription.
				</Typography>

				<div className={classes.modulesItemCentered}>
					<QButton text="Okay" onClick={setStep} />
				</div>
			</Stack>
		</div>
	);
};
UpgradeFinish.propTypes = {
	setStep: PropTypes.func.isRequired,
};
