import { Box, Container, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { CarouselContext, useTypedAppContext } from "../../libs/typedContextLib";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "./QButton";
import QStepper from "./QStepper";

interface CarouselProps {
	slideList: CarouselSlide[];
}

export interface CarouselSlide {
	group: string;
	slide: ReactElement;
	buttons?: {
		goForward?: boolean;
		goForwardText?: string;
		goBackward?: boolean;
		goBackwardText?: string;
	};
}

const useStyles = makeStyles(() => ({
	container: {
		height: "100%",
		width: "100%",
		maxWidth: "1200px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "0px",
	},
	sectionContent: {
		width: "100%",
		maxWidth: "816px",
		paddingLeft: "8px",
		paddingRight: "8px",
		overflow: "auto",
	},
	sectionStepper: {
		width: "100%",
		maxWidth: "1200px",
	},
	sectionButtons: {
		width: "100%",
		maxWidth: "800px",
		paddingTop: "64px",
	},
	flexBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
	},
}));

const Carousel: FC<CarouselProps> = ({ slideList }) => {
	const [step, setStep] = useState<number>(0);
	const [groupStep, setGroupStep] = useState<number>(0);
	const [groupList, setGroupList] = useState<string[]>([]);
	const {
		UIContext: { appHeight, appWidth },
	} = useTypedAppContext();

	useEffect(() => {
		const tempGroupList = slideList.reduce((groups, slide) => {
			if (!groups.includes(slide.group)) {
				groups.push(slide.group);
			}
			return groups;
		}, [] as string[]);
		setGroupList(tempGroupList);
	}, [slideList]);

	const buttons = {
		...{ goForward: true, goForwardText: "Next", goBackward: true, goBackwardText: "Back" },
		...slideList[step].buttons,
	};

	useEffect(() => {
		const groupIndex = groupList.findIndex((group) => group === slideList[step].group);
		if (groupIndex >= 0) {
			setGroupStep(groupIndex);
		}
	}, [step]);

	const goBackward = () => {
		if (step > 0) {
			setStep(step - 1);
		}
	};

	const goForward = () => {
		if (step < slideList.length - 1) {
			setStep(step + 1);
		}
	};

	const ref = useRef<HTMLDivElement | null>(null);
	const [padding, setPadding] = useState(0);
	const classes = useStyles();

	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			if (ref.current?.firstElementChild) {
				const contentHeight = ref.current.firstElementChild.getBoundingClientRect().height;
				const viewHeight = ref.current.getBoundingClientRect().height;
				const heightDifference = (viewHeight - contentHeight) / 2;
				const pad = heightDifference > 0 ? heightDifference : 0;
				setPadding(pad);
			}
		});

		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				resizeObserver.unobserve(ref.current);
			}
		};
	}, [appHeight, appWidth, step]);

	const carouselChildrenStyle = {
		carouselChildren: {
			height: "100%",
			width: "100%",
			paddingRight: padding === 0 ? "4px" : "0px",
			display: "block",
			overflowY: "auto",
			paddingTop: `${padding.toString()}px`,
			alignSelf: "center",
		},
	};

	return (
		<CarouselContext.Provider value={{ goForward }}>
			{appWidth < modulesTheme.breakpoints.values.tablet ? (
				<Stack
					style={{
						height: "100%",
					}}
					spacing="16px"
				>
					<QStepper activeStep={groupStep} steps={groupList} />
					<Box ref={ref} sx={carouselChildrenStyle.carouselChildren}>
						{slideList[step].slide}
					</Box>
					{(buttons.goBackward || buttons.goForward) && (
						<Stack alignSelf="center" direction="row" spacing="20px">
							{step !== 0 && buttons.goBackward && (
								<QButton
									text={buttons.goBackwardText}
									variant="outlined"
									onClick={goBackward}
								/>
							)}
							{step !== slideList.length - 1 && buttons.goForward && (
								<QButton text={buttons.goForwardText} onClick={goForward} />
							)}
						</Stack>
					)}
				</Stack>
			) : (
				<Container className={classes.container}>
					<div className={classes.sectionStepper}>
						<QStepper activeStep={groupStep} steps={groupList} />
					</div>
					<Box className={classes.container}>
						<div className={classes.sectionContent}>
							<Box className={classes.flexBox}>{slideList[step].slide}</Box>
						</div>
						<div className={classes.sectionButtons}>
							{(buttons.goBackward || buttons.goForward) && (
								<Stack
									direction="row"
									spacing="20px"
									width="100%"
									justifyContent="center"
								>
									{step !== 0 && buttons.goBackward && (
										<QButton
											text={buttons.goBackwardText}
											variant="outlined"
											onClick={goBackward}
										/>
									)}
									{step !== slideList.length - 1 && buttons.goForward && (
										<QButton text={buttons.goForwardText} onClick={goForward} />
									)}
								</Stack>
							)}
						</div>
					</Box>
				</Container>
			)}
		</CarouselContext.Provider>
	);
};

export default Carousel;
