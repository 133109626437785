import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from "@mui/icons-material/Create";

const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
		marginRight: theme.spacing(2),
	},
	icon: {
		color: theme.palette.primary.main,
	},
}));

export default function EditMemberButton({ action }) {
	// const [open, setOpen] = React.useState(false);
	const classes = useStyles();

	function handleClick() {
		// setOpen(true);
		action();
	}

	// function handleCancel() {
	//   setOpen(false);
	// }

	// function handleDelete() {
	//   setOpen(false);
	//   // deleteMember();
	// }

	return (
		<>
			<Tooltip title="Edit member">
				<IconButton className={classes.button} onClick={handleClick} size="large">
					<CreateIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			{/* <DeleteMemberDialog
      open={open}
      handleCancel={handleCancel}
      handleDelete={handleDelete}
      // groupName={groupName}
    /> */}
		</>
	);
}
