import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { ModulesInputRadioPage } from "../../../model/modules/modules.Model";
import { ModulesData } from "../../../model/modulesData/modulesData.Model";
import { modulesStyles } from "../modulesStyles";

export interface RadioBoxProps {
	page: ModulesInputRadioPage;
	modulesData: ModulesData;
}

const RadioBox: FC<RadioBoxProps> = ({ page, modulesData }) => {
	const classes = modulesStyles();
	const [selection, setSelection] = useState("");

	useEffect(() => {
		setSelection(modulesData.getPageRecord(page.id, page.prompt) ?? "");
	}, [page.id]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelection(e.target.value);
		modulesData.setPageRecord(page.id, page.prompt, e.target.value);
	};

	return (
		<>
			<Typography variant="h6">{page.prompt}</Typography>
			<RadioGroup value={selection} onChange={handleChange}>
				{page.radioOptions.map((option) => {
					return (
						<FormControlLabel
							value={option}
							control={<Radio className={classes.radioBoxRadioButton} />}
							label={option}
							key={option}
						/>
					);
				})}
			</RadioGroup>
		</>
	);
};

export default RadioBox;
