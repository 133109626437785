import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import PeopleTableHead from "./PeopleTableHead";
import PeopleTableBody from "./PeopleTableBody";
import { PEOPLE_TABLE_TYPE } from "../../shared/constants";

import { MEMBER_TABLE_HEAD_CELLS, PENDING_TABLE_HEAD_CELLS } from "./TableHelpers";

const useStyles = makeStyles(() => ({
	table: {
		"& td": {
			whiteSpace: "nowrap",
		},
		"& th": {
			whiteSpace: "nowrap",
		},
	},
}));

export default function PeopleTable({ tableType, data }) {
	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("name");

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const headCells =
		tableType === PEOPLE_TABLE_TYPE.MEMBER ? MEMBER_TABLE_HEAD_CELLS : PENDING_TABLE_HEAD_CELLS;

	return (
		<Paper>
			<TableContainer>
				<Table stickyHeader className={classes.table}>
					<PeopleTableHead
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<PeopleTableBody
						tableType={tableType}
						order={order}
						orderBy={orderBy}
						data={data}
					/>
				</Table>
			</TableContainer>
		</Paper>
	);
}
