import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "count",
		numeric: false,
		disablePadding: false,
		label: "Count",
		// minWidth: 100,
	},
	{
		id: "stimulus",
		numeric: true,
		disablePadding: false,
		label: "Stimulus",
		// minWidth: 100,
	},
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={headCell.disablePadding ? "none" : undefined}
						style={{ minWidth: headCell.minWidth }}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
		borderRadius: 15,
	},

	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	noThoughts: {
		textAlign: "center",
		padding: "4em",
		fontStyle: "italic",
	},
	bottomRow: {
		"& .MuiTablePagination-displayedRows": {
			margin: 0,
		},
		"& .MuiTablePagination-selectLabel": {
			margin: 0,
		},
		"& .MuiTablePagination-input": {
			marginRight: 5,
			marginLeft: 0,
		},
		"& .MuiTablePagination-toolbar": {
			paddingLeft: 8,
		},
		"& .MuiTablePagination-actions": {
			marginLeft: 0,
		},
		"& .MuiIconButton-root": {
			paddingRight: 2,
			paddingLeft: 2,
		},
	},
}));

export default function EnhancedTable(props) {
	const data = props.data
		? [
				...props.data
					.filter((reflection) => reflection.stimulus != undefined)
					.reduce((tempMap, reflection) => {
						if (typeof reflection.stimulus === "string") {
							if (!tempMap.has(reflection.stimulus))
								tempMap.set(reflection.stimulus, { ...reflection, count: 0 });
							tempMap.get(reflection.stimulus).count++;
						} else {
							for (let i = 0; i < reflection.stimulus.length; i++) {
								let stim = reflection.stimulus[i];
								if (!tempMap.has(stim))
									tempMap.set(stim, {
										...reflection,
										stimulus: stim,
										count: 0,
										timestamp: reflection.timestamp + i,
									});
								tempMap.get(stim).count++;
							}
						}
						return tempMap;
					}, new Map())
					.values(),
			]
		: [];

	const classes = useStyles();
	const [order, setOrder] = React.useState("desc");
	const [orderBy, setOrderBy] = React.useState("count");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// useEffect(() => {
	//   setPage(0);
	//   setRowsPerPage(5);
	// }, [data]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = data.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<TableContainer className={classes.container}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={"small"}
						aria-label="enhanced table stimulus"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
						/>
						<TableBody>
							{stableSort(data, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const labelId = `enhanced-table-stimulus-checkbox-${index}`;
									return (
										<TableRow hover tabIndex={-1} key={row.timestamp}>
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												align="left"
											>
												{row.count}
											</TableCell>
											<TableCell align="left">{row.stimulus}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 33 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					className={classes.bottomRow}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}
EnhancedTable.propTypes = {
	data: PropTypes.array.isRequired,
};
