import { Invitation } from "@model/user/user.model";
import { ThemeProvider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { modulesStyles } from "src/components/Modules/modulesStyles";
import { modulesTheme } from "src/components/Modules/modulesTheme";
import QButton from "src/components/common/QButton";
import QModal from "src/components/common/QModal";
import { NavBarState } from "src/components/home/NavBar";
import { useTypedAppContext } from "src/libs/typedContextLib";
import { colors } from "../Theme";
import { apiAcceptInvitation, apiUpdateUser } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { TelemetryAction } from "../model/telemetry";
import { getAuthenticatedUserInfo } from "../utility/App.util";

const useStyles = makeStyles(() => ({
	invitationRowContainer: {
		flexDirection: "row",
		display: "flex",
		padding: 10,
		paddingLeft: 20,
		alignItems: "center",
		justifyContent: "center",
	},
}));

const Profile: FC = () => {
	const {
		//@ts-expect-error not typed
		appState: { invitations, authenticatedUserInfo },
		//@ts-expect-error not typed
		setSignedOutAppState,
		//@ts-expect-error not typed
		appDispatch,
	} = useAppContext();

	const {
		UIContext: { navBarState },
		Auth: { setCognitoUser },
	} = useTypedAppContext();

	const classes = modulesStyles();
	const history = useNavigate();

	async function handleClick() {
		logger.telemetry({ action: TelemetryAction.click, event: "profile_sign_out" });
		setCognitoUser(null);
		setSignedOutAppState();
	}

	const changePassword = async () => {
		logger.telemetry({ action: TelemetryAction.click, event: "profile_change_password" });
		try {
			await Auth.forgotPassword(authenticatedUserInfo.email);
			history("/forgot_password_verification");
		} catch (error) {
			console.log(error);
		}
	};

	const seenInvitations =
		!!invitations &&
		invitations.length > 0 &&
		invitations.filter((invitation: Invitation) => invitation.seen);
	const newInvitations =
		!!invitations &&
		invitations.length > 0 &&
		invitations.filter((invitation: Invitation) => !invitation.seen);

	const [showPopUp, setShowPopUp] = React.useState(false);

	React.useEffect(() => {
		if (newInvitations) {
			setShowPopUp(true);
		}
	}, []);

	const dismissInvitation = React.useCallback(async () => {
		setShowPopUp(false);
		appDispatch({
			type: "setAppState",
			invitations: [...seenInvitations, { ...newInvitations[0], seen: true }],
		});
		await apiUpdateUser(newInvitations[0].accountId, newInvitations[0].userId, {
			seen: true,
		});
	}, [seenInvitations, newInvitations]);

	const acceptInvitation = React.useCallback(async (company: string, accountId: string) => {
		await apiAcceptInvitation(company, accountId);
		await getAuthenticatedUserInfo(appDispatch);
	}, []);

	const showInvites = !!seenInvitations && seenInvitations.length > 0;

	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.pageBase : classes.pageBaseMobile
				}
			>
				<QModal
					open={!!showPopUp && !!newInvitations && newInvitations.length > 0}
					setOpen={setShowPopUp}
					cancelButton
					cancelButtonText="Dismiss"
					onCancel={dismissInvitation}
					saveButton
					saveButtonText="Accept"
					onSave={async () => {
						await acceptInvitation(
							newInvitations[0].company,
							newInvitations[0].accountId,
						);
						setShowPopUp(false);
						history("/home/shared_insights");
					}}
					width="300px"
					disableCancelClick
				>
					<Stack alignItems="center" spacing={modulesTheme.spacing(2)}>
						<Typography variant="h5">You're invited!</Typography>
						<Typography>
							You have been invited to join {newInvitations[0]?.team ?? "a new team"}{" "}
							at {newInvitations[0]?.company ?? "a new company"}. Joining a team
							allows you to share and view aggregated insights with the team.
						</Typography>
					</Stack>
				</QModal>

				{authenticatedUserInfo && (
					<Stack
						maxWidth="800px"
						justifyContent="center"
						alignItems="center"
						width="100%"
						height="100%"
						alignSelf="center"
					>
						<Stack
							width={showInvites ? "100%" : "auto"}
							spacing={modulesTheme.spacing(3)}
						>
							<Typography variant="h1">Profile</Typography>
							<Stack>
								<Typography variant="h5">{`${authenticatedUserInfo.firstName} ${authenticatedUserInfo.lastName}`}</Typography>
								<Typography variant="body">
									{authenticatedUserInfo.email}
								</Typography>
							</Stack>
							{showInvites && (
								<Stack spacing={modulesTheme.spacing(2)}>
									<Typography variant="h5">Pending Invitations</Typography>
									<div
										style={{
											borderRadius: 25,
											border: `1px solid ${colors.midGray}`,
											backgroundColor: "white",
										}}
									>
										{seenInvitations.map(
											(invitation: Invitation, index: number) => (
												<InvitationRow
													index={index}
													key={index}
													companyName={invitation.company}
													teamName={invitation.team ?? ""}
													accept={async () => {
														await acceptInvitation(
															invitation.company,
															invitation.accountId,
														);
														history("/home/shared_insights");
													}}
												/>
											),
										)}
									</div>
								</Stack>
							)}
							<Stack alignSelf="flex-start" spacing={modulesTheme.spacing(2)}>
								<QButton
									text="Change password"
									onClick={() => {
										void changePassword();
									}}
								/>
								<QButton text="Sign out" onClick={handleClick} />
							</Stack>
						</Stack>
					</Stack>
				)}
			</div>
		</ThemeProvider>
	);
};

export default Profile;

interface InvitationRowProps {
	companyName: string;
	teamName: string;
	index: number;
	accept: () => void;
}

const InvitationRow: FC<InvitationRowProps> = ({ companyName, teamName, index, accept }) => {
	const classes = useStyles();
	return (
		<div
			className={classes.invitationRowContainer}
			style={index === 0 ? {} : { borderTop: `1px solid ${colors.midGray}` }}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				spacing={modulesTheme.spacing(2)}
				width="100%"
			>
				<Stack>
					<Typography variant="h5">Company</Typography>
					<Typography>{companyName}</Typography>
				</Stack>
				<Stack>
					<Typography variant="h5">Team</Typography>
					<Typography>{teamName}</Typography>
				</Stack>
				<QButton text="Accept" onClick={accept} />
			</Stack>
		</div>
	);
};
