import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		color: theme.palette.primary.main,
	},
	subheading: {
		marginBottom: "64px",
		textAlign: "center",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	link: {
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
}));

export default function ChangePasswordConfirmComponent() {
	const history = useNavigate();
	const classes = useStyles();

	function clickHandler(e) {
		e.preventDefault();
		history("/");
	}

	return (
		<Container component="main" maxWidth="sm" className={classes.container}>
			<Typography paragraph variant="h5" className={classes.title}>
				Password Changed
			</Typography>
			<Container maxWidth="xs">
				<Typography variant={"body1"} className={classes.subheading}>
					Your password has been successfully updated!
				</Typography>

				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					onClick={clickHandler}
				>
					Return to Sign In
				</Button>
			</Container>
		</Container>
	);
}
