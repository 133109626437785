import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import QuimbyLogo from "../../images/quimbyLogo512.png";

export interface LoadingIconProps {
	size: "small" | "medium" | "large";
	text?: string;
}

const LoadingIcon: FC<LoadingIconProps> = ({ size, text }) => {
	const height = size === "large" ? 300 : size === "medium" ? 200 : 100;
	return (
		<Grid
			container
			spacing="20px"
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>
				<img
					className="logo-animation"
					src={QuimbyLogo}
					alt="Quimby logo"
					height={height}
				/>
			</Grid>
			<Grid item>{text && <Typography variant="h4">{text}</Typography>}</Grid>
		</Grid>
	);
};

export default LoadingIcon;
