import { ThemeProvider } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SetupInviteTeam from "src/components/setup/SetupInviteTeam";
import SetupProfile from "src/components/setup/SetupProfile";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import Carousel, { CarouselSlide } from "../components/common/Carousel";
import BrowserNotificationConfig from "../components/setup/BrowserNotificationConfig";
import SetupConclusion from "../components/setup/SetupConclusion";
import SetupDataPrivacy from "../components/setup/SetupDataPrivacy";
import SetupGoals from "../components/setup/SetupGoals";
import SetupReflection from "../components/setup/SetupReflection";
import SetupReminder from "../components/setup/SetupReminder";
import SetupStatements from "../components/setup/SetupStatements";
import { apiUpdateSetupInfo } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { SetupContext, useTypedAppContext } from "../libs/typedContextLib";
import { SetupGoal, SetupStatement } from "../model/setup.Model";
import { guids } from "../shared/constants";

const Setup: FC = () => {
	const [setupType, setSetupType] = useState<string>("");
	const [render, setRender] = useState<boolean>(false);

	useEffect(() => {
		if (cognitoUser) {
			cognitoUser.getUserAttributes((error, attributes) => {
				if (error) {
					logger.error(error);
				} else {
					if (attributes) {
						const profileAttribute = attributes.find(
							(attr) => attr.getName() === "profile",
						);
						if (profileAttribute) {
							setSetupType(profileAttribute.getValue());
						}
					}
				}
			});
		}
	}, []);

	const {
		//@ts-expect-error AppContext is not typed
		appState: { authenticatedUserInfo },
	} = useAppContext();

	const {
		Auth: { cognitoUser },
	} = useTypedAppContext();

	const profileSetupSlides: CarouselSlide[] = [
		{
			slide: <SetupProfile />,
			group: "Create your profile",
			buttons: { goForward: false, goBackward: false },
		},
	];

	const individualSlideList: CarouselSlide[] = [
		{ slide: <SetupGoals />, group: "Getting to know you" },
		{ slide: <SetupStatements />, group: "Getting to know you" },
		{ slide: <SetupDataPrivacy />, group: "Data and privacy" },
		{ slide: <SetupReflection />, group: "Practice emotional resilience" },
		{ slide: <SetupReminder />, group: "Set your reminders" },
		{ slide: <BrowserNotificationConfig />, group: "Set your reminders" },
		{ slide: <SetupConclusion />, group: "Set your reminders" },
	];

	const teamSlideList: CarouselSlide[] = [
		{
			slide: <SetupInviteTeam />,
			group: "Add team members",
			buttons: { goBackward: false, goForward: false },
		},
		{ slide: <SetupGoals />, group: "Getting to know you", buttons: { goBackward: false } },
		{ slide: <SetupStatements />, group: "Getting to know you" },
		{ slide: <SetupDataPrivacy />, group: "Data and privacy" },
		{ slide: <SetupReflection />, group: "Practice emotional resilience" },
		{ slide: <SetupReminder />, group: "Set your reminders" },
		{ slide: <BrowserNotificationConfig />, group: "Set your reminders" },
		{ slide: <SetupConclusion />, group: "Set your reminders" },
	];

	const createSetupSlideList = () => {
		const list = [];
		if (!authenticatedUserInfo) {
			list.push(...profileSetupSlides);
		}
		if (setupType === "team") {
			list.push(...teamSlideList);
		} else {
			list.push(...individualSlideList);
		}
		return list;
	};

	const slideList = createSetupSlideList();

	const [setupStatements, setSetupStatements] = useState<SetupStatement[]>([
		{ id: guids.SETUP_STATEMENT_0, isChecked: false },
		{ id: guids.SETUP_STATEMENT_1, isChecked: false },
		{ id: guids.SETUP_STATEMENT_2, isChecked: false },
		{ id: guids.SETUP_STATEMENT_3, isChecked: false },
		{ id: guids.SETUP_STATEMENT_4, isChecked: false },
		{ id: guids.SETUP_STATEMENT_5, isChecked: false },
	]);
	const [setupGoals, setSetupGoals] = useState<SetupGoal[]>([
		{ id: guids.SETUP_GOAL_0, isChecked: false },
		{ id: guids.SETUP_GOAL_1, isChecked: false },
		{ id: guids.SETUP_GOAL_2, isChecked: false },
		{ id: guids.SETUP_GOAL_3, isChecked: false },
	]);
	const [setupComplete, setSetupComplete] = useState<boolean>(
		authenticatedUserInfo?.setupInfo?.completedSetup,
	);

	const contextValue: SetupContext = {
		setSetupGoals,
		setupGoals,
		setSetupStatements,
		setupStatements,
		setSetupComplete,
		setupComplete,
	};
	const history = useNavigate();

	useEffect(() => {
		function apiUpdateSetupState() {
			const checkedGoals = setupGoals.reduce((result, goal) => {
				if (goal.isChecked) {
					result.push(goal.id);
				}
				return result;
			}, [] as string[]);

			const checkedStatements = setupStatements.reduce((result, statement) => {
				if (statement.isChecked) {
					result.push(statement.id);
				}
				return result;
			}, [] as string[]);

			const data = {
				goals: checkedGoals,
				statements: checkedStatements,
				completedSetup: setupComplete,
			};

			return apiUpdateSetupInfo(data);
		}

		async function submitSetupInfo() {
			try {
				await apiUpdateSetupState();
			} catch (e) {
				logger.error(e, "apiUpdateSetupState");
			}
		}
		void submitSetupInfo();
	}, [setupGoals, setupStatements, setupComplete]);

	const {
		UIContext: { appWidth },
	} = useTypedAppContext();
	const classes = modulesStyles();

	// If already setup then skip
	useEffect(() => {
		if (authenticatedUserInfo?.setupInfo?.completedSetup) {
			history("/home/dashboard_user");
		}
		setRender(true);
	}, []);

	return (
		<ThemeProvider theme={modulesTheme}>
			<SetupContext.Provider value={contextValue}>
				{render && (
					<div
						className={
							appWidth >= modulesTheme.breakpoints.values.tablet
								? classes.fullPage
								: classes.fullPageMobile
						}
					>
						<Carousel slideList={slideList} />
					</div>
				)}
			</SetupContext.Provider>
		</ThemeProvider>
	);
};

export default Setup;
