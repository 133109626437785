import GroupChip from "./GroupChip";
import { MEMBER_STATUS } from "../../shared/constants";
import PersonChip from "./PersonChip";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useAppContext } from "../../libs/contextLib";

const useStyles = makeStyles((theme) => ({
	// TODO: Old way of doing things but had issues, will delete if not needed
	// root: {
	//   display: "grid",
	//   rowGap: theme.spacing(1),
	//   gridTemplateColumns: " repeat(auto-fill, minmax(min(24ch, 100%), 1fr))",
	//   justifyItems: "center",
	// },
	root: {
		display: "flex",
		gap: theme.spacing(1),
		flexFlow: "row wrap",
	},
}));

export default function ChipGrid({ people = [], isEditMode, onDelete }) {
	const { appDispatch } = useAppContext();
	const classes = useStyles();
	const peopleArr = people.filter((pp) => !!pp); //Should not have an undefined element, but we do
	return (
		<div className={classes.root}>
			{peopleArr.map((person, index) => {
				let isGroup = person?.SK?.startsWith("G:") ?? false;

				return isGroup ? (
					<GroupChip
						key={index}
						name={person.name}
						isEditMode={isEditMode}
						groupManagers={person.groupManagers}
						groupMembers={person.groupMembers}
						// TODO: Currently not used
						// onDelete={() => {
						//   onDelete(person.SK);
						// }}
						onClick={() => {
							appDispatch({
								type: "setSelectedGroupId",
								selectedGroupId: person.SK,
							});
						}}
					/>
				) : (
					<PersonChip
						key={index}
						name={
							person.status === MEMBER_STATUS.MEMBER
								? `${person.firstName} ${person.lastName}`
								: person.email
						}
						status={person.status}
						isEditMode={isEditMode}
						onDelete={() => {
							onDelete(person.SK);
						}}
					/>
				);
			})}
		</div>
	);
}
