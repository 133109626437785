import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { FC } from "react";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";

const SetupDataPrivacy: FC = () => {
	const classes = modulesStyles();
	return (
		<Stack spacing={modulesTheme.spacing(2)} justifyContent="center" alignItems="center">
			<Typography variant="h5">Who can see your data?</Typography>
			<Typography variant="body1">
				Your safety and confidentiality is important to us.
			</Typography>
			<Stack spacing={modulesTheme.spacing(2)} maxWidth="800px">
				<div style={{ display: "flex", alignItems: "center" }}>
					<img
						alt="Eyeball"
						className={classes.setupIcon}
						src={require("../../images/data_privacy_3.png")}
					/>
					<Typography variant="body2">
						Only you can see your data. Individual responses are never shared.
					</Typography>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<img
						alt="Group of people"
						className={classes.setupIcon}
						src={require("../../images/data_privacy.png")}
					/>
					<Typography variant="body2">
						Group insights represent aggregated data from your team. Aggregated data is
						shown if at least three unique users submit responses.
					</Typography>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<img
						alt="Hands holding a heart"
						className={classes.setupIcon}
						src={require("../../images/data_privacy_2.png")}
					/>
					<Typography variant="body2">
						To promote transparency and a supportive culture, both managers and team
						members can see the group insights.
					</Typography>
				</div>
			</Stack>
		</Stack>
	);
};
SetupDataPrivacy.propTypes = {
	goForward: PropTypes.func,
};

export default SetupDataPrivacy;
