import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { EmotionStimulusData, EmotionWordGroup } from "@model/reflection/reflection.Model";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { FC } from "react";
import QChip from "src/components/common/QChip";
import { colors } from "../../../Theme";
import {
	emotionWordGroups,
	emotionWordList,
} from "../../../components/ReflectionSlides/SharedComponents/EmotionWordList";
import EmotionWordPicker from "../../../components/ReflectionSlides/SharedComponents/EmotionWordPicker";
import StimulusPicker from "../../../components/ReflectionSlides/SharedComponents/StimulusPicker";
import QModal from "../../../components/common/QModal";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";

const useStyles = makeStyles(() => ({
	inputField: {
		textTransform: "capitalize",
		borderColor: colors.teal,
		borderStyle: "solid",
		borderRadius: 6,
		borderWidth: "2px",
		backgroundColor: "transparent",
		paddingLeft: 5,
		color: colors.black,
		width: "100%",
		minHeight: 40,
		"& .MuiAutocomplete-inputRoot": {
			paddingTop: 2,
		},
	},
	padding: {
		paddingBottom: "20px",
	},
	showMore: {
		color: colors.teal,
		fontSize: 18,
		alignSelf: "flex-end",
		display: "inline-block",
		pointerEvents: "auto",
		cursor: "pointer",
		textAlign: "right",
	},
	showMoreContainer: {
		width: "100%",
		height: 50,
		position: "relative",
		textAlign: "right",
		justifyContent: "flex-end",
		display: "flex",
	},
	popUpClose: {
		display: "block",
		width: 30,
		height: 30,
		textAlign: "center",
		position: "absolute",
		right: 5,
		top: 5,
		cursor: "pointer",
		fontSize: 30,
		fontWeight: "bold",
	},
	popUpContainer: {
		borderRadius: 6,
		border: "solid 1px #707070",
		backgroundColor: "#fff",
		padding: "20px",
		paddingTop: "40px",
		position: "absolute",
		zIndex: 1,
		marginBottom: "20px",
	},
	pupUpText: {
		fontWeight: "300",
		fontSize: 10,
		lineHeight: 1,
		display: "inline-flex",
	},
}));

interface EmotionStimulusSlideProps {
	page: ModulesLegacyPage;
}

const EmotionStimulusSlide: FC<EmotionStimulusSlideProps> = ({ page }) => {
	const { emotionStimList, setEmotionStimList, stimuli } = useReflectionModelContext();
	const [emotionWordPicked, setEmotionWordPicked] = React.useState("");
	const [stimulusPopUp, setStimulusPopUp] = React.useState(false);
	const [selectedEmotion, setSelectedEmotion] = React.useState(-1);

	const classes = useStyles();

	React.useEffect(() => {
		if (emotionWordPicked) {
			const item = document.getElementById(emotionWordPicked.toLocaleLowerCase());
			if (item) {
				if (item) {
					const itemPos = item.offsetTop;
					const container = document.getElementById(emotionWordPicked.toLowerCase())
						?.parentElement?.parentElement;
					container!.scrollTop = itemPos;
				}
			}
		}
	}, [emotionWordPicked]);

	const addEmotionWord = (word: string) => {
		if (
			!emotionStimList.find(
				(emotion) => emotion.emotion.toLocaleLowerCase() === word.toLocaleLowerCase(),
			)
		) {
			setEmotionStimList(
				emotionStimList.concat({
					emotion: word,
					stimuli: [],
					shareStimuli: false,
				}),
			);
			setSelectedEmotion(emotionStimList.length);
			setStimulusPopUp(true);
		}
	};

	const removeEmotionWord = (index: number) => {
		const temp = JSON.parse(JSON.stringify(emotionStimList));
		temp.splice(index, 1);
		setEmotionStimList(temp);
		setStimulusPopUp(false);
		setSelectedEmotion(-1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5">
						What emotions are you feeling most strongly right now?
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Autocomplete
						multiple
						selectOnFocus
						multiline="true"
						//@ts-expect-error forcing type of value
						value={emotionStimList}
						inputValue={emotionWordPicked}
						onInputChange={(_event, newInputValue) => {
							const lista = newInputValue.split(",");
							if (lista.length > 1) {
								if (
									!emotionStimList.find(
										(x) =>
											x.emotion.toLocaleLowerCase() ===
											lista[0].toLocaleLowerCase(),
									)
								) {
									addEmotionWord(
										lista
											.map((x) => x.trim())
											.filter((x) => x)
											.toString()
											.toLocaleLowerCase(),
									);
									setStimulusPopUp(true);
								}
								setEmotionWordPicked("");
							} else {
								setEmotionWordPicked(newInputValue);
							}
						}}
						id="emotions"
						options={emotionWordList}
						sx={{ width: "100%" }}
						freeSolo
						onChange={(_event, newValue, reason) => {
							if (reason === "selectOption" || reason === "createOption") {
								setStimulusPopUp(true);
								addEmotionWord(newValue[newValue.length - 1]);
							}
							if (reason === "clear") {
								setEmotionStimList([]);
								setStimulusPopUp(false);
								setSelectedEmotion(-1);
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								InputProps={{ ...params.InputProps, disableUnderline: true }}
								inputProps={{
									...params.inputProps,
									style: { textTransform: "capitalize", paddingLeft: 10 },
								}}
								className={classes.inputField}
								variant="standard"
								hiddenLabel
								placeholder="Type your emotions here"
								margin="normal"
							/>
						)}
						renderTags={(value) =>
							//@ts-expect-error forcing option type
							value.map((option: EmotionStimulusData, index) => (
								<div
									style={{
										padding: "4px",
									}}
								>
									<QChip
										key={index}
										label={option.emotion}
										onDelete={() => {
											removeEmotionWord(index);
										}}
										onClick={() => {
											setSelectedEmotion(index);
											setStimulusPopUp(true);
										}}
										clickable
										variant={
											index === selectedEmotion ? "contained" : "outlined"
										}
									/>
								</div>
							))
						}
					/>
					{stimulusPopUp && ( //have to do this because stimulus picker barfs on no data
						<StimulusPopUp
							open={stimulusPopUp}
							setOpen={setStimulusPopUp}
							emotionStimList={emotionStimList}
							setEmotionStimList={setEmotionStimList}
							selectedEmotion={selectedEmotion}
							stimuli={stimuli}
						/>
					)}
				</Grid>
				<Grid item mobile={12}>
					<EmotionWordPicker
						emotionStimList={emotionStimList}
						addEmotion={addEmotionWord}
						emotionWords={emotionWordGroups as EmotionWordGroup[]}
					/>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

const StimulusPopUp = ({
	//@ts-expect-error type any
	open,
	//@ts-expect-error type any
	setOpen,
	//@ts-expect-error type any
	emotionStimList,
	//@ts-expect-error type any
	setEmotionStimList,
	//@ts-expect-error type any
	selectedEmotion,
	//@ts-expect-error type any
	stimuli,
}) => {
	return (
		<QModal open={open} setOpen={setOpen} disableCancelClick saveButton saveButtonText="Done">
			<StimulusPicker
				emotionStimList={emotionStimList}
				setEmotionStimList={setEmotionStimList}
				selectedEmotion={selectedEmotion}
				stimuli={stimuli}
			/>
		</QModal>
	);
};
StimulusPopUp.propTypes = {
	emotionStimList: PropTypes.arrayOf(
		PropTypes.shape({
			emotion: PropTypes.string.isRequired,
			stimuli: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					inputValue: PropTypes.string.isRequired,
					new: PropTypes.bool,
				}),
			),
			shareStimuli: PropTypes.bool.isRequired,
		}),
	),
	setEmotionStimList: PropTypes.func.isRequired,
	selectedEmotion: PropTypes.number,
	stimuli: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			inputValue: PropTypes.string.isRequired,
		}),
	),
	setOpen: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default EmotionStimulusSlide;
