import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EDIT_RECORD_KEY, KILL_EDIT_MODE_ACTION } from "../../shared/constants";
import EditButtons from "./EditButtons";
import clsx from "clsx";
import ParentGroupPicker from "./ParentGroupPicker";

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
		width: "fit-content",
	},
}));

export default function ParentGroupPickerEditControl({
	groupsTree,
	selectedGroupId,
	parentGroupId,
	setParentGroupId,
	updateGroupEditRecord,
	killEditMode,
	canEditGroupsSection,
}) {
	const [showEditIcon, setShowEditIcon] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isModified, setIsModified] = useState(false);
	const [parentGroupIdCopy, setParentGroupIdCopy] = useState(parentGroupId);
	const [isLoading, setIsLoading] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (killEditMode) {
			killEditMode === KILL_EDIT_MODE_ACTION.SAVE ? void saveAction() : cancelAction();
		}
	}, [killEditMode]);

	useEffect(() => {
		setIsEditMode(parentGroupIdCopy !== parentGroupId);

		updateGroupEditRecord({
			key: EDIT_RECORD_KEY.PARENT,
			isModified: isModified,
		});
	}, [isModified, parentGroupIdCopy, parentGroupId]);

	// Reset this to the selected group's parent whenever
	// the selected group changes
	useEffect(() => {
		setParentGroupIdCopy(parentGroupId);
	}, [selectedGroupId]);

	function handleMouseEnter() {
		if (canEditGroupsSection) setShowEditIcon(true);
	}

	function handleMouseLeave() {
		if (canEditGroupsSection) setShowEditIcon(false);
	}

	async function saveAction() {
		setIsLoading(true);

		await setParentGroupId(parentGroupIdCopy);

		setIsLoading(false);
		setIsModified(false);
		setIsEditMode(false);
	}

	function cancelAction() {
		setParentGroupIdCopy(parentGroupId);
		setIsModified(false);
		setIsEditMode(false);
	}

	function changeHandler(value) {
		setParentGroupIdCopy(value);
		setIsModified(value !== parentGroupId);
	}

	return (
		<div className={classes.root}>
			<div
				className={clsx(showEditIcon && classes.editHover)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<EditButtons
					saveAction={saveAction}
					cancelAction={cancelAction}
					showEditIcon={showEditIcon}
					setShowEditIcon={setShowEditIcon}
					isEditMode={isEditMode}
					setIsEditMode={setIsEditMode}
					isModified={isModified}
					isLoading={isLoading}
				>
					<ParentGroupPicker
						groupsTree={groupsTree}
						selectedGroupId={selectedGroupId}
						parentGroupId={parentGroupIdCopy}
						changeHandler={changeHandler}
						canEditGroupsSection={canEditGroupsSection}
					/>
				</EditButtons>
			</div>
		</div>
	);
}
