import { ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, ReactNode } from "react";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";
import { NavBarState } from "../home/NavBar";

const useStyles = makeStyles(() => ({
	container: {
		height: "100%",
		width: "100%",
		maxWidth: "1200px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "0px",
	},
	sectionContent: {
		width: "100%",
		maxWidth: "816px",
		paddingLeft: "8px",
		paddingRight: "8px",
		overflow: "auto",
	},
	flexBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
	},
}));

interface FullPageWrapperProps {
	children: ReactNode;
}

const FullPageWrapper: FC<FullPageWrapperProps> = ({ children }) => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();
	const classes2 = useStyles();
	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.fullPage : classes.fullPageMobile
				}
			>
				<div className={classes2.container}>
					<div className={classes2.sectionContent}>{children}</div>
				</div>
			</div>
		</ThemeProvider>
	);
};

export default FullPageWrapper;
