import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import LoaderButton from "./LoaderButton";
import { Auth } from "aws-amplify";
import { useFormFields, useErrorFields } from "../../libs/hooksLib";
import {
	validateVerificationCode,
	validateEmail,
	validatePassword,
	validateVerifyPassword,
} from "../../libs/formValidationLib";
import { logger } from "../../libs/logLib";
import { useAppContext } from "../../libs/contextLib";

const PASSWORD_REQUIREMENTS =
	"Password requires 8 characters minimum, a lowercase letter, an uppercase letter, a number and a special character";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		color: theme.palette.primary.main,
	},
	padding: {
		marginBottom: "32px",
	},
	errorText: {
		color: theme.palette.error.main,
		marginBottom: "32px",
		textAlign: "center",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	link: {
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
}));

export default function ForgotPasswordComponent() {
	const history = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [errorText, setErrorText] = useState("");
	const {
		appState: { authenticatedUserInfo },
	} = useAppContext();
	const [fields, handleFieldChange] = useFormFields({
		email: authenticatedUserInfo.email ?? "",
		newPassword: "",
		verifyNewPassword: "",
		confirmationCode: "",
	});

	const [errors, setError] = useErrorFields({
		email: { errorText: "", error: false },
		newPassword: { errorText: "", error: false },
		verifyNewPassword: { errorText: "", error: false },
		confirmationCode: { errorText: "", error: false },
	});
	const classes = useStyles();

	function validateForm() {
		return (
			!errors.confirmationCode.error &&
			!errors.email.error &&
			!errors.newPassword.error &&
			!errors.verifyNewPassword.error
		);
	}

	async function handleSubmit(event) {
		event.preventDefault();

		setIsLoading(true);

		// AWS Cognito integration here
		try {
			await Auth.forgotPasswordSubmit(
				fields.email,
				fields.confirmationCode,
				fields.newPassword,
			);
			history("/change_password_confirmation");
			setErrorText("");
		} catch (e) {
			setIsLoading(false);
			setErrorText(logger.error(e, "forgotPasswordSubmit"));
		}
	}

	function handleLinkClick(e) {
		e.preventDefault();
		history("/");
	}

	function handleFocus(e) {
		setError(e.target.id, { errorText: "", error: false });
	}

	function handleBlurValidation(e) {
		const { id, value } = e.target;

		switch (id) {
			case "confirmationCode": {
				setError(id, validateVerificationCode(value));
				break;
			}
			case "email": {
				setError(id, validateEmail(value));
				break;
			}
			case "newPassword": {
				setError(id, validatePassword(value));
				break;
			}
			case "verifyNewPassword": {
				setError(id, validateVerifyPassword(value, fields.newPassword));
				break;
			}
			default:
				break;
		}
	}

	function handleKeyUp(e) {
		e.preventDefault();

		// ENTER Key
		if (e.keyCode === 13) {
			e.target.blur();
		}
	}

	return (
		<Container component="main" maxWidth="sm" className={classes.container}>
			<Typography paragraph variant="h5" className={classes.title}>
				Set New Password
			</Typography>
			<Container maxWidth="xs">
				<Typography variant={"body1"} className={classes.padding}>
					Please enter the confirmation code sent to your email address below, your email
					address and a new password.
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								autoFocus
								required
								name="confirmationCode"
								variant="outlined"
								fullWidth
								id="confirmationCode"
								label="Confirmation Code"
								type="text"
								value={fields.confirmationCode}
								onChange={handleFieldChange}
								onKeyUp={handleKeyUp}
								error={errors.confirmationCode.error}
								helperText={errors.confirmationCode.errorText}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								autoComplete="email"
								type="email"
								value={fields.email}
								onChange={handleFieldChange}
								onBlur={handleBlurValidation}
								onFocus={handleFocus}
								onKeyUp={handleKeyUp}
								error={errors.email.error}
								helperText={errors.email.errorText}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="newPassword"
								label="New Password"
								type="password"
								id="newPassword"
								value={fields.newPassword}
								onChange={handleFieldChange}
								onBlur={handleBlurValidation}
								onFocus={handleFocus}
								onKeyUp={handleKeyUp}
								error={errors.newPassword.error}
								helperText={
									errors.newPassword.error
										? errors.newPassword.errorText
										: PASSWORD_REQUIREMENTS
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="verifyNewPassword"
								label="Verify New Password"
								type="password"
								id="verifyNewPassword"
								value={fields.verifyNewPassword}
								onChange={handleFieldChange}
								onBlur={handleBlurValidation}
								onFocus={handleFocus}
								onKeyUp={handleKeyUp}
								error={errors.verifyNewPassword.error}
								helperText={errors.verifyNewPassword.errorText}
							/>
						</Grid>
						<LoaderButton
							style={{ marginTop: "10px" }}
							buttonText="Submit"
							isLoading={isLoading}
							isValidated={validateForm()}
						/>
					</Grid>
					<Grid container>
						<Grid item xs></Grid>

						<Grid item>
							<Link
								id="signIn"
								href="#"
								variant="body2"
								className={classes.link}
								onClick={handleLinkClick}
								underline="hover"
							>
								Return to sign in
							</Link>
						</Grid>
					</Grid>
				</form>
			</Container>
		</Container>
	);
}
