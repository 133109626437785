import { Autocomplete, Grid, TextField, Tooltip, tooltipClasses } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { FC, useState } from "react";
import { colors } from "../../../../Theme";
import { useReflectionModelContext } from "../../../../libs/typedContextLib";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ModulesNav from "../../Shared/ModulesNav";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	button: {
		backgroundColor: "white",
		borderRadius: 20,
		padding: 10,
		height: 40,
		minWidth: 100,
		alignItems: "center",
		cursor: "pointer",
		margin: 8,
		justifyContent: "center",
		display: "flex",
		borderStyle: "solid",
		borderWidth: "1px",
		borderColor: colors.teal,
		"&:hover": {
			backgroundColor: colors.lightTeal,
		},
	},
	smallButton: {
		backgroundColor: colors.midTeal,
		borderRadius: 20,
		padding: 10,
		height: 25,
		minWidth: 95,
		alignItems: "center",
		cursor: "pointer",
		margin: 2,
		justifyContent: "center",
		display: "flex",
		borderStyle: "solid",
		borderWidth: "1px",
		borderColor: colors.midTeal,
	},
	buttonText: {
		color: colors.teal,
		textAlign: "center",
		fontSize: 16,
		fontWeight: 500,
	},
	stimuliContainer: {
		flexDirection: "row",
		justifyContent: "center",
		display: "flex",
		flexWrap: "wrap",
		paddingTop: "4%",
		alignContent: "flex-start",
	},
	inputField: {
		textTransform: "capitalize",
		borderColor: colors.midGray,
		borderStyle: "solid",
		borderRadius: 6,
		borderWidth: "1px",
		backgroundColor: "transparent",
		paddingLeft: 5,
		color: colors.teal,
		width: "100%",
		height: 40,
		"& .MuiAutocomplete-inputRoot": {
			paddingTop: 2,
		},
	},
	showMore: {
		color: colors.black,
		fontWeight: "bold",
		fontSize: 18,
		alignSelf: "flex-end",
		display: "inline-block",
		pointerEvents: "auto",
		cursor: "pointer",
	},
	popUpContainer: {
		borderRadius: 15,
		border: "solid 1px #707070",
		backgroundColor: "#fff",
		width: 300,
		padding: 20,
		position: "absolute",
	},
	pupUpText: {
		fontWeight: "300",
		fontSize: 10,
		lineHeight: 1,
		display: "inline-flex",
	},
	switchContainer: {
		flexDirection: "row",
		marginTop: 15,
		alignItems: "center",
		justifyContent: "space-around",
		display: "flex",
	},
	switchContainerSpan: {
		fontWeight: "bold",
		fontSize: 12,
	},
	popUpClose: {
		display: "block",
		width: 20,
		height: 25,
		textAlign: "center",
		position: "absolute",
		right: 5,
		top: 5,
		cursor: "pointer",
	},
}));

const values = [
	"Acceptance",
	"Assertiveness",
	"Authenticity",
	"Autonomy",
	"Belonging",
	"Care",
	"Compassion",
	"Connection",
	"Contribution",
	"Cooperation",
	"Courage",
	"Creativity",
	"Curiosity",
	"Encouragement",
	"Excitement",
	"Flexibility",
	"Gratitude",
	"Honesty",
	"Justice",
	"Kindness",
	"Learning & Growth",
	"Mindfulness",
	"Persistence",
	"Purpose",
	"Recognition",
	"Respect",
	"Responsibility",
	"Safety",
	"Stability",
	"Trust",
	"Understanding",
];

interface ValuesSlideProps {
	page: ModulesLegacyPage;
}

const ValuesSlide: FC<ValuesSlideProps> = ({ page }) => {
	const { value, setValue, weight, setWeight } = useReflectionModelContext();
	const classes = useStyles();
	const onChangeHandle = (
		_event: React.SyntheticEvent<Element, Event>,
		newValue: string | null,
		reason: string,
	) => {
		if (reason === "clear") {
			setValue("");
			setWeight(0);
		} else {
			setValue(newValue ?? "");
		}
	};

	const saveTheValue = (value: string, weight: number) => {
		setValue(value);
		setWeight(weight);
	};

	const InputButtons = () => {
		if (!value) {
			return null;
		}
		return (
			<div style={{ display: "flex", position: "absolute", right: 30, top: 20 }}>
				<div
					className={classes.smallButton}
					style={
						weight === -1
							? { backgroundColor: colors.midGray, borderColor: colors.midGray }
							: {}
					}
				>
					<Typography
						className={classes.buttonText}
						onClick={() => setWeight(1)}
						style={{ color: "white", textTransform: "capitalize" }}
					>
						Satisfied
					</Typography>
				</div>
				<div
					className={classes.smallButton}
					style={
						weight === 1
							? { backgroundColor: colors.midGray, borderColor: colors.midGray }
							: weight === -1
								? { backgroundColor: colors.orange, borderColor: colors.orange }
								: {}
					}
				>
					<Typography
						className={classes.buttonText}
						onClick={() => setWeight(-1)}
						style={{ color: "white", textTransform: "capitalize" }}
					>
						Challenged
					</Typography>
				</div>
			</div>
		);
	};
	const textColor = colors.black;

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5">What are you valuing in this moment?</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1">
						Choose the value and whether you perceive it as satisfied or in conflict.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography paragraph variant="body1">
						Emotions can direct you to what you care about most. E.g., If you are
						fulfilled because you were able to help a coworker, contribution or
						connection might be a value that is important to you. If you are
						disappointed because you felt excluded by others, belonging or compassion
						might be important to you. Type in your value or choose from the options
						below.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Autocomplete
						value={value}
						onChange={onChangeHandle}
						inputValue={value}
						onInputChange={(_event, newInputValue) => {
							setValue(newInputValue);
							setWeight(undefined);
						}}
						id="valuing"
						sx={{ width: "100%" }}
						options={values}
						freeSolo
						renderInput={(params) => {
							return (
								<div style={{ position: "relative" }}>
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											disableUnderline: true,
										}}
										inputProps={{
											...params.inputProps,
											style: {
												textTransform: "capitalize",
												paddingLeft: 10,
												color: textColor,
											},
										}}
										className={classes.inputField}
										variant="standard"
										hiddenLabel
										placeholder="Type your answer here."
										margin="normal"
									/>
									<InputButtons />
								</div>
							);
						}}
						renderOption={(props, option, { inputValue }) => {
							const word = option;
							const matches = match(word, inputValue);
							const parts = parse(word, matches);
							return (
								<li {...props}>
									<div>
										{parts.map(
											(
												part: { highlight: boolean; text: string },
												index: number,
											) => (
												<span
													key={index}
													style={{
														fontWeight: part.highlight ? 700 : 400,
														textTransform: "capitalize",
													}}
												>
													{part.text}
												</span>
											),
										)}
									</div>
								</li>
							);
						}}
					/>
				</Grid>
				<Grid item mobile={12}>
					<div className={classes.stimuliContainer}>
						{!!values &&
							values.map((str) => (
								<Button
									value={str}
									key={str}
									savedValue={value}
									savedWeight={weight}
									onPress={(weight) => {
										saveTheValue(str, weight);
									}}
								/>
							))}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default ValuesSlide;

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "rgba(255,255,255,.5)",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
	},
}));

interface ButtonProps {
	value: string;
	onPress: (number: number) => void;
	savedValue: string;
	savedWeight: number | undefined;
}

const Button: FC<ButtonProps> = ({ value, onPress, savedValue, savedWeight }) => {
	const classes = useStyles();
	const active = savedValue.toLocaleLowerCase() === value.toLocaleLowerCase();
	const [open, setOpen] = useState(false);
	let backgroundColor;
	switch (savedWeight) {
		case 1:
			backgroundColor = colors.teal;
			break;
		case -1:
			backgroundColor = colors.orange;
			break;
		case 0:
			backgroundColor = colors.lightGray;
			break;
		default:
			backgroundColor = colors.white;
			break;
	}
	return (
		<HtmlTooltip
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			title={
				<div style={{ display: "flex", zIndex: 20000 }}>
					<div className={classes.smallButton} onClick={() => onPress(1)}>
						<Typography
							className={classes.buttonText}
							style={{ color: "white", textTransform: "capitalize" }}
						>
							Satisfied
						</Typography>
					</div>
					<div className={classes.smallButton} onClick={() => onPress(-1)}>
						<Typography
							className={classes.buttonText}
							style={{ color: "white", textTransform: "capitalize" }}
						>
							Challenged
						</Typography>
					</div>
				</div>
			}
			arrow
			placement="top"
		>
			<div
				className={classes.button}
				style={
					active ? { backgroundColor: backgroundColor, borderColor: backgroundColor } : {}
				}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<Typography
					className={classes.buttonText}
					style={
						active
							? { color: "white", textTransform: "capitalize" }
							: { textTransform: "capitalize" }
					}
				>
					{value}
				</Typography>
			</div>
		</HtmlTooltip>
	);
};
