import RefreshIcon from "@mui/icons-material/Refresh";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { useAppContext } from "../libs/contextLib";

const CHILD_GROUP_INDENT = 16;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const useMenuStyles = makeStyles((theme) => ({
	root: {
		"&:hover": {
			color: "black",
			backgroundColor: theme.palette.primary.light,
			"& .childGroup": {
				borderLeft: "solid 2px black",
			},
		},
		"& .childGroup": {
			borderLeft: "solid 2px #000000DE",
			paddingLeft: "8px",
		},
	},
	selected: {
		color: "white",
		backgroundColor: theme.palette.primary.main,
		"& .childGroup": {
			borderLeft: "solid 2px white",
		},
		"&.Mui-selected": {
			backgroundColor: theme.palette.primary.main,
		},
		"&.Mui-selected:hover": {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

export default function GroupPicker(props) {
	const classes = useStyles();
	const menuClasses = useMenuStyles();
	const { flatGroupTree, dataSource, setDataSource, updateReflections } = props;

	const handleChange = (event) => {
		setDataSource(event.target.value);
	};

	// Used to remove hierarchy styling from the selected item
	const renderSelectedItem = (value) => {
		if (flatGroupTree.length === 0) return "No groups configured";
		const found = flatGroupTree.find((group) => group.groupId === value);
		if (found) {
			return found.name;
		}
		return "select group";
	};
	return (
		<div className={classes.root}>
			<FormControl
				variant="outlined"
				className={classes.formControl}
				disabled={flatGroupTree.length === 0}
			>
				<InputLabel id="demo-simple-select-outlined-label">Groups</InputLabel>
				<Select
					variant="standard"
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					value={dataSource}
					onChange={handleChange}
					label="Groups"
					renderValue={renderSelectedItem}
				>
					{flatGroupTree.map((groupInfo, index) => {
						let indent = groupInfo.level * CHILD_GROUP_INDENT;
						let showBar = groupInfo.level > 0 && !groupInfo.isGOG;

						return (
							<MenuItem
								key={index}
								value={groupInfo.groupId}
								classes={{
									root: menuClasses.root,
									selected: menuClasses.selected,
								}}
							>
								{groupInfo.isGOG ? (
									groupInfo.name
								) : (
									<div
										className={`${showBar && "childGroup"}`}
										style={{ marginLeft: `${indent}px` }}
									>
										{groupInfo.name}
									</div>
								)}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<Tooltip title="Refresh">
				<IconButton
					color="primary"
					className={classes.button}
					onClick={updateReflections}
					size="large"
				>
					<RefreshIcon />
				</IconButton>
			</Tooltip>
		</div>
	);
}

GroupPicker.propTypes = {
	flatGroupTree: PropTypes.array.isRequired,
	dataSource: PropTypes.array.isRequired,
	setDataSource: PropTypes.any.isRequired,
	updateReflections: PropTypes.func.isRequired,
};

export const SubscriptionPicker = ({ updateReflections }) => {
	const classes = useStyles();
	const menuClasses = useMenuStyles();
	const {
		appState: { groupsArray, currentId },
		appDispatch,
		// refreshGroupPicker
	} = useAppContext();

	const handleChange = (event) => {
		appDispatch({
			type: "setAppState",
			currentId: event.target.value,
		});
		updateReflections(event.target.value);
	};

	React.useEffect(() => {
		// eslint-disable-next-line no-undef
		const test = window.setTimeout(() => {
			//this is a hacky solution
			//TODO: find why calling updateReflections() above won't refresh the reflections data
			updateReflections();
		}, 200);
		return () => {
			clearInterval(test);
		};
	}, [currentId]);

	if (!groupsArray) return null;

	return (
		<FormControl
			variant="outlined"
			className={classes.formControl}
			disabled={groupsArray.length <= 1}
		>
			<InputLabel id="demo-simple-select-outlined-label">Subscriptions</InputLabel>
			<Select
				variant="standard"
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				value={currentId}
				onChange={handleChange}
				label="Groups"
			>
				{!!groupsArray &&
					groupsArray.map((group, index) => {
						return (
							<MenuItem
								key={index}
								value={group.PK}
								classes={{
									root: menuClasses.root,
									selected: menuClasses.selected,
								}}
							>
								{group.groupTree[0].name}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
};
SubscriptionPicker.propTypes = {
	updateReflections: PropTypes.func.isRequired,
};
