import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import Opposite_Action from "../../../../images/interventions/intervention_opposite_action.png";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import ClickableLink from "../../../common/ClickableLink";
import ProgressBar from "../../../common/ProgressBar";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const promptList = [
	{
		title: "Opposite Action",
		body: (
			<div>
				When we feel anxious about our workload or a specific task, our tendency is to avoid
				or procrastinate. But over time that leads to more anxiety. Practice confronting the
				stimulus of the to-do list by taking one step at a time. Pick one simple (least
				effort or the most well-defined thing) from your to-do list and get it done. <br />{" "}
				<br />
				Make sure you reward yourself for finishing it.
			</div>
		),
		image: Opposite_Action,
		imageAlt: "Opposite action",
	},
];

interface OppositeActionInterventionSlideProps {
	page: ModulesLegacyPage;
}

const OppositeActionInterventionSlide: FC<OppositeActionInterventionSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const [step, setStep] = React.useState(0);

	const nextStep = () => {
		setStep(step + 1);
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt={promptList[step].imageAlt}
							src={promptList[step].image}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					{promptList[step].title === "" ? (
						""
					) : (
						<Typography variant="h5" paddingBottom="20px">
							{promptList[step].title}
						</Typography>
					)}
					<Typography variant="body1">{promptList[step].body}</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={` ${classes.modulesItemCentered} ${
							promptList.length === 1 ? classes.hidden : ""
						}`}
					>
						<ProgressBar totalSteps={promptList.length} step={step} />
					</div>
					<div className={classes.modulesItemCentered}>
						{step >= promptList.length - 1 ? (
							<></>
						) : (
							<ClickableLink text="Continue" onClick={nextStep} />
						)}
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};
export default OppositeActionInterventionSlide;
