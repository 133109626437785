import React from "react";
import Chip from "@mui/material/Chip";
import GroupIcon from "@mui/icons-material/Group";
import GroupChipToolTip from "./GroupChipTooltip";
import { useStyles } from "./GroupChip.styles";

export default function GroupChip({ name, groupManagers, groupMembers }) {
	const classes = useStyles();

	return (
		<GroupChipToolTip groupManagers={groupManagers} groupMembers={groupMembers}>
			<Chip
				icon={<GroupIcon className={classes.groupIcon} />}
				label={`${name} (${groupMembers.length})`}
				className={classes.group}
				classes={{ clickable: classes.clickable }}
			/>
		</GroupChipToolTip>
	);
}
