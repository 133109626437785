import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import FeelingSlider from "../../../components/ReflectionSlides/SharedComponents/FeelingSlider";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";

export interface MoraleSlideProps {
	page: ModulesLegacyPage;
}

const MoraleSlide: FC<MoraleSlideProps> = ({ page }) => {
	const { userName } = useReflectionModelContext();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid
				container
				justifyContent="center"
				spacing="20px"
				height="100%"
				alignItems="center"
			>
				<Grid item mobile={12}>
					<Typography variant="h2">
						{`${userName ? `Hey ${userName}` : "Hey"}, how are you feeling?`}
					</Typography>
					<FeelingSlider />
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default MoraleSlide;
