import React, { useRef, useEffect, useState } from "react";
import ChipInput from "../common/ChipInput";
import { Tooltip, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { parseChipString, removeDuplicateChips, validateChips } from "./EmailInviteControl.utils";
import { useStyles } from "./EmailInviteControl.styles";
import clsx from "clsx";

export default function EmailInviteControl({
	emailChips,
	setEmailChips,
	error,
	setError,
	emails,
	availableSeats,
}) {
	const [availableInvites, setAvailableInvites] = useState(0);
	const hackRef = useRef();
	const classes = useStyles();
	let chipKey = 0;

	useEffect(() => {
		//rest error
		setError({ error: false, message: "" });
		let validEmails = emailChips.filter((chip) => chip.isValid);
		const tempAvailableInvites = availableSeats - validEmails.length;
		setAvailableInvites(tempAvailableInvites);
		if (tempAvailableInvites < 0) {
			setError({
				error: true,
				message: `You have used all the available invites. Please update your subscription to include more members.`,
			});
		}
		if (emailChips.length !== validEmails.length) {
			setError({ error: true, message: "Check highlighted emails for errors." });
		}
	}, [emailChips, setError]);

	function handleAddChip(chipString) {
		let parsedChips = parseChipString(chipString);
		let newChips = removeDuplicateChips(parsedChips, emailChips, emails);
		let validatedChips = validateChips(newChips);

		setEmailChips([...emailChips, ...validatedChips]);
	}

	function handleDeleteChip(chip, index) {
		let newArray = emailChips.filter((chip, chipIndex) => {
			return chipIndex !== index;
		});

		setEmailChips(newArray);
	}

	// Give focus to the input element when the box is clicked
	function handleChipBoxClick() {
		let theChild = hackRef.current.input.current.children[0];
		theChild?.focus();
	}

	// Commit any entered values when TAB is pressed or button is clicked
	function handleBlur() {
		let theChild = hackRef.current.input.current.children[0];
		if (theChild?.value) {
			handleAddChip(theChild.value);
		}
	}

	function chipRenderer({ value, isFocused, handleClick, handleDelete }) {
		const baseClass = clsx(classes.chipRootValid, isFocused && classes.chipFocused);

		return value.isValid ? (
			<Chip
				key={chipKey++}
				label={value.text}
				onClick={handleClick}
				onDelete={handleDelete}
				classes={{
					root: clsx(baseClass),
				}}
			></Chip>
		) : (
			<Tooltip key={chipKey++} title="Invalid email" aria-label="invalid email">
				<Chip
					label={value.text}
					onClick={handleClick}
					onDelete={handleDelete}
					classes={{
						root: clsx(baseClass, classes.chipRootInvalid),
					}}
				></Chip>
			</Tooltip>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.chipBox} onClick={handleChipBoxClick}>
				<ChipInput
					value={emailChips}
					disableUnderline={true}
					onAdd={(chip) => handleAddChip(chip)}
					onDelete={(chip, index) => handleDeleteChip(chip, index)}
					InputProps={{ autoFocus: true }}
					ref={hackRef}
					alwaysShowPlaceholder={true}
					fullWidthInput={true}
					fullWidth={true}
					chipRenderer={chipRenderer}
					onBlur={handleBlur}
				/>
			</div>
			<Typography
				className={clsx(classes.helperText, error.error && classes.error)}
				variant="caption"
			>
				{error.error ? error.message : `Available Invites: ${availableInvites}`}
			</Typography>
		</div>
	);
}
