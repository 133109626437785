import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import GroupChipToolTipSection from "./GroupChipTooltipSection";

const useStyles = makeStyles(() => ({
	root: { "& > *:not(:last-child)": { marginBottom: "16px" } },
}));

export default function GroupChipToolTip({ groupManagers, groupMembers, children }) {
	const classes = useStyles();

	return (
		<Tooltip
			title={
				<div className={classes.root}>
					<GroupChipToolTipSection title="Managers" people={groupManagers} />
					<GroupChipToolTipSection title="Members" people={groupMembers} />
				</div>
			}
		>
			{children}
		</Tooltip>
	);
}
