import PropTypes from "prop-types";
import React from "react";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));

export default function TimePicker(props) {
	const classes = useStyles();

	const changeHandler = (e) => {
		props.setTime(e.currentTarget.value);
	};

	return (
		<form className={classes.container} noValidate>
			<TextField
				variant="standard"
				id="time"
				label={props.label}
				type="time"
				defaultValue={props.defaultTime}
				onChange={changeHandler}
				className={classes.textField}
				color="primary"
				InputLabelProps={{
					shrink: true,
					color: "primary",
				}}
				inputProps={{
					step: 300, // 5 min
					color: "primary",
				}}
			/>
		</form>
	);
}

TimePicker.propTypes = {
	label: PropTypes.string,
	defaultTime: PropTypes.string.isRequired,
	setTime: PropTypes.func.isRequired,
};
