import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	},
	button: {
		width: "25%",
		minWidth: 150,
		borderRadius: 10,
		textTransform: "none",
		fontSize: 20,
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default function LoaderButton({ isLoading, isValidated, buttonText, style }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				style={{ ...style }}
				type="submit"
				variant="contained"
				color="primary"
				size="large"
				disabled={isLoading || !isValidated}
			>
				{buttonText}
			</Button>
			{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
		</div>
	);
}
LoaderButton.propTypes = {
	isLoading: PropTypes.bool,
	isValidated: PropTypes.bool,
	buttonText: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
