import { OS } from "../shared/constants";

export function getOS() {
	if (navigator.userAgent.indexOf("Win") !== -1) return OS.WINDOWS;
	if (navigator.userAgent.indexOf("Mac") !== -1) return OS.MAC;
	if (navigator.userAgent.indexOf("X11") !== -1) return OS.UNIX;
	if (navigator.userAgent.indexOf("Linux") !== -1) return OS.LINUX;

	return OS.UNKNOWN;
}

export function isEmptyObject(object: object) {
	return Object.keys(object).length === 0 && object.constructor === Object;
}

export function formatDate(dateString: string | number | Date, displayWeekday: boolean = false) {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		...(displayWeekday && { weekday: "long" }),
	};

	return new Date(dateString).toLocaleDateString(undefined, options);
}
