export function addLeadingZero(value) {
	return ("0" + value).slice(-2);
}

export function twoDigitYear(value) {
	return value.toString().slice(2);
}

export function convertTo12Hour(value) {
	if (+value === 12) {
		return value;
	}

	return +value % 12;
}

export function amOrPm(value) {
	return +value >= 12 ? "p" : "a";
}

// date is a string of the form {YEAR}_{WEEK NUMBER}
export function convertGroupInsightDateToNumber(date) {
	const timeParts = date.split("_");
	return +(timeParts[0] + addLeadingZero(timeParts[1]));
}

// /**
//  * Returns the week number for this date.  dowOffset is the day of week the week
//  * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
//  * the week returned is the ISO 8601 week number.
//  * @param int dowOffset
//  * @return int
//  */
// Date.prototype.getWeek = function (dowOffset) {
//   /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

//   dowOffset = typeof dowOffset == "number" ? dowOffset : 0; //default dowOffset to zero
//   var newYear = new Date(this.getFullYear(), 0, 1);
//   var day = newYear.getDay() - dowOffset; //the day of week the year begins on
//   day = day >= 0 ? day : day + 7;
//   var daynum =
//     Math.floor(
//       (this.getTime() -
//         newYear.getTime() -
//         (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
//         86400000
//     ) + 1;
//   var weeknum;
//   //if the year starts before the middle of a week
//   if (day < 4) {
//     weeknum = Math.floor((daynum + day - 1) / 7) + 1;
//     if (weeknum > 52) {
//       let nYear = new Date(this.getFullYear() + 1, 0, 1);
//       let nday = nYear.getDay() - dowOffset;
//       nday = nday >= 0 ? nday : nday + 7;
//       /*if the next year starts before the middle of
//                         the week, it is week #1 of that year*/
//       weeknum = nday < 4 ? 1 : 53;
//     }
//   } else {
//     weeknum = Math.floor((daynum + day - 1) / 7);
//   }
//   return weeknum;
// };

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
export function getWeek(date, dowOffset) {
	/*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

	dowOffset = typeof dowOffset == "number" ? dowOffset : 1; //default dowOffset to zero
	var newYear = new Date(date.getFullYear(), 0, 1);
	var day = newYear.getDay() - dowOffset; //the day of week the year begins on
	day = day >= 0 ? day : day + 7;
	var daynum =
		Math.floor(
			(date.getTime() -
				newYear.getTime() -
				(date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
				86400000,
		) + 1;
	var weeknum;
	//if the year starts before the middle of a week
	if (day < 4) {
		weeknum = Math.floor((daynum + day - 1) / 7) + 1;
		if (weeknum > 52) {
			let nYear = new Date(date.getFullYear() + 1, 0, 1);
			let nday = nYear.getDay() - dowOffset;
			nday = nday >= 0 ? nday : nday + 7;
			/*if the next year starts before the middle of
                        the week, it is week #1 of that year*/
			weeknum = nday < 4 ? 1 : 53;
		}
	} else {
		weeknum = Math.floor((daynum + day - 1) / 7);
	}
	return weeknum;
}

// https://codepen.io/Venugopal46/pen/WrxdLY
export function getDateRangeOfWeek(weekNo, y) {
	var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
	d1 = new Date("" + y + "");
	numOfdaysPastSinceLastMonday = d1.getDay() - 1;
	d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
	d1.setDate(d1.getDate() + 7 * (weekNo - getWeek(d1)));
	// rangeIsFrom = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear();
	rangeIsFrom = d1.getMonth() + 1 + "/" + d1.getDate();
	d1.setDate(d1.getDate() + 6);
	// rangeIsTo = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear() ;
	rangeIsTo = d1.getMonth() + 1 + "/" + d1.getDate();
	return rangeIsFrom + " - " + rangeIsTo;
}

export function getFirstDayOfWeek(weekNo, y) {
	var d1, numOfdaysPastSinceLastMonday;
	d1 = new Date("" + y + "");
	numOfdaysPastSinceLastMonday = d1.getDay() - 1;
	d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
	d1.setDate(d1.getDate() + 7 * (weekNo - getWeek(d1)));
	let firstDay = d1.getMonth() + 1 + "/" + d1.getDate();
	return firstDay;
}

// https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/
// function to calculate local time
// in a different city
// given the city's UTC offset
export function calcTime(city, offset) {
	// create Date object for current location
	const d = new Date();

	// convert to msec
	// add local time zone offset
	// get UTC time in msec
	const utc = d.getTime() + d.getTimezoneOffset() * 60000;

	// create new Date object for different city
	// using supplied offset
	const nd = new Date(utc + 3600000 * offset);

	// return time as a string
	//return "The local time in " + city + " is " + nd.toLocaleString();

	//return new date
	return nd;
}

function secondsDiff(d1, d2) {
	let millisecondDiff = d2 - d1;
	let secDiff = Math.floor(millisecondDiff / 1000);
	return secDiff;
}

function minutesDiff(d1, d2) {
	let seconds = secondsDiff(d1, d2);
	let minutesDiff = Math.floor(seconds / 60);
	return minutesDiff;
}

function hoursDiff(d1, d2) {
	let minutes = minutesDiff(d1, d2);
	let hoursDiff = Math.floor(minutes / 60);
	return hoursDiff;
}

function daysDiff(d1, d2) {
	let hours = hoursDiff(d1, d2);
	let daysDiff = Math.floor(hours / 24);
	return daysDiff;
}

export function weeksDiff(d1, d2) {
	let days = daysDiff(d1, d2);
	let weeksDiff = Math.floor(days / 7);

	// Add 1 to include current week
	return weeksDiff + 1;
}

export function formatDate(timestamp) {
	const date = new Date(timestamp);
	const year = twoDigitYear(date.getFullYear());
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${month}/${day}/${year}`;
}

export function formatTime(timestamp) {
	const date = new Date(timestamp);
	const hours = convertTo12Hour(date.getHours());
	const minutes = addLeadingZero(date.getMinutes());
	const meridiem = amOrPm(date.getHours());

	return `${hours}:${minutes}${meridiem}`;
}

export function formatFullDate(timestamp) {
	return `${formatDate(timestamp)}, ${formatTime(timestamp)}`;
}

export const uniqueId = (length = 16) => {
	return parseInt(
		Math.ceil(Math.random() * Date.now())
			.toPrecision(length)
			.toString()
			.replace(".", ""),
	);
};

export const timeStamp = () => Math.floor(Date.now() / 1000);
