import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
		width: "fit-content",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		gap: theme.spacing(1),
		marginBottom: theme.spacing(0.5),
	},
	alignItemsBaseline: {
		alignItems: "baseline",
	},
	alignItemsCenter: {
		alignItems: "center",
	},
	editButton: {
		fontSize: "0.75rem",
		textTransform: "none",
	},
	loaderButtonWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));
