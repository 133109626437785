export enum AccountStatus {
	/** Free trial period has started and not yet ended */
	FREE_TRIAL = "FREE_TRIAL",

	/** Free trial period has ended and no payment information has been entered */
	FREE_TRIAL_ENDED = "FREE_TRIAL_ENDED",

	/** Active account, no billing problems. Free trial period might still be active */
	ACTIVE = "ACTIVE",

	/** Active account, but Stripe couldn't bill this customer */
	BILLING_PROBLEMS = "BILLING_PROBLEMS",

	/** Disabled account */
	DISABLED = "DISABLED",
}

export interface Trial {
	trialStartDate: string;
	trialEndDate: string;
}

/** Billing method for Team accounts */
export enum BillingType {
	/** Billing periodicity not yet set */
	None = "none",

	/** Billing is on a monthly basis */
	Monthly = "monthly",

	/** Billing is on a yearly basis */
	Yearly = "yearly",

	/** Billing is performed manually */
	Enterprise = "enterprise",
}

export interface Account {
	/** 'S:SUBSCRIPTION_INFO' */
	PK: string;
	/** Account ID */
	SK: string;
	company: string;
	userId: string;
	createdAt: string;
	lastUpdated: string;
	status: AccountStatus;
	startDate: string;
	members: string[];
	roles: Record<string, string[]>;
	trialPeriod?: Trial;
	workspaceId: string;
	stripeCustomerId?: string;
	activeMembersCount: number;
	billingType: BillingType;
	hasSlackInstall?: AccountSlackInstallState;
	slackConfig?: AccountSlackConfig;
	seats?: number;
}

export enum AccountSlackInstallState {
	TRUE = "true",
	FALSE = "false",
}

export interface AccountSlackConfig {
	existingUserAutoAdd: boolean;
	slackGroupIds: string[];
}
