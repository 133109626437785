import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import QButton from "src/components/common/QButton";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import { ModulesLegacyPage } from "../../../model/modules/modules.Model";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

interface ModulesLastSlideProps {
	page: ModulesLegacyPage;
}
const ModulesLastSlide: FC<ModulesLastSlideProps> = ({ page }) => {
	const classes = modulesStyles();
	const { saveOnClick, reflectionsCount, saving } = useReflectionModelContext();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Stack height="100%" justifyContent="center">
				<Grid
					container
					direction="row"
					alignItems="center"
					justifyContent="center"
					spacing="20px"
				>
					<Grid item mobile={12} navBar={4}>
						<div className={classes.modulesItemCentered}>
							<img
								className={classes.modulesStaticImage}
								src="https://quimby-modules-dev.s3.us-east-2.amazonaws.com/public/common/fireworks.png"
								alt="Fireworks celebration"
							/>
						</div>
					</Grid>
					<Grid item mobile={12} navBar={8}>
						<Typography variant="h5">
							{page.overrideDescription ? "Good Job!" : page.title}
						</Typography>
						<Typography variant="body">
							{page.overrideDescription
								? `You have completed ${reflectionsCount} ${
										reflectionsCount > 1 ? "check-ins" : "check-in"
									}.`
								: page.description}
						</Typography>
					</Grid>
					<Grid item mobile={12}>
						<div className={classes.modulesItemCentered}>
							<QButton
								onClick={saveOnClick}
								text="Save & Close"
								disabled={saving}
								loading={saving}
							/>
						</div>
					</Grid>
				</Grid>
			</Stack>
		</ModulesNav>
	);
};

export default ModulesLastSlide;
