import { Grid, ThemeProvider } from "@mui/material";
import React from "react";
import DownloadTile from "src/components/common/DownloadTile";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import { NavBarState } from "../components/home/NavBar";
import { useTypedAppContext } from "../libs/typedContextLib";
import { OS } from "../shared/constants";

export default function Download() {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();

	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.pageBase : classes.pageBaseMobile
				}
			>
				<Grid
					container
					spacing={modulesTheme.spacing(5)}
					justifyContent="center"
					alignItems="center"
					maxWidth="800px"
					alignSelf="center"
					height="100%"
				>
					<Grid item width="100%" mobile={12} navBar={6}>
						<DownloadTile os={OS.WINDOWS} />
					</Grid>
					<Grid item width="100%" mobile={12} navBar={6}>
						<DownloadTile os={OS.MAC} />
					</Grid>
				</Grid>
			</div>
		</ThemeProvider>
	);
}
