import Link from "@mui/material/Link";
import { Variant } from "@mui/material/styles/createTypography";
import React, { FC } from "react";

export interface Props {
	text: string;
	onClick: React.MouseEventHandler;
	underline?: "none" | "hover" | "always";
	variant?: Variant;
}

const LinkButton: FC<Props> = ({ text, variant, underline, onClick }) => {
	return (
		<Link component="button" underline={underline} onClick={onClick} variant={variant}>
			{text}
		</Link>
	);
};
export default LinkButton;
