import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	button: {
		textTransform: "none",
	},
}));

export default function SaveChangesDialog({ open, groupName, handleDontSave, handleSave }) {
	const classes = useStyles();

	return (
		<Dialog open={open} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">
				{`Do you want to save the changes made to ${groupName}?`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Your changes will be lost if you don’t save them.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button className={classes.button} onClick={handleDontSave} color="primary">
					Don't Save
				</Button>
				<Button
					className={classes.button}
					variant="contained"
					onClick={handleSave}
					color="primary"
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
