export const HOME_PAGE_ROUTES = {
	dashboardUser: { path: "/home/dashboard_user", route: "dashboard_user", title: "My Morale" },
	values: { path: "/home/user/values", route: "user/values", title: "My Values" },
	sharedInsights: {
		path: "/home/shared_insights",
		route: "shared_insights",
		title: "Shared insights",
	},
	sharedInsightsTrial: {
		path: "/home/shared_insights_trial",
		route: "shared_insights_trial",
		title: "Shared insights",
	},
	intentions: { path: "/home/intentions", route: "intentions", title: "Intentions" },
	intention_details: {
		path: "/home/intentions/:moduleId",
		route: "intentions/:moduleId",
		title: "Intentions Module",
	},
	toolkit: { path: "/home/toolkit", route: "toolkit", title: "My Toolkit" },
	notification: { path: "/home/routine", route: "routine", title: "My Routine" },
	profile: { path: "/home/profile", route: "profile", title: "Profile" },
	download: { path: "/home/download", route: "download", title: "Download" },
	adminMembers: { path: "/home/admin/members", route: "admin/members", title: "Members" },
	adminGroups: { path: "/home/admin/groups", route: "admin/groups", title: "Groups" },
	adminSubscription: {
		path: "/home/admin/subscription",
		route: "admin/subscription",
		title: "Subscription",
	},
} as const;

type RouteItem = Record<string, string>;

const HOME_PATH_LOOKUP: RouteItem[] = Object.entries(HOME_PAGE_ROUTES).map(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	([_key, value]) => ({
		[value.path]: value.title,
	}),
);

export const findPageTitleByPath = (pathname: string): string => {
	const found = HOME_PATH_LOOKUP.find((item) =>
		Object.prototype.hasOwnProperty.call(item, pathname),
	);
	return found ? found[pathname] : "";
};
