import { TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { ModulesInputTextPage } from "../../../model/modules/modules.Model";
import { ModulesData } from "../../../model/modulesData/modulesData.Model";
import { modulesStyles } from "../modulesStyles";

const MAX_CHARACTERS = 500;

export interface JournalBoxProps {
	page: ModulesInputTextPage;
	modulesData: ModulesData;
}

const JournalBox: FC<JournalBoxProps> = ({ page, modulesData }) => {
	const [charRemaining, setCharRemaining] = useState(MAX_CHARACTERS);
	const [journal, setJournal] = useState("");
	useEffect(() => {
		setJournal(modulesData.getPageRecord(page.id, page.prompt) ?? "");
	}, [page.id]);
	const classes = modulesStyles();
	const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const charCount = MAX_CHARACTERS - e.target.value.length;
		if (charCount >= 0) {
			setCharRemaining(charCount);
			setJournal(e.target.value);
		}
		modulesData.setPageRecord(page.id, page.prompt, e.target.value);
	};

	const charRemainingString = () => {
		if (charRemaining < MAX_CHARACTERS) {
			return `Characters left: ${charRemaining}`;
		} else {
			return "";
		}
	};

	return (
		<>
			<Typography variant="body1">{page.prompt}</Typography>
			<TextField
				className={classes.journalBox}
				variant="filled"
				fullWidth
				id="thoughts"
				name="thoughts"
				type="thoughts"
				multiline
				minRows={4}
				maxRows={6}
				label={page.placeholder}
				InputProps={{ className: classes.journalBoxInput }}
				value={journal}
				onChange={onTextFieldChange}
				helperText={charRemainingString()}
			/>
			{page.info && <Typography variant="info">{page.info}</Typography>}
		</>
	);
};

export default JournalBox;
