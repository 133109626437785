import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";

const useStyles = makeStyles(() => ({
	// HACK to get set min height for row
	root: {
		minHeight: "32px",
		display: "flex",
		alignItems: "center",
	},
}));

export default function MemberTableCellName({ row }) {
	const classes = useStyles();

	return (
		<TableCell component="th" scope="row" align="left">
			<div className={classes.root}>{row.name}</div>
		</TableCell>
	);
}
