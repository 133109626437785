import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import GroupInsightsLineChartTooltip from "./GroupInsightsLineChartTooltip";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React from "react";
import Theme from "../../Theme";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WeekUtils from "week-utils";
import makeStyles from "@mui/styles/makeStyles";
import { twoDigitYear } from "../../utility/HelperFunctions";

const weekUtils = new WeekUtils();
const TOOLTIP_CURSOR_COLOR = Theme.palette.secondary.main;

const AXIS_COLOR = "rgba(0,0,0,.5)";
const CARTESIAN_GRID_COLOR = AXIS_COLOR;
const STROKE_COLOR = Theme.palette.primary.main;
const THUMB_ICON_COLOR = "#9f9f9f";

const useStyles = makeStyles(() => ({
	paper: {
		// Theme 1
		// background: theme.palette.primary.main,
		background: "white",
	},
	thumbsUp: {
		color: THUMB_ICON_COLOR,
	},
	thumbsDown: {
		color: THUMB_ICON_COLOR,
	},
	rowDiv: {
		display: "flex",
		flexDirection: "row",
	},
	columnDiv: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: 400,
		paddingTop: 20,
		paddingBottom: 47,
		paddingLeft: 30,
		marginRight: -70,
	},
	disabled: {
		background: "white",
		minHeight: 475,
		minWidth: 275,
		borderRadius: 15,
	},
}));

function formatTimestamp(rawTime) {
	if (!rawTime) return;
	let timeParts = rawTime.toString().split("_");
	let monday = weekUtils.getWeekDate(timeParts[0], timeParts[1]).weekStart;
	let mondayString = monday.getMonth() + 1 + "/" + monday.getDate();
	return `  ${mondayString}  `;
}

function formatTooltipTimestamp(rawTime) {
	const date = new Date(+rawTime);
	const year = twoDigitYear(date.getFullYear());
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `Date : ${month}/${day}/${year}`;
}

// Scale goes from -1 to 11 to allow some room for the extremes
function formatMoodIndex(value) {
	if (value === 0) {
		return "";
	}

	if (value === 10) {
		return "";
	}

	return "";
}

export default function GroupInsightsLineChart({ data, isDisabled }) {
	const classes = useStyles();
	if (isDisabled || !data || data.length < 1) return <Paper className={classes.disabled} />;
	return (
		<Paper className={classes.paper}>
			<div className={classes.rowDiv}>
				<div className={classes.columnDiv}>
					<ThumbUpIcon className={classes.thumbsUp} />
					<ThumbDownIcon className={classes.thumbsDown} />
				</div>
				<ResponsiveContainer minHeight="475px" minWidth="200px">
					<LineChart
						width={800}
						height={475}
						data={data}
						margin={{
							top: 30,
							right: 30,
							left: 30,
							bottom: 30,
						}}
					>
						<CartesianGrid
							vertical={false}
							strokeDasharray="3 3"
							stroke={CARTESIAN_GRID_COLOR}
						/>
						<XAxis
							dataKey="date"
							stroke={AXIS_COLOR}
							tickFormatter={formatTimestamp}
							// TODO: Leaving this here in case we want to go down this route again
							// dataKey='fakeIndex'
							// type='number'
							// interval={0}
							// allowDecimals={false}
							// domain={domain}
							// allowDataOverflow={true}
						/>
						<YAxis
							stroke={AXIS_COLOR}
							domain={[0, 10]}
							tickLine
							tickFormatter={formatMoodIndex}
							label={{
								angle: -90,
								position: "insideLeft",
							}}
						/>
						<Line
							type="monotone"
							dataKey="feeling"
							stroke={STROKE_COLOR}
							activeDot={true}
							strokeWidth={2}
							dot={true}
							isAnimationActive={false}
						/>
						{
							<Tooltip
								cursor={{ stroke: TOOLTIP_CURSOR_COLOR, strokeWidth: 2 }}
								content={
									<GroupInsightsLineChartTooltip
										labelFormatter={(value) => formatTooltipTimestamp(value)}
									/>
								}
							/>
						}
					</LineChart>
				</ResponsiveContainer>
			</div>
		</Paper>
	);
}

export const MemoizedGroupInsightsLineChart = React.memo(GroupInsightsLineChart);

GroupInsightsLineChart.propTypes = {
	data: PropTypes.array,
	isDisabled: PropTypes.bool,
};
