import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableRow from "@mui/material/TableRow";
import PeopleTableCellRoles from "./PeopleTableCellRoles";
import PendingTableCellInvitationDate from "./PendingTableCellInvitationDate";
import PendingTableCellName from "./PendingTableCellName";
import { MEMBER_ROLES } from "../../shared/constants";
import { useAppContext } from "../../libs/contextLib";

const useStyles = makeStyles((theme) => ({
	editMode: {
		backgroundColor: theme.palette.reflectionBackgroundColor,
		"&.MuiTableRow-hover:hover": {
			backgroundColor: theme.palette.reflectionBackgroundColor,
			"& .MuiTableCell-body": {
				color: theme.palette.text.dark,
			},
		},
	},
	disableHover: {
		pointerEvents: "none",
	},
}));

export default function MemberTableRow({
	row,
	handleMouseEnter,
	handleMouseLeave,
	hoverRowId,
	setHoverRowId,
	editRowId,
	setEditRowId,
	saveAction,
	cancelAction,
	setEditRowInfo,
}) {
	const {
		appState: { selectedSubscriptionId },
	} = useAppContext();

	// TODO: HACK UNTIL I FIX THIS ON THE BACKEND
	let mappedRoleId = row.role ? row.role : MEMBER_ROLES.none.id;

	// eslint-disable-next-line no-extra-boolean-cast
	const userSubs = !!row.subscription
		? Array.isArray(row.subscription)
			? row.subscription
			: [!!row.subscription]
		: undefined;
	const currentSub = userSubs.find((sub) => sub.subscriptionId === selectedSubscriptionId);
	// eslint-disable-next-line no-extra-boolean-cast
	mappedRoleId = !!row.role
		? row.role
		: currentSub && !!currentSub.roles && currentSub.roles.admin
			? "admin"
			: "none";

	const [isModified, setIsModified] = useState(false);
	const [modifiedRole, setModifiedRole] = useState("");
	const classes = useStyles();
	const disableHoverRow = editRowId !== "" && editRowId !== row.userId;

	function roleChangeHandler(value) {
		setModifiedRole(value);
		setIsModified(value !== mappedRoleId);
	}

	return (
		<TableRow
			classes={
				editRowId === row.userId
					? { root: classes.editMode }
					: disableHoverRow
						? { root: classes.disableHover }
						: null
			}
			hover
			tabIndex={-1}
			key={row.userId}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			data-user-id={row.userId}
		>
			<PendingTableCellName editRowId={editRowId} row={row} />
			<PeopleTableCellRoles
				editRowId={editRowId}
				row={row}
				mappedRoleId={mappedRoleId}
				roleChangeHandler={roleChangeHandler}
			/>
			<PendingTableCellInvitationDate
				row={row}
				saveAction={() => {
					saveAction(modifiedRole);
					setIsModified(false);
				}}
				cancelAction={() => {
					setModifiedRole("");
					setIsModified(false);
					cancelAction();
				}}
				hoverRowId={hoverRowId}
				setHoverRowId={setHoverRowId}
				editRowId={editRowId}
				setEditRowId={setEditRowId}
				isModified={isModified}
				setEditRowInfo={setEditRowInfo}
			/>
		</TableRow>
	);
}
