import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import { ModulesReminderSetupPage } from "../../../model/modules/modules.Model";
import { ModulesData } from "../../../model/modulesData/modulesData.Model";
import ModulesNav from "../Shared/ModulesNav";
import MilestoneReminderList from "../Shared/ModulesReminderList";

interface ReminderSetupTemplateProps {
	page: ModulesReminderSetupPage;
}

const ReminderSetupTemplate: FC<ReminderSetupTemplateProps> = ({ page }) => {
	const state = useReflectionModelContext();
	const modulesData = state.modulesData as unknown as ModulesData;

	//save default reminders once
	page.reminderGroups.forEach((reminderGroup) => {
		reminderGroup.reminders.forEach((reminder) => {
			if (!reminder.id) {
				reminder.id = uuidv4();
			}
			if (!modulesData.getReminderRecord(page.id, reminder.id)) {
				modulesData.setReminderRecord(page.id, reminder.id, JSON.stringify(reminder));
			}
		});
	});

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container spacing="20px" justifyContent="center">
				<Grid item mobile={12} tablet={11}>
					<Typography variant="h5">{page.title}</Typography>
				</Grid>
				{page.description && (
					<Grid item mobile={12} tablet={11}>
						<Typography
							variant="body1"
							dangerouslySetInnerHTML={{ __html: page.description }}
						/>
					</Grid>
				)}
				{page.reminderGroups.map((reminderGroup) => (
					<Grid item mobile={12} tablet={10}>
						<MilestoneReminderList
							key={reminderGroup.groupTitle}
							modulesData={modulesData}
							pageId={page.id}
							reminderGroup={reminderGroup}
						/>
					</Grid>
				))}
			</Grid>
		</ModulesNav>
	);
};

export default ReminderSetupTemplate;
