// Solution from https://github.com/recharts/recharts/issues/275

import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import PropTypes from "prop-types";
import React from "react";
import WeekUtils from "week-utils";

// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways
//TODO Create a custom component, importing non public apis may lead to undesired results

const weekUtils = new WeekUtils();

function getDateRangeOfWeek(year, weekNumber) {
	let weekStart = weekUtils.getWeekDate(year, weekNumber).weekStart;
	let weekEnd = weekUtils.getWeekDate(year, weekNumber).weekEnd;

	let weekStartFormatted = weekStart.getMonth() + 1 + "/" + weekStart.getDate();
	let weekEndFormatted = weekEnd.getMonth() + 1 + "/" + weekEnd.getDate();

	return weekStartFormatted + " - " + weekEndFormatted;
}

const GroupInsightsLineChartTooltip = (props) => {
	// we don't need to check payload[0] as there's a better prop for this purpose
	if (!props.active) {
		// I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
		return null;
	}

	// Payload is null when the graph has no data
	if (props.payload === null) {
		return null;
	}

	// Calculate date range
	const [year, weekNumber] = props.payload[0].payload.date.split("_");
	const dateRange = getDateRangeOfWeek(year, weekNumber);

	// Generate participation text
	let participationText;
	if (Number.isNaN(props.payload[0].payload.feeling)) {
		participationText = {
			value: "Data not available because less than 3 people participated.",
		};
	} else {
		participationText = {
			name: "Participation",
			value: props.payload[0].payload.participation,
		};
	}

	const newPayload = [
		{
			name: "Week",
			value: dateRange,
		},
		participationText,
		//* Commenting this out for now because I don't want Y-axis values to show
		// ...props.payload,
	];

	// we render the default, but with our overridden payload
	// return <DefaultTooltipContent {...props} payload={newPayload} />;
	return <DefaultTooltipContent payload={newPayload} />;
};

export default GroupInsightsLineChartTooltip;

GroupInsightsLineChartTooltip.propTypes = {
	active: PropTypes.bool,
	payload: PropTypes.array,
};
