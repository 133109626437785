import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, useState } from "react";
import { colors } from "../../../Theme";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

const MAX_CHARACTERS = 500;

const useTextFieldStyles = makeStyles(() => ({
	root: {
		borderRadius: 9,
		marginTop: 2,
		borderColor: colors.teal,
		"&:after": { borderBottom: "none !important" },
		"&:before": { borderBottom: "none !important" },
		"&:hover:after": { borderBottom: "none !important" },
		"&:focus:after": { borderBottom: "none !important" },
		"&:hover:before": { borderBottom: "none !important" },
		"&:focus:before": { borderBottom: "none !important" },
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		"&:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.3)",
		},
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
		"&:hover:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
	},
}));

interface GratitudeSlideProps {
	page: ModulesLegacyPage;
}

const GratitudeSlide: FC<GratitudeSlideProps> = ({ page }) => {
	const { gratitude, setGratitude } = useReflectionModelContext();
	const [charRemaining, setCharRemaining] = useState(MAX_CHARACTERS);

	const classes = modulesStyles();
	const textFieldClass = useTextFieldStyles();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const onTextFieldChange = (e) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const charCount = MAX_CHARACTERS - e.target.value.length;

		if (charCount >= 0) {
			setCharRemaining(charCount);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			setGratitude(e.target.value);
		}
	};

	const charRemainingString = () => {
		if (charRemaining < MAX_CHARACTERS) {
			return `Characters left: ${charRemaining}`;
		} else {
			return "";
		}
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5">Practice gratitude</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body">
						One method of practicing gratitude is through journaling. Take a few moments
						and write down what you are grateful for. This could include anything from a
						good cup of coffee to a supportive friend.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<TextField
						className={classes.interventionBox}
						variant="filled"
						fullWidth
						id="gratitude"
						name="gratitude"
						type="gratitude"
						multiline
						rows={11}
						maxRows={11}
						label="Your thoughts are not visible to anyone but you. Type away."
						InputProps={{ className: textFieldClass.root }}
						value={gratitude}
						onChange={onTextFieldChange}
						helperText={charRemainingString()}
					/>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default GratitudeSlide;
