import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { ModulesData } from "src/model/modulesData/modulesData.Model";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import {
	ModulesInputPage,
	isModulesInputRadioPage,
	isModulesInputSurveyPage,
	isModulesInputTextPage,
} from "../../../model/modules/modules.Model";
import JournalBox from "../Shared/JournalBox";
import ModulesNav from "../Shared/ModulesNav";
import RadioBox from "../Shared/RadioBox";
import SurveyQuestion from "../Shared/SurveyQuestion";

interface InputTemplateProps {
	page: ModulesInputPage;
}

const InputTemplate: FC<InputTemplateProps> = ({ page }) => {
	const state = useReflectionModelContext();
	const modulesData = state.modulesData as unknown as ModulesData;
	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Stack direction="column" spacing="10px" justifyContent="center">
				<Typography variant="h5">{page.title}</Typography>
				{page.description && (
					<Typography
						variant="body"
						dangerouslySetInnerHTML={{ __html: page.description }}
					/>
				)}

				{isModulesInputTextPage(page) && (
					<JournalBox page={page} modulesData={modulesData} />
				)}
				{isModulesInputRadioPage(page) && (
					<RadioBox page={page} modulesData={modulesData} />
				)}
				{isModulesInputSurveyPage(page) &&
					page.surveyQuestions.map((question) => {
						return (
							<>
								<SurveyQuestion
									key={question.question}
									pageId={page.id}
									question={question}
									modulesData={modulesData}
								/>
							</>
						);
					})}
			</Stack>
		</ModulesNav>
	);
};

export default InputTemplate;
