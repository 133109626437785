const wordList1 = [
	{ word: "Happy", pleasant: true },
	{ word: "Inspired", pleasant: true },
	{ word: "Content", pleasant: true },
	{ word: "Confident", pleasant: true },
	{ word: "Hopeful", pleasant: true },
	{ word: "Joyful", bgOpacity: 0.2, pleasant: true },
	{ word: "Interested", bgOpacity: 0.2, pleasant: true },
	{ word: "Proud", bgOpacity: 0.2, pleasant: true },
	{ word: "Accepted", bgOpacity: 0.2, pleasant: true },
	{ word: "Powerful", bgOpacity: 0.2, pleasant: true },
	{ word: "Peaceful", bgOpacity: 0.2, pleasant: true },
	{ word: "Intimate", bgOpacity: 0.2, pleasant: true },
	{ word: "Optimistic", bgOpacity: 0.2, pleasant: true },
	{ word: "Liberated", bgOpacity: 0.4, pleasant: true },
	{ word: "Ecstatic", bgOpacity: 0.4, pleasant: true },
	{ word: "Amused", bgOpacity: 0.4, pleasant: true },
	{ word: "Inquisitive", bgOpacity: 0.4, pleasant: true },
	{ word: "Important", bgOpacity: 0.4, pleasant: true },
	{ word: "Respected", bgOpacity: 0.4, pleasant: true },
	{ word: "Fulfilled", bgOpacity: 0.4, pleasant: true },
	{ word: "Courageous", bgOpacity: 0.4, pleasant: true },
	{ word: "Provocative", bgOpacity: 0.4, pleasant: true },
	{ word: "Loving", bgOpacity: 0.4, pleasant: true },
	{ word: "Sensitive", bgOpacity: 0.4, pleasant: true },
	{ word: "Playful", bgOpacity: 0.4, pleasant: true },
	{ word: "Open", bgOpacity: 0.4, pleasant: true },
	{ word: "Grateful", bgOpacity: 0.4, pleasant: true },
];
const wordList2 = [
	{ word: "Surprised", pleasant: false },
	{ word: "Confused", pleasant: false },
	{ word: "Amazed", pleasant: true },
	{ word: "Excited", pleasant: true },
	{ word: "Energetic", pleasant: true },
	{ word: "Startled", bgOpacity: 0.4, pleasant: false },
	{ word: "Shocked", bgOpacity: 0.4, pleasant: true },
	{ word: "Dismayed", bgOpacity: 0.4, pleasant: false },
	{ word: "Disillusioned", bgOpacity: 0.4, pleasant: false },
	{ word: "Perplexed", bgOpacity: 0.4, pleasant: false },
	{ word: "Astonished", bgOpacity: 0.4, pleasant: true },
	{ word: "Awe", bgOpacity: 0.4, pleasant: true },
	{ word: "Eager", bgOpacity: 0.4, pleasant: true },
];
const wordList3 = [
	{ word: "Fearful", pleasant: false },
	{ word: "Insecure", pleasant: false },
	{ word: "Overwhelmed", pleasant: false },
	{ word: "Anxious", pleasant: false },
	{ word: "Stress", pleasant: false },
	{ word: "Humiliated", bgOpacity: 0.2, pleasant: false },
	{ word: "Rejected", bgOpacity: 0.2, pleasant: false },
	{ word: "Submissive", bgOpacity: 0.2, pleasant: false },
	{ word: "Scared", bgOpacity: 0.2, pleasant: false },
	{ word: "Ridiculed", bgOpacity: 0.4, pleasant: false },
	{ word: "Disrespected", bgOpacity: 0.4, pleasant: false },
	{ word: "Excluded", bgOpacity: 0.4, pleasant: false },
	{ word: "Alienating", bgOpacity: 0.4, pleasant: false },
	{ word: "Insignificant", bgOpacity: 0.4, pleasant: false },
	{ word: "Worthless", bgOpacity: 0.4, pleasant: false },
	{ word: "Inferior", bgOpacity: 0.4, pleasant: false },
	{ word: "Inadequate", bgOpacity: 0.4, pleasant: false },
	{ word: "Worried", bgOpacity: 0.4, pleasant: false },
	{ word: "Frightened", bgOpacity: 0.4, pleasant: false },
	{ word: "Terrified", bgOpacity: 0.4, pleasant: false },
];
const wordList4 = [
	{ word: "Angry", pleasant: false },
	{ word: "Frustrated", pleasant: false },
	{ word: "Irritated", pleasant: false },
	{ word: "Withdrawn", pleasant: false },
	{ word: "Annoyed", pleasant: false },
	{ word: "Hurt", bgOpacity: 0.2, pleasant: false },
	{ word: "Threatened", bgOpacity: 0.2, pleasant: false },
	{ word: "Hateful", bgOpacity: 0.2, pleasant: false },
	{ word: "Mad", bgOpacity: 0.2, pleasant: false },
	{ word: "Aggressive", bgOpacity: 0.2, pleasant: false },
	{ word: "Distant", bgOpacity: 0.2, pleasant: false },
	{ word: "Critical", bgOpacity: 0.2, pleasant: false },
	{ word: "Embarrassed", bgOpacity: 0.4, pleasant: false },
	{ word: "Devastated", bgOpacity: 0.4, pleasant: false },
	{ word: "Intimidated", bgOpacity: 0.4, pleasant: false },
	{ word: "Jealous", bgOpacity: 0.4, pleasant: false },
	{ word: "Resentful", bgOpacity: 0.4, pleasant: false },
	{ word: "Violated", bgOpacity: 0.4, pleasant: false },
	{ word: "Furious", bgOpacity: 0.4, pleasant: false },
	{ word: "Enraged", bgOpacity: 0.4, pleasant: false },
	{ word: "Provoked", bgOpacity: 0.4, pleasant: false },
	{ word: "Hostile", bgOpacity: 0.4, pleasant: false },
	{ word: "Infuriated", bgOpacity: 0.4, pleasant: false },
	{ word: "Suspicious", bgOpacity: 0.4, pleasant: false },
	{ word: "Skeptical", bgOpacity: 0.4, pleasant: false },
	{ word: "Sarcastic", bgOpacity: 0.4, pleasant: false },
];
const wordList5 = [
	{ word: "Disgusted", pleasant: false },
	{ word: "Disappointed", pleasant: false },
	{ word: "Judgmental", pleasant: false },
	{ word: "Hesitant", pleasant: false },
	{ word: "Tired", pleasant: false },
	{ word: "Awful", bgOpacity: 0.2, pleasant: false },
	{ word: "Avoidant", bgOpacity: 0.2, pleasant: false },
	{ word: "Disapproving", bgOpacity: 0.2, pleasant: false },
	{ word: "Loathing", bgOpacity: 0.4, pleasant: false },
	{ word: "Repugnant", bgOpacity: 0.4, pleasant: false },
	{ word: "Revolted", bgOpacity: 0.4, pleasant: false },
	{ word: "Revulsion", bgOpacity: 0.4, pleasant: false },
	{ word: "Detestable", bgOpacity: 0.4, pleasant: false },
	{ word: "Aversion", bgOpacity: 0.4, pleasant: false },
];
const wordList6 = [
	{ word: "Sad", pleasant: false },
	{ word: "Guilty", pleasant: false },
	{ word: "Depressed", pleasant: false },
	{ word: "Lonely", pleasant: false },
	{ word: "Bored", pleasant: false },
	{ word: "Abandoned", bgOpacity: 0.2, pleasant: false },
	{ word: "Despair", bgOpacity: 0.2, pleasant: false },
	{ word: "Remorseful", bgOpacity: 0.4, pleasant: false },
	{ word: "Ashamed", bgOpacity: 0.4, pleasant: false },
	{ word: "Ignored", bgOpacity: 0.4, pleasant: false },
	{ word: "Victimized", bgOpacity: 0.4, pleasant: false },
	{ word: "Powerless", bgOpacity: 0.4, pleasant: false },
	{ word: "Vulnerable", bgOpacity: 0.4, pleasant: false },
	{ word: "Dejected", bgOpacity: 0.4, pleasant: false },
	{ word: "Empty", bgOpacity: 0.4, pleasant: false },
	{ word: "Outcast", bgOpacity: 0.4, pleasant: false },
	{ word: "Isolated", bgOpacity: 0.4, pleasant: false },
	{ word: "Apathetic", bgOpacity: 0.4, pleasant: false },
	{ word: "Indifferent", bgOpacity: 0.4, pleasant: false },
];
export const emotionWordGroups = [
	{ list: wordList1, baseColor: "#facd51" },
	{ list: wordList2, baseColor: "#e9ab54" },
	{ list: wordList3, baseColor: "#db8e6a" },
	{ list: wordList4, baseColor: "#b65957" },
	{ list: wordList5, baseColor: "#8eafa6" },
	{ list: wordList6, baseColor: "#77b2ce" },
];

const wordLists = [wordList1, wordList2, wordList3, wordList4, wordList5, wordList6];
export const emotionWordList = wordLists.flat().map((word) => word.word);

export const emotionWordListFull = wordLists.flat();
