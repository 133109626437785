import { Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import React, { ChangeEvent, FC, FormEvent } from "react";
import { useSetupContext } from "../../libs/typedContextLib";
import DataDisclaimer from "./DataDisclaimer";

const statements = {
	statement1: "I feel burnt out at times",
	statement2: "I feel fatigued from too many meetings.",
	statement3: "I feel tired from long periods of sitting at my desk.",
	statement4: "It has been hard to draw a line between work and home life.",
	statement5: "It's difficult to stay focused at work.",
	statement6: "I often feel the day flies by, and I haven't accomplished enough.",
};

const SetupStatements: FC = () => {
	const { setupStatements, setSetupStatements } = useSetupContext();

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.currentTarget;

		const newStatements = [...setupStatements];
		newStatements.splice(Number(value), 1, {
			...setupStatements[Number(value)],
			isChecked: checked,
		});

		setSetupStatements(newStatements);
	};

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
	}

	return (
		<Stack spacing={"20px"} justifyContent="center" alignItems="center">
			<Typography variant="h6">
				Do any of the statements below resonate with you? Select all that apply.
			</Typography>
			<div>
				<form onSubmit={handleSubmit} noValidate>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									value={0}
									id="goal0"
									name="goal0"
									checked={setupStatements[0].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement1}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={1}
									color="primary"
									id="goal1"
									name="goal1"
									checked={setupStatements[1].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement2}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={2}
									color="primary"
									id="goal2"
									name="goal2"
									checked={setupStatements[2].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement3}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={3}
									color="primary"
									id="goal3"
									name="goal3"
									checked={setupStatements[3].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement4}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={4}
									color="primary"
									id="goal4"
									name="goal4"
									checked={setupStatements[4].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement5}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={5}
									color="primary"
									id="goal5"
									name="goal5"
									checked={setupStatements[5].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{statements.statement6}</Typography>}
						/>
					</Grid>
				</form>
			</div>
			<DataDisclaimer />
		</Stack>
	);
};

export default SetupStatements;
