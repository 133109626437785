import {
	Checkbox,
	FormControlLabel,
	Grid,
	Link,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { modulesStyles } from "src/components/Modules/modulesStyles";
import { modulesTheme } from "src/components/Modules/modulesTheme";
import FullPageWrapper from "src/components/common/FullPageWrapper";
import QButton from "src/components/common/QButton";
import { setLocalStorage } from "src/utility/App.util";
import { validateUserCode } from "../libs/apiLib";
import { validateEmail, validatePassword } from "../libs/formValidationLib";
import { logger } from "../libs/logLib";
import { StoreKey } from "../model/entities";

const RegisterPage: FC = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [emailError, setEmailError] = useState<string>("");
	const [passwordError, setPasswordError] = useState<string>("");
	const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
	const [signUpType, setSignUpType] = useState<string>("");
	const [validCode, setValidCode] = useState<boolean>(false);
	const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
	const classes = modulesStyles();
	const location = useLocation();
	const navigate = useNavigate();

	const onLoad = async () => {
		const queryParams = new URLSearchParams(location.search);
		const signUpCode = queryParams.get("signUpCode") ?? "";
		setSignUpType(queryParams.get("signUpType") ?? "");
		if (signUpCode !== "") {
			try {
				const response = await validateUserCode(signUpCode);
				if (response.email && response.status === "PENDING") {
					setValidCode(true);
					setEmail(response.email);
				} else {
					setEmail(response.email);
					setEmailError("An account with the given email already exists.");
				}
			} catch (e) {
				logger.warn("Invalid sign up code");
			}
		}
	};

	useEffect(() => {
		void onLoad();
	}, []);

	const validateConfirmPassword = (confirmPassword: string, password: string) => {
		if (password !== confirmPassword) {
			setConfirmPasswordError("Passwords do not match");
		} else {
			setConfirmPasswordError("");
		}
	};

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		const result = validateEmail(e.target.value);
		if (result.error) {
			setEmailError(result.errorText);
		} else {
			setEmailError("");
		}
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		const result = validatePassword(e.target.value);
		if (result.error) {
			setPasswordError(result.errorText);
		} else {
			setPasswordError("");
		}
		validateConfirmPassword(confirmPassword, e.target.value);
	};

	const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(e.target.value);
		validateConfirmPassword(e.target.value, password);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			const signUpResponse = await Auth.signUp({
				username: email,
				password: password,
				attributes: {
					profile: signUpType,
				},
				clientMetadata: {
					autoConfirmUser: validCode.toString(),
				},
			});
			if (validCode) {
				await Auth.signIn(email, password);
				setLocalStorage(StoreKey.REMEMBER_ME, true);
				navigate("/setup");
			} else {
				navigate(`/verify?email=${encodeURIComponent(email)}`);
			}
			logger.log("response", signUpResponse);
		} catch (e) {
			if (e instanceof Error) {
				logger.error("SignUp Error:", e.message);
				if (e.message === "An account with the given email already exists.") {
					setEmailError(e.message);
				}
			} else {
				logger.error(e);
			}
		}
		setLoading(false);
	};

	return (
		<FullPageWrapper>
			<Stack
				width="100%"
				justifyContent="center"
				alignItems="center"
				alignSelf="center"
				spacing={modulesTheme.spacing(3)}
			>
				<Typography
					variant="h2"
					sx={{
						color: modulesTheme.palette.primary.main,
						textAlign: "center",
					}}
				>
					Create a Quimby Account
				</Typography>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={2} maxWidth="400px">
						<Grid item mobile={12}>
							<TextField
								fullWidth
								label="Email"
								variant="outlined"
								required
								value={email}
								onChange={handleEmailChange}
								error={Boolean(emailError)}
								helperText={emailError}
								disabled={validCode}
							/>
						</Grid>
						<Grid item mobile={12}>
							<TextField
								fullWidth
								label="Password"
								variant="outlined"
								type="password"
								required
								value={password}
								onChange={handlePasswordChange}
								error={Boolean(passwordError)}
								helperText={passwordError}
							/>
						</Grid>
						<Grid item mobile={12}>
							<TextField
								fullWidth
								label="Confirm Password"
								variant="outlined"
								type="password"
								required
								value={confirmPassword}
								onChange={handleConfirmPasswordChange}
								error={Boolean(confirmPasswordError)}
								helperText={confirmPasswordError}
							/>
						</Grid>
						<Grid item mobile={12} style={{ paddingTop: 20, paddingBottom: "10px" }}>
							<FormControlLabel
								control={
									<Checkbox
										value="termsOfUse"
										id="termsOfUse"
										checked={acceptTerms}
										onChange={() => setAcceptTerms(!acceptTerms)}
									/>
								}
								label={
									<Typography variant="body1">
										I have read and agree to the{" "}
										<Link
											target="_blank"
											href="https://quimby.app/services-agreement"
										>
											Services Agreement
										</Link>{" "}
										and{" "}
										<Link target="_blank" href="https://quimby.app/eula">
											End User License Agreement
										</Link>
										{"."}
									</Typography>
								}
							/>
						</Grid>
						<Grid item mobile={12}>
							<div className={classes.modulesItemCentered}>
								<QButton
									text="Sign Up"
									type="submit"
									variant="contained"
									onClick={() => {}}
									loading={loading}
									disabled={
										emailError !== "" ||
										passwordError !== "" ||
										confirmPasswordError !== "" ||
										!acceptTerms
									}
								/>
							</div>
						</Grid>
					</Grid>
				</form>
			</Stack>
		</FullPageWrapper>
	);
};

export default RegisterPage;
