import { Container, Grid, ThemeProvider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import Theme, { colors } from "../Theme";
import FilterDataControl from "../components/FilterDataControl";
import GroupPicker from "../components/GroupPicker";
import { modulesTheme } from "../components/Modules/modulesTheme";
import { emotionWordGroups } from "../components/ReflectionSlides/SharedComponents/EmotionWordList";
import { MemoizedGroupInsightsLineChart } from "../components/visualize/GroupInsightsLineChart";
import GroupStimulusChart from "../components/visualize/GroupInsightsStimulus";
import GroupInsightsWordcloud from "../components/visualize/GroupInsightsWordCloud";
import { apiGetReflections } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { dataType, dateRange } from "../shared/constants";
import { refreshSubscriptionInfo } from "../utility/App.util";
import {
	getData,
	getFilteredStimulusData,
	getTimeStamp,
	hasDataChanged,
	mapData,
	updateCache,
} from "../utility/dashboardUserHelpers";

const SECONDARY_COLOR = Theme.palette.words.customStimulus;

const UNSELECTED_WORD = { text: undefined };

// Used to clear selection
const CLEARZONE0 = "clearzone0";
const CLEARZONE1 = "clearzone1";
const CLEARZONE2 = "clearzone2";
const CLEARZONE3 = "clearzone3";
const CLEARZONE4 = "clearzone4";
const CLEARZONEALL = "clearzoneall";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flex: 1,
		background: colors.lightGray,
		paddingTop: "40px",
	},
	root: {
		marginTop: theme.spacing(2),
	},
	tilesArea: {
		paddingTop: theme.spacing(4),
		width: "100%",
	},
	title: {
		marginBottom: theme.spacing(1),
		pointerEvents: "none",
	},
	controls: {
		//gridTemplateColumns: "25% 50% 25%",
		display: "flex",
		width: "100%",
	},
	groupPicker: {
		// gridColumn: "1/2",
		justifySelf: "left",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "flex-start",
	},
	filterControl: {
		margin: "auto",
	},
	"@media (max-width: 600px)": {
		controls: {
			flexDirection: "column",
		},
	},
}));

const DashboardUsersGroup = () => {
	const {
		appState: { flatGroupTree, reflectionCache, dataSource, currentId, selectedSubscriptionId },
		appDispatch,
	} = useAppContext();

	const [domain, setDomain] = useState(dateRange.ONE_MONTH);
	const [selectedWord, setSelectedWord] = useState(undefined);
	// These two bits of state are used to help coordinate how
	// the Morale Chart and Wordcloud interact when selecting data
	const [forceWordCloudRender, setForceWordCloudRender] = useState(Math.random());

	const classes = useStyles();

	const updateReflections = useCallback(
		async (forceUpdate = false) => {
			if (!flatGroupTree.length) {
				await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
			}
			// Fetch data from AWS
			const fetchedData = await apiGetReflections(dataSource);
			// Check timestamp to see if data is new
			const dataHasChanged = hasDataChanged(dataSource, fetchedData, reflectionCache);

			// If data is new (or we're forcing an update) map data and update cache
			if (dataHasChanged || forceUpdate) {
				// Get timestamp
				const timestamp = getTimeStamp(dataSource, fetchedData);

				// Map data
				const mappedData = mapData(dataSource, fetchedData);

				// Update cache
				const updatedCache = updateCache(
					dataSource,
					{ timestamp: timestamp, data: mappedData },
					reflectionCache,
				);

				appDispatch({
					type: "setReflectionCache",
					reflectionCache: updatedCache,
				});

				// Used to force rerender of wordcloud
				setForceWordCloudRender(Math.random());
			}
		},
		[dataSource, reflectionCache, appDispatch, currentId],
	);

	const onWordClick = useCallback((word) => {
		if (word.text !== undefined) {
			logger.telemetry({
				action: "click",
				event: "shared_insights_wordcloud_word",
				eventData: {
					value: word.text,
				},
			});
		}
		setSelectedWord(word.text);
	}, []);

	const getWordColor = useCallback(
		(word) => {
			const foundWord = emotionWordGroups.find((list) =>
				list.list.find(
					(wrd) => wrd.word.toLocaleLowerCase() === word.text.toLocaleLowerCase(),
				),
			);

			if (selectedWord === undefined) {
				if (foundWord) {
					return foundWord.baseColor;
				}
				return SECONDARY_COLOR;
			}

			if (word.text === selectedWord) {
				if (foundWord) {
					return foundWord.baseColor;
				}
				return SECONDARY_COLOR;
			}

			return "grey";
		},
		[selectedWord, emotionWordGroups],
	);

	useEffect(() => {
		void updateReflections();
	}, [dataSource, reflectionCache, updateReflections, currentId]);

	function handleClick(e) {
		let id = e.target.id;
		if (id?.includes("clearzone")) {
			setSelectedWord("");
			onWordClick(UNSELECTED_WORD);
		}
	}
	const {
		UIContext: { homeHidden },
	} = useTypedAppContext();

	return (
		<ThemeProvider theme={modulesTheme}>
			<div className={classes.main} onClick={handleClick} id={CLEARZONE0}>
				<Container maxWidth={false} className={classes.root}>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						id={CLEARZONE1}
					>
						<div className={classes.controls}>
							<div className={classes.groupPicker}>
								<GroupPicker
									flatGroupTree={flatGroupTree}
									currentId={currentId}
									dataSource={dataSource}
									setDataSource={(value) => {
										appDispatch({ type: "setDataSource", dataSource: value });
									}}
									updateReflections={async () => {
										await refreshSubscriptionInfo(
											selectedSubscriptionId,
											appDispatch,
										);
										await updateReflections(true);
										setSelectedWord("");
										onWordClick(UNSELECTED_WORD);
									}}
								/>
							</div>
							<div className={classes.filterControl}>
								<FilterDataControl
									domain={domain}
									setDomain={(dm) => {
										setDomain(dm);
										logger.telemetry({
											action: "click",
											event: "shared insights date range",
											eventData: {
												value: dm,
											},
										});
										setSelectedWord("");
										onWordClick(UNSELECTED_WORD);
									}}
								/>
							</div>
						</div>

						<Grid
							container
							spacing={6}
							justifyContent="space-evenly"
							alignItems="center"
							className={classes.tilesArea}
							id={CLEARZONEALL}
						>
							<Grid item mobile={12} tablet={6} id={CLEARZONE2}>
								<Typography variant="h2" className={classes.title}>
									Team Morale
								</Typography>
								{
									<MemoizedGroupInsightsLineChart
										data={getData(
											dataSource,
											reflectionCache,
											dataType.MOOD,
											domain,
										)}
										isDisabled={flatGroupTree.length === 0}
									/>
								}
							</Grid>
							<Grid item mobile={12} tablet={6} id={CLEARZONE3}>
								<Typography variant="h2" className={classes.title}>
									Emotions Word Cloud
								</Typography>
								{!homeHidden && (
									<GroupInsightsWordcloud
										words={getData(
											dataSource,
											reflectionCache,
											dataType.EMOTION,
											domain,
										)}
										dataSource={dataSource}
										key={forceWordCloudRender}
										onWordClick={onWordClick}
										getWordColor={getWordColor}
									/>
								)}
							</Grid>
							<Grid
								item
								mobile={12}
								tablet={6}
								id={CLEARZONE4}
								style={{ alignSelf: "flex-start" }}
							>
								<Typography variant="h2" className={classes.title}>
									Stimulus
								</Typography>
								<GroupStimulusChart
									data={getFilteredStimulusData(
										dataSource,
										reflectionCache,
										dataType.STIMULUS,
										domain,
										selectedWord,
									)}
									key={forceWordCloudRender}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>
		</ThemeProvider>
	);
};

export default DashboardUsersGroup;
