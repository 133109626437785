import { EDIT_RECORD_KEY } from "../../shared/constants";
import PersonGroup from "./PersonGroup";
import React from "react";

export default function ManagerPersonGroup({
	people,
	editGroup,
	updateGroupEditRecord,
	killEditMode,
	canEditGroupsSection,
}) {
	return (
		<PersonGroup
			title="Members"
			people={people}
			setPeople={async (value) => {
				await editGroup(EDIT_RECORD_KEY.MEMBERS, value);
			}}
			updateGroupEditRecord={updateGroupEditRecord}
			editLabel="Add members"
			lonelyMessage="Add at least three members to the group to view aggregated data."
			killEditMode={killEditMode}
			canEditGroupsSection={canEditGroupsSection}
		/>
	);
}
