import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import Theme from "../../Theme";
import { twoDigitYear } from "../../utility/HelperFunctions";
import MyInsightsLineChartTooltip from "./MyInsightsLineChartTooltip";

const AXIS_COLOR = "rgba(0,0,0,.5)";
const CARTESIAN_GRID_COLOR = AXIS_COLOR;
const STROKE_COLOR = Theme.palette.primary.main;
const TOOLTIP_CURSOR_COLOR = Theme.palette.secondary.main;
const THUMB_ICON_COLOR = "#9f9f9f";

const useStyles = makeStyles(() => ({
	paper: {
		background: "white",
		borderRadius: 15,
	},
	thumbsUp: {
		color: THUMB_ICON_COLOR,
	},
	thumbsDown: {
		color: THUMB_ICON_COLOR,
	},
	rowDiv: {
		display: "flex",
		flexDirection: "row",
	},
	columnDiv: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		minHeight: 475,
		paddingTop: 20,
		paddingBottom: 47,
		paddingLeft: 15,
		marginRight: -70,
	},
	// Theme 2
}));

// Scale goes from -1 to 11 to allow some room for the extremes
function formatMoodIndex(value) {
	if (value === 0) {
		return "";
	}

	if (value === 10) {
		return "";
	}

	return "";
}

function formatTimestamp(rawTime) {
	let date = new Date(+rawTime);

	// This prevents Nan from showing when rerendering
	if (Number.isNaN(date.getMonth())) {
		return "";
	}

	// Added a little extra spacing around the labels
	return `  ${date.getMonth() + 1}/${date.getDate()}  `;
}

function formatTooltipTimestamp(rawTime) {
	const date = new Date(+rawTime);
	const year = twoDigitYear(date.getFullYear());
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `Date : ${month}/${day}/${year}`;
}

export default function MyInsightsLineChart({ data, selectedData, handleDotClick }) {
	const classes = useStyles();

	// Have to merge data into a single array to make the x-axis happy
	let mergedData =
		data.length === selectedData.length
			? data.map((d, index) => {
					let selection =
						selectedData.length === 0 ? Number.Nan : selectedData[index].feeling;
					return { ...d, selection: selection };
				})
			: [];
	mergedData = mergedData.filter(
		(element) =>
			element.feeling !== null &&
			element.feeling !== undefined &&
			(!element.multiEmotionIndex || element.multiEmotionIndex === 0),
	);

	return (
		<Paper className={classes.paper}>
			<div className={classes.rowDiv}>
				<div className={classes.columnDiv}>
					<ThumbUpIcon className={classes.thumbsUp} />
					<ThumbDownIcon className={classes.thumbsDown} />
				</div>
				<ResponsiveContainer minHeight="475px" minWidth="200px">
					<LineChart
						width={800}
						height={400}
						data={mergedData}
						onClick={handleDotClick}
						margin={{
							top: 30,
							right: 30,
							left: 30,
							bottom: 30,
						}}
					>
						<CartesianGrid
							vertical={false}
							strokeDasharray="3 3"
							stroke={CARTESIAN_GRID_COLOR}
						/>
						<XAxis
							dataKey="date"
							stroke={AXIS_COLOR}
							tickFormatter={formatTimestamp}
							// Leaving this here in case we want to go down this route again
							// dataKey='fakeIndex'
							// type='number'
							// interval={0}
							// allowDecimals={false}
							// domain={domain}
							// allowDataOverflow={true}
						/>
						<YAxis
							stroke={AXIS_COLOR}
							domain={[0, 10]}
							tickLine
							tickFormatter={formatMoodIndex}
							label={{
								angle: -90,
								position: "insideLeft",
							}}
						/>
						<Line
							type="monotone"
							dataKey="selection"
							stroke={"transparent"}
							dot={{
								r: 8,
								stroke: TOOLTIP_CURSOR_COLOR,
								fill: TOOLTIP_CURSOR_COLOR,
							}}
							isAnimationActive={false}
						/>
						<Line
							type="monotone"
							dataKey="feeling"
							stroke={STROKE_COLOR}
							dot={true}
							strokeWidth={1.5}
							isAnimationActive={false}
							activeDot={{
								cursor: "pointer",
								r: 6,
								stroke: TOOLTIP_CURSOR_COLOR,
								fill: TOOLTIP_CURSOR_COLOR,
							}}
						/>
						{
							<Tooltip
								cursor={{ stroke: TOOLTIP_CURSOR_COLOR, strokeWidth: 2 }}
								content={
									<MyInsightsLineChartTooltip
										labelFormatter={(value) => formatTooltipTimestamp(value)}
									/>
								}
							/>
						}
					</LineChart>
				</ResponsiveContainer>
			</div>
		</Paper>
	);
}

export const MemoizedMyInsightsLineChart = React.memo(MyInsightsLineChart);

MyInsightsLineChart.propTypes = {
	data: PropTypes.array.isRequired,
	selectedData: PropTypes.array.isRequired,
	handleDotClick: PropTypes.func.isRequired,
};
