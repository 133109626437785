import { Close } from "@mui/icons-material";
import { Box, Modal, Stack, ThemeProvider } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { colors } from "../../Theme";
import { logger } from "../../libs/logLib";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "./QButton";

interface QModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	saveButton?: boolean;
	saveButtonText?: string;
	onSave?: () => void;
	cancelButton?: boolean;
	cancelButtonText?: string;
	onCancel?: () => void;
	deleteButton?: boolean;
	deleteButtonText?: string;
	onDelete?: () => void;
	cancelX?: boolean;
	onCancelX?: () => void;
	disableCancelClick?: boolean;
	onCancelClick?: () => void;
	children: ReactNode;
	width?: string;
}

const QModal: FC<QModalProps> = ({
	open,
	setOpen,
	children,
	saveButton = false,
	saveButtonText = "Save",
	onSave = () => {},
	cancelButton = false,
	cancelButtonText = "Cancel",
	onCancel = () => {},
	deleteButton = false,
	deleteButtonText = "Delete",
	onDelete = () => {},
	cancelX = false,
	onCancelX = () => {},
	disableCancelClick = false,
	onCancelClick = () => {},
	width = "fit-content",
}) => {
	const {
		UIContext: { appHeight, appWidth },
	} = useTypedAppContext();

	const VERTICAL_PADDING = 60;
	const HORIZONTAL_PADDING = 16;

	const [modalMaxHeight, setModalMaxHeight] = useState(appHeight - VERTICAL_PADDING * 2);
	const [modalMaxWidth, setModalMaxWidth] = useState(appWidth - HORIZONTAL_PADDING * 2);

	useEffect(() => {
		setModalMaxHeight(appHeight < 800 ? appHeight - VERTICAL_PADDING * 2 : 600);
		setModalMaxWidth(appWidth < 900 ? appWidth - HORIZONTAL_PADDING * 2 : 700);
	}, [appHeight, appWidth]);

	return (
		<ThemeProvider theme={modulesTheme}>
			<Modal
				open={open}
				onClose={() => {
					if (!disableCancelClick) {
						try {
							onCancelClick();
							setOpen(false);
						} catch (e) {
							logger.log(e);
						}
					}
				}}
			>
				<Stack
					style={{
						position: "fixed",
						display: "flex",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						backgroundColor: colors.white,
						borderRadius: "20px",
						overflow: "hidden",
						height: "fit-content",
						width: width,
						maxHeight: modalMaxHeight,
						maxWidth: modalMaxWidth,
						outline: 0,
					}}
				>
					{cancelX && (
						<Box
							sx={{
								alignSelf: "end",
								paddingRight: "6px",
								paddingTop: "6px",
							}}
							onClick={() => {
								try {
									onCancelX();
									setOpen(false);
								} catch (e) {
									logger.log(e);
								}
							}}
						>
							<Close />
						</Box>
					)}
					<Box
						sx={{
							overflowY: "auto",
							paddingTop: "16px",
							paddingLeft: "16px",
							paddingRight: "16px",
						}}
					>
						{children}
					</Box>
					{(cancelButton || saveButton || deleteButton) && (
						<Stack
							direction="row"
							justifyContent="end"
							width="100%"
							padding="16px"
							sx={{ bottom: 0 }}
						>
							{cancelButton && (
								<QButton
									variant="text"
									text={cancelButtonText}
									onClick={() => {
										try {
											onCancel();
											setOpen(false);
										} catch (e) {
											logger.log(e);
										}
									}}
								/>
							)}
							{deleteButton && (
								<QButton
									variant="text"
									text={deleteButtonText}
									onClick={() => {
										try {
											onDelete();
											setOpen(false);
										} catch (e) {
											logger.log(e);
										}
									}}
								/>
							)}
							{saveButton && (
								<QButton
									variant="text"
									text={saveButtonText}
									onClick={() => {
										try {
											onSave();
											setOpen(false);
										} catch (e) {
											logger.log(e);
										}
									}}
								/>
							)}
						</Stack>
					)}
				</Stack>
			</Modal>
		</ThemeProvider>
	);
};

export default QModal;
