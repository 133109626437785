import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import QButton from "src/components/common/QButton";
import { colors } from "../../../Theme";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

interface PositiveMindsetConfirmationSlideProps {
	page: ModulesLegacyPage;
}

const PositiveMindsetConfirmationSlide: FC<PositiveMindsetConfirmationSlideProps> = ({ page }) => {
	const { saveOnClick, setModuleIntrosComplete, setModuleReminders } =
		useReflectionModelContext();
	const classes = modulesStyles();

	const [enabled, setEnabled] = React.useState(true);

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5" textAlign="center">
						Practice positive mindset habits daily
					</Typography>
				</Grid>
				<Grid item mobile={12} tablet={8}>
					<div
						style={{
							padding: 10,
							borderStyle: "solid",
							borderColor: enabled ? colors.teal : colors.darkerGray,
							borderWidth: 2,
							borderRadius: 10,
							marginBottom: 20,
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								paddingBottom: 10,
							}}
						>
							<Typography variant="h6">Affirmations</Typography>
							<Typography
								variant="h6"
								style={{
									marginLeft: "auto",
									color: enabled ? colors.teal : colors.darkerGray,
								}}
							>
								{enabled ? "Every Check-in" : "Disabled"}
							</Typography>
						</div>
						<Typography variant="body1">
							Affirmations will be added to all of your check-ins
						</Typography>
					</div>

					<div
						style={{
							padding: 10,
							borderStyle: "solid",
							borderColor: enabled ? colors.teal : colors.darkerGray,
							borderWidth: 2,
							borderRadius: 10,
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								paddingBottom: 10,
							}}
						>
							<Typography variant="h6">Gratitude</Typography>
							<Typography
								variant="h6"
								style={{
									marginLeft: "auto",
									color: enabled ? colors.teal : colors.darkerGray,
								}}
							>
								{enabled ? "Weekdays @ 5:00 PM" : "Disabled"}
							</Typography>
						</div>
						<Typography variant="body1">
							Gratitude will be added to your afternoon check-ins
						</Typography>
					</div>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1" textAlign="center">
						You can change these settings anytime in your Profile.
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Stack direction="column" spacing={2}>
						<div className={classes.modulesItemCentered}>
							<QButton
								text="Save & Close"
								onClick={() => {
									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									//@ts-ignore
									setModuleIntrosComplete(["gratitude", "affirmations"]);
									if (enabled) {
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										//@ts-ignore
										setModuleReminders([
											{ module: "affirmations", config: { all: true } },
											{
												module: "gratitude",
												config: {
													time: "17:00",
													days: [
														false,
														true,
														true,
														true,
														true,
														true,
														false,
													],
												},
											},
										]);
									}
									saveOnClick();
								}}
							/>
						</div>
						<div className={classes.modulesItemCentered}>
							<QButton
								text={enabled ? "Opt Out" : " Opt In "}
								onClick={() => {
									setEnabled(!enabled);
								}}
								variant="text"
							/>
						</div>
					</Stack>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default PositiveMindsetConfirmationSlide;
