/* eslint-disable no-undef */
// ^^^ for localStorage and sessionStorage
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { mapArrayToObjectHACK } from "../components/group/groupManagementHelpers";
import {
	apiGetGroupTree,
	apiGetSubscription,
	apiGetUser,
	apiListGroups,
	apiListUsers,
} from "../libs/apiLib";
import { logger } from "../libs/logLib";
import { StoreKey } from "../model/entities";
import { createFlatGroupTreeNEW } from "./GroupPickerHelpers";

export const appReducer = (state, action) => {
	switch (action.type) {
		case "setReminders":
			return { ...state, reminders: action.reminders };
		case "setErrorText":
			return { ...state, toastErrorText: action.toastErrorText };
		case "setIsSetupComplete":
			return { ...state, isSetupComplete: action.isSetupComplete };
		case "setIsAuthenticating":
			return { ...state, isAuthenticating: action.isAuthenticating };
		case "setUserHasAuthenticated":
			return { ...state, userHasAuthenticated: action.userHasAuthenticated };
		case "setCognitoUser":
			return { ...state, cognitoUser: action.cognitoUser };
		case "setAuthenticatedUserInfo":
			return { ...state, authenticatedUserInfo: action.authenticatedUserInfo };
		case "setSetupInfo":
			return {
				...state,
				authenticatedUserInfo: {
					...state.authenticatedUserInfo,
					setupInfo: action.setupInfo,
				},
			};
		case "setFlatGroupTree":
			return { ...state, flatGroupTree: action.flatGroupTree };
		case "setUpdateAvailable":
			return { ...state, updateAvailable: action.updateAvailable };
		case "setReflectionCache":
			return { ...state, reflectionCache: action.reflectionCache };
		case "refreshReflectionData":
			return { ...state, refreshReflectionData: action.refreshReflectionData };
		case "setDataSource":
			return { ...state, dataSource: action.dataSource };
		case "setHasSubscription":
			return { ...state, hasSubscription: action.hasSubscription };
		case "setWebPushNotifications":
			return { ...state, webPushNotifications: action.webPushNotifications };
		case "setQuimbyServiceWorker":
			return { ...state, quimbyServiceWorker: action.quimbyServiceWorker };
		case "setSelectedSubscriptionId":
			return {
				...state,
				selectedSubscriptionId: action.selectedSubscriptionId,
			};
		case "signInUserWithSubscription":
			return {
				...state,
				cognitoUser: action.cognitoUser,
				authenticatedUserInfo: action.authenticatedUserInfo,
				hasSubscription: action.hasSubscription,
				dataSource: action.dataSource,
				flatGroupTree: action.flatGroupTree,
				reminders: action.reminders,
				isSetupComplete: action.isSetupComplete,
				userHasAuthenticated: action.userHasAuthenticated,
				selectedSubscriptionId: action.selectedSubscriptionId,
				selectedSubscriptionIsAdmin: action.selectedSubscriptionIsAdmin,
				selectedSubscriptionInfo: action.selectedSubscriptionInfo,
				invited: action.invited,
				invitations: action.invitations,
				subscriptionIds: action.subscriptionIds,
				userDays: action.userDays,
				subscriptions: action.subscriptions,
				subscriptionMembers: action.subscriptionMembers,
				groups: action.groups,
				groupsTree: action.groupsTree,
			};

		case "refreshSubscriptionInfo":
			return {
				...state,
				dataSource: action.dataSource,
				flatGroupTree: action.flatGroupTree,
				allGroups: action.allGroups,
				groupsArray: action.groupsArray,
				selectedSubscriptionIsAdmin: action.selectedSubscriptionIsAdmin,
				subscriptionMembers: action.subscriptionMembers,
				groups: action.groups,
				groupsTree: action.groupsTree,
				selectedSubscriptionInfo: action.selectedSubscriptionInfo,
			};

		case "signOut":
			return {
				...state,
				userHasAuthenticated: false,
				cognitoUser: "",
				authenticatedUserInfo: "",
				dataSource: undefined,
				flatGroupTree: [],
				reflectionCache: {},
				hasSubscription: false,
				subscriptions: [],
				selectedSubscriptionId: "",
				selectedSubscriptionIsAdmin: false,
				selectedSubscriptionInfo: {},
				userDays: 0,
				reminders: [],
				uuid: "",
				subscriptionMembers: [],
				groups: {},
				groupsTree: [],
			};
		case "setAppState":
			return {
				...state,
				...action,
			};
		default:
			break;
	}

	return state;
};

export const initialAppState = {
	reminders: [],
	isSetupComplete: false,
	isAuthenticating: true,
	userHasAuthenticated: false,
	cognitoUser: "",
	authenticatedUserInfo: "",
	flatGroupTree: [],
	reflectionCache: {},
	dataSource: undefined,
	hasSubscription: false,
	selectedSubscriptionId: "",
	selectedSubscriptionIsAdmin: false,
	selectedSubscriptionInfo: {},
	toastErrorText: undefined,
	uuid: undefined,
	userDays: 0,
	subscriptions: [],
	subscriptionPlans: {
		monthly: { price: 5.99, currency: "usd" },
		yearly: { price: 59.88, currency: "usd" },
	},
	subscriptionMembers: [],
	groups: {},
	groupsTree: [],
	refreshReflectionData: false,
};

function isAdmin(subscriptions, subscriptionId) {
	const subscriptionFound = subscriptions.find(
		(subscription) => subscription.subscriptionId === subscriptionId,
	);
	if (subscriptionFound) {
		if (subscriptionFound.roles?.admin && subscriptionFound.roles.admin) {
			return true;
		}
	}
	return false;
}

function flattenSubscriptions(userInfo) {
	let mappedDevSubscriptions = [];
	// eslint-disable-next-line no-extra-boolean-cast
	if (!!userInfo.devSubscriptions) {
		mappedDevSubscriptions = userInfo.devSubscriptions.map((devSubscription) => {
			return {
				subscriptionId: devSubscription,
				isDev: true,
				company: devSubscription.company ?? devSubscription,
				roles: { admin: true },
			};
		});
	}
	return [...userInfo.subscription, ...mappedDevSubscriptions];
}

export async function getAuthenticatedUserInfo(appDispatch) {
	const user = await Auth.currentAuthenticatedUser();
	const userInfo = await apiGetUser();
	if (!userInfo) {
		throw new Error("User account not configured");
	}

	if (userInfo.status === "REMOVED") {
		throw new Error("User account disabled");
	}

	userInfo.reminders = mutateReminders(userInfo.reminders);

	let invitations = userInfo.invited;

	// Calc days user has been with us
	const TODAY = new Date();
	const createdDate = new Date(userInfo.humanCreatedAt);
	const getUserDays = (TODAY, createdDate) => {
		if (createdDate) {
			const dayDiff = Math.floor((TODAY - createdDate) / (1000 * 60 * 60 * 24));
			return dayDiff;
		} else {
			return 0;
		}
	};
	// If user is part of a subscription
	// Figure out the groupTree and dataSource
	const hasSubscription = userInfo.PK !== "S:NO_SUBSCRIPTION";
	const hasMultiSubscriptions = Array.isArray(userInfo.subscription);
	let subscriptionId;
	let subscriptionIds = [];
	if (hasMultiSubscriptions) {
		userInfo.subscription.map((subs) => {
			if (subs.subscriptionId !== "S:NO_SUBSCRIPTION") {
				subscriptionIds.push(subs.subscriptionId);
			}
		});
		subscriptionId = hasSubscription
			? subscriptionIds[0]
			: userInfo.subscription[0].subscriptionId;
	} else {
		subscriptionId = userInfo.subscription.subscriptionId;
	}

	const [groupsTreeInfo, allTheGroupInfos] = await Promise.all([
		await apiGetGroupTree(subscriptionId),
		await apiListGroups(subscriptionId),
	]);
	const rootGroup = allTheGroupInfos.find(
		(item) => !!item && !!item.SK && item.SK.startsWith("GG:"),
	);
	const usersGroups = allTheGroupInfos.filter((group) => {
		return (
			(group.PK === subscriptionId &&
				!!group.members &&
				group.members.includes(userInfo.PK)) ||
			(!!group.managers && group.managers.includes(userInfo.SK ?? "NULL"))
		);
	});
	const tempFlatGroupTree = createFlatGroupTreeNEW(
		usersGroups,
		userInfo.managedGroups,
		allTheGroupInfos,
		groupsTreeInfo?.groupTree ?? [],
	);

	const subscriptions = flattenSubscriptions(userInfo);

	const selectedSubscriptionIsAdmin = isAdmin(subscriptions, subscriptionId);

	let subscriptionMembersObject = [];
	let groupsObject = {};
	let groupsTree = [];
	let groups = [];
	let subscriptionMembers = [];
	let selectedSubscriptionInfo = {};

	if (selectedSubscriptionIsAdmin) {
		[selectedSubscriptionInfo, groups, groupsTree, subscriptionMembers] = await Promise.all([
			apiGetSubscription(subscriptionId),
			apiListGroups(subscriptionId),
			apiGetGroupTree(subscriptionId),
			apiListUsers(subscriptionId),
		]);

		// TODO: This transform should be done by the API call
		groupsObject = mapArrayToObjectHACK(groups);
		subscriptionMembersObject = mapArrayToObjectHACK(subscriptionMembers);
	}
	appDispatch({
		type: "signInUserWithSubscription",
		cognitoUser: user,
		authenticatedUserInfo: userInfo,
		hasSubscription: hasSubscription,
		dataSource: rootGroup?.SK ?? false,
		flatGroupTree: tempFlatGroupTree,
		reminders: userInfo.reminders,
		isSetupComplete: userInfo.setupInfo.completedSetup,
		userHasAuthenticated: true,
		selectedSubscriptionId: subscriptionId,
		selectedSubscriptionIsAdmin: selectedSubscriptionIsAdmin,
		selectedSubscriptionInfo: selectedSubscriptionInfo,
		invited: userInfo.invited,
		invitations,
		subscriptions: subscriptions,
		userDays: getUserDays(TODAY, createdDate),
		subscriptionMembers: subscriptionMembersObject,
		groups: groupsObject,
		groupsTree: groupsTree?.groupTree ?? undefined,
	});

	return userInfo;
}

export async function refreshSubscriptionInfo(subscriptionId, appDispatch) {
	const userInfo = await apiGetUser();
	const devSubscriptions = userInfo.devSubscriptions ?? [];
	const isDevSubscription = devSubscriptions.find((sub) => sub === subscriptionId);

	// If user is part of a subscription
	// Figure out the groupTree and dataSource

	const [groupsTreeInfo, allTheGroupInfos] = await Promise.all([
		await apiGetGroupTree(subscriptionId),
		await apiListGroups(subscriptionId),
	]);

	const rootGroup = allTheGroupInfos.find(
		(item) => !!item && !!item.SK && item.SK.startsWith("GG:"),
	);
	const usersGroups = allTheGroupInfos.filter((group) => {
		if (isDevSubscription) {
			return group.PK === subscriptionId;
		} else {
			return (
				(group.PK === subscriptionId &&
					!!group.members &&
					group.members.includes(userInfo.SK)) ||
				(!!group.managers && group.managers.includes(userInfo.SK ?? "NULL"))
			);
		}
	});
	const tempFlatGroupTree = createFlatGroupTreeNEW(
		usersGroups,
		userInfo.managedGroups,
		allTheGroupInfos,
		groupsTreeInfo?.groupTree ?? [],
	);
	const subscriptions = flattenSubscriptions(userInfo);
	const selectedSubscriptionIsAdmin = isAdmin(subscriptions, subscriptionId);

	let subscriptionMembersObject = {};
	let groupsObject = {};
	let groupsTree = [];
	let groups = [];
	let subscriptionMembers = [];
	let selectedSubscriptionInfo = {};

	if (selectedSubscriptionIsAdmin) {
		[selectedSubscriptionInfo, groups, groupsTree, subscriptionMembers] = await Promise.all([
			apiGetSubscription(subscriptionId),
			apiListGroups(subscriptionId),
			apiGetGroupTree(subscriptionId),
			apiListUsers(subscriptionId),
		]);

		// TODO: This transform should be done by the API call

		groupsObject = mapArrayToObjectHACK(groups);
		subscriptionMembersObject = mapArrayToObjectHACK(subscriptionMembers ?? []);
	}
	appDispatch({
		type: "refreshSubscriptionInfo",
		dataSource: rootGroup?.SK ?? false,
		flatGroupTree: tempFlatGroupTree,
		selectedSubscriptionIsAdmin: selectedSubscriptionIsAdmin,
		selectedSubscriptionInfo: selectedSubscriptionInfo,
		subscriptions: subscriptions,
		subscriptionMembers: subscriptionMembersObject,
		groups: groupsObject,
		groupsTree: groupsTree?.groupTree ?? undefined,
	});

	return userInfo;
}

export async function setSignedOutAppState(appDispatch) {
	try {
		setLocalStorage(StoreKey.REMEMBER_ME, false);
		await Auth.signOut();
		logger.log("[AWS] Success: user has signed out");
		appDispatch({ type: "signOut" });
	} catch (error) {
		logger.error(error, "setSignedOutAppState");
		logger.log("e: ", error);
	}
}

function mutateReminders(reminders) {
	const mutatedReminders = reminders.map((reminder) => {
		if (!("moraleCheckIn" in reminder)) {
			if ("modules" in reminder && reminder.modules) {
				reminder["moraleCheckIn"] = false;
			} else {
				reminder["moraleCheckIn"] = true;
			}
		}
		if (!("affirmations" in reminder)) {
			reminder["affirmations"] = false;
		}
		if (!("gratitude" in reminder)) {
			reminder["gratitude"] = false;
		}
		return reminder;
	});

	return mutatedReminders;
}

export function configModuleReminders(reminders, remindersConfig) {
	let newReminders = [...reminders];
	remindersConfig.forEach((item) => {
		if (item.config && item.module) {
			if (item.config.all) {
				newReminders = newReminders.map((value) => {
					return { ...value, [item.module]: true };
				});
			} else if (item.config.time && item.config.days) {
				const foundIndex = newReminders.findIndex(
					(x) =>
						JSON.stringify(x.days) === JSON.stringify(item.config.days) &&
						x.time === item.config.time &&
						x.enabled,
				);
				if (foundIndex >= 0) {
					newReminders[foundIndex] = {
						...newReminders[foundIndex],
						[item.module]: true,
					};
				} else {
					newReminders = [
						...newReminders,
						{
							enabled: true,
							days: item.config.days,
							time: item.config.time,
							moraleCheckIn: false,
							[item.module]: true,
						},
					];
				}
			}
		}
	});

	return newReminders;
}

export function getLocalStorage(key) {
	const value = localStorage.getItem(key);
	return value;
}

export function setLocalStorage(key, value) {
	localStorage.setItem(key, value);
}

export function getSessionStorage(key) {
	const value = sessionStorage.getItem(key);
	return value;
}

export function setSessionStorage(key, value) {
	sessionStorage.setItem(key, value);
}

/**
 * @returns {string}
 */
export const getTelemetrySessionId = () => {
	let sessionId;
	sessionId = getSessionStorage(StoreKey.SESSION_ID);
	if (!sessionId) {
		sessionId = uuidv4();
		setSessionStorage(StoreKey.SESSION_ID, sessionId);
	}
	return sessionId;
};

export const getUUID = () => {
	let uuid;
	try {
		uuid = getLocalStorage(StoreKey.UUID);
	} catch (e) {
		logger.error(e);
	}
	if (!uuid) {
		uuid = uuidv4();
		try {
			setLocalStorage(StoreKey.UUID, uuid);
		} catch (e) {
			logger.error(e);
		}
	}
	return uuid;
};
