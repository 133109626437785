import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { MEMBER_STATUS } from "../shared/constants";
import { useAppContext } from "../libs/contextLib";
import MembersTabControl from "../components/members/MembersTabControl";
const useStyles = makeStyles((theme) => ({
	root: {
		padding: "2em",
		backgroundColor: theme.palette.lightGray,
		display: "flex",
		justifyContent: "center",
		width: "100%",
	},
}));

export default function Members() {
	const {
		appState: { subscriptionMembers },
	} = useAppContext();
	const [members, setMembers] = useState([]);
	const [pending, setPending] = useState([]);
	const [emails, setEmails] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		function fetchMemberInfo() {
			const subscriptionMembersArray = Object.values(subscriptionMembers ?? []);

			setMembers(
				subscriptionMembersArray.filter((item) => item.status === MEMBER_STATUS.MEMBER),
			);

			setPending(
				subscriptionMembersArray.filter((item) => item.status === MEMBER_STATUS.PENDING),
			);

			setEmails(subscriptionMembersArray.map((item) => item.email));
		}

		fetchMemberInfo();
	}, [subscriptionMembers]);

	return (
		<div className={classes.root}>
			<MembersTabControl members={members} pending={pending} emails={emails} />
		</div>
	);
}
