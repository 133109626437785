import { Info } from "@mui/icons-material";
import { Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QChip from "src/components/common/QChip";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesColors, modulesTheme } from "../components/Modules/modulesTheme";
import QButton from "../components/common/QButton";
import { NavBarState } from "../components/home/NavBar";
import { apiGetModules } from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { Module } from "../model/modules/modules.Model";
import { TelemetryAction } from "../model/telemetry";

const ModulesSVG =
	"https://quimby-modules-dev.s3.us-east-2.amazonaws.com/public/common/milestones.svg";

const ModulesDetailsPage = () => {
	const {
		UIContext: { appWidth, navBarState },
	} = useTypedAppContext();

	const history = useNavigate();

	const [modules, setModules] = useState<Module[]>([]);

	const classes = modulesStyles();

	//@ts-expect-error not typed
	const { appState } = useAppContext();
	const hasSubscription: boolean = appState.hasSubscription;

	const loadModules = async () => {
		try {
			const modules = await apiGetModules();

			modules.sort((a, b) => {
				if (a.moduleStatus.moduleState === b.moduleStatus.moduleState) {
					return 0;
				}

				if (a.moduleStatus.moduleState === "active") {
					return -1;
				}

				if (b.moduleStatus.moduleState === "active") {
					return 1;
				}

				return 0;
			});
			setModules(modules);
		} catch (e) {
			logger.error(e);
		}
	};

	useEffect(() => {
		void loadModules();
	}, [hasSubscription]);

	const handleLoadModule = (module: Module) => {
		logger.telemetry({
			action: TelemetryAction.click,
			event: "Open Module",
			eventData: {
				id: module.moduleId,
				title: module.title,
				version: module.version,
				status: module.moduleStatus.moduleState,
			},
		});
		history(`/home/intentions/${module.moduleId}`);
	};

	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.pageBase : classes.pageBaseMobile
				}
			>
				<Stack>
					<Typography variant="h1">Intentions</Typography>
					<Typography variant="body" paddingTop="10px">
						Intentions give your mind a focus and direction. Pick an intention to learn
						the tools and habits for shifting your mindset and building resilience.
					</Typography>
					<div style={{ paddingTop: "20px", display: "flex" }}>
						<Info color="primary"></Info>
						<Typography variant="body">
							We recommend only participating in one intention at a time.
						</Typography>
					</div>
					{modules?.map((module) => {
						return (
							<>
								<div className={classes.modulePreviewBox}>
									<Grid container direction="row" spacing="10px">
										{appWidth > modulesTheme.breakpoints.values.tablet && (
											<Grid item mobile={2}>
												<img
													className={classes.modulePreviewImage}
													src={
														module.thumbnailPath
															? module.thumbnailPath
															: ModulesSVG
													}
													alt="Module Image"
												></img>
											</Grid>
										)}

										<Grid item mobile={12} tablet={8}>
											<Typography variant="h3">{module.title}</Typography>
											<Typography variant="body1" paddingTop={"10px"}>
												{module.description}
											</Typography>
											<div style={{ paddingTop: ".5rem" }}>
												{module.moduleStatus.moduleState === "active" && (
													<QChip
														label={"Active"}
														backgroundColor={"#ffbf00"}
													/>
												)}
												{module.moduleStatus.moduleState === "complete" && (
													<QChip
														label={"Complete"}
														backgroundColor={
															modulesColors.neutrals._500
														}
													/>
												)}
											</div>
										</Grid>
										<Grid item mobile={12} tablet={2} alignSelf="center">
											<QButton
												onClick={() => handleLoadModule(module)}
												text={
													module.moduleStatus.moduleState === "active" ||
													module.moduleStatus.moduleState === "complete"
														? "Continue"
														: "Preview"
												}
												variant={
													module.moduleStatus.moduleState === "active"
														? "contained"
														: "outlined"
												}
											/>
										</Grid>
									</Grid>
								</div>
							</>
						);
					})}
				</Stack>
			</div>
		</ThemeProvider>
	);
};

export default ModulesDetailsPage;
