import { Reminder } from "../reminder/reminder.Model";

export interface ModulesPageData {
	/** Identifier for the source of the data. Encoded in the format: moduleSessionId:section:activity:page. Used as SK */
	source: string;
	/** UTC ISO string representing the date and time of data save*/
	timestamp: string;
	/** array of key:value pairs e.g. [{"question":"answer"}]*/
	data: Array<{ key: string; value: string }>;
}

export interface ModulesDatum extends ModulesPageData {
	/** User.SK also know as userId used as primary key */
	userId: string;
}

export class ModulesData {
	private data: ModulesPageData[];
	private remindersData: ModulesPageData[];

	public constructor() {
		this.data = [];
		this.remindersData = [];
	}

	public getPageRecord(source: string, recordKey: string) {
		const pageFound = this.data.find((datum) => datum.source === source);
		if (!pageFound) {
			return undefined;
		}
		const foundRecord = pageFound.data.find((record) => record.key === recordKey);
		if (!foundRecord) {
			return undefined;
		}
		return foundRecord.value;
	}

	public setPageRecord(source: string, recordKey: string, recordValue: string) {
		const pageFoundIndex = this.data.findIndex((page) => page.source === source);
		const timestamp = new Date().toISOString();
		if (pageFoundIndex >= 0) {
			//page found
			const recordFoundIndex = this.data[pageFoundIndex].data.findIndex(
				(record) => record.key === recordKey,
			);
			if (recordFoundIndex >= 0) {
				//record found, update record
				this.data[pageFoundIndex].data[recordFoundIndex].value = recordValue;
				this.data[pageFoundIndex].timestamp = timestamp;
			} else {
				//record not found, create new record
				this.data[pageFoundIndex].data.push({
					key: recordKey,
					value: recordValue,
				});
				this.data[pageFoundIndex].timestamp = timestamp;
			}
		} else {
			//page not found - create page and record
			this.data.push({
				source: source,
				timestamp: timestamp,
				data: [{ key: recordKey, value: recordValue }],
			});
		}
	}

	public setData(data: ModulesPageData[]): void {
		this.data = data;
	}

	public getData() {
		return this.data;
	}

	public getReminderRecord(source: string, recordKey: string) {
		const pageFound = this.remindersData.find((datum) => datum.source === source);
		if (!pageFound) {
			return undefined;
		}
		const foundRecord = pageFound.data.find((record) => record.key === recordKey);
		if (!foundRecord) {
			return undefined;
		}
		return foundRecord.value;
	}

	public setReminderRecord(source: string, recordKey: string, recordValue: string) {
		const pageFoundIndex = this.remindersData.findIndex((page) => page.source === source);
		const timestamp = new Date().toISOString();
		if (pageFoundIndex >= 0) {
			//page found
			const recordFoundIndex = this.remindersData[pageFoundIndex].data.findIndex(
				(record) => record.key === recordKey,
			);
			if (recordFoundIndex >= 0) {
				//record found, update record
				this.remindersData[pageFoundIndex].data[recordFoundIndex].value = recordValue;
				this.remindersData[pageFoundIndex].timestamp = timestamp;
			} else {
				//record not found, create new record
				this.remindersData[pageFoundIndex].data.push({
					key: recordKey,
					value: recordValue,
				});
				this.remindersData[pageFoundIndex].timestamp = timestamp;
			}
		} else {
			//page not found - create page and record
			this.remindersData.push({
				source: source,
				timestamp: timestamp,
				data: [{ key: recordKey, value: recordValue }],
			});
		}
	}

	public getRemindersData() {
		const modulesReminders = this.remindersData;

		const out = modulesReminders.reduce((output, moduleReminder) => {
			if (!moduleReminder.data) {
				return output;
			}
			const reminderList = moduleReminder.data.reduce((map: Reminder[], reminder) => {
				if (!reminder.value) {
					return map;
				}
				const reminderObject = JSON.parse(reminder.value);
				return map.concat(reminderObject);
			}, [] as Reminder[]);
			return output.concat(reminderList);
		}, [] as Reminder[]);
		return out;
	}
}
