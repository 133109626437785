import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import SubscriptionStatusIndicator from "./SubscriptionStatusIndicator";
import { MEMBER_STATUS, EDIT_RECORD_KEY, KILL_EDIT_MODE_ACTION } from "../../shared/constants";
import EditButtons from "./EditButtons";
import clsx from "clsx";
import { TextField, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
		width: "fit-content",
	},
	title: {
		width: "fit-content",
		display: "flex",
		alignItems: "baseline",
		gap: theme.spacing(1),
		marginBottom: theme.spacing(0.5),
		// "&:hover": {
		//   cursor: "pointer",
		// },
	},
	editHover: {
		cursor: "pointer",
	},
	statusIndicators: { display: "flex", flexFlow: "row wrap", gap: "4px" },
}));

export default function GroupName({
	groupName,
	pending,
	needsApproval,
	setGroupName,
	updateGroupEditRecord,
	killEditMode,
	canEditGroupsSection,
}) {
	const [showEditIcon, setShowEditIcon] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isModified, setIsModified] = useState(false);
	const [groupNameCopy, setGroupNameCopy] = useState(groupName);
	const [isLoading, setIsLoading] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (killEditMode) {
			killEditMode === KILL_EDIT_MODE_ACTION.SAVE ? void saveAction() : cancelAction();
		}
	}, [killEditMode]);

	useEffect(() => {
		updateGroupEditRecord({
			key: EDIT_RECORD_KEY.NAME,
			isModified: isModified,
		});
	}, [isModified, groupNameCopy]);

	useEffect(() => {
		setGroupNameCopy(groupName);
	}, [groupName]);

	function handleMouseEnter() {
		if (canEditGroupsSection) setShowEditIcon(true);
	}

	function handleMouseLeave() {
		if (canEditGroupsSection) setShowEditIcon(false);
	}

	function handleClick() {
		if (canEditGroupsSection) {
			if (!isEditMode) {
				setIsEditMode(!isEditMode);
			}
		}
	}

	async function saveAction() {
		setIsLoading(true);

		// Don't allow group names to be the empty string
		const finalGroupName = groupNameCopy ? groupNameCopy : groupName;
		await setGroupName(finalGroupName);

		setIsLoading(false);
		setIsModified(false);
		setIsEditMode(false);
	}

	function cancelAction() {
		setGroupNameCopy(groupName);
		setIsModified(false);
		setIsEditMode(false);
	}

	function changeHandler(e) {
		setGroupNameCopy(e.target.value);

		if (!isModified) {
			setIsModified(true);
		}
	}

	async function keyUpHandler(e) {
		// ENTER Key
		if (e.keyCode === 13) {
			await saveAction();
		}
	}

	return (
		<div className={classes.root}>
			<div
				className={clsx(showEditIcon && classes.editHover)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleClick}
			>
				<EditButtons
					saveAction={saveAction}
					cancelAction={cancelAction}
					showEditIcon={showEditIcon}
					setShowEditIcon={setShowEditIcon}
					isEditMode={isEditMode}
					setIsEditMode={setIsEditMode}
					isModified={isModified}
					isLoading={isLoading}
				>
					{isEditMode ? (
						<TextField
							autoFocus
							label="Group Name"
							value={groupNameCopy}
							onChange={changeHandler}
							onKeyUp={keyUpHandler}
							fullWidth={false}
						/>
					) : (
						<Typography variant="h2">{groupName}</Typography>
					)}
				</EditButtons>
			</div>
			<div className={classes.statusIndicators}>
				{
					// TODO Disabling this until we figure out what to do with it.
					/* <SubscriptionStatusIndicator
          status={MEMBER_STATUS.MEMBER}
          quantity={members}
          showEmpty={true}
        /> */
				}
				<SubscriptionStatusIndicator status={MEMBER_STATUS.PENDING} quantity={pending} />
				<SubscriptionStatusIndicator
					status={MEMBER_STATUS.NEEDS_APPROVAL}
					quantity={needsApproval}
				/>
			</div>
		</div>
	);
}
