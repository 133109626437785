import { EmotionStimulusData, EmotionWordGroup } from "@model/reflection/reflection.Model";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, useState } from "react";
import { colors } from "src/Theme";
import { modulesTheme } from "src/components/Modules/modulesTheme";

const useStyles = makeStyles(() => ({
	paper: {
		display: "flex",
		flexFlow: "column nowrap",
		borderRadius: 10,
		cursor: "pointer",
		scrollBehavior: "smooth",
		position: "relative",
		overflowY: "scroll",
		height: 180,
		"&::-webkit-scrollbar": {
			width: 12,
		},
		"&::-webkit-scrollbar-thumb": {
			border: "3px solid transparent",
			backgroundClip: "content-box",
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: "#ffffff45",
			borderTopLeftRadius: 0,
			borderTopRightRadius: 10,
			borderBottomRightRadius: 10,
			borderBottomLeftRadius: 0,
		},
		minWidth: 150,
		width: "100%",
	},
	paperInactive: {
		display: "flex",
		flexFlow: "column nowrap",
		borderRadius: 10,
		cursor: "pointer",
		scrollBehavior: "smooth",
		position: "relative",
		overflowY: "hidden",
		height: 180,
		minWidth: 150,
		width: "100%",
	},
	listItem: {
		height: 30,
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "rgba(0,0,0,.2) !important",
			color: "white",
		},
	},
	listItemText: { fontSize: 16 },
}));

interface ListItemProps {
	value: {
		word: string;
		bgOpacity: number;
	};
	active: boolean;
	onClick: () => void;
	link?: boolean;
}

const ListItem: FC<ListItemProps> = ({ value, active, onClick, link = false }) => {
	const classes = useStyles();
	return (
		<div
			id={value.word.toLowerCase()}
			key={value.word}
			onClick={onClick}
			style={
				link
					? {
							color: colors.teal,
							backgroundColor: `rgba(255,255,255,.4)`,
							textDecoration: "underline",
						}
					: active
						? { backgroundColor: `rgba(0,0,0,.3)`, color: "white" }
						: { backgroundColor: `rgba(255,255,255,.4)` }
			}
			className={classes.listItem}
		>
			<span className={classes.listItemText}>{value.word}</span>
		</div>
	);
};

export interface EmotionWordPickerProps {
	emotionStimList: EmotionStimulusData[];
	addEmotion: (word: string) => void;
	emotionWords: EmotionWordGroup[];
}

const EmotionWordPicker: FC<EmotionWordPickerProps> = (props) => {
	const { emotionStimList, addEmotion, emotionWords } = props;
	const [expandEmotions, setExpandEmotions] = useState<{ [key: number]: boolean }>({});
	const classes = useStyles();

	const isActiveEmotion = (emotion: string) => {
		if (emotionStimList.length === 0) {
			return false;
		}
		const found = emotionStimList.find(
			(emotionStim) => emotionStim.emotion.toLocaleLowerCase() === emotion.toLowerCase(),
		);
		if (found) {
			return true;
		}
		return false;
	};
	return (
		<Grid container spacing={modulesTheme.spacing(3)}>
			{emotionWords.map((emotionGroup, emotionGroupIndex) => (
				<Grid
					item
					mobile={6}
					tablet={4}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<Paper
						id={`${emotionGroup.list[0].word}_`}
						key={emotionGroup.list[0].word}
						style={{ backgroundColor: emotionGroup.baseColor }}
						elevation={1}
						className={
							!expandEmotions[emotionGroupIndex]
								? classes.paperInactive
								: classes.paper
						}
					>
						<List style={{ padding: 0 }}>
							{emotionGroup.list
								.slice(
									0,
									expandEmotions[emotionGroupIndex]
										? emotionGroup.list.length
										: 5,
								)
								.map((value) => (
									<ListItem
										value={value}
										key={value.word}
										onClick={() => addEmotion(value.word)}
										active={isActiveEmotion(value.word)}
									/>
								))}
							<ListItem
								value={{
									word: expandEmotions[emotionGroupIndex]
										? "show less"
										: "show more",
									bgOpacity: 1,
								}}
								onClick={() =>
									setExpandEmotions({
										...expandEmotions,
										[emotionGroupIndex]: !expandEmotions[emotionGroupIndex],
									})
								}
								link
								active={true}
							/>
						</List>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};

export default EmotionWordPicker;
