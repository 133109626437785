import { Grid, ListItem, ListItemText, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import QButton from "src/components/common/QButton";
import { ModulesData } from "src/model/modulesData/modulesData.Model";
import { formatDays, formatTime } from "../../reminder/reminderHelperFunctions";
import { modulesStyles } from "../modulesStyles";
import ModulesReminderEditDialog from "./ModulesReminderEditDialog";

export interface MilestoneReminderListItemProps {
	reminderId: string;
	pageId: string;
	modulesData: ModulesData;
}

const MilestoneReminderListItem: FC<MilestoneReminderListItemProps> = ({
	reminderId,
	modulesData,
	pageId,
}) => {
	const [reminder] = useState(
		JSON.parse(modulesData.getReminderRecord(pageId, reminderId) ?? ""),
	);
	const [open, setOpen] = useState(false);
	const [daysCopy, setDaysCopy] = useState(reminder.days);
	const [timeCopy, setTimeCopy] = useState(reminder.time);

	const handleSave = () => {
		reminder.time = timeCopy;
		reminder.days = daysCopy;
		modulesData.setReminderRecord(pageId, reminder.id, JSON.stringify(reminder));
	};
	const handleCancel = () => {
		setDaysCopy(reminder.days);
		setTimeCopy(reminder.time);
	};

	const generateByBdayString = (days: boolean[]) => {
		let byDayString = "";
		byDayString += days[0] ? "SU," : "";
		byDayString += days[1] ? "MO," : "";
		byDayString += days[2] ? "TU," : "";
		byDayString += days[3] ? "WE," : "";
		byDayString += days[4] ? "TH," : "";
		byDayString += days[5] ? "FR," : "";
		byDayString += days[6] ? "SA," : "";
		return byDayString;
	};

	const leadingZeros = (string: string, digitsNeeded: number) => {
		const addedZeros = "00" + string;
		return addedZeros.substring(addedZeros.length - digitsNeeded);
	};

	const generateIcsString = (
		days: boolean[],
		time: string,
		interval = 1,
		count = 1,
		durationHours = 0,
		durationMinutes = 15,
	) => {
		const NOW = new Date();
		const splitTime = time.split(":");
		const hours = (Number(splitTime[0]) + NOW.getTimezoneOffset() / 60).toString();
		const minutes = splitTime[1];

		let numberOfDays = days.reduce((count, day) => {
			if (day) {
				count = count + 1;
			}
			return count;
		}, 0);
		if (numberOfDays <= 0) {
			numberOfDays = 1;
		}
		// multiple count by number of days to generate the right number of events
		count = count * numberOfDays;

		const timestamp =
			NOW.getUTCFullYear().toString() +
			leadingZeros((NOW.getUTCMonth() + 1).toString(), 2) +
			leadingZeros(NOW.getUTCDate().toString(), 2) +
			`T` +
			hours +
			minutes +
			`00Z\n`;
		let icsString = `BEGIN:VCALENDAR\n`;
		icsString += `VERSION:2.0\n`;
		icsString += `PRODID:Quimby\n`;
		icsString += `BEGIN:VEVENT\n`;
		icsString += `DTSTAMP:` + timestamp;
		icsString += `DTSTART:` + timestamp;
		icsString +=
			`RRULE:FREQ=WEEKLY;BYDAY=` +
			generateByBdayString(days) +
			`;INTERVAL=` +
			interval +
			`;COUNT=` +
			count +
			`\n`;
		icsString += `DURATION:PT` + durationHours + `H` + durationMinutes + `M\n`;
		icsString += `SUMMARY:Quimby Focus Time | Module Practice\n`;
		icsString += `DESCRIPTION:Launch the Quimby desktop app to complete your weekly module activity.\n`;
		icsString += `STATUS:CONFIRMED\n`;
		icsString += `X-MICROSOFT-CDO-BUSYSTATUS:BUSY\n`;
		icsString += `END:VEVENT\n`;
		icsString += `END:VCALENDAR`;
		return icsString;
	};

	const addToCalendar = () => {
		const string = generateIcsString(
			reminder.days,
			reminder.time,
			reminder.calRecurrenceInterval,
			reminder.calRecurrenceCount,
			reminder.calRecurrenceHours,
			reminder.calRecurrenceMins,
		);
		const blob = new Blob([string], {
			type: "text;charset=utf-8;",
		});
		const link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute(
				"download",
				"Quimby Calender Event " +
					formatDays(reminder.days) +
					" " +
					formatTime(reminder.time) +
					".ics",
			);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const classes = modulesStyles();
	return (
		<>
			<ListItem className={classes.milestoneListItem}>
				<Grid container justifyContent="center" height="100%" alignItems="center">
					<Grid item mobile={6}>
						<ListItemText
							primary={
								<Typography variant="h2">{formatTime(reminder.time)}</Typography>
							}
							secondary={
								<Typography variant="body">{formatDays(reminder.days)}</Typography>
							}
						/>
					</Grid>
					<Grid item mobile={6}>
						<Grid container spacing="10px">
							<Grid item mobile={12} navBar={7}>
								{reminder.addToCalendar && (
									<QButton
										onClick={() => {
											addToCalendar();
										}}
										text="Add to calendar"
									/>
								)}
							</Grid>
							<Grid item mobile={12} navBar={5}>
								{reminder.editable && (
									<QButton
										onClick={() => {
											setOpen(!open);
										}}
										text="Edit time"
										variant="outlined"
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ListItem>
			<ModulesReminderEditDialog
				open={open}
				setOpen={setOpen}
				days={daysCopy}
				setDays={setDaysCopy}
				time={timeCopy}
				setTime={setTimeCopy}
				save={handleSave}
				cancel={handleCancel}
			/>
		</>
	);
};

export default MilestoneReminderListItem;
