import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, MouseEventHandler, ReactElement } from "react";
import { modulesColors } from "../Modules/modulesTheme";

interface QChipProps {
	onClick?: MouseEventHandler;
	onDelete?: MouseEventHandler;
	label: string;
	variant?: "outlined" | "contained";
	clickable?: boolean;
	icon?: ReactElement;
	size?: string;
	color?: "primary" | "secondary";
	backgroundColor?: string;
}

const chipStyles = makeStyles(() => ({
	chip: {
		borderRadius: "8px",
		textTransform: "none",
		fontSize: 15,
		fontWeight: "normal",
		boxShadow: "none",
		fontFamily: "Nunito",
	},
	readOnly: {
		backgroundColor: modulesColors.primary.mid,
		color: modulesColors.neutrals._800,
	},
	contained: {
		border: `1px solid ${modulesColors.primary.main}`,
		backgroundColor: modulesColors.primary.mid,
		"&:focus": {
			backgroundColor: modulesColors.primary.main,
			color: modulesColors.neutrals._100,
			boxShadow: "none",
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.main,
			color: modulesColors.neutrals._100,
		},
	},
	outlined: {
		color: modulesColors.primary.dark,
		border: `1px solid ${modulesColors.primary.main}`,
		background: "none",
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.main,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._100,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._800,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
	},
	secondaryReadOnly: {
		backgroundColor: modulesColors.secondary.mid,
		color: modulesColors.neutrals._800,
	},
	secondaryContained: {
		border: `1px solid ${modulesColors.secondary.main}`,
		backgroundColor: modulesColors.secondary.mid,
		"&:focus": {
			backgroundColor: modulesColors.secondary.main,
			color: modulesColors.neutrals._800,
			boxShadow: "none",
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.secondary.main,
			color: modulesColors.neutrals._800,
		},
	},
	secondaryOutlined: {
		color: modulesColors.secondary.dark,
		border: `1px solid ${modulesColors.secondary.main}`,
		background: "none",
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.secondary.main,
			borderColor: modulesColors.secondary.main,
			color: modulesColors.neutrals._100,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.secondary.mid,
			borderColor: modulesColors.secondary.main,
			color: modulesColors.neutrals._800,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.secondary.mid,
			borderColor: modulesColors.secondary.main,
			color: modulesColors.neutrals._800,
		},
	},
}));

const QChip: FC<QChipProps> = ({
	onClick = () => {},
	onDelete = undefined,
	variant = "contained",
	label,
	icon,
	clickable = false,
	size = "medium",
	color = "primary",
	backgroundColor = "none",
}) => {
	const classes = chipStyles();

	return (
		<Chip
			label={label}
			className={`${classes.chip} ${
				color === "primary"
					? !clickable
						? classes.readOnly
						: variant === "contained"
							? classes.contained
							: classes.outlined
					: !clickable
						? classes.secondaryReadOnly
						: variant === "contained"
							? classes.secondaryContained
							: classes.secondaryOutlined
			}
		`}
			style={{ backgroundColor: backgroundColor }}
			onClick={onClick}
			onDelete={clickable ? onDelete : undefined}
			clickable={clickable}
			deleteIcon={clickable ? icon : undefined}
			sx={{ size: size }}
		/>
	);
};

export default QChip;
