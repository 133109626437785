import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import ModulesNav from "../Shared/ModulesNav";

interface AffirmationSlideProps {
	page: ModulesLegacyPage;
}

const AffirmationSlide: FC<AffirmationSlideProps> = ({ page }) => {
	const { affirmation } = useReflectionModelContext();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<Typography variant="h5" style={{ textAlign: "center" }}>
						Today’s affirmation
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1" style={{ textAlign: "center", paddingBottom: 50 }}>
						{affirmation.prompt}
					</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography
						style={{
							textAlign: "center",
							fontSize: 30,
							padding: 10,
						}}
					>
						&quot;{affirmation.text}&quot;
					</Typography>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default AffirmationSlide;
