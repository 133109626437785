import {
	Avatar,
	Stack,
	Step,
	StepConnector,
	StepIconProps,
	StepLabel,
	Stepper,
	Typography,
	stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { modulesColors } from "../Modules/modulesTheme";
import { NavBarState } from "../home/NavBar";
import ProgressBar from "./ProgressBar";

interface QStepperProps {
	steps: string[];
	activeStep: number;
}

const QConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: modulesColors.primary.mid,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: modulesColors.primary.mid,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: modulesColors.neutrals._300,
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const StepIcon = (props: StepIconProps) => {
	const { active, completed, icon } = props;
	const diameter = active ? 31 : 24;
	return (
		<Avatar
			sx={{
				width: diameter,
				height: diameter,
				backgroundColor: active
					? modulesColors.primary.main
					: completed
						? modulesColors.primary.mid
						: modulesColors.neutrals._300,
				outline: active ? `${modulesColors.primary.main} solid 1px` : "",
				border: active ? `2px solid ${modulesColors.neutrals._100}` : "",
			}}
		>
			<Typography variant="h5">{icon}</Typography>
		</Avatar>
	);
};

const useStyles = makeStyles({
	iconContainerInactive: {
		paddingRight: 0,
	},
	stepInactive: {
		paddingRight: "0px",
		paddingLeft: "0px",
	},
	stepActive: {
		paddingLeft: "0px",
	},
});

const QStepper: FC<QStepperProps> = ({ steps, activeStep }) => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = useStyles();
	return (
		<>
			{steps &&
				steps.length > 0 &&
				(navBarState === NavBarState.FULL ? (
					<Stack>
						<Stack direction="row" spacing="16px" alignItems="center">
							<StepIcon active icon={activeStep + 1} />
							<Stack>
								<Typography variant="body3">
									Step {activeStep + 1}/{steps.length}
								</Typography>
								<Typography variant="h5">{steps[activeStep]}</Typography>
							</Stack>
						</Stack>
						<ProgressBar step={activeStep} totalSteps={steps.length} />
					</Stack>
				) : (
					<Stepper activeStep={activeStep} connector={<QConnector />}>
						{steps.map((step, index) => {
							const active = index === activeStep;
							return (
								<Step
									key={step}
									className={active ? classes.stepActive : classes.stepInactive}
								>
									<StepLabel
										classes={
											active
												? {}
												: {
														iconContainer:
															classes.iconContainerInactive,
													}
										}
										StepIconComponent={StepIcon}
									>
										{active ? (
											<Typography variant="h6">{step}</Typography>
										) : (
											<></>
										)}
									</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				))}
		</>
	);
};

export default QStepper;
