import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GroupsTreeControl from "./GroupsTreeControl";
import { makeStyles } from "@mui/styles";

const DEFAULT_GROUPNAME = "New Group";

const useStyles = makeStyles(() => ({
	button: {
		textTransform: "none",
	},
}));

export default function CreateGroupDialog({
	open,
	handleCancel,
	handleCreate,
	groupsTree,
	selectedGroupId,
}) {
	const classes = useStyles();
	const [localSelectedGroupId, setLocalSelectedGroupId] = useState(selectedGroupId);
	const [expanded, setExpanded] = useState(["G:0"]);
	const [groupName, setGroupName] = useState(DEFAULT_GROUPNAME);

	function handleGroupNameChange(e) {
		setGroupName(e.target.value);
	}

	function exitHandler() {
		setGroupName(DEFAULT_GROUPNAME);
	}

	function enteringHandler() {
		// console.log("Entering!");
		setExpanded(["G:0"]);
		setLocalSelectedGroupId(selectedGroupId);
	}

	return (
		<Dialog
			fullWidth={true}
			open={open}
			aria-labelledby="form-dialog-title"
			TransitionProps={{
				onEntering: enteringHandler,
				onExit: exitHandler,
			}}
		>
			<DialogTitle id="form-dialog-title">Create a new group</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					label="Group Name"
					value={groupName}
					onChange={handleGroupNameChange}
					fullWidth
				/>
				<DialogContentText style={{ marginTop: "24px" }}>
					Select a parent group:
				</DialogContentText>
				<GroupsTreeControl
					groupsTree={groupsTree}
					selectedGroupId={localSelectedGroupId}
					setSelectedGroupId={setLocalSelectedGroupId}
					expanded={expanded}
					disableToggle={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button className={classes.button} onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button
					className={classes.button}
					variant="contained"
					onClick={() => {
						handleCreate(groupName, localSelectedGroupId);
					}}
					color="primary"
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
