import { EmotionStimulusData, StimuliData } from "@model/reflection/reflection.Model";
import {
	Autocomplete,
	Grid,
	Stack,
	TextField,
	capitalize,
	createFilterOptions,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { FC } from "react";
import { modulesColors, modulesTheme } from "src/components/Modules/modulesTheme";
import QChip from "src/components/common/QChip";
import QModal from "src/components/common/QModal";

const useStyles = makeStyles(() => ({
	stimuliContainer: {
		flexDirection: "row",
		display: "flex",
		flexWrap: "wrap",
		alignContent: "flex-start",
		marginTop: 5,
		border: "1px solid",
		borderColor: modulesColors.neutrals._500,
		borderRadius: "6px",
	},
	inputField: {
		textTransform: "capitalize",
		borderColor: modulesColors.neutrals._500,
		borderStyle: "solid",
		borderRadius: "6px",
		borderWidth: "1px",
		backgroundColor: "transparent",
		paddingLeft: 5,
		color: modulesColors.primary.main,
		width: "100%",
		minHeight: 40,
		marginTop: 2,
		"& .MuiAutocomplete-inputRoot": {
			paddingTop: 2,
		},
	},
}));
const filter = createFilterOptions();

interface StimulusPickerProps {
	emotionStimList: EmotionStimulusData[];
	setEmotionStimList: React.Dispatch<React.SetStateAction<EmotionStimulusData[]>>;
	selectedEmotion: number;
	stimuli: StimuliData[];
}

const StimulusPicker: FC<StimulusPickerProps> = ({
	emotionStimList,
	setEmotionStimList,
	selectedEmotion,
	stimuli,
}) => {
	const classes = useStyles();
	const [stimulusList, setStimulusList] = React.useState(
		emotionStimList[selectedEmotion].stimuli ?? [],
	);
	const [inputValue, setInputValue] = React.useState("");
	const [sharePopUp, setSharePopUp] = React.useState(false);
	const [shareStimuli, setShareStimuli] = React.useState(
		emotionStimList[selectedEmotion].shareStimuli ?? false,
	);

	if (!stimuli) {
		return <div>LOADING...</div>;
	}
	// const onShareStimuli = (share: boolean) => {
	// 	setShareStimuli(share);
	// 	emotionStimList[selectedEmotion].shareStimuli = share;
	// 	setEmotionStimList(emotionStimList);
	// };

	React.useEffect(() => {
		emotionStimList[selectedEmotion].stimuli = stimulusList;
		setEmotionStimList(emotionStimList);
	}, [stimulusList]);

	React.useEffect(() => {
		setStimulusList(emotionStimList[selectedEmotion].stimuli);
		setShareStimuli(emotionStimList[selectedEmotion].shareStimuli);
		setSharePopUp(false);
	}, [selectedEmotion]);

	const removeStimulus = (stimulus: string) => {
		const newList = stimulusList.filter((stimuli) => stimulus !== stimuli.title);
		setStimulusList(newList);
	};

	return (
		<Grid container height="100%">
			<Grid item mobile={12}>
				<Typography variant="h5">
					What might you attribute {'"' + emotionStimList[selectedEmotion].emotion + '" '}
					to?
				</Typography>
				<Typography paragraph variant="body1">
					Identifying the stimulus and paying attention to your thoughts can help you
					uncover meaningful patterns over time.
				</Typography>
			</Grid>
			<Grid item mobile={12}>
				<Typography variant="h6">Stimulus</Typography>
				<Autocomplete
					multiple
					multiline="true"
					value={stimulusList}
					onChange={(_event, newValue, reason) => {
						if (reason === "createOption") {
							if (
								!stimulusList.find(
									(x) =>
										x.inputValue ===
										//@ts-expect-error TODO
										newValue[newValue.length - 1].toLocaleLowerCase(),
								)
							) {
								if (
									!stimuli.find(
										(x) =>
											x.inputValue ===
											newValue[
												newValue.length - 1
												//@ts-expect-error TODO
											].toLocaleLowerCase(),
									)
								) {
									setStimulusList(
										stimulusList.concat({
											inputValue:
												newValue[
													newValue.length - 1
													//@ts-expect-error TODO
												].toLocaleLowerCase(),
											title: newValue[
												newValue.length - 1
												//@ts-expect-error TODO
											].toLocaleLowerCase(),
											new: true,
										}),
									);
									setSharePopUp(true);
								} else {
									setStimulusList(
										stimulusList.concat({
											inputValue:
												newValue[
													newValue.length - 1
													//@ts-expect-error TODO
												].toLocaleLowerCase(),
											title: newValue[
												newValue.length - 1
												//@ts-expect-error TODO
											].toLocaleLowerCase(),
											new: false,
										}),
									);
								}
							}
						}

						if (reason === "selectOption") {
							if (
								!stimulusList.find(
									(x) =>
										x.inputValue ===
										newValue[
											newValue.length - 1
											//@ts-expect-error TODO
										].inputValue.toLocaleLowerCase(),
								)
							) {
								//@ts-expect-error TODO
								if (newValue[newValue.length - 1].new) {
									setSharePopUp(true);
								}
								setStimulusList(
									stimulusList.concat({
										inputValue:
											newValue[
												newValue.length - 1
												//@ts-expect-error TODO
											].inputValue.toLocaleLowerCase(),
										title: newValue[
											newValue.length - 1
											//@ts-expect-error TODO
										].title.toLocaleLowerCase(),
										//@ts-expect-error TODO
										new: newValue[newValue.length - 1].new || false,
									}),
								);
							}
						}
						if (reason === "removeOption") {
							//@ts-expect-error TODO
							setStimulusList(newValue);
							setSharePopUp(false);
						}
						if (reason === "clear") {
							setStimulusList([]);
							setSharePopUp(false);
						}
					}}
					inputValue={inputValue}
					onInputChange={(_event, newInputValue) => {
						//handles comma as delimiter
						const lista = newInputValue.split(",");
						if (lista.length > 1) {
							if (
								!stimulusList.find(
									(x) => x.inputValue === lista[0].toLocaleLowerCase(),
								)
							) {
								const newValue = {
									inputValue: lista
										.map((x) => x.trim())
										.filter((x) => x)
										.toString()
										.toLocaleLowerCase(),
									title: lista
										.map((x) => x.trim())
										.filter((x) => x)
										.toString()
										.toLocaleLowerCase(),
									new: true,
								};
								//set
								setStimulusList(stimulusList.concat(newValue));
								setSharePopUp(true);
							}
							setInputValue("");
						} else {
							setInputValue(newInputValue.toLocaleLowerCase());
						}
					}}
					id="stimulus"
					sx={{ width: "100%" }}
					options={stimuli}
					freeSolo
					getOptionLabel={(option) => {
						const word = typeof option === "string" ? option : option.inputValue;
						return word;
					}}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
								}}
								inputProps={{
									...params.inputProps,
									style: { textTransform: "capitalize", paddingLeft: 10 },
								}}
								className={classes.inputField}
								variant="standard"
								hiddenLabel
								placeholder="Press Enter To Separate Stimuli."
								margin="normal"
							/>
						);
					}}
					//@ts-expect-error TODO
					filterOptions={(options, params) => {
						//@ts-expect-error TODO
						const filtered = filter(options, params);
						if (
							params.inputValue !== "" &&
							!stimuli.find(
								({ inputValue }) =>
									inputValue.toLocaleLowerCase() ===
									params.inputValue.toLocaleLowerCase(),
							)
						) {
							filtered.push({
								inputValue: params.inputValue,
								title: `Add stimulus "${params.inputValue}"`,
								new: true,
							});
						}
						return filtered;
					}}
					renderOption={(props, option, { inputValue }) => {
						const word = option.title; //{inputValue: 'asd', title: 'Add "asd"'}
						const matches = match(word, inputValue);
						const parts = parse(word, matches);
						return (
							<li {...props}>
								<div>
									{parts.map((part, index) => (
										<span
											key={index}
											style={{
												fontWeight: part.highlight ? 700 : 400,
												textTransform: "capitalize",
											}}
										>
											{part.text}
										</span>
									))}
								</div>
							</li>
						);
					}}
					renderTags={(value) =>
						value.map((option, index) => (
							<div
								style={{
									padding: "4px",
								}}
							>
								<QChip
									key={index}
									label={capitalize(option.title)}
									clickable
									onDelete={() => {
										removeStimulus(option.title);
									}}
									variant="outlined"
								/>
							</div>
						))
					}
				/>

				<QModal open={sharePopUp} setOpen={setSharePopUp} saveButton disableCancelClick>
					<Stack
						maxWidth="500px"
						spacing={modulesTheme.spacing(2)}
						alignItems="center"
						justifyContent="center"
					>
						<Typography variant="h6">
							Do you want to share your custom stimulus?
						</Typography>
						<Typography variant="info1">
							We notice your stimulus is not on our list. If your stimulus is not
							personally identifying, we recommend you add it to the shared stimuli
							list so it is visible in the aggregate team insights and more easily
							accessible to you in the future. Note, your reflections are always
							private and your selection is never visible to anyone. Only aggregates
							and trends across the team are shared.
						</Typography>
						<Stack
							direction="row"
							alignItems="center"
							spacing={modulesTheme.spacing(1)}
						>
							<Typography variant="h6" textAlign="right">
								Keep private
							</Typography>
							<Switch
								onChange={(e) => setShareStimuli(e.target.checked)}
								checked={shareStimuli}
							/>
							<Typography variant="h6">Add to shared stimulus</Typography>
						</Stack>
					</Stack>
				</QModal>
			</Grid>
			<Grid item mobile={12} height="100%">
				<div className={classes.stimuliContainer}>
					{!!stimuli &&
						stimuli.map((sti) => (
							<div
								style={{
									padding: "4px",
								}}
							>
								<QChip
									label={capitalize(sti.title)}
									key={sti.title}
									onClick={() => {
										if (
											!stimulusList.find(
												(x) =>
													x.inputValue === sti.title.toLocaleLowerCase(),
											)
										) {
											setStimulusList(
												stimulusList.concat({
													inputValue: sti.title.toLocaleLowerCase(),
													title: sti.title.toLocaleLowerCase(),
													new: false,
												}),
											);
										} else {
											removeStimulus(sti.title);
										}
									}}
									clickable
									variant={
										stimulusList.length > 0 &&
										stimulusList.find(
											(x) =>
												x.inputValue.toLocaleLowerCase() ===
												sti.title.toLocaleLowerCase(),
										)
											? "contained"
											: "outlined"
									}
								/>
							</div>
						))}
				</div>
			</Grid>
		</Grid>
	);
};

export default StimulusPicker;
