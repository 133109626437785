import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ModulesMilestoneProgressItem } from "src/model/modules/modules.Model";
import { generateReflectionPathName } from "../../../model/reflection/reflection.Manager";
import { modulesStyles } from "../modulesStyles";
//import { colors } from "../../../Theme";

export interface ModulesStaticListProgressProps {
	progress: ModulesMilestoneProgressItem[];
	moduleId: string;
}

const ModulesStaticListProgress: FC<ModulesStaticListProgressProps> = ({ progress, moduleId }) => {
	const classes = modulesStyles();
	const history = useNavigate();
	const launchModuleCheckIn = (isNext: boolean, activityPage: string) => {
		history(
			generateReflectionPathName({ modules: true, activity: activityPage }, moduleId, isNext),
		);
	};

	return (
		<Stack direction="row" className={classes.mileStoneListItemFlex}>
			{progress.map((item) => {
				return (
					<div key={item.text} className={classes.milestoneListItemProgress}>
						{typeof item.iterations === "number" ? (
							Array.from({ length: item.iterations }).map((_, index) => (
								<div
									key={`${item.text}-iteration-${index}`}
									style={{ display: "flex" }}
								>
									{item.isLocked ? (
										<RadioButtonUnchecked
											className={classes.milestoneListItemProgressLocked}
										/>
									) : (
										<CheckCircle
											className={classes.milestoneListItemProgressUnlocked}
										/>
									)}
									{item.isLocked && !item.isNext ? (
										<Tooltip title="Unlocks when previous steps are completed">
											<Typography
												variant="body3"
												key={item.text}
												marginRight="10px"
											>
												{item.text}
											</Typography>
										</Tooltip>
									) : (
										<Typography
											variant="body3"
											key={item.text}
											marginRight="10px"
											style={{
												textDecoration: "underline",
												color: "#61b5aa",
												cursor: "pointer",
											}}
											onClick={() => {
												launchModuleCheckIn(
													item.isNext ? true : false,
													item.link ?? "",
												);
											}}
										>
											{item.text}
										</Typography>
									)}
								</div>
							))
						) : item.isLocked ? (
							<RadioButtonUnchecked
								className={classes.milestoneListItemProgressLocked}
							/>
						) : (
							<CheckCircle className={classes.milestoneListItemProgressUnlocked} />
						)}
						{typeof item.iterations === "number" ? null : item.isLocked &&
						  !item.isNext ? (
							<Tooltip title="Unlocks when previous steps are completed">
								<Typography variant="body3" key={item.text} marginRight="10px">
									{item.text}
								</Typography>
							</Tooltip>
						) : (
							<Typography
								variant="body3"
								key={item.text}
								marginRight="10px"
								style={{
									textDecoration: "underline",
									color: "#61b5aa",
									cursor: "pointer",
								}}
								onClick={() => {
									launchModuleCheckIn(
										item.isNext ? true : false,
										item.link ?? "",
									);
								}}
							>
								{item.text}
							</Typography>
						)}
					</div>
				);
			})}
		</Stack>
	);
};
export default ModulesStaticListProgress;
