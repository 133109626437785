import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAppContext } from "../../libs/contextLib";
import Theme from "../../Theme";
import { refreshSubscriptionInfo } from "../../utility/App.util";
import { logger } from "../../libs/logLib";
const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.white,
		marginBottom: theme.spacing(1),
	},
	formControl: {
		background: theme.palette.white,
		height: "40px",
		maxWidth: 184,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

export default function SubscriptionPicker(show) {
	const {
		appState: { subscriptions, selectedSubscriptionId },
		appDispatch,
	} = useAppContext();
	const classes = useStyles();

	const handleChange = async (event) => {
		appDispatch({
			type: "setSelectedSubscriptionId",
			selectedSubscriptionId: event.target.value,
		});
		await refreshSubscriptionInfo(event.target.value, appDispatch);
	};

	return show ? (
		<div className={classes.root}>
			<Select
				className={classes.formControl}
				disableUnderline
				variant="standard"
				style={{ color: Theme.palette.primary.main, paddingTop: "10px" }}
				value={selectedSubscriptionId}
				onChange={handleChange}
				onClick={() => logger.telemetry({ action: "click", event: "subscription_picker" })}
			>
				{subscriptions.map((subscription, index) => (
					<MenuItem key={index} value={subscription.subscriptionId}>
						{subscription.subscriptionId === "S:NO_SUBSCRIPTION"
							? "Individual"
							: subscription.company}
					</MenuItem>
				))}
			</Select>
		</div>
	) : null;
}
