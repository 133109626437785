import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { NavBarState } from "../home/NavBar";
import QButton, { QButtonProps } from "./QButton";

export interface ButtonStackProps {
	buttons: QButtonProps[];
	justifyContent?: string;
}

const ButtonStack: FC<ButtonStackProps> = ({ buttons, justifyContent = "center" }) => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();

	return (
		<Stack
			direction={navBarState === NavBarState.FULL ? "column" : "row"}
			spacing="10px"
			justifyContent={justifyContent}
		>
			{buttons.map((button, index) => {
				return (
					<QButton
						key={index}
						variant={button.variant}
						onClick={button.onClick}
						text={button.text}
						width={navBarState === NavBarState.FULL ? "100%" : undefined}
					/>
				);
			})}
		</Stack>
	);
};

export default ButtonStack;
