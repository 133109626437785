import { Close } from "@mui/icons-material";
import { Box, Button, Modal, Snackbar, ThemeProvider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { colors } from "../Theme";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import {
	configureReflectionSlides,
	createInterventionDeck,
} from "../components/ReflectionSlides/reflectionSlideConfig";
import LoadingIcon from "../components/common/LoadingIcon";
import QModal from "../components/common/QModal";
import { NavBarState } from "../components/home/NavBar";
import {
	apiGetDailyAffirmation,
	apiGetReflectionStats,
	apiGetStimuliList,
	apiPostReflection,
	apiPostStimulus,
	apiUpdateReminders,
	apiUpdateSetupInfo,
} from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { ReflectionModalContext, useTypedAppContext } from "../libs/typedContextLib";
import {
	isModulesInputRadioPage,
	isModulesInputSurveyPage,
	isModulesInputTextPage,
} from "../model/modules/modules.Model";
import {
	getModuleSlides,
	getModulesPageData,
	saveModulesData,
} from "../model/modules/modules.View";
import { ModulesData } from "../model/modulesData/modulesData.Model";
import { configModuleReminders, getAuthenticatedUserInfo } from "../utility/App.util";

const ReflectionModal = ({ showReflectionModal, hideReflectionModal, reflectionModules }) => {
	const {
		appDispatch,
		appState: { authenticatedUserInfo, reminders },
	} = useAppContext();
	const {
		UIContext: { appHeight, appWidth, navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();
	const [feeling, setFeeling] = useState(0);
	const [emotionStimList, setEmotionStimListCore] = useState([]);
	const [emotionStimListJSON, setEmotionStimListJSON] = useState([]);
	const [thoughts, setThoughts] = useState("");
	const [step, setStep] = useState(0);
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [stimuli, setStimuli] = useState([]); //list of stimuli
	const [reflectionsCount, setReflectionsCount] = useState(0); //how many reflections the user submitted
	const [value, setValue] = useState("");
	const [weight, setWeight] = useState();
	const [prompt, setPrompt] = useState("");
	const [gratitude, setGratitude] = useState("");
	const [selectedInterventionList, setSelectedInterventionList] = useState([]);
	const [selectedInterventionIndex, setSelectedInterventionIndex] = useState(-1);
	const [recommendationSuggestion, setRecommendationSuggestion] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [intervention, setIntervention] = useState("");
	const [slidesLoading, setSlidesLoading] = useState(true);
	const [userName, setUserName] = useState();
	const [slideList, setSlideList] = useState([]);
	const [modulesSlides, setModulesSlides] = useState([]);
	const [reflectionSlides, setReflectionSlides] = useState([]);
	const [interventionSlideList, setInterventionSlideList] = useState([]);
	const [setupInfo, setSetupInfo] = useState({});
	const [affirmation, setAffirmation] = useState({
		prompt: "Read mindfully and engage.",
		text: "I embrace my unique qualities and talents.",
	});
	const [reflectionError, setError] = React.useState();
	const [modulesData, setModulesData] = useState(new ModulesData());
	const [saving, setSaving] = useState(false);

	const startTime = Date.now();
	const reflectionSessionId = uuidv4();

	const setEmotionStimList = (ESList) => {
		setEmotionStimListCore(ESList);
		setEmotionStimListJSON(JSON.stringify(ESList));
	};

	const saveOnClick = () => apiUpdateReflection(true, true, false);

	const apiUpdateReflection = useCallback(
		async (isCompleted, closeReflection) => {
			setSaving(true);
			let dateTime = startTime.toString();
			let multiEntry = false;
			let multiEntryIndex = 0;
			// if modules then save independently if completed
			if (reflectionModules?.modules || reflectionModules?.toolkit) {
				if (isCompleted) {
					const increment =
						reflectionModules?.incrementReflection === undefined
							? true
							: reflectionModules.incrementReflection;
					const activity = reflectionModules?.activity
						? reflectionModules.activity
						: undefined;
					await saveModulesData(modulesData, activity, increment);
					const modulesReminders = modulesData.getRemindersData();
					if (modulesReminders.length) {
						await overwriteModuleReminders(modulesReminders);
					}
					if (slideList.filter((slide) => slide.isLegacy).length > 1) {
						reflectionModules.moraleCheckIn = true;
					}
				}
				try {
					const modulesBody = {
						timestamp: dateTime++,
						totalTime: (Date.now() - startTime) / 1000,
						completed: isCompleted,
						multiEntry: multiEntry,
						multiEntryIndex: multiEntryIndex++,
						reflectionModules: { toolkit: true, modules: true },
						reflectionSessionId: reflectionSessionId,
					};
					const response = await apiPostReflection(modulesBody);
					if ("status" in response && response.status === "ERROR") {
						setError(response.error);
					}
				} catch (e) {
					logger.error(e, "apiPostReflection");
					setError("An error has occurred while submitting your reflection.");
				}
			}
			// if gratitude, save it as separate
			if (
				reflectionModules.gratitude ||
				(setupInfo.modulesSetup && setupInfo.modulesSetup.gratitude)
			) {
				multiEntry = reflectionModules.moraleCheckIn || reflectionModules.affirmations;
				try {
					const gratitudeBody = {
						timestamp: dateTime++,
						thoughts: gratitude,
						totalTime: (Date.now() - startTime) / 1000,
						completed: isCompleted,
						prompt: "Gratitude Check-In",
						multiEntry: multiEntry,
						multiEntryIndex: multiEntryIndex++,
						reflectionModules: { gratitude: true },
						reflectionSessionId: reflectionSessionId,
					};
					const response = await apiPostReflection(gratitudeBody);
					if ("status" in response && response.status === "ERROR") {
						setError(response.error);
					}
				} catch (e) {
					logger.error(e);
					setError("An error has occurred while submitting your reflection.");
				}
			}
			if (
				reflectionModules.affirmations ||
				(setupInfo.modulesSetup && setupInfo.modulesSetup.affirmations)
			) {
				multiEntry = reflectionModules.moraleCheckIn || reflectionModules.gratitude;
				try {
					const affirmationBody = {
						timestamp: dateTime++,
						totalTime: (Date.now() - startTime) / 1000,
						completed: isCompleted,
						multiEntry: multiEntry,
						multiEntryIndex: multiEntryIndex++,
						reflectionModules: { affirmations: true },
						reflectionSessionId: reflectionSessionId,
					};
					const response = await apiPostReflection(affirmationBody);
					if ("status" in response && response.status === "ERROR") {
						setError(response.error);
					}
				} catch (e) {
					logger.error(e, "apiPostReflection");
					setError("An error has occurred while submitting your reflection.");
				}
			}

			if (reflectionModules?.moraleCheckIn) {
				if (emotionStimList.length === 0) {
					try {
						const body1 = {
							timestamp: dateTime++,
							moodIndex: feeling / 100,
							emotionWord: "",
							thoughts: thoughts,
							slideIndex: step,
							totalTime: (Date.now() - startTime) / 1000,
							completed: isCompleted,
							stimulus: [],
							value: value,
							valueWeight: weight,
							interventionList: selectedInterventionList,
							prompt: prompt,
							multiEmotion: false,
							multiEmotionIndex: 0,
							recommendedIntervention: recommendationSuggestion,
							multiEntry: multiEntry,
							multiEntryIndex: multiEntryIndex++,
							reflectionModules: reflectionModules,
							reflectionSessionId: reflectionSessionId,
						};
						const response = await apiPostReflection(body1);

						if ("status" in response && response.status === "ERROR") {
							setError(response.error);
						} else if (closeReflection) {
							hideReflectionModal();
							resetState();
						}
					} catch (e) {
						logger.error(e, "apiPostReflection");
						setError("An error has occurred while submitting your reflection.");
					}
				} else {
					multiEntry = true;
					for (let [index, emotion] of emotionStimList.entries()) {
						for (let stimulus of emotion.stimuli) {
							if (
								stimulus.new &&
								emotion.shareStimuli &&
								isCompleted &&
								closeReflection
							) {
								try {
									await apiPostStimulus(
										stimulus.inputValue,
										emotion.shareStimulus,
									);
								} catch (e) {
									// eslint-disable-next-line no-undef
									window.api.error("Stimulus Post Failed", e);
								}
							}
						}
						try {
							const body = {
								timestamp: dateTime++,
								moodIndex: feeling / 100,
								emotionWord: emotion.emotion,
								thoughts: thoughts,
								slideIndex: step,
								totalTime: (Date.now() - startTime) / 1000,
								completed: isCompleted,
								stimulus: emotion.stimuli.map((x) => x.inputValue),
								value: value,
								valueWeight: weight,
								interventionList: selectedInterventionList,
								prompt: prompt,
								multiEmotion: emotionStimList.length > 1 ? true : false,
								multiEmotionIndex: index,
								interventionRecommendation: recommendationSuggestion,
								multiEntry: multiEntry,
								multiEntryIndex: multiEntryIndex++,
								reflectionModules: reflectionModules,
								reflectionSessionId: reflectionSessionId,
							};
							const response = await apiPostReflection(body);

							if ("status" in response && response.status === "ERROR") {
								setError(response.error);
							}
						} catch (e) {
							logger.error(e, "apiPostReflection");
							setError("An error has occurred while submitting your reflection.");
						}
					}
					hideReflectionModal();
					resetState();
				}
			} else {
				hideReflectionModal();
				resetState();
			}
			setSaving(false);
		},
		[
			startTime,
			feeling,
			thoughts,
			step,
			emotionStimList,
			prompt,
			value,
			weight,
			recommendationSuggestion,
			selectedInterventionList,
			gratitude,
		],
	);
	const resetState = () => {
		setSlidesLoading(true);
		setStep(0);
		setSlideList([]);
		setFeeling(0);
		setEmotionStimList([]);
		setThoughts("");
		setValue("");
		setWeight();
		setPrompt("");
		setGratitude("");
		setSelectedInterventionList([]);
		setSelectedInterventionIndex(-1);
		setRecommendationSuggestion("");
		setIntervention("");
		setModulesData(new ModulesData());
	};

	useEffect(() => {
		resetState();
		void onload();
	}, [reflectionModules]);

	const onload = async () => {
		//Load stimulus
		const stimuliList = await apiGetStimuliList();
		const filteredList = stimuliList
			.filter((stimulus) => stimulus.SK)
			.map((stimulus) => ({
				inputValue: stimulus.SK.replace("W:", ""),
				title: stimulus.SK.replace("W:", ""),
			}));
		setStimuli(filteredList);

		//load reflection stats
		const stats = await apiGetReflectionStats();
		const refCount = (stats.reflections ?? 0) + 1;
		setReflectionsCount(refCount);

		//load user info
		const tempSetupInfo = authenticatedUserInfo.setupInfo ?? {};
		setSetupInfo(tempSetupInfo);
		setUserName(authenticatedUserInfo.firstName);
		if (reflectionModules?.modules || reflectionModules?.toolkit) {
			// if modules or toolkit, load modules or toolkit
			const modulesSlides = await getModuleSlides(
				reflectionModules?.activity,
				reflectionModules?.toolkit,
			);
			setModulesSlides(modulesSlides);

			const arrId = modulesSlides
				.filter(
					(slide) =>
						isModulesInputTextPage(slide.slide().props.page) ||
						isModulesInputRadioPage(slide.slide().props.page) ||
						isModulesInputSurveyPage(slide.slide().props.page),
				)
				.map((slide) => slide.id);
			const replace = await getModulesPageData(arrId);

			const updatedModulesData = new ModulesData();
			updatedModulesData.setData(replace);
			setModulesData(updatedModulesData);
		} else {
			//load daily affirmation
			if (reflectionModules?.affirmations || reflectionModules?.gratitude) {
				const date = new Date();
				const dateString =
					date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
				const affirmation = await apiGetDailyAffirmation(dateString);
				if (affirmation.text && affirmation.prompt) {
					setAffirmation(affirmation);
				}
			}
			// load reflectionSlides
			loadReflectionSlides(tempSetupInfo);
		}
	};

	useEffect(() => {
		if (reflectionModules?.modules || reflectionModules?.toolkit) {
			if (modulesSlides.length) {
				setSlideList(modulesSlides);
				setSlidesLoading(false);
				return;
			}
		} else {
			if (reflectionSlides.length) {
				setSlideList(reflectionSlides);
				setSlidesLoading(false);
				return;
			}
		}
		setSlidesLoading(true);
	}, [reflectionSlides, modulesSlides]);

	useEffect(() => {
		const interventionInListIndex = slideList.findIndex((x) => x.id === intervention);
		if (interventionInListIndex >= 0) {
			setStep(interventionInListIndex);
			setIntervention("");
		} else {
			const found = interventionSlideList.find((x) => x.id === intervention);
			if (found) {
				setSelectedInterventionList(selectedInterventionList.concat(found.id));
				setSelectedInterventionIndex(selectedInterventionIndex + 1);
				setStep(step + 1);
				setIntervention("");
			}
		}
	}, [intervention]);

	const loadReflectionSlides = (setupInfoTemp) => {
		if (!reflectionModules?.modules) {
			const reflectionSlides = configureReflectionSlides(
				reflectionModules,
				setupInfoTemp,
				selectedInterventionList,
				selectedInterventionIndex,
				interventionSlideList,
			);
			setReflectionSlides(reflectionSlides);
		}
	};

	useEffect(() => {
		const interventionDeck = createInterventionDeck(
			emotionStimList,
			recommendationSuggestion,
			setRecommendationSuggestion,
		);
		setInterventionSlideList(interventionDeck);
	}, [emotionStimListJSON]);

	useEffect(() => {
		if (selectedInterventionIndex !== -1) {
			loadReflectionSlides(setupInfo);
		}
	}, [selectedInterventionIndex]);

	const overwriteModuleReminders = async (newReminders) => {
		appDispatch({ type: "setReminders", reminders: newReminders });
		await apiUpdateReminders(newReminders);
	};

	const goBackward = () => {
		if (step > 0) {
			setStep(step - 1);
		}
	};
	const goForward = () => {
		if (step < slideList.length - 1) {
			setStep(step + 1);
		}
	};

	const setModuleIntrosComplete = (moduleKeyList) => {
		let newSetupInfo = setupInfo;
		if (!newSetupInfo.moduleSetup) {
			newSetupInfo.moduleSetup = {};
		}
		moduleKeyList.forEach((moduleKey) => {
			newSetupInfo.moduleSetup[moduleKey] = true;
		});
		setSetupInfo(newSetupInfo);
		const saveSetupInfo = async () => {
			await apiUpdateSetupInfo(newSetupInfo);
			await getAuthenticatedUserInfo(appDispatch);
		};
		Promise.all(saveSetupInfo()).catch((e) => {
			logger.error("Update Setup Info Failed: ", e);
		});
	};

	const setModuleReminders = (remindersConfig) => {
		const newReminders = configModuleReminders(reminders, remindersConfig);
		Promise.all(overwriteModuleReminders(newReminders)).catch((e) => {
			logger.error("Update Reminders Failed: ", e);
		});
	};

	const modalStyle = {
		reflectionModalBoxDesktop: {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: 900,
			height: appHeight - 80,
			minHeight: 300,
			maxHeight: 800,
			backgroundColor: colors.white,
			borderRadius: "20px",
			outline: 0,
		},
		reflectionModalBoxMobile: {
			position: "absolute",
			bottom: "0px",
			height: appHeight - 40,
			width: appWidth,
			backgroundColor: colors.white,
			borderRadius: "20px 20px 0px 0px",
			outline: 0,
			overflow: "hidden",
		},
	};

	return (
		<ThemeProvider theme={modulesTheme}>
			<Modal open={showReflectionModal} onClose={() => {}}>
				<Box
					sx={
						navBarState === NavBarState.SHIFT
							? modalStyle.reflectionModalBoxDesktop
							: modalStyle.reflectionModalBoxMobile
					}
				>
					<ReflectionModalContext.Provider
						value={{
							goBackward,
							goForward,
							modulesData,
							feeling,
							setFeeling,
							userName,
							emotionStimList,
							setEmotionStimList,
							stimuli,
							thoughts,
							setThoughts,
							saveOnClick,
							reflectionsCount,
							affirmation,
							gratitude,
							setGratitude,
							setModuleIntrosComplete,
							setModuleReminders,
							prompt,
							setPrompt,
							value,
							setValue,
							weight,
							setWeight,
							interventionSlideList,
							setIntervention,
							saving,
						}}
					>
						<div className={classes.reflectionModalDragBar}>
							<Button
								onClick={() => {
									setShowCancelDialog(true);
								}}
								className={classes.reflectionModalCloseButton}
							>
								<Close />
							</Button>
						</div>
						{slidesLoading ? (
							<div className={classes.reflectionModalLoading}>
								<LoadingIcon size="medium" />
							</div>
						) : (
							<>
								<div className={classes.reflectionModalSlidesRoot}>
									{slideList[step].slide()}
								</div>
							</>
						)}
						<QModal
							open={showCancelDialog}
							setOpen={setShowCancelDialog}
							saveButton
							saveButtonText="Save & Close"
							onSave={() => apiUpdateReflection(true, true)}
							cancelButton
							cancelButtonText="Don't Save"
							onCancel={() => apiUpdateReflection(false, true)}
						>
							<Typography variant="h5">
								Do you want to save your reflection before exiting?
							</Typography>
						</QModal>
						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							autoHideDuration={6000}
							open={!!reflectionError && reflectionError.length > 1}
							onClose={() => setError(undefined)}
							message={reflectionError}
						/>
					</ReflectionModalContext.Provider>
				</Box>
			</Modal>
		</ThemeProvider>
	);
};
ReflectionModal.propTypes = {
	hideReflectionModal: PropTypes.func.isRequired,
	showReflectionModal: PropTypes.bool.isRequired,
	reflectionModules: PropTypes.shape({}),
};

export default ReflectionModal;
