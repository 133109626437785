import { appUrl } from "../index";
import { logger } from "./logLib";

export enum ServiceWorkerState {
	DISABLED = "disabled",
	ENABLED = "enabled",
	ERROR = "error",
}

export interface ServiceWorkerStatus {
	state: ServiceWorkerState;
	message: string;
}

export class QuimbyServiceWorker {
	#message: string | undefined;
	#state: ServiceWorkerState;
	#registration: ServiceWorkerRegistration | undefined;

	constructor() {
		this.#state = ServiceWorkerState.DISABLED;
		this.#registration = undefined;
		this.#message = undefined;
	}

	getStatus() {
		return {
			state: this.#state,
			message: this.#message ?? "",
		} as ServiceWorkerStatus;
	}

	async initializeServiceWorker() {
		if ("serviceWorker" in navigator) {
			try {
				const registration = await navigator.serviceWorker.register(
					appUrl + "/quimby-service-worker.js",
				);
				this.#state = ServiceWorkerState.ENABLED;
				this.#registration = registration;
				return true;
			} catch (e) {
				this.#state = ServiceWorkerState.ERROR;
				this.#registration = undefined;
				this.#message = "Service worker failed to start";
				logger.error("Initialize service worker error:", e);
				return false;
			}
		} else {
			this.#state = ServiceWorkerState.ERROR;
			this.#message = "Your browser does not support service workers";
			return false;
		}
	}

	async terminateServiceWorker() {
		if (this.#registration) {
			const unRegister = await this.#registration.unregister();
			if (!unRegister) {
				this.#state = ServiceWorkerState.ERROR;
				this.#registration = undefined;
				this.#message = "Error terminating service worker";
				return false;
			}
		}
		this.#state = ServiceWorkerState.DISABLED;
		this.#registration = undefined;
		this.#message = "Service worker disabled";
		return true;
	}

	getRegistration() {
		return this.#registration;
	}
}
