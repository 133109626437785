import makeStyles from "@mui/styles/makeStyles";
import React, { FC, ReactNode } from "react";
import { modulesTheme } from "../Modules/modulesTheme";
import Copyright from "../common/Copyright";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexFlow: "column nowrap",
		flex: 1,
		backgroundColor: modulesTheme.palette.background.default,
	},

	content: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

interface SignInTemplateProps {
	children: ReactNode;
}

const SignInTemplate: FC<SignInTemplateProps> = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.content}>{children}</div>
			<div>
				<Copyright />
			</div>
		</div>
	);
};

export default SignInTemplate;
