// ModuleDetails.tsx

import { CircularProgress, Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QButtonStack from "src/components/common/QButtonStack";
import { BillingType } from "src/model/account/account.Model";
import { User } from "src/model/entities";
import { Module, ModuleMilestones, ModuleState } from "src/model/modules/modules.Model";
import { generateReflectionPathName } from "src/model/reflection/reflection.Manager";
import MilestoneStaticList from "../components/Modules/Shared/MilestoneStaticList";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import QButton, { QButtonProps } from "../components/common/QButton";
import {
	apiCreateModulesUserConfig,
	apiGetModuleMilestones,
	apiGetModuleMilestonesBySessionId,
	apiGetModules,
	apiUpdateModulesUserConfig,
	apiUpgradeSubscription,
} from "../libs/apiLib";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";

const ModulesSVG =
	"https://quimby-modules-dev.s3.us-east-2.amazonaws.com/public/common/milestones.svg";

const ModuleDetails = () => {
	const classes = modulesStyles();
	const {
		UIContext: { appWidth },
	} = useTypedAppContext();

	const navigate = useNavigate();

	//@ts-expect-error nopt typed
	const { appState, getAuthenticatedUserInfo, appDispatch } = useAppContext();
	const hasSubscription: boolean = appState.hasSubscription;
	const authenticatedUserInfo: User = appState.authenticatedUserInfo;

	const [milestonesPageData, setMilestonesPageData] = useState({} as ModuleMilestones);
	const { moduleId } = useParams();
	const [module, setModule] = useState<Module | null>(null);
	const [moduleNotFound, setModuleNotFound] = useState<boolean>(false);
	const [moduleState, setModuleState] = useState<ModuleState>(ModuleState.NEW);
	const [upgrading, setUpgrading] = useState(false);

	useEffect(() => {
		void loadMilestones();
	}, [hasSubscription, authenticatedUserInfo, moduleState]);

	const launchModuleCheckIn = (inputData?: ModuleMilestones) => {
		const pageData = inputData ? inputData : milestonesPageData;
		if (pageData) {
			const activeSection = pageData.milestonePage.milestones.find(
				(milestone) => milestone.state === "active",
			);
			if (activeSection) {
				const nextActivity = activeSection.progress?.find(
					(activity) => activity.isLocked === true,
				);
				if (nextActivity) {
					navigate(
						generateReflectionPathName(
							{
								modules: true,
								activity: nextActivity?.link ?? "",
							},
							pageData.moduleId,
							true,
						),
					);
					return;
				}
			}
		}
		navigate(generateReflectionPathName({ modules: true }, milestonesPageData.moduleId, true));
	};

	const updateModule = async (newState: ModuleState) => {
		if (module) {
			switch (newState) {
				case ModuleState.ACTIVE:
					if (module.moduleStatus.moduleSessionId) {
						if (moduleState === ModuleState.COMPLETE) {
							await apiUpdateModulesUserConfig(module.moduleStatus.moduleSessionId, {
								moduleState: ModuleState.ACTIVE,
								moduleStep: "_reset",
							});
						} else {
							await apiUpdateModulesUserConfig(module.moduleStatus.moduleSessionId, {
								moduleState: ModuleState.ACTIVE,
							});
						}
					} else {
						await apiCreateModulesUserConfig({
							moduleId: module.moduleId,
							moduleVersion: module.version,
						});
						const data = await loadMilestones();
						//Launch first activity
						launchModuleCheckIn(data);
					}
					break;
				case ModuleState.CANCELED:
					if (module.moduleStatus.moduleSessionId) {
						await apiUpdateModulesUserConfig(module.moduleStatus.moduleSessionId, {
							moduleState: newState,
							moduleStep: "",
						});
					}
					break;
				default:
					if (module.moduleStatus.moduleSessionId) {
						await apiUpdateModulesUserConfig(module.moduleStatus.moduleSessionId, {
							moduleState: newState,
						});
					}
			}
			setModuleState(newState);
		}
	};

	const loadMilestones = async () => {
		const modules = await apiGetModules();
		const foundModule = modules.find((module) => module.moduleId === moduleId);
		if (!foundModule) {
			setModuleNotFound(true);
			return;
		}
		setModule(foundModule);
		let data;
		if (foundModule?.moduleStatus?.moduleSessionId) {
			data = await apiGetModuleMilestonesBySessionId(
				foundModule.moduleId,
				foundModule.moduleStatus.moduleSessionId,
			);
			if (data) {
				setMilestonesPageData(data);
				setModuleState(foundModule.moduleStatus.moduleState);
			}
		} else if (foundModule?.moduleId) {
			data = await apiGetModuleMilestones(foundModule.moduleId);
			if (data) {
				setMilestonesPageData(data);
			}
		} else {
			logger.log("Failed to Load Milestones");
		}
		if (!hasSubscription) {
			setModuleState(ModuleState.LOCKED);
		}
		return data;
	};

	const startTrial = async () => {
		setUpgrading(true);
		if (!authenticatedUserInfo.subscription) {
			logger.error("Upgrade Error: User has no subscription");
			return;
		}
		const subscriptions = Array.isArray(authenticatedUserInfo.subscription)
			? authenticatedUserInfo.subscription
			: [authenticatedUserInfo.subscription];
		const subscription = subscriptions.find(
			(subs) => !!subs.subscriptionId && subs.subscriptionId === "S:NO_SUBSCRIPTION",
		);

		// First we upgrade the account
		const companyName = subscription?.company
			? subscription.company
			: `${authenticatedUserInfo.firstName}-${authenticatedUserInfo.lastName}`;
		try {
			const response = await apiUpgradeSubscription(companyName, BillingType.Monthly, 1);
			if (response.status === "ERROR") {
				throw new Error(`${response.error}`);
			}
		} catch (e) {
			logger.error("Upgrade error:", e);
			setUpgrading(false);
		}
		await getAuthenticatedUserInfo(appDispatch);
		setUpgrading(false);
		setModuleState(ModuleState.NEW);
	};

	const modulesButtons = () => {
		let buttons = [] as QButtonProps[];
		switch (moduleState) {
			case ModuleState.NEW || ModuleState.CANCELED:
				buttons = [
					{
						onClick: () => updateModule(ModuleState.ACTIVE),
						text: "Start journey",
					},
				];
				break;
			case ModuleState.ACTIVE:
				buttons = [
					{
						onClick: () => {
							launchModuleCheckIn();
						},
						text: "Launch next step",
					},
				];
				break;
			case ModuleState.PAUSED:
				buttons = [
					{
						onClick: () => updateModule(ModuleState.ACTIVE),
						text: "Resume",
					},
				];
				break;
			case ModuleState.LOCKED:
				buttons = [
					{
						onClick: () => {
							void startTrial();
						},
						text: "Start a 14 day trial",
					},
				];
				break;
			default: // Complete,
				buttons = [
					{
						onClick: () => updateModule(ModuleState.ACTIVE),
						text: "Restart journey",
					},
				];
				break;
		}
		return buttons;
	};
	return (
		<ThemeProvider theme={modulesTheme}>
			{milestonesPageData.title ? (
				<div
					className={`${
						appWidth > modulesTheme.breakpoints.values.tablet
							? classes.pageBase
							: classes.pageBaseMobile
					}`}
				>
					<Grid container direction="row" spacing="20px">
						<Grid item mobile={10}>
							<Stack direction="column" spacing="10px">
								<Typography variant="h1">
									{milestonesPageData.milestonePage.pageTitle}
								</Typography>
								<Typography variant="body">
									{milestonesPageData.milestonePage.description}
								</Typography>
								<div style={{ display: "flex" }}>
									{upgrading ? (
										<CircularProgress />
									) : (
										<QButtonStack buttons={modulesButtons()} />
									)}
									<div style={{ marginLeft: modulesTheme.spacing(2) }}>
										<QButton
											onClick={() => navigate("/home/intentions")}
											text="Back"
											variant="outlined"
										/>
									</div>
								</div>
								<Grid item mobile={12}>
									<Typography variant="info1">
										{!hasSubscription
											? `No credit card required`
											: `Disclaimer: This is not intended to provide, overrule, or replace a medical diagnosis, treatment, or advice. Consult a medical professional for anxiety-related help and before making any decisions.`}
									</Typography>
								</Grid>
							</Stack>
						</Grid>
						{appWidth > modulesTheme.breakpoints.values.tablet && (
							<Grid item mobile={2}>
								<img
									className={classes.modulesDetailsPageImage}
									src={module?.thumbnailPath ? module.thumbnailPath : ModulesSVG}
									alt="Module Image"
								></img>
							</Grid>
						)}
						<Grid item mobile={12}>
							<MilestoneStaticList
								milestones={milestonesPageData.milestonePage.milestones}
								listItemClassName={`${
									appWidth > modulesTheme.breakpoints.values.tablet
										? classes.modulesHomeMilestoneListItem
										: classes.modulesHomeMilestoneListItemMobile
								}`}
								moduleId={milestonesPageData.moduleId}
							/>
						</Grid>
					</Grid>
				</div>
			) : (
				<div className={classes.loading}>
					{moduleNotFound ? (
						<Stack spacing={modulesTheme.spacing(3)} alignItems="center">
							<Typography variant="h5">Module not found</Typography>
							<QButton
								text="Return to Intentions"
								onClick={() => {
									navigate("/home/intentions");
								}}
							/>
						</Stack>
					) : (
						<Typography variant="h4">Loading...</Typography>
					)}
				</div>
			)}
		</ThemeProvider>
	);
};

export default ModuleDetails;
