import React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { BUTTON_STYLES } from "../../shared/constants";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	button: {
		textTransform: "none",
	},
}));

export default function ActionButton({
	label,
	action,
	style = BUTTON_STYLES.SOLID,
	isDisabled = false,
}) {
	const classes = useStyles();

	async function clickHandler() {
		action();
	}

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				variant={style}
				color="primary"
				size="small"
				disabled={isDisabled}
				onClick={clickHandler}
			>
				{label}
			</Button>
		</div>
	);
}
