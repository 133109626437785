import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";
import PeopleTableRowButtons from "./PeopleTableRowButtons";
import { formatDate } from "../../libs/utilityLib";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));

export default function PendingTableCellInvitationDate({
	row,
	hoverRowId,
	setHoverRowId,
	editRowId,
	setEditRowId,
	saveAction,
	cancelAction,
	isModified,
	setEditRowInfo,
}) {
	const classes = useStyles();

	return (
		<TableCell align="left">
			<div className={classes.root}>
				<span>{row.createdAt ? `${formatDate(row.createdAt)}` : ""}</span>
				<PeopleTableRowButtons
					row={row}
					saveAction={saveAction}
					cancelAction={cancelAction}
					hoverRowId={hoverRowId}
					setHoverRowId={setHoverRowId}
					editRowId={editRowId}
					setEditRowId={setEditRowId}
					isModified={isModified}
					setEditRowInfo={setEditRowInfo}
				/>
			</div>
		</TableCell>
	);
}
