import React, { useState } from "react";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import ConfigureReminder from "../Routines/ConfigureReminder";
import QButton from "../common/QButton";
import QModal from "../common/QModal";

// const MAX_REMINDERS = 6;
const DEFAULT_TIME = "09:00";
const DEFAULT_DAYS = [false, true, true, true, true, true, false];

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		alignItems: "center",
		"& *:focus": {
			outline: "none",
		},
	},
	title: {
		flex: 1,
	},
	buttons: {
		flex: 0,
	},
}));

const RemindersListSubheader = React.forwardRef((props) => {
	const { addReminder } = props;
	const [newDays, setNewDays] = useState(DEFAULT_DAYS);
	const [newTime, setNewTime] = useState(DEFAULT_TIME);
	const [open, setOpen] = useState(false);
	const [activity, setActivity] = useState({});
	const classes = useStyles();

	function handleAddButtonClick() {
		setOpen(true);
	}
	const handleSave = () => {
		const newReminder = {
			days: [...newDays],
			time: newTime,
			enabled: true,
			moraleCheckIn: activity.check_in,
			affirmations: activity.affirmations,
			gratitude: activity.gratitude,
			modules: activity.modules,
			id: uuidv4(),
		};

		addReminder(newReminder);
		setOpen(false);
		resetDefaultTimes();
	};

	function resetDefaultTimes() {
		setNewTime(DEFAULT_TIME);
		setNewDays(DEFAULT_DAYS);
	}

	return (
		<>
			<QModal
				open={open}
				setOpen={setOpen}
				disableCancelClick
				saveButton
				saveButtonText="Save"
				onSave={handleSave}
				cancelButton
				cancelButtonText="Cancel"
				width="320px"
			>
				<ConfigureReminder
					days={newDays}
					setDays={setNewDays}
					time={newTime}
					setTime={setNewTime}
					setActivity={setActivity}
				/>
			</QModal>
			<Grid container style={{ maxWidth: "800px" }}>
				<Grid item container justifyContent={"space-between"}>
					<Typography variant="h5" className={classes.title}>
						Reminders
					</Typography>
					<QButton
						onClick={handleAddButtonClick}
						text="Add New"
						icon=<AddRoundedIcon />
					/>
				</Grid>
			</Grid>
		</>
	);
});

export default RemindersListSubheader;
RemindersListSubheader.displayName = "RemindersListSubheader";
RemindersListSubheader.propTypes = {
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func.isRequired,
	deleteReminders: PropTypes.func.isRequired,
	addReminder: PropTypes.func.isRequired,
	deleteFlags: PropTypes.array.isRequired,
	reminders: PropTypes.array,
};
