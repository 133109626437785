import React, { useEffect, useState } from "react";
import { BUTTON_STYLES } from "../../shared/constants";
import ActionButton from "../common/ActionButton";
import { useAppContext } from "../../libs/contextLib";
import AddMembersDialog from "./AddMembersDialog";
import { apiAddMembersToSubscription, apiGetSubscription } from "../../libs/apiLib";
import { refreshSubscriptionInfo } from "../../utility/App.util";
// import { logger } from "../../libs/logLib";

export default function AddMembersButton({ emails }) {
	const [error, setError] = useState({ error: false, message: "" });
	const [open, setOpen] = useState(false);
	const [availableSeats, setAvailableSeats] = useState(0);
	const {
		appState: {
			selectedSubscriptionId,
			subscriptions,
			authenticatedUserInfo,
			groupsTree,
			selectedGroupId,
		},
		appDispatch,
	} = useAppContext();

	function action() {
		setOpen(true);
	}

	function handleCancel() {
		resetState();
	}
	const selectedSubscriptionIndex = subscriptions.findIndex(
		(sub) => sub.subscriptionId === selectedSubscriptionId,
	);

	useEffect(() => {
		const getSubscription = async (selectedSubscriptionId) => {
			const result = await apiGetSubscription(selectedSubscriptionId);
			setAvailableSeats(result.seats - result.members.length);
		};
		void getSubscription(selectedSubscriptionId);
	}, [selectedSubscriptionId]);

	async function handleCreate(emailChips, groupId) {
		const managerName = {
			firstName: authenticatedUserInfo.firstName,
			lastName: authenticatedUserInfo.lastName,
		};
		const companyName = subscriptions[selectedSubscriptionIndex].company
			? subscriptions[selectedSubscriptionIndex].company
			: "";

		await apiAddMembersToSubscription(
			selectedSubscriptionId,
			companyName,
			managerName,
			authenticatedUserInfo.email,
			emailChips.map((item) => item.text),
			groupId,
		);

		resetState();
		await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
	}

	function resetState() {
		setError({ error: false, message: "" });
		setOpen(false);
	}

	return (
		<>
			<ActionButton label="Add Members" action={action} style={BUTTON_STYLES.SOLID} />
			<AddMembersDialog
				open={open}
				handleCancel={handleCancel}
				handleCreate={handleCreate}
				emails={emails}
				error={error}
				setError={setError}
				groupsTree={groupsTree}
				selectedGroupId={selectedGroupId}
				availableSeats={availableSeats}
			/>
		</>
	);
}
