import { Grid, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { apiCreateUser } from "../../libs/apiLib";
import { useAppContext } from "../../libs/contextLib";
import { logger } from "../../libs/logLib";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { TelemetryAction } from "../../model/telemetry";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";

const SetupProfile: FC = () => {
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [company, setCompany] = useState<string>("");
	const [role, setRole] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [phoneNumberError, setPhoneNumberError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const {
		Auth: { cognitoUser },
	} = useTypedAppContext();

	useEffect(() => {
		if (cognitoUser) {
			cognitoUser.getUserAttributes((error, attributes) => {
				if (error) {
					logger.error(error);
				} else {
					if (attributes) {
						const emailAttribute = attributes.find(
							(attr) => attr.getName() === "email",
						);
						if (emailAttribute) {
							setEmail(emailAttribute.getValue());
						}
					}
				}
			});
		}
	}, []);

	//@ts-expect-error not typed
	const { getAuthenticatedUserInfo, appDispatch } = useAppContext();

	const classes = modulesStyles();

	const validPhoneRegex = RegExp(
		/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, //eslint-disable-line no-useless-escape
	);

	const isPhoneValid = (phone: string) => {
		return validPhoneRegex.test(phone);
	};

	const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setPhoneNumber(e.target.value);
		if (!isPhoneValid(e.target.value)) {
			setPhoneNumberError(
				"Invalid phone number. Example format: +xx (xxx) xxx-xxxx ext xxxx",
			);
		} else {
			setPhoneNumberError("");
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		try {
			await apiCreateUser(
				firstName,
				lastName,
				company,
				role,
				phoneNumber,
				email,
				true,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
			);
			logger.telemetry({
				action: TelemetryAction.log,
				event: "setup_createUser",
			});
			await getAuthenticatedUserInfo(appDispatch);
		} catch (e) {
			logger.error(e, "getAuthenticatedUserInfo");
			appDispatch({
				type: "setErrorText",
				toastErrorText:
					"Error creating profile. Please try again. If you continue to get this error contact: support@quimby.app",
			});
		}
		setLoading(false);
	};
	return (
		<>
			<Stack
				width="100%"
				justifyContent="center"
				alignItems="center"
				alignSelf="center"
				spacing={modulesTheme.spacing(3)}
			>
				<Typography variant="h2">Create your profile</Typography>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={2} maxWidth="800px">
						<Grid item mobile={12} navBar={6}>
							<TextField
								fullWidth
								label="First Name"
								variant="outlined"
								required
								value={firstName}
								onChange={(event) => {
									setFirstName(event.target.value);
								}}
							/>
						</Grid>
						<Grid item mobile={12} navBar={6}>
							<TextField
								fullWidth
								label="Last Name"
								variant="outlined"
								required
								value={lastName}
								onChange={(event) => {
									setLastName(event.target.value);
								}}
							/>
						</Grid>
						<Grid item mobile={12} navBar={6}>
							<TextField
								fullWidth
								label="Company"
								variant="outlined"
								required
								value={company}
								onChange={(event) => {
									setCompany(event.target.value);
								}}
							/>
						</Grid>
						<Grid item mobile={12} navBar={6}>
							<TextField
								fullWidth
								label="Role"
								variant="outlined"
								value={role}
								onChange={(event) => {
									setRole(event.target.value);
								}}
							/>
						</Grid>
						<Grid item mobile={12} navBar={6}>
							<TextField
								fullWidth
								label="Phone Number"
								variant="outlined"
								value={phoneNumber}
								onChange={handlePhoneNumberChange}
								error={Boolean(phoneNumberError)}
								helperText={phoneNumberError}
							/>
						</Grid>
						<Grid item mobile={12}>
							<div className={classes.modulesItemCentered}>
								<QButton
									text="Continue"
									type="submit"
									variant="contained"
									onClick={() => {}}
									loading={loading}
									disabled={phoneNumberError !== ""}
								/>
							</div>
						</Grid>
					</Grid>
				</form>
			</Stack>
		</>
	);
};

export default SetupProfile;
