import { EDIT_RECORD_KEY, MEMBER_STATUS } from "../../shared/constants";

import DeleteGroupButton from "./DeleteGroupButton";
import GroupName from "./GroupName";
import ManagerPersonGroup from "./ManagerPersonGroup";
import MemberPersonGroup from "./MemberPersonGroup";
import ParentGroupPickerEditControl from "./ParentGroupPickerEditControl";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	groupDetails: {
		background: "white",
		padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
		borderLeft: "2px solid lightgray",
		display: "grid",
		gridTemplateRows: "auto auto auto 1fr auto",
		gridTemplateColumns: "1fr",
		rowGap: theme.spacing(5),
		width: "100%",
	},
	deleteButton: {
		justifySelf: "end",
	},
}));

function getStatusQuantity(members, status) {
	return members.filter((member) => !!member?.status && member.status === status).length;
}

export default function GroupDetails({
	groupsTree,
	selectedGroup,
	editGroup,
	deleteGroup,
	updateGroupEditRecord,
	canEditGroupsSection,
	killEditMode,
}) {
	if (!selectedGroup || !groupsTree) {
		return null;
	}
	const classes = useStyles();
	const { id, parentGroupId, name, managers, members, isDeletable } = selectedGroup;
	return (
		<>
			<div className={classes.groupDetails}>
				<GroupName
					groupName={name}
					setGroupName={async (value) => {
						await editGroup(id, EDIT_RECORD_KEY.NAME, value);
					}}
					members={members.length}
					pending={getStatusQuantity(members, MEMBER_STATUS.PENDING)}
					needsApproval={getStatusQuantity(members, MEMBER_STATUS.NEEDS_APPROVAL)}
					updateGroupEditRecord={(record) => {
						return updateGroupEditRecord({ id, ...record });
					}}
					killEditMode={killEditMode}
					canEditGroupsSection={canEditGroupsSection}
				/>
				<ParentGroupPickerEditControl
					groupsTree={groupsTree}
					selectedGroupId={id}
					parentGroupId={parentGroupId}
					setParentGroupId={async (value) => {
						await editGroup(id, EDIT_RECORD_KEY.PARENT, value);
					}}
					updateGroupEditRecord={(record) => {
						return updateGroupEditRecord({ id, ...record });
					}}
					killEditMode={killEditMode}
					canEditGroupsSection={canEditGroupsSection}
				/>
				<ManagerPersonGroup
					people={managers}
					editGroup={async (key, value) => {
						await editGroup(id, key, value);
					}}
					updateGroupEditRecord={(record) => {
						return updateGroupEditRecord({ id, ...record });
					}}
					killEditMode={killEditMode}
					canEditGroupsSection={canEditGroupsSection}
				/>
				<MemberPersonGroup
					people={members}
					editGroup={async (key, value) => {
						await editGroup(id, key, value);
					}}
					updateGroupEditRecord={(record) => {
						return updateGroupEditRecord({ id, ...record });
					}}
					killEditMode={killEditMode}
					canEditGroupsSection={canEditGroupsSection}
				/>

				{
					// The Root Group is not deletable so conditionally show the delete button
					<div className={classes.deleteButton}>
						<DeleteGroupButton
							deleteGroup={deleteGroup}
							groupName={name}
							isDisabled={!isDeletable || !canEditGroupsSection}
						/>
					</div>
				}
			</div>
		</>
	);
}
