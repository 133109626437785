/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { appUrl } from "../index";
import { Module, ModuleMilestones, ModulePage } from "../model/modules/modules.Model";
import { ModulesPageData } from "../model/modulesData/modulesData.Model";
import {
	apiCreateModulesUserConfigInput,
	updateModulesUserConfigInput,
} from "../model/modulesUserConfig/modulesUserConfig.Model";
import { TelemetryEvent, TelemetrySession } from "../model/telemetry";
import { UserNotificationTargetConfig } from "../model/user/user.model";
import {
	GROUP_TREE_ID,
	MY_INSIGHTS,
	UPDATE_SUBSCRIPTION_ACTION,
	defaults,
} from "../shared/constants";
import { logger } from "./logLib";

const QUIMBY = "quimby";
const V2 = "v2";

const GOOGLE = `${V2}/google`;
const MODULES = `${V2}/modules`;
const MODULES_AFFIRMATIONS = `${MODULES}/affirmations/getAffirmation`;
const NOTIFICATION = `${V2}/notification/web-app`;
const REFLECTIONS = `${V2}/reflections`;
const REFLECTIONS_GROUP = `${REFLECTIONS}/group`;
const REFLECTION_REPORT = `${V2}/reflectionsReport`;
const STIMULI = `${V2}/stimuli`;
const SUBSCRIPTIONS = `${V2}/subscriptions`;
const SUBSCRIPTIONS_INVITE = `${SUBSCRIPTIONS}/processUserInvite`;
const SUBSCRIPTIONS_DOWNGRADE = `${SUBSCRIPTIONS}/downgradeToIndividualAccounts`;
const SUBSCRIPTIONS_BILLING = `${SUBSCRIPTIONS}/billingInfo`;
const SUBSCRIPTIONS_PAYMENT_SETUP = `${SUBSCRIPTIONS}/setupPayment`;
const SUBSCRIPTIONS_PAYMENT_UPDATE = `${SUBSCRIPTIONS}/updatePayment`;
const TELEMETRY_EVENT = `${V2}/telemetry/event`;
const TELEMETRY_SESSION = `${V2}/telemetry/session`;
const TOOLKITS = `${V2}/toolkits`;
const USERS = `${V2}/users`;
const USERS_REMINDERS = `${USERS}/reminders`;
const USERS_SETUP = `${USERS}/setupInfo`;
const USERS_CODE = `${USERS}/code`;
const SUBSCRIPTION_UPGRADE = `${USERS}/upgradeToTeamAccount`;

const isLocal = () => {
	if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") {
		return true;
	}
	return false;
};

const generateLocalHeaders = async () => {
	const headers = {
		headers: {
			"cognito-identity-id": undefined,
		},
	};
	try {
		const currentUser = await Auth.currentUserInfo();
		if (!currentUser || !currentUser.id) {
			throw new Error("Error generating local headers");
		}
		headers.headers["cognito-identity-id"] = currentUser.id;
	} catch (e) {
		//don't surface error
	}
	return headers;
};

const QuimbyAPI = {
	get: isLocal()
		? async (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.get(apiName, path, { ...(await generateLocalHeaders()), body: body })
		: (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.get(
					apiName,
					path,
					JSON.stringify(body) !== JSON.stringify({}) ? { body: body } : {},
				),
	post: isLocal()
		? async (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.post(apiName, path, { ...(await generateLocalHeaders()), body: body })
		: (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.post(
					apiName,
					path,
					JSON.stringify(body) !== JSON.stringify({}) ? { body: body } : {},
				),
	put: isLocal()
		? async (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.put(apiName, path, { ...(await generateLocalHeaders()), body: body })
		: (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.put(
					apiName,
					path,
					JSON.stringify(body) !== JSON.stringify({}) ? { body: body } : {},
				),
	del: isLocal()
		? async (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.del(apiName, path, { ...(await generateLocalHeaders()), body: body })
		: (apiName: string, path: string, body: { [key: string]: any } = {}) =>
				API.del(
					apiName,
					path,
					JSON.stringify(body) !== JSON.stringify({}) ? { body: body } : {},
				),
};

//*********************************  API FUNCTIONS  *********************************

//*********************************  GROUPS  *********************************

export const apiGetGroupTree = (subscriptionId: string) => {
	//TODO cast return type to ?
	return apiGetGroup(subscriptionId, GROUP_TREE_ID);
};

export const apiGetGroup = async (subscriptionId: string, groupId: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups/${groupId}`);
};

export const apiListGroups = async (subscriptionId: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups`);
};

//TODO set input type to ?
export const apiUpdateGroup = async (subscriptionId: string, groupId: string, data: any) => {
	if (data.members) {
		const members = data.members.filter((user: any) => !!user);
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups/${groupId}`, {
			...data,
			members,
		});
	}
	//TODO cast return type to ?
	return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups/${groupId}`, {
		...data,
	});
};

export const apiCreateGroup = async (
	subscriptionId: string,
	companyId: string,
	name: string,
	parentGroupId: string,
	managers = [],
	members = [],
	//TODO set to creators timezone offset
	timezoneOffset = -5,
) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups`, {
		companyId,
		name,
		parentGroupId,
		managers,
		members,
		timezoneOffset,
	});
};

export const apiDeleteGroup = async (subscriptionId: string, groupId: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.del(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/groups/${groupId}`);
};

//*********************************  NOTIFICATION  *********************************

export const apiSetWebPushSubscription = async (pushSubscription: PushSubscription) => {
	const output = pushSubscription.toJSON();
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, NOTIFICATION + "/set", { ...output });
};

export const apiSendTestWebPushNotification = async (userId: string) => {
	const payload = {
		userId: userId,
		target: "web-app",
		type: "test",
	};
	//TODO cast return type to ?
	return QuimbyAPI.post(QUIMBY, NOTIFICATION + "/send", payload);
};

export const apiGetSignInWithGoogleUrl = async (returnPath: string) => {
	const payload = {
		body: { returnUrl: appUrl + returnPath },
	};
	return API.post(QUIMBY, GOOGLE + "/getAuthUrl", payload);
};

export const apiGetUserNotificationTargetConfig = async () => {
	try {
		const response = await API.get(QUIMBY, USERS + "/notificationTargetConfig", {});
		return response as UserNotificationTargetConfig;
	} catch (e) {
		throw new Error("Api get user notification target config error" + e);
	}
};

export const apiRevokeGoogleAuth = async () => {
	const payload = {};
	return API.post(QUIMBY, GOOGLE + "/revokeAuth", payload);
};

//*********************************  MODULES  *********************************

export const apiGetModules = async () => {
	try {
		const response = await QuimbyAPI.get(QUIMBY, MODULES, {});
		return response as Module[];
	} catch (e) {
		throw new Error("Api get modules error" + e);
	}
};

export const apiGetModuleMilestonesBySessionId = async (
	moduleId: string,
	moduleSessionId: string,
) => {
	try {
		const response = await QuimbyAPI.get(
			QUIMBY,
			MODULES + "/milestones/" + moduleId + "/" + moduleSessionId,
			{},
		);
		return response as ModuleMilestones;
	} catch (e) {
		throw new Error("Api get module milestone error" + e);
	}
};

export const apiGetModuleMilestones = async (moduleId: string) => {
	try {
		const response = await QuimbyAPI.get(QUIMBY, MODULES + "/milestones/" + moduleId, {});
		return response as ModuleMilestones;
	} catch (e) {
		throw new Error("Api get module details error" + e);
	}
};

export const apiCreateModulesUserConfig = async (data: apiCreateModulesUserConfigInput) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, MODULES + "/userConfig/create", data);
};

export const apiUpdateModulesUserConfig = async (
	moduleSessionId: string,
	data: updateModulesUserConfigInput,
) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, MODULES + "/userConfig/update", {
		moduleSessionId: moduleSessionId,
		...data,
	});
};

export const apiGetModulesCheckInPages = async (reflectionActivity: string) => {
	try {
		const response = await QuimbyAPI.get(
			QUIMBY,
			MODULES + `/activities/${reflectionActivity}`,
			{},
		);
		return response as ModulePage[];
	} catch (e) {
		throw new Error("Api get modules check in pages error" + e);
	}
};

export const apiGetModulesPageData = async (source: string) => {
	try {
		const response = await QuimbyAPI.get(QUIMBY, MODULES + `/activities/editor/${source}`, {});
		//TODO cast return type to ?
		return response;
	} catch (e) {
		throw new Error("Api get placeholder error" + e);
	}
};

export const apiCreateModulesData = async (modulePageData: ModulesPageData[]) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, MODULES + "/data/create", [...modulePageData]);
};

export const apiUpdateModulesUserConfigStep = async (): Promise<boolean[]> => {
	const TODAY = new Date();
	TODAY.setHours(0, 0, 0, 0);
	return await QuimbyAPI.post(QUIMBY, MODULES + "/userConfig/updateStep", {
		action: "next",
		timestamp: TODAY.getTime().toString(),
	});
};

export const apiUpdateModulesUserConfigStepBySession = async (
	sessionPath: string,
): Promise<boolean[]> => {
	const TODAY = new Date();
	TODAY.setHours(0, 0, 0, 0);
	return await QuimbyAPI.post(QUIMBY, MODULES + "/userConfig/updateStep/bySession", {
		action: "next",
		timestamp: TODAY.getTime().toString(),
		sessionPath: sessionPath,
	});
};

export const apiGetDailyAffirmation = async (date: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${MODULES_AFFIRMATIONS}?date=${date}`);
};

//*********************************  REFLECTION  *********************************

export const apiGetReflections = async (dataSource: string) => {
	try {
		if (dataSource === MY_INSIGHTS) {
			//TODO cast return type to ?
			const response = await QuimbyAPI.get(QUIMBY, REFLECTIONS);
			const newResponse = response.map((reflection: any) => {
				return {
					...reflection,
					timestamp: `${new Date(reflection.timestamp).getTime()}`,
					userId: reflection.id.slice(2),
				};
			});
			//TODO cast return type to ?
			return newResponse;
		} else {
			if (!dataSource || typeof dataSource != "string") return [];
			//TODO cast return type to ?
			return await QuimbyAPI.get(QUIMBY, `${REFLECTIONS_GROUP}/${dataSource}`);
		}
	} catch (e) {
		logger.error("apiGetReflections Error:", e);
	}
};

export const apiGetReflectionStats = async () => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${REFLECTIONS}/stats`);
};

export const apiGetStimuliList = async () => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, STIMULI);
};

export const apiPostStimulus = async (word: string, shared: boolean) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, STIMULI, {
		word,
		shared,
	});
};

export const getCsvFileString = async () => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, REFLECTION_REPORT);
};

export const apiPostReflection = async (data: { [key: string]: any }) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, REFLECTIONS, data);
};

//*********************************  SUBSCRIPTIONS  *********************************

//TODO set input type to ?
export const apiCreateSubscription = async (
	company: string,
	managerName: string,
	managerEmail: string,
	members: any[] = [],
) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS, {
		company,
		managerName,
		managerEmail,
		members,
	});
};

export const apiAcceptInvitation = async (company: string, accountId: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS_INVITE, {
		action: "ACCEPT",
		company,
		accountId,
	});
};

//TODO set input type to ?
export const apiUpdateSubscription = async (subscriptionId: string, data: any) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`, {
			...data,
		});
	} catch (e) {
		logger.error("apiUpdateSubscription Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiAddMembersToSubscription = async (
	subscriptionId: string,
	company: string,
	managerName: string,
	managerEmail: string,
	newMembers: any,
	groupId: string,
) => {
	try {
		const data = {
			[UPDATE_SUBSCRIPTION_ACTION.KEY]: UPDATE_SUBSCRIPTION_ACTION.ADD_MEMBERS,
			company,
			managerName,
			managerEmail,
			newMembers,
			groupId,
		};
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`, { ...data });
	} catch (e) {
		logger.error("apiAddMembersToSubscription Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiUpgradeSubscriptionType = async (period: any, subscriptionId: string) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`, {
			[UPDATE_SUBSCRIPTION_ACTION.KEY]: UPDATE_SUBSCRIPTION_ACTION.SET_BILLING_TYPE,
			billingType: period,
		});
	} catch (e) {
		logger.error("apiUpgradeSubscriptionType Error:", e);
		//TODO return something ?
	}
};

export const apiRemoveMemberFromSubscription = async (
	subscriptionId: string,
	removedUserId: string,
) => {
	try {
		const data = {
			[UPDATE_SUBSCRIPTION_ACTION.KEY]: UPDATE_SUBSCRIPTION_ACTION.REMOVE_MEMBER,
			removedUserId,
		};
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`, { ...data });
	} catch (e) {
		logger.error("apiRemoveMemberFromSubscription Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiUpgradeSubscriptionRoles = async (subscriptionId: string, admins: any) => {
	if (!subscriptionId || subscriptionId === "S:NO_SUBSCRIPTION") return null;
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`, {
			[UPDATE_SUBSCRIPTION_ACTION.KEY]: UPDATE_SUBSCRIPTION_ACTION.UPDATE_ROLES,
			roles: {
				admins,
			},
		});
	} catch (e) {
		logger.error("apiUpgradeSubscriptionRoles Error:", e);
		return null;
	}
};

export const apiGetSubscription = async (subscriptionId: string) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.get(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}`);
	} catch (e) {
		logger.error(e);
		//TODO return something ?
	}
};

export const apiListUsers = async (subscriptionId: string) => {
	//TODO cast return type to ?
	if (subscriptionId === "S:NO_SUBSCRIPTION") return [];
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/users`);
};

export const apiDowngradeSubscription = async (subscriptionId: string) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS_DOWNGRADE, {
			accountId: subscriptionId,
		});
	} catch (e) {
		logger.error("apiDowngradeSubscription Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiUpgradeSubscription = async (
	company: string,
	billingType: string,
	seats: number,
) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.post(QUIMBY, SUBSCRIPTION_UPGRADE, {
			company: company,
			billingType: billingType,
			seats: seats,
		});
	} catch (e) {
		logger.error("apiUpgradeSubscription Error:", e);
		//TODO return something ?
	}
};

export const apiGetBillingInfo = async (subscriptionId: string) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS_BILLING, {
			accountId: subscriptionId,
		});
	} catch (e) {
		logger.error("apiGetBillingInfo Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiUpdatePaymentMethod = async (subscriptionId: string, paymentMethodId: any) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS_PAYMENT_UPDATE, {
			accountId: subscriptionId,
			paymentMethodId: paymentMethodId,
		});
	} catch (e) {
		logger.error("apiUpdatePaymentMethod Error:", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiSetupPaymentMethod = async (
	subscriptionId: string,
	paymentMethodId: any,
	billingType: any,
) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.post(QUIMBY, SUBSCRIPTIONS_PAYMENT_SETUP, {
			accountId: subscriptionId,
			paymentMethodId: paymentMethodId,
			billingType: billingType ?? "monthly",
		});
	} catch (e) {
		logger.error("apiSetupPaymentMethod Error:", e);
		//TODO return something ?
	}
};

export const apiGetStripePortalUrl = async (subscriptionId: string, returnUrl: string) => {
	try {
		return (await QuimbyAPI.post(QUIMBY, `${SUBSCRIPTIONS}/${subscriptionId}/stripePortalUrl`, {
			returnUrl,
		})) as string;
	} catch (e) {
		logger.error("apiGetStripePortalUrl Error:", e);
		return "";
	}
};

//*********************************  TELEMETRY  *********************************

export async function apiPostTelemetryEvent(data: TelemetryEvent) {
	const response = await QuimbyAPI.post(QUIMBY, TELEMETRY_EVENT, data);
	//TODO cast return type to ?
	return response;
}

export async function apiPostTelemetrySession(data: TelemetrySession) {
	const response = await QuimbyAPI.post(QUIMBY, TELEMETRY_SESSION, data);
	//TODO cast return type to ?
	return response;
}

//*********************************  TOOLKITS  *********************************

export const apiGetToolkits = async () => {
	try {
		const response = await QuimbyAPI.get(QUIMBY, TOOLKITS, {});
		return response as Module[];
	} catch (e) {
		throw new Error("Api get modules error" + e);
	}
};

export const apiGetToolkitPages = async (toolkitId: string) => {
	try {
		const response = await QuimbyAPI.get(QUIMBY, TOOLKITS + `/activities/${toolkitId}`, {});
		return response as ModulePage[];
	} catch (e) {
		throw new Error("Api get toolkits check in pages error" + e);
	}
};

//*********************************  USER  *********************************

export const apiGetUser = async () => {
	// TODO: cast type to User
	return await QuimbyAPI.get(QUIMBY, USERS);
};

export const apiCreateUser = async (
	firstName: string,
	lastName: string,
	company: string,
	role: string,
	phone: string,
	email: string,
	contactPermission: boolean,
	timezone: string,
) => {
	const reminders = defaults.REMINDERS.map((reminder) => {
		return { ...reminder, id: uuidv4() };
	});
	// TODO cast type to ?
	return await QuimbyAPI.post(QUIMBY, USERS, {
		firstName: firstName,
		lastName: lastName,
		company: company,
		role: role,
		phone: phone,
		email: email,
		contactPermission: contactPermission,
		reminders: reminders,
		setupInfo: defaults.SETUPINFO,
		groups: defaults.GROUPS,
		managedGroups: defaults.MANAGED_GROUPS,
		timezone: timezone,
	});
};

export const apiUpdateUser = async (
	accountId: string,
	userId: string,
	fields: { [key: string]: any },
) => {
	//TODO cast return type to ?
	return await QuimbyAPI.put(QUIMBY, USERS, {
		userId,
		accountId,
		fields,
	});
};

//TODO set input type to ?
export const apiUpdateReminders = async (reminders: any) => {
	try {
		//TODO cast return type to ?
		return await QuimbyAPI.put(QUIMBY, USERS_REMINDERS, { reminders: reminders });
	} catch (e) {
		logger.error("Update Reminders Error: ", e);
		//TODO return something ?
	}
};

//TODO set input type to ?
export const apiUpdateSetupInfo = async (setupInfo: any) => {
	//TODO cast return type to ?
	return await QuimbyAPI.put(QUIMBY, USERS_SETUP, { setupInfo: setupInfo });
};

export const validateUserEmail = async (email: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${USERS}/${email}`);
};

export const validateUserCode = async (code: string) => {
	//TODO cast return type to ?
	return await QuimbyAPI.get(QUIMBY, `${USERS_CODE}/${code}`);
};
