// Helpful AWS CORS stuff: https://docs.aws.amazon.com/AmazonS3/latest/userguide/ManageCorsUsing.html

import { Paper, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { logger } from "../../libs/logLib";
import { TelemetryAction } from "../../model/telemetry";
import { OS, QUIMBY_AWS_PATHS } from "../../shared/constants";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "./QButton";

const osMap = {
	[OS.WINDOWS]: { title: "Windows" },
	[OS.MAC]: { title: "Mac" },
};

interface DownloadTileProps {
	os: string;
}

const DownloadTile: FC<DownloadTileProps> = ({ os }) => {
	const classes = modulesStyles();

	async function clickHandler() {
		logger.telemetry({
			action: TelemetryAction.click,
			event: "start quimby download",
			eventData: { os: os },
		});
		try {
			void fetch(`${QUIMBY_AWS_PATHS.DOWNLOAD_ENDPOINT}/${os}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					// eslint-disable-next-line no-undef
					const a = document.createElement("a");
					a.href = data.url;
					a.download = "";
					a.click();
				});
		} catch (e) {
			logger.error(e);
		}
	}

	return (
		<Paper className={classes.notificationStatusBox}>
			<Stack
				width="100%"
				justifyContent="space-between"
				alignItems="center"
				spacing={modulesTheme.spacing(5)}
				padding={modulesTheme.spacing(2)}
			>
				<Typography variant="h2">{osMap[os].title}</Typography>
				<QButton
					text={`Download for ${osMap[os].title}`}
					variant="contained"
					onClick={clickHandler}
				/>
			</Stack>
		</Paper>
	);
};

export default DownloadTile;
