import { CheckCircle, ExpandLess, ExpandMore, WatchLater } from "@mui/icons-material";
import { Avatar, Grid, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { FC } from "react";
import { NavBarState } from "src/components/home/NavBar";
import { ModulesMilestone } from "src/model/modules/modules.Model";
import { useTypedAppContext } from "../../../libs/typedContextLib";
import { modulesStyles } from "../modulesStyles";
import ModulesStaticListProgress from "./MilestoneStaticListProgress";

export interface MilestoneStaticListItemProps {
	milestone: ModulesMilestone;
	moduleId: string;
	listItemClassName?: string;
	listItemSelectedClassName?: string;
	isActiveMileStone: boolean;
	milestoneIndex: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
	activeIndex: number | null;
}

const MilestoneStaticListItem: FC<MilestoneStaticListItemProps> = ({
	milestone,
	listItemClassName,
	listItemSelectedClassName,
	isActiveMileStone,
	setActiveIndex,
	milestoneIndex,
	moduleId,
}) => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();

	const toggleMilestone = () => {
		const nextIndex = isActiveMileStone ? null : milestoneIndex;
		setActiveIndex(nextIndex);
	};

	return (
		<>
			<ListItem
				className={`
        ${
			listItemClassName
				? listItemClassName
				: navBarState === NavBarState.FULL
					? classes.milestoneListItemMobile
					: classes.milestoneListItem
		}
        ${
			milestone.selected
				? listItemSelectedClassName
					? listItemSelectedClassName
					: classes.milestoneListItemSelected
				: ""
		} 
        ${milestone.faded ? classes.milestoneListItemFaded : ""}`}
			>
				<Grid
					container
					wrap="wrap"
					justifyContent="space-evenly"
					alignItems="center"
					spacing={2}
				>
					<Grid
						item
						mobile={11}
						style={{ alignSelf: "flex-start", maxWidth: "100%", flexBasis: "100%" }}
					>
						<Grid
							container
							wrap="wrap"
							alignItems="center"
							style={{ paddingTop: ".5rem" }}
						>
							<Grid container>
								<Grid item mobile={2} tablet={1}>
									<Avatar
										className={`
											${classes.milestoneListItemAvatar} 
											${milestone.selected ? classes.milestoneListItemAvatarSelected : ""}
										`}
									>
										{milestone.step}
									</Avatar>
								</Grid>
								<Grid
									item
									mobile={milestone.state === undefined ? 10 : 4}
									tablet={7}
								>
									<ListItemText
										className={`${
											milestone.description
												? classes.milestoneListItemTextTopAligned
												: ""
										}`}
										primary={
											<div
												style={{
													cursor: milestone.progress
														? "pointer"
														: "initial",
												}}
												onClick={
													milestone.progress
														? () => toggleMilestone()
														: undefined
												}
											>
												<Typography variant="h5">
													{milestone.title}
												</Typography>
											</div>
										}
									/>
								</Grid>
								<Grid item mobile={6} tablet={4}>
									<ListItemIcon className={classes.milestoneListItemIcon}>
										{milestone.state === "active" && (
											<>
												<Typography
													className={classes.milestoneListItemIconText}
													variant="info1"
												>
													{milestone.percentComplete + "% complete"}
												</Typography>
												<WatchLater
													className={
														classes.milestoneListItemIconWatchLater
													}
												/>
											</>
										)}
										{milestone.state === "locked" && (
											<>
												<Typography
													className={classes.milestoneListItemIconText}
													variant="info1"
												>
													{milestone.timeEstimate}
												</Typography>
											</>
										)}
										{milestone.state === "complete" && (
											<>
												<Typography
													className={classes.milestoneListItemIconText}
													variant="info1"
												>
													Done
												</Typography>
												<CheckCircle
													className={classes.milestoneListItemIconCheck}
												></CheckCircle>
											</>
										)}

										{milestone.progress && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => toggleMilestone()}
											>
												{isActiveMileStone ? (
													<ExpandLess />
												) : (
													<ExpandMore />
												)}
											</div>
										)}
									</ListItemIcon>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item mobile={1}></Grid>
								<Grid item>
									<ListItemText
										className={`${
											milestone.description
												? classes.milestoneListItemTextTopAligned
												: ""
										}`}
										primary={
											<div
												style={{
													cursor: milestone.progress
														? "pointer"
														: "initial",
												}}
												onClick={
													milestone.progress
														? () => toggleMilestone()
														: undefined
												}
											>
												{milestone.description && (
													<Typography variant="body2" paddingTop="8px">
														{milestone.description}
													</Typography>
												)}
											</div>
										}
										secondary={
											<>
												{milestone.progress && isActiveMileStone && (
													<ModulesStaticListProgress
														progress={milestone.progress}
														moduleId={moduleId}
													/>
												)}
											</>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ListItem>
		</>
	);
};

export default MilestoneStaticListItem;
