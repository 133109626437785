import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";
import { ModulesLegacyPage } from "../../../../model/modules/modules.Model";
import { VIDEOS } from "../../../../shared/constants";
import ModulesNav from "../../Shared/ModulesNav";

const useStyles = makeStyles(() => ({
	container: {
		alignSelf: "center",
		textAlign: "center",
		flex: 1,
	},
	button: {
		display: "block",
		padding: "10px 20px",
		borderRadius: 8,
		minWidth: 160,
		textTransform: "none",
		fontSize: 18,
	},
	buttonContainer: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},
}));

interface BoxBreathingSlideProps {
	page: ModulesLegacyPage;
}

const BoxBreathingSlide: FC<BoxBreathingSlideProps> = ({ page }) => {
	const classes = useStyles();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item mobile={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<video id="background-video" autoPlay loop muted style={{ maxWidth: 360 }}>
							<source src={VIDEOS.BOX_BREATHING} type="video/mp4" />
						</video>
					</div>
				</Grid>
				<Grid item mobile={12}>
					<div className={classes.buttonContainer}>
						<Typography paragraph variant="body1">
							Continue to the next screen when you feel ready
						</Typography>
					</div>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default BoxBreathingSlide;
