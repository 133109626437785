import { Checkbox, Grid, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { modulesTheme } from "../Modules/modulesTheme";
import DayPicker from "../reminder/DayPicker";
import TimePicker from "../reminder/TimePicker";

const MORALECHECKIN_ID = "moraleCheckIn";
const AFFIRMATIONS_ID = "affirmations";
const GRATITUDE_ID = "gratitude";
const MODULES_ID = "modules";

export default function ConfigureReminder(props) {
	const { days, setDays, time, setTime, activity, setActivity } = props;

	const [moraleCheckIn, setMoraleCheckIn] = useState(true);
	const [affirmations, setAffirmations] = useState(false);
	const [gratitude, setGratitude] = useState(false);
	const [modules, setModules] = useState(false);

	const handleActivityChange = (id, value) => {
		switch (id) {
			case MORALECHECKIN_ID:
				setMoraleCheckIn(value);
				break;
			case AFFIRMATIONS_ID:
				setAffirmations(value);
				break;
			case GRATITUDE_ID:
				setGratitude(value);
				break;
			case MODULES_ID:
				setModules(value);
				break;
			default:
				break;
		}

		setActivity({
			check_in: id === "moraleCheckIn" ? value : moraleCheckIn,
			affirmations: id === "affirmations" ? value : affirmations,
			gratitude: id === "gratitude" ? value : gratitude,
			modules: id === "modules" ? value : modules,
		});
	};
	useEffect(() => {
		if (activity) {
			setMoraleCheckIn(activity.check_in);
			setAffirmations(activity.affirmations);
			setGratitude(activity.gratitude);
			setModules(activity.modules);
		} else {
			setActivity({
				modules: modules,
				affirmations: affirmations,
				gratitude: gratitude,
				check_in: moraleCheckIn,
			});
		}
	}, []);
	return (
		<>
			<Grid container textAlign="center">
				<Grid item mobile={12}>
					<Typography fontStyle="Merriweather" fontSize={24}>
						Configure Reminder
					</Typography>
				</Grid>
				<Grid item mobile={12} paddingTop={modulesTheme.spacing(5)}>
					<TimePicker defaultTime={time} time={time} setTime={setTime} />
				</Grid>
				<Grid item mobile={12} paddingTop={modulesTheme.spacing(3)}>
					<DayPicker days={days} setDays={setDays} />
				</Grid>
				<Grid item mobile={12} textAlign="start" paddingTop={modulesTheme.spacing(3)}>
					<Typography fontStyle="Nunito" fontSize={15}>
						Activity
					</Typography>
					<Grid container>
						<Grid item mobile={6}>
							<FormControlLabel
								control={
									<Checkbox
										id={MORALECHECKIN_ID}
										color="primary"
										checked={moraleCheckIn}
										onClick={() =>
											handleActivityChange(MORALECHECKIN_ID, !moraleCheckIn)
										}
									/>
								}
								labelPlacement="end"
								label={
									<Typography noWrap={true} sx={{ fontSize: 13 }}>
										Morale Check-In
									</Typography>
								}
							/>
						</Grid>
						<Grid item mobile={6}>
							<FormControlLabel
								control={
									<Checkbox
										id={AFFIRMATIONS_ID}
										color="primary"
										checked={affirmations}
										onClick={() =>
											handleActivityChange(AFFIRMATIONS_ID, !affirmations)
										}
									/>
								}
								labelPlacement="end"
								label={<Typography sx={{ fontSize: 13 }}>Affirmations</Typography>}
							/>
						</Grid>
						<Grid item mobile={6}>
							<FormControlLabel
								control={
									<Checkbox
										id={GRATITUDE_ID}
										color="primary"
										checked={gratitude}
										onClick={() =>
											handleActivityChange(GRATITUDE_ID, !gratitude)
										}
									/>
								}
								labelPlacement="end"
								label={<Typography sx={{ fontSize: 13 }}>Gratitude</Typography>}
							/>
						</Grid>
						<Grid item mobile={6}>
							<FormControlLabel
								control={
									<Checkbox
										id={MODULES_ID}
										color="primary"
										checked={modules}
										onClick={() => handleActivityChange(MODULES_ID, !modules)}
									/>
								}
								labelPlacement="end"
								label={<Typography sx={{ fontSize: 13 }}>Intentions</Typography>}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
