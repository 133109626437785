import { Snackbar } from "@mui/material";
import React, { useEffect, useReducer } from "react";
import AppShell from "../components/AppShell";
import { AppContext } from "../libs/contextLib";
import { WebPushNotifications } from "../libs/pushNotificationsLib";
import { QuimbyServiceWorker } from "../libs/quimbyServiceWorkerLib";
import { TypedAppContextProvider } from "../libs/typedContextLib";
import {
	appReducer,
	getAuthenticatedUserInfo,
	getTelemetrySessionId,
	getUUID,
	initialAppState,
	setSignedOutAppState,
} from "../utility/App.util";
import "./App.css";

const App = () => {
	getTelemetrySessionId();
	const uuid = getUUID();

	const [appState, appDispatch] = useReducer(appReducer, {
		...initialAppState,
		uuid,
	});
	const { toastErrorText } = appState;

	//Start service worker
	useEffect(() => {
		const initServiceWorker = async () => {
			const quimbyServiceWorker = new QuimbyServiceWorker();
			await quimbyServiceWorker.initializeServiceWorker();
			appDispatch({
				type: "setQuimbyServiceWorker",
				quimbyServiceWorker: quimbyServiceWorker,
			});
			const webPushNotifications = new WebPushNotifications();
			await webPushNotifications.initializePushNotifications(quimbyServiceWorker);
			appDispatch({
				type: "setWebPushNotifications",
				webPushNotifications: webPushNotifications,
			});
		};

		void initServiceWorker();
	}, []);

	//Handle ResizeObserver loop error caused by React-WordCloud. It is not a critical error. Remove this when React-WorldCloud is replaced.
	useEffect(() => {
		const handleResizeObserverError = (e) => {
			if (
				e.message === "ResizeObserver loop limit exceeded" ||
				e.message === "ResizeObserver loop completed with undelivered notifications."
			) {
				// eslint-disable-next-line no-undef
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div",
				);
				// eslint-disable-next-line no-undef
				const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay",
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		};
		// eslint-disable-next-line no-undef
		window.addEventListener("error", handleResizeObserverError);
		// eslint-disable-next-line no-undef
		return () => window.removeEventListener("error", handleResizeObserverError);
	}, []);

	return (
		<TypedAppContextProvider>
			<AppContext.Provider
				value={{
					setSignedOutAppState: () => setSignedOutAppState(appDispatch),
					getAuthenticatedUserInfo: () => getAuthenticatedUserInfo(appDispatch),
					appState,
					appDispatch,
				}}
			>
				<AppShell />
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={6000}
					open={!!toastErrorText && toastErrorText.length > 1}
					onClose={() => appDispatch({ type: "setErrorText", toastErrorText: undefined })}
					message={toastErrorText}
				/>
			</AppContext.Provider>
		</TypedAppContextProvider>
	);
};

export default App;
