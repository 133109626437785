import { Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import backgroundImage from "../../../images/modules/exerciseBackground.svg";
import { ModulesExercisePage } from "../../../model/modules/modules.Model";
import QButtonStack from "../../common/QButtonStack";
import InfoBox from "../Shared/InfoBox";
import LinkButton from "../Shared/LinkButton";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";
interface ExerciseTemplateProps {
	page: ModulesExercisePage;
}

const ExerciseTemplate: FC<ExerciseTemplateProps> = ({ page }) => {
	const [selectedExercise, setSelectedExercise] = useState(0);
	const [transcriptVisible, setTranscriptVisible] = useState(false);
	const classes = modulesStyles();
	const exerciseList = [...page.exercises];
	const buttonList = exerciseList
		.map((exercise, index) => {
			return {
				onClick: () => setSelectedExercise(index),
				text: exercise.selectButtonText,
				noFill: true,
				index: index,
			};
		})
		.filter((item) => item.index !== selectedExercise);
	useEffect(() => {
		const videoElement: HTMLVideoElement = document.getElementById(
			"Video_Element",
		) as HTMLVideoElement;
		if (videoElement) {
			videoElement.load();
		}
		const audioElement: HTMLAudioElement = document.getElementById(
			"Audio_Element",
		) as HTMLAudioElement;
		if (audioElement) {
			audioElement.load();
		}
	}, [selectedExercise, exerciseList]);
	return (
		<ModulesNav
			pageTitle={page.pageTitle}
			buttonConfig={page.buttonConfig}
			backgroundImage={backgroundImage}
		>
			<Stack direction="column" spacing="10px" justifyContent="center" alignItems="center">
				<div className={classes.modulesExerciseContainer}>
					<Stack direction="column" justifyContent="center" spacing="10px">
						<Typography variant="h5">
							{page.exercises[selectedExercise].title}
						</Typography>
						{page.exercises[selectedExercise].description && (
							<Typography
								variant="body"
								dangerouslySetInnerHTML={{
									__html: page.exercises[selectedExercise].description ?? "",
								}}
							/>
						)}
						{isFileType(page.exercises[selectedExercise].filePath, ".mp3") && (
							<audio
								id="Audio_Element"
								className={classes.modulesAudioPlayer}
								controls
							>
								<source
									src={page.exercises[selectedExercise].filePath}
									type="audio/mpeg"
								/>
							</audio>
						)}
						{isFileType(page.exercises[selectedExercise].filePath, [
							".png",
							".svg",
							".jpg",
							".gif",
						]) && (
							<img
								id="Image_Element"
								className={classes.modulesExerciseImage}
								src={page.exercises[selectedExercise].filePath}
								alt=""
							/>
						)}
						{isFileType(page.exercises[selectedExercise].filePath, ".mp4") && (
							<video
								id="Video_Element"
								className={classes.modulesVideoPlayer}
								controls={true}
							>
								<source
									src={page.exercises[selectedExercise].filePath}
									type="video/mp4"
								/>
							</video>
						)}
						{page.exercises[selectedExercise].transcript && (
							<LinkButton
								onClick={() => setTranscriptVisible(!transcriptVisible)}
								text={
									transcriptVisible
										? "Hide Meditation Transcript"
										: "Meditation Transcript"
								}
								underline="always"
								variant="body1"
							/>
						)}
						{transcriptVisible && (
							<Typography
								variant="body"
								dangerouslySetInnerHTML={{
									__html: page.exercises[selectedExercise].transcript,
								}}
							/>
						)}
						{page.exercises[selectedExercise].infoBoxText && (
							<InfoBox text={page.exercises[selectedExercise].infoBoxText} />
						)}
					</Stack>
				</div>
				{buttonList && <QButtonStack buttons={buttonList} justifyContent="center" />}
			</Stack>
		</ModulesNav>
	);
};

const isFileType = (filePath: string, ext: string | string[]) => {
	if (!Array.isArray(ext)) {
		ext = [ext];
	}
	let found = false;
	ext.forEach((item) => {
		if (filePath.includes(item)) {
			found = true;
		}
	});

	return found;
};

export default ExerciseTemplate;
