import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { modulesStyles } from "../../components/Modules/modulesStyles";
import { NavBarState } from "../../components/home/NavBar";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { modulesColors } from "../Modules/modulesTheme";
import { QButtonProps } from "../common/QButton";
import QButtonStack from "../common/QButtonStack";
import "./styles.css";

const localStyles = {
	content: {
		borderRadius: 4,
		borderColor: modulesColors.neutrals._600,
		borderWidth: 1,
		height: 300,
		width: "100%",
		backgroundColor: "white",
		padding: 10,
		display: "flex",
		flexDirection: "column",
		marginTop: 20,
	},
	input: {
		minHeight: 100,
		flex: 1,
		flexGrow: 1,
		alignSelf: "stretch",
		justifySelf: "stretch",
		borderColor: "white",
		outline: "none",
		boxShadow: "none",
		border: 0,
		verticalAlign: "top",
		textAlignVertical: "top",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "16px",
		fontFamily: `"Nunito","Helvetica","Arial",sans-serif`,
		fontWeight: 400,
		lineHeight: 1.5,
	},
};

interface InviteToTeamProps {
	setInvites: (invites: string[]) => void;
	setStep: (skip: boolean) => void;
}

const InviteToTeam: FC<InviteToTeamProps> = ({ setInvites, setStep }) => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();

	const buttonConfig: QButtonProps[] = [
		{
			text: "Skip for now",
			variant: "outlined",
			onClick: () => {
				setStep(true);
			},
		},
		{
			text: "Invite Team",
			variant: "contained",
			onClick: () => {
				setStep(false);
			},
		},
	];

	return (
		<div
			className={navBarState === NavBarState.FULL ? classes.pageBaseMobile : classes.pageBase}
		>
			<Stack
				spacing="20px"
				height="100%"
				justifyContent="center"
				alignItems="center"
				maxWidth="800px"
				alignSelf="center"
			>
				<Typography variant="h5">
					Add the emails for people you would like to invite to Quimby.
				</Typography>
				<EmailInput setInvites={setInvites} />
				<QButtonStack buttons={buttonConfig} />
			</Stack>
		</div>
	);
};

export default InviteToTeam;

interface EmailInputState {
	items: string[];
	value: "";
	error: string | null;
}

interface EmailInputProps {
	setInvites: (invites: string[]) => void;
}

class EmailInput extends React.Component<EmailInputProps, EmailInputState> {
	constructor(props: EmailInputProps) {
		super(props);
		const state: EmailInputState = {
			items: [],
			value: "",
			error: null,
		};
		this.state = state;
	}
	//@ts-expect-error unknown type
	handleKeyDown = (evt) => {
		if (["Enter", "Tab", ",", " "].includes(evt.key)) {
			evt.preventDefault();
			const value = this.state.value.trim();
			if (value && this.isValid(value)) {
				this.setState({
					items: [...this.state.items, this.state.value],
					value: "",
				});
				this.props.setInvites([...this.state.items, this.state.value]);
			}
		}
	};
	//@ts-expect-error unknown type
	handleChange = (evt) => {
		this.setState({
			value: evt.target.value,
			error: null,
		});
	};
	//@ts-expect-error unknown type
	handleDelete = (item) => {
		this.setState({
			items: this.state.items.filter((i) => i !== item),
		});
	};
	//@ts-expect-error unknown type
	handlePaste = (evt) => {
		evt.preventDefault();
		const paste = evt.clipboardData.getData("text");
		// eslint-disable-next-line no-useless-escape
		const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
		if (emails) {
			const toBeAdded = emails.filter((email: string) => !this.isInList(email));
			this.setState({
				items: [...this.state.items, ...toBeAdded],
			});
		}
	};
	isValid(email: string) {
		let error = null;
		if (this.isInList(email)) {
			error = `${email} has already been added.`;
		}
		if (!this.isEmail(email)) {
			error = `${email} is not a valid email address.`;
		}
		if (error) {
			this.setState({ error });
			return false;
		}
		return true;
	}
	isInList(email: string) {
		return this.state.items.includes(email);
	}
	isEmail(email: string) {
		// eslint-disable-next-line no-useless-escape
		return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
	}
	render() {
		return (
			<>
				{/**@ts-expect-error unknown type */}
				<div style={localStyles.content}>
					<div>
						{this.state.items.map((item) => (
							<div className="tag-item" key={item}>
								<span style={{ color: "white" }}>{item}</span>
								<button
									type="button"
									className="button"
									onClick={() => this.handleDelete(item)}
								>
									&times;
								</button>
							</div>
						))}
					</div>
					<textarea
						style={localStyles.input}
						value={this.state.value}
						onKeyDown={this.handleKeyDown}
						onChange={this.handleChange}
						onPaste={this.handlePaste}
					/>
				</div>
				{this.state.error && <p className="error">{this.state.error}</p>}
			</>
		);
	}
}
