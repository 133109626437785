import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { modulesTheme } from "../Modules/modulesTheme";
import WebPushNotification from "../webPushNotifications/WebPushNotificationsStatus";

interface BrowserNotificationConfigProps {}

const BrowserNotificationConfig: FC<BrowserNotificationConfigProps> = () => {
	return (
		<Stack spacing={modulesTheme.spacing(2)} alignItems="center" justifyContent="center">
			<Typography variant="h5">Configure your browser notifications</Typography>
			<Typography variant="body1">
				To ensure you get notified at the times of your reminders, please ensure you have
				allowed notifications from Quimby.
			</Typography>
			<WebPushNotification />
			<Typography
				variant="body1"
				dangerouslySetInnerHTML={{
					__html: `You will also need to <a href='https://www.quimbyapp.com/browser-notification-faq'>allow browser notifications on your system.</a>`,
				}}
			/>
		</Stack>
	);
};

export default BrowserNotificationConfig;
