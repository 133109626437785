import { User } from "@model/entities";
import CloseIcon from "@mui/icons-material/Close";
import {
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Stack,
	ThemeProvider,
	Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modulesStyles } from "src/components/Modules/modulesStyles";
import { modulesColors, modulesTheme } from "src/components/Modules/modulesTheme";
import QButton from "src/components/common/QButton";
import QSelect from "src/components/common/QSelect";
import { NavBarState } from "src/components/home/NavBar";
import { apiGetToolkits, apiUpgradeSubscription } from "src/libs/apiLib";
import { useAppContext } from "src/libs/contextLib";
import { logger } from "src/libs/logLib";
import { useTypedAppContext } from "src/libs/typedContextLib";
import { BillingType } from "src/model/account/account.Model";
import { Module, ModuleData, ModuleType } from "src/model/modules/modules.Model";
import { generateReflectionPathName } from "src/model/reflection/reflection.Manager";
import Frame1 from "../../src/images/demo-toolkits/Frame 1.svg";
import Frame2 from "../../src/images/demo-toolkits/Frame 2.svg";
import Frame3 from "../../src/images/demo-toolkits/Frame 3.svg";
import Frame4 from "../../src/images/demo-toolkits/Frame 4.svg";
import Frame5 from "../../src/images/demo-toolkits/Frame 5.svg";
import Frame6 from "../../src/images/demo-toolkits/Frame 6.svg";

interface MenuItemMetaData {
	value: string;
	menuLabel: string;
}

interface filterDataType {
	value: string;
	name: string;
}

const DemoToolkits = [
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame3,
		title: "Belly breathing",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame2,
		title: "Create an intention for your business",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame5,
		title: "Calm your mind with the 5 senses exercises",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame4,
		title: "Gratitude meditation",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame1,
		title: "Team activity",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
	{
		description: "",
		moduleId: "a1b2c3d4-e5f6-4a5b-9c8d-1a2b3c4d5e6f",
		thumbnailPath: Frame6,
		title: "More tools...",
		version: "0.0.1",
		type: ModuleType.TOOLKIT,
	},
];

const MyToolkits = () => {
	const classes = modulesStyles();

	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	//@ts-expect-error no Types
	const { appState, getAuthenticatedUserInfo, appDispatch } = useAppContext();
	const history = useNavigate();

	const [toolkits, setToolkits] = useState<Module[]>();
	const [demoToolKits, setDemoToolKits] = useState<ModuleData[]>();
	const [renderToolkits, setRenderToolkits] = useState<Module[]>();
	const [searchParam, setSearchParam] = useState("");
	const [upgrading, setUpgrading] = useState(false);
	const [typeMenus, setTypeMenus] = useState<MenuItemMetaData[]>();
	const [durationFilterValue, setDurationFilterValue] = useState("");
	const [typeFilterValue, setTypeFilterValue] = useState("");

	const [durationMenus, setDurationMenus] = useState<MenuItemMetaData[]>();

	const authenticatedUserInfo: User = appState.authenticatedUserInfo;

	const fetchToolkits = async () => {
		const kits = await apiGetToolkits();
		setToolkits(
			kits.sort((a, b) => {
				const titleA = a.title.toUpperCase();
				const titleB = b.title.toUpperCase();
				return titleA.localeCompare(titleB);
			}),
		);
		if (kits.length === 0) {
			setDemoToolKits(DemoToolkits);
		}
		setRenderToolkits(kits);
		const tMenus = [
			{
				value: "all",
				menuLabel: "All",
			},
		];
		const dMenus = [
			{
				value: "all",
				menuLabel: "All",
			},
		];
		for (let i = 0; i < kits.length; i++) {
			const tagsType = kits[i].tags?.[1].split(":");

			if (tagsType?.[0] === "type") {
				if (!tMenus.map((item) => item.value).includes(tagsType[1])) {
					tMenus.push({
						value: tagsType[1],
						menuLabel: tagsType[1],
					});
				}
			}

			const secondTagsType = kits[i].tags?.[0].split(":");

			if (secondTagsType?.[0] === "duration") {
				if (!dMenus.map((item) => item.value).includes(secondTagsType[1])) {
					dMenus.push({
						value: secondTagsType[1],
						menuLabel: secondTagsType[1],
					});
				}
			}
		}
		setTypeMenus(tMenus);
		setDurationMenus(dMenus);
	};

	useEffect(() => {
		void fetchToolkits();
	}, []);

	const startTrial = async () => {
		setUpgrading(true);
		if (!authenticatedUserInfo.subscription) {
			logger.error("Upgrade Error: User has no subscription");
			return;
		}
		const subscriptions = Array.isArray(authenticatedUserInfo.subscription)
			? authenticatedUserInfo.subscription
			: [authenticatedUserInfo.subscription];
		const subscription = subscriptions.find(
			(subs) => !!subs.subscriptionId && subs.subscriptionId === "S:NO_SUBSCRIPTION",
		);

		// First we upgrade the account
		const companyName = subscription?.company
			? subscription.company
			: `${authenticatedUserInfo.firstName}-${authenticatedUserInfo.lastName}`;
		try {
			const response = await apiUpgradeSubscription(companyName, BillingType.Monthly, 1);
			if (response.status === "ERROR") {
				throw new Error(`${response.error}`);
			}
		} catch (e) {
			logger.error("Upgrade error:", e);
			setUpgrading(false);
		}
		await getAuthenticatedUserInfo(appDispatch);
		setUpgrading(false);
		// setModuleState(ModuleState.NEW);
		void fetchToolkits();
	};

	const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setSearchParam(e.target.value);

		setDurationFilterValue("all");
		setTypeFilterValue("all");
		const filteredToolkits = toolkits?.filter((toolkit) =>
			toolkit.title.toLowerCase().includes(e.target.value.toLowerCase()),
		);

		setRenderToolkits(filteredToolkits);
	};

	const handleFilterChange = (selectedValue: filterDataType) => {
		const durationFiltered =
			typeFilterValue !== "all" && durationFilterValue !== "all"
				? toolkits?.filter(
						(toolkit) =>
							toolkit.tags &&
							toolkit.tags.some((tag) =>
								tag.includes(
									selectedValue.name === "duration"
										? typeFilterValue
										: durationFilterValue,
								),
							),
					)
				: toolkits;

		const typeData =
			selectedValue.value !== "all"
				? toolkits?.filter(
						(toolkit) =>
							toolkit.tags &&
							toolkit.tags.some((tag) => tag.includes(selectedValue.value)),
					)
				: toolkits;

		const intersectionArray = durationFiltered?.filter((durationToolkit) =>
			typeData?.some((typeToolkit) => durationToolkit === typeToolkit),
		);
		setRenderToolkits(intersectionArray);
	};

	const launchToolkitCheckIn = (activityPage: string) => {
		history(generateReflectionPathName({ toolkit: true, activity: activityPage }, "", false));
	};

	return (
		<>
			<ThemeProvider theme={modulesTheme}>
				<div
					className={
						navBarState === NavBarState.SHIFT
							? classes.pageBase
							: classes.pageBaseMobile
					}
				>
					<Stack height="100%" spacing={modulesTheme.spacing(2)}>
						<Typography variant="h1">My Toolkits</Typography>
						<Typography variant="body">
							Tools for you to train a new mindset and a new way of being.
						</Typography>
						<Grid container flexDirection="row">
							{(toolkits?.length ?? 0) > 0 ? (
								<Grid container>
									<Grid item mobile={12} marginBottom="2rem">
										<Paper
											component="form"
											sx={{
												p: "2px 4px",
												display: "flex",
												alignItems: "center",
												maxWidth: 320,
											}}
										>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Search Toolkits"
												inputProps={{ "aria-label": "search toolkits" }}
												value={searchParam}
												onChange={(e) => handleSearch(e)}
											/>
											<IconButton
												type="button"
												sx={{ p: "10px" }}
												aria-label="search"
												onClick={() => {
													setSearchParam("");
													setRenderToolkits(toolkits);
												}}
											>
												<CloseIcon />
											</IconButton>
										</Paper>
									</Grid>
									<Grid item container mobile={12}>
										<div style={{ paddingRight: "1rem" }}>
											<QSelect
												menus={durationMenus}
												handleFilterChange={handleFilterChange}
												label="Duration"
												setValue={setDurationFilterValue}
												value={durationFilterValue}
											/>
										</div>
										<div style={{ paddingLeft: "1rem" }}>
											<QSelect
												menus={typeMenus}
												handleFilterChange={handleFilterChange}
												label="All"
												setValue={setTypeFilterValue}
												value={typeFilterValue}
											/>
										</div>
									</Grid>
									<Grid container spacing={8}>
										{renderToolkits?.map((toolkit, index) => (
											<Grid
												item
												mobile={12}
												navBar={6}
												tablet={4}
												key={index}
												textAlign="center"
												marginTop="2rem"
												alignItems="center"
												display="flex"
												flexDirection="column"
												justifyContent="center"
												wrap="wrap"
											>
												<div
													style={{
														borderRadius: "1rem",
														backgroundColor:
															modulesColors.primary.light,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														maxWidth: "fit-content",
													}}
													className={classes.toolkitGridItem}
													onClick={() => {
														launchToolkitCheckIn(toolkit.moduleId);
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															flexWrap: "wrap",
														}}
													>
														<img
															src={toolkit.thumbnailPath}
															alt={toolkit.title}
															style={{
																width: "250px",
																height: "200px",
																objectFit: "contain",
																padding: ".5rem",
															}}
														/>
													</div>
												</div>
												<QButton
													variant="text"
													text={toolkit.title}
													onClick={() => {
														launchToolkitCheckIn(toolkit.moduleId);
													}}
												/>
											</Grid>
										))}
										<Grid item mobile={12}>
											{(renderToolkits?.length ?? 0) <= 0 && (
												<Typography
													style={{ marginTop: "2rem" }}
													variant="h3"
												>
													No Toolkit found!
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid item mobile={12}>
										<Typography variant="body">
											You don't have access. Upgrade your account to get
											access.
										</Typography>
									</Grid>
									<Grid item mobile={12} marginTop={modulesTheme.spacing(3)}>
										{upgrading ? (
											<CircularProgress />
										) : (
											<QButton
												onClick={startTrial}
												text={"Start a 14 day trial"}
											/>
										)}
									</Grid>
									<Grid container spacing={8}>
										{demoToolKits?.map((toolkit, index) => (
											<Grid
												item
												mobile={12}
												tablet={4}
												key={index}
												textAlign="center"
												marginTop="2rem"
												alignItems="center"
												display="flex"
												flexDirection="column"
												justifyContent="center"
											>
												<div
													style={{
														borderRadius: "1rem",
														backgroundColor:
															modulesColors.primary.light,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														opacity: 0.7,
														maxWidth: "fit-content",
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															flexWrap: "wrap",
															cursor: "not-allowed",
														}}
													>
														<img
															src={toolkit.thumbnailPath}
															alt={toolkit.title}
															style={{
																width: "250px",
																height: "200px",
																objectFit: "contain",
																padding: ".5rem",
															}}
														/>
													</div>
												</div>
												<QButton
													variant="text"
													disabled={true}
													text={toolkit.title}
												/>
											</Grid>
										))}
									</Grid>
								</>
							)}
						</Grid>
					</Stack>
				</div>
			</ThemeProvider>
		</>
	);
};

export default MyToolkits;
