import { Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import React, { ChangeEvent, FC, FormEvent } from "react";
import { useSetupContext } from "../../libs/typedContextLib";
import { modulesTheme } from "../Modules/modulesTheme";
import DataDisclaimer from "./DataDisclaimer";

const goals = {
	statement1: "Share feedback with management.",
	statement2: "Measure and be more mindful of my mood.",
	statement3: "Increase emotional resilience and intelligence.",
	statement4: "Know how my team is doing.",
	statement5: "Manage anxiety and build confidence in my career.",
};

const SetupGoals: FC = () => {
	const { setupGoals, setSetupGoals } = useSetupContext();

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.currentTarget;

		const newGoals = [...setupGoals];
		newGoals.splice(Number(value), 1, { ...setupGoals[Number(value)], isChecked: checked });

		setSetupGoals(newGoals);
	};

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
	}

	return (
		<Stack spacing={modulesTheme.spacing(2)} justifyContent="center" alignItems="center">
			<Typography variant="h6">
				We would love to learn more about you to improve your experience. What&apos;s your
				goal for using this app? Select all that apply.
			</Typography>
			<div>
				<form onSubmit={handleSubmit} noValidate>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									value={0}
									id="goal0"
									name="goal0"
									checked={setupGoals[0].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{goals.statement1}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={1}
									color="primary"
									id="goal1"
									name="goal1"
									checked={setupGoals[1].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{goals.statement2}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={2}
									color="primary"
									id="goal2"
									name="goal2"
									checked={setupGoals[2].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{goals.statement3}</Typography>}
						/>
					</Grid>
					<Grid item mobile={12}>
						<FormControlLabel
							control={
								<Checkbox
									value={3}
									color="primary"
									id="goal3"
									name="goal3"
									checked={setupGoals[3].isChecked}
									onChange={onChangeHandler}
								/>
							}
							label={<Typography variant="body1">{goals.statement4}</Typography>}
						/>
					</Grid>
				</form>
			</div>
			<DataDisclaimer />
		</Stack>
	);
};

export default SetupGoals;
