import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, MouseEventHandler } from "react";
import { modulesTheme } from "../Modules/modulesTheme";

const useStyles = makeStyles(() => ({
	tile: {
		margin: modulesTheme.spacing(2),
		width: 240,
		height: 135,
		borderRadius: 20,
		textTransform: "none",
		fontSize: 24,
		alignSelf: "center",
	},
	small: {
		width: 160,
		height: 90,
		fontSize: 16,
		margin: modulesTheme.spacing(1),
	},
}));

interface TileButtonProps {
	onClick: MouseEventHandler;
	text: string;
	small?: boolean;
}

const TileButton: FC<TileButtonProps> = ({ onClick, text, small = false }) => {
	const classes = useStyles();
	return (
		<Button
			color="primary"
			size="large"
			variant="contained"
			onClick={onClick}
			className={`${classes.tile} ${small && classes.small}`}
		>
			{text}
		</Button>
	);
};

export default TileButton;
