const WEEKDAYS = "Weekdays";
const WEEKENDS = "Weekends";
const EVERYDAY = "Every day";
const ONLY_ONCE = "Only once";
const DAYS = [
	{ shortName: "Sun", longName: "Sunday" },
	{ shortName: "Mon", longName: "Monday" },
	{ shortName: "Tue", longName: "Tuesday" },
	{ shortName: "Wed", longName: "Wednesday" },
	{ shortName: "Thu", longName: "Thursday" },
	{ shortName: "Fri", longName: "Friday" },
	{ shortName: "Sat", longName: "Saturday" },
];

export function formatTime(time) {
	const [hours, minutes] = time.split(":");
	let formattedHour = +hours > 12 ? +hours % 12 : +hours === 0 ? 12 : +hours;
	let suffix = +hours < 12 ? "am" : "pm";
	return `${formattedHour}:${minutes} ${suffix}`;
}

// Single day: Full name
// Multiple days: 3-letter abbreviation
// M-F: Weekdays
// Sa-Su: Weekends
// All days: Every day
// Weekends/Weekdays, Mon
// No days: Only once
export function formatDays(days) {
	let formattedDays = "";

	const weekdaysFlag = days[1] && days[2] && days[3] && days[4] && days[5];
	const weekendsFlag = days[0] && days[6];

	// Check for every day case
	if (weekdaysFlag && weekendsFlag) {
		return EVERYDAY;
	}

	// Check for weekends case
	if (weekendsFlag) {
		formattedDays += WEEKENDS;
		formattedDays = days.reduce((result, day, index) => {
			if (index !== 0 && index !== 6) {
				if (day) {
					result += `, ${DAYS[index].shortName}`;
				}
			}
			return result;
		}, formattedDays);

		return formattedDays;
	}

	//check for weekdays case
	else if (weekdaysFlag) {
		formattedDays += WEEKDAYS;
		if (days[0]) {
			formattedDays += `, ${DAYS[0].shortName}`;
		} else if (days[6]) {
			formattedDays += `, ${DAYS[6].shortName}`;
		}

		return formattedDays;
	}

	//check for individual days
	let firstDayIndex = -1;

	for (let i = 0; i < days.length; i++) {
		if (days[i]) {
			if (firstDayIndex === -1) {
				firstDayIndex = i;
			} else {
				// there is more than one day selected
				formattedDays += `, ${DAYS[i].shortName}`;
			}
		}
	}

	// No days were selected
	if (firstDayIndex === -1) {
		return ONLY_ONCE;
	}

	// There was only a single day so use the long name
	if (formattedDays === "") {
		formattedDays = DAYS[firstDayIndex].longName;
	}
	// There are multiple days so insert short name of first day at beginning
	else {
		formattedDays = `${DAYS[firstDayIndex].shortName}${formattedDays}`;
	}

	return formattedDays;
}
