import { UserSubscriptionData } from "@model/entities";
import { Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { UPDATE_SUBSCRIPTION_ACTION } from "src/shared/constants";
import { apiGetGroupTree, apiUpdateSubscription, apiUpgradeSubscription } from "../../libs/apiLib";
import { useAppContext } from "../../libs/contextLib";
import { logger } from "../../libs/logLib";
import { useCarouselContext } from "../../libs/typedContextLib";
import { TelemetryAction } from "../../model/telemetry";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";
import EmailInviteControl from "../email/EmailInviteControl";
import { EmailChip } from "../email/EmailInviteControl.utils";

const SetupInviteTeam: FC = () => {
	const [emailChips, setEmailChips] = useState<EmailChip[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState(false);

	const {
		//@ts-expect-error not typed
		appState: { authenticatedUserInfo },
		//@ts-expect-error not typed
		appDispatch,
		//@ts-expect-error not typed
		getAuthenticatedUserInfo,
	} = useAppContext();

	const { goForward } = useCarouselContext();

	const createSubscription = async (skipTeam: boolean) => {
		const invites = emailChips.map((chip) => chip.text);

		const noSubscription = authenticatedUserInfo.subscription.find(
			(sub: UserSubscriptionData) => sub.subscriptionId === "S:NO_SUBSCRIPTION",
		);

		const companyName =
			noSubscription.company ??
			`${authenticatedUserInfo.firstName}-${authenticatedUserInfo.lastName}`;
		try {
			setIsLoading(true);
			//We upgrade user to team
			const response = await apiUpgradeSubscription(
				companyName,
				"monthly",
				skipTeam ? 1 : invites.length + 1,
			);
			console.log(response);
			if (response.data.SK) {
				const subscriptionId = response.data.SK;
				//Next: if everything went fine either invite users or skip
				if (!skipTeam) {
					let data = {};
					try {
						const groupTree = await apiGetGroupTree(subscriptionId);
						data = {
							[UPDATE_SUBSCRIPTION_ACTION.KEY]:
								UPDATE_SUBSCRIPTION_ACTION.ADD_MEMBERS,
							company: companyName,
							newMembers: invites,
							managerName: {
								...authenticatedUserInfo,
							},
							groupId: groupTree.groupTree[0].children[0].SK,
						};
					} catch (e) {
						logger.error("Get Group Tree Failed:", e);
						data = {
							[UPDATE_SUBSCRIPTION_ACTION.KEY]:
								UPDATE_SUBSCRIPTION_ACTION.ADD_MEMBERS,
							company: companyName,
							newMembers: invites,
							managerName: {
								...authenticatedUserInfo,
							},
						};
					}
					await apiUpdateSubscription(subscriptionId, data);
				}
			}
			await getAuthenticatedUserInfo(appDispatch);
			logger.telemetry({
				action: TelemetryAction.pageview,
				event: "Team Manager Sign Up Complete",
			});
			setIsLoading(false);
		} catch (e) {
			logger.error("Error creating subscription:", e);
		} finally {
			setIsLoading(false);
			goForward();
		}
	};

	return (
		<Stack spacing={modulesTheme.spacing(2)} justifyContent="center" alignItems="center">
			<Typography variant="h2">Add Team Members</Typography>
			<Typography variant="body">
				Add the email addresses for team members you would like to invite to Quimby. Press
				Enter, Comma or Space to separate different emails.
			</Typography>
			<EmailInviteControl
				emailChips={emailChips}
				setEmailChips={setEmailChips}
				error={error}
				setError={setError}
				emails={[authenticatedUserInfo.email]}
				info={`To receive team insights, invite 3 or more people.`}
			/>
			<Stack direction="row" spacing={modulesTheme.spacing(3)}>
				<QButton
					onClick={() => {
						void createSubscription(true);
					}}
					text="Skip for now"
					variant="outlined"
					loading={isLoading}
					disabled={isLoading}
				/>
				<QButton
					onClick={() => {
						void createSubscription(false);
					}}
					text="Invite team members"
					loading={isLoading}
					disabled={isLoading}
				/>
			</Stack>
		</Stack>
	);
};

export default SetupInviteTeam;
