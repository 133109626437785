import { EDIT_RECORD_KEY } from "../../shared/constants";
import PersonGroup from "./PersonGroup";
import React from "react";

export default function ManagerPersonGroup({
	people,
	editGroup,
	updateGroupEditRecord,
	killEditMode,
	canEditGroupsSection,
}) {
	return (
		<PersonGroup
			title="Managers"
			people={people}
			setPeople={async (value) => {
				await editGroup(EDIT_RECORD_KEY.MANAGERS, value);
			}}
			updateGroupEditRecord={updateGroupEditRecord}
			editLabel="Add managers"
			lonelyMessage="Add at least one manager for the group."
			killEditMode={killEditMode}
			canEditGroupsSection={canEditGroupsSection}
		/>
	);
}
