import { useState } from "react";

export function useFormFields(initialState) {
	const [fields, setValues] = useState(initialState);
	return [
		fields,
		function (event, newFields) {
			let value =
				event.target.type === "checkbox" ? event.target.checked : event.target.value;
			setValues({
				...fields,
				...newFields,
				[event.target.id]: value,
			});
		},
	];
}

export function useErrorFields(initialState) {
	const [fields, setValues] = useState(initialState);

	return [
		fields,
		function (id, value) {
			setValues({
				...fields,
				[id]: value,
			});
		},
	];
}

export function useFormFields2(initialState) {
	const [fields, setValues] = useState(initialState);

	return [
		fields,
		function (event) {
			let value =
				event.target.type === "checkbox" ? event.target.checked : event.target.value;

			// TODO: Do this in a less hacky way
			value = event.target.id === "email" ? value.toLowerCase() : value;

			setValues({
				...fields,
				[event.target.id]: { value, isTouched: true },
			});
		},
		function (newFields) {
			setValues({ ...fields, ...newFields });
		},
	];
}
