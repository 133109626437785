import React from "react";
import { MEMBER_STATUS } from "../../shared/constants";
import { Chip } from "@mui/material";
import { useStyles } from "./PersonChip.styles";

export default function PersonChip({ name, status, isEditMode, onDelete }) {
	const classes = useStyles();

	const statusColor = {
		[MEMBER_STATUS.MEMBER]: classes.member,
		[MEMBER_STATUS.PENDING]: classes.pending,
		[MEMBER_STATUS.NEEDS_APPROVAL]: classes.needsApproval,
	};

	return isEditMode ? (
		<Chip label={name} onDelete={onDelete} className={classes.editModeChip} />
	) : (
		<Chip label={name} className={statusColor[status]} />
	);
}
