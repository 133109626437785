import { Button } from "@mui/material";
import React, { FC, MouseEventHandler } from "react";
import GOOGLE_LOGO from "../../images/googleLogo.png";

interface GoogleButtonProps {
	text: string;
	onClick: MouseEventHandler;
}

const GoogleButton: FC<GoogleButtonProps> = ({ text, onClick }) => {
	return (
		<>
			<Button
				style={{
					height: "40px",
					backgroundColor: "#FFFFFF",
					color: "#5F5F5F",
					textTransform: "none",
					fontFamily: "Roboto",
					fontWeight: "bold",
					borderRadius: "5px",
					border: "1px",
					borderColor: "#5F5F5F",
					borderStyle: "solid",
				}}
				onClick={onClick}
				startIcon={
					<img
						style={{ width: "18px", height: "18px", margin: "15px" }}
						src={GOOGLE_LOGO}
						alt="Google Icon"
					/>
				}
			>
				{text}
			</Button>
		</>
	);
};

export default GoogleButton;
