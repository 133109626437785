import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";

interface MenuItemMetaData {
	value: string;
	menuLabel: string;
}

interface filterDataType {
	value: string;
	name: string;
}

interface QSelectProps {
	label: string;
	menus?: MenuItemMetaData[];
	handleFilterChange?: (selectedValue: filterDataType) => void;
	value: string;
	setValue: (selectedValue: string) => void;
}

const useStyles = makeStyles(() => ({
	selectBefore: {
		"&:before": {
			borderBottom: "none",
		},
		"&:hover:after": {
			borderBottom: "none",
		},
		"&:active:after": {
			borderBottom: "none",
		},
		"&:focus:after": {
			borderBottom: "none",
		},
	},
}));

const QSelect: FC<QSelectProps> = ({
	menus,
	label,
	value,
	setValue,
	handleFilterChange = () => {},
}) => {
	const classes = useStyles();

	const handleChange = (event: SelectChangeEvent) => {
		setValue(event.target.value as string);
		handleFilterChange(event.target);
	};

	return (
		<>
			<Box sx={{ minWidth: 120 }}>
				<FormControl fullWidth style={{ borderBottom: "none" }}>
					<InputLabel id="demo-simple-select-label">{label}</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={value}
						label={label}
						name={label === "All" ? "type" : label.toLowerCase()}
						onChange={handleChange}
						className={classes.selectBefore}
						style={{
							backgroundColor: "white",
							borderRadius: "1.5rem",
							textAlign: "center",
							border: "none",
						}}
					>
						{menus?.map((menu) => {
							return <MenuItem value={menu?.value}>{menu?.menuLabel}</MenuItem>;
						})}
					</Select>
				</FormControl>
			</Box>
		</>
	);
};

export default QSelect;
