import ForgotPasswordVerificationComponent from "./ForgotPasswordVerificationComponent";
import React from "react";
import SignInTemplate from "./SignInTemplate";

export default function SignIn() {
	return (
		<SignInTemplate>
			<ForgotPasswordVerificationComponent />
		</SignInTemplate>
	);
}
