import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import GroupsDataTile from "./GroupsDataTile";
import CreateGroupButton from "./CreateGroupButton";
import GroupsTreeControl from "./GroupsTreeControl";

const useStyles = makeStyles((theme) => ({
	groupSideBarRoot: {
		background: theme.palette.lightGray,
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		marginTop: "4px",
	},
	topPart: {
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(4),
		borderBottom: "2px solid lightGray",
		borderTop: "2px solid lightGray",
		width: "100%",
	},
	tree: {
		padding: theme.spacing(4),
		alignSelf: "flex-start",
	},
}));

export default function GroupSidebar({
	selectedGroupId,
	groups,
	groupsTree,
	createNewGroup,
	canEditGroupsSection,
	setSelectedGroupId,
}) {
	const classes = useStyles();

	function numberOfGroups(groups) {
		return Object.keys(groups).length;
	}

	return (
		<div className={classes.groupSideBarRoot}>
			<div className={classes.topPart}>
				<GroupsDataTile numberOfGroups={numberOfGroups(groups)} />
				<CreateGroupButton
					groupsTree={groupsTree}
					createNewGroup={createNewGroup}
					selectedGroupId={selectedGroupId}
					canEditGroupsSection={canEditGroupsSection}
				/>
			</div>
			<div className={classes.tree}>
				<GroupsTreeControl
					groups={groups}
					groupsTree={groupsTree}
					selectedGroupId={selectedGroupId}
					setSelectedGroupId={setSelectedGroupId}
				/>
			</div>
		</div>
	);
}
