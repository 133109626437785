import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
	tabPanelRoot: {
		backgroundColor: "white",
		flex: 0,
	},
});

export default function TabPanel({ children, activeTabId, tabPanelId }) {
	const classes = useStyles();

	return (
		<div className={classes.tabPanelRoot} hidden={activeTabId !== tabPanelId}>
			{activeTabId === tabPanelId && <div>{children} </div>}
		</div>
	);
}
