import { Stack } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RedirectPage from "src/pages/Redirect";
import RegisterPage from "src/pages/Register";
import VerifyPage from "src/pages/Verify";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { StoreKey } from "../model/entities";
import { TelemetryAction, TelemetrySource } from "../model/telemetry";
import DownloadApp from "../pages/DownloadApp";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Setup from "../pages/Setup";
import SignIn from "../pages/SignIn";
import { getLocalStorage, getUUID } from "../utility/App.util";
import { modulesTheme } from "./Modules/modulesTheme";
import LoadingIcon from "./common/LoadingIcon";
import { NavBarState } from "./home/NavBar";
import ChangePasswordConfirmation from "./signIn/ChangePasswordConfirm";
import ForgotPassword from "./signIn/ForgotPassword";
import ForgotPasswordVerification from "./signIn/ForgotPasswordVerification";

export default function AppShell() {
	const [loading, setLoading] = useState<boolean>(true);
	const uuid = getUUID();
	const {
		Auth: { cognitoUser, setCognitoUser },
	} = useTypedAppContext();
	const {
		//@ts-expect-error no Types
		appState: { userHasAuthenticated, authenticatedUserInfo },
		//@ts-expect-error no Types
		getAuthenticatedUserInfo,
	} = useAppContext();

	useEffect(() => {
		const onLoad = async () => {
			setLoading(true);
			logger.telemetry({
				userId: authenticatedUserInfo.SK ? authenticatedUserInfo.SK : uuid,
				source: TelemetrySource.webapp,
				version: process.env.REACT_APP_VERSION ?? "",
			});
			//Try to auto login
			const rememberMe = getLocalStorage(StoreKey.REMEMBER_ME);
			logger.telemetry({
				action: TelemetryAction.log,
				event: "remember_me",
				eventData: { rememberMe: rememberMe ?? "" },
			});
			if (rememberMe === "true") {
				try {
					const cognitoAuthenticatedUser = await Auth.currentAuthenticatedUser();
					try {
						await getAuthenticatedUserInfo();
						setCognitoUser(cognitoAuthenticatedUser);
					} catch (e) {
						if (e instanceof Error) {
							if (e.message === "User account not configured") {
								logger.warn("user account not configured");
								setCognitoUser(cognitoAuthenticatedUser);
							} else if (e.message === "User account disabled") {
								logger.warn("User account disabled");
							} else {
								logger.error(e);
							}
						} else {
							logger.error(e);
						}
					}
				} catch (e) {
					if (e instanceof Error) {
						logger.warn(e.message);
					} else {
						logger.error(e);
					}
				}
			}
			setLoading(false);
		};
		void onLoad();
	}, []);

	const {
		UIContext: { setAppHeight, setNavBarState, setAppWidth, appWidth, navBarState },
	} = useTypedAppContext();

	useEffect(() => {
		// Function to update window width in state
		const updateWindowDims = () => {
			setAppWidth(window.innerWidth);
			setAppHeight(window.innerHeight);
		};

		// Attach the event listener
		window.addEventListener("resize", updateWindowDims);

		// Remove the event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateWindowDims);
		};
	}, []);

	useEffect(() => {
		if (appWidth <= modulesTheme.breakpoints.values.navBar) {
			setNavBarState(NavBarState.FULL);
		} else if (appWidth < modulesTheme.breakpoints.values.desktop) {
			setNavBarState(NavBarState.OVERLAY);
		} else {
			setNavBarState(NavBarState.SHIFT);
		}
	}, [appWidth]);

	useEffect(() => {
		logger.telemetry({
			action: TelemetryAction.log,
			event: "navBarState",
			eventData: { state: navBarState },
		});
	}, [navBarState]);

	return (
		<Router>
			{loading ? (
				<Stack width="100%" height="100%" justifyContent="center" alignItems="center">
					<LoadingIcon size="medium" />
				</Stack>
			) : (
				<div className="App">
					<Routes>
						{userHasAuthenticated ? (
							<>
								<Route path="/" element={<SignIn />} />
								<Route path="/home/*" element={<Home />} />
								<Route path="/forgot_password" element={<ForgotPassword />} />
								<Route
									path="/forgot_password_verification"
									element={<ForgotPasswordVerification />}
								/>
								<Route
									path="/change_password_confirmation"
									element={<ChangePasswordConfirmation />}
								/>
								<Route path="/signup" element={<RedirectPage />} />
								<Route path="/setup" element={<Setup />} />
								<Route path="/team-signup" element={<RedirectPage />} />
								<Route path="/download" element={<DownloadApp />} />
								<Route path="/DownloadApp" element={<DownloadApp />} />
								<Route path="/register" element={<RegisterPage />} />
								<Route path="/verify" element={<VerifyPage />} />
								<Route path="*" element={<NotFound />}></Route>
							</>
						) : (
							<>
								<Route path="/forgot_password" element={<ForgotPassword />} />
								<Route
									path="/forgot_password_verification"
									element={<ForgotPasswordVerification />}
								/>
								<Route
									path="/change_password_confirmation"
									element={<ChangePasswordConfirmation />}
								/>
								<Route path="/signup" element={<RedirectPage />} />
								<Route path="/register" element={<RegisterPage />} />
								<Route path="/verify" element={<VerifyPage />} />
								{cognitoUser && <Route path="/setup" element={<Setup />} />}
								<Route path="/team-signup" element={<RedirectPage />} />
								<Route path="/download" element={<DownloadApp />} />
								<Route path="/DownloadApp" element={<DownloadApp />} />
								<Route path="*" element={<SignIn />} />
							</>
						)}
					</Routes>
				</div>
			)}
		</Router>
	);
}
