import { Reminder } from "./reminder/reminder.Model";

// Keys for Local Storage
export enum StoreKey {
	REMEMBER_ME = "REMEMBER_ME",
	UUID = "UUID",
	INVITATIONS = "INVITATIONS",
	SESSION_ID = "SESSION_ID",
	MODULE_PREVIEW = "module-preview-show-once",
}
export enum MemberStatus {
	MEMBER = "MEMBER",
	PENDING = "PENDING",
	NEEDS_APPROVAL = "NEEDS_APPROVAL",
	REMOVED = "REMOVED",
}

export interface UserPermissions {
	modules: string[];
}

export interface UserPlaceholder {
	/** Account ID */
	PK: string;
	/** E-mail User ID */
	SK: string;
	email: string;
	subscription?: UserSubscriptionData | UserSubscriptionData[];
	status: MemberStatus;
	workspaceId: string;
	createdAt: string;
	removedTimestamp?: string;
	signUpCode?: string;
	seen?: boolean;
	devSubscriptions?: string[];
	userPermissions?: UserPermissions;
}

export interface UserSetupInfo {
	completedSetup?: boolean;
	goals?: string[];
	statements?: string[];
	moduleSetup?: {
		moraleCheckIn?: boolean;
		affirmations?: boolean;
		gratitude?: boolean;
		exists?: boolean;
	};
}

export interface UserSubscriptionData {
	/** Account ID */
	subscriptionId: string;
	company?: string;
	roles?: Record<string, boolean>;
	/** For individual accounts only */
	stripeCustomerId?: string;
}

export interface User extends UserPlaceholder {
	firstName?: string;
	lastName?: string;
	role?: string;
	phone?: string;
	contactPermission?: boolean;
	reminders: Reminder[];
	setupInfo: UserSetupInfo;
	isDev?: boolean | null;
	timezone?: string;
}
