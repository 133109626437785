import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	root: {
		"&.MuiTreeItem-root > .Mui-selected ": {
			backgroundColor: "transparent !important",
		},
		"& .Mui-focused ": {
			backgroundColor: "transparent !important",
		},
		"&.MuiTreeItem-root > .Mui-selected > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
		"&.Mui-focused > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
		"&.MuiTreeItem-contents:selected > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
		"&.MuiTreeItem-root > .Mui-selected:focus > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
		"&.MuiTreeItem-root > .Mui-selected:hover > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
		"&.MuiTreeItem-root > .MuiTreeItem-contents:focus > .MuiTreeItem-label": {
			color: theme.palette.text.light,
			backgroundColor: theme.palette.group.main,
			width: "fit-content",
		},
	},
	label: {
		padding: "0 1em",
		width: "fit-content",
		"&:hover": {
			backgroundColor: theme.palette.group.light,
			color: theme.palette.text.light,
		},
	},
}));
