import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import ModulesNav from "src/components/Modules/Shared/ModulesNav";
import GRATITUDE from "../../../images/positiveMindset/gratitude.gif";
import { modulesStyles } from "../modulesStyles";

interface GratitudeAffirmationsIntroSlideProps {
	page: ModulesLegacyPage;
}

const GratitudeAffirmationsIntroSlide: FC<GratitudeAffirmationsIntroSlideProps> = ({ page }) => {
	const classes = modulesStyles();

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" spacing="20px">
				<Grid item mobile={12}>
					<div className={classes.modulesItemCentered}>
						<img
							alt="Gratitude"
							src={GRATITUDE}
							className={classes.interventionImage}
						/>
					</div>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="h5">Benefits of gratitude & affirmations</Typography>
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="body1">
						Practicing gratitude has been found to reduce symptoms of depression &
						anxiety, and increase feelings of happiness & life satisfaction. Practicing
						gratitude can help you develop resilience by providing a sense of
						perspective and helping you to focus on the positive aspects of your life,
						even during challenging times. <br />
						<br />
						Affirmations have been shown to have a positive impact on mental health,
						self-esteem, and overall well-being. They can help you build self-confidence
						by reinforcing positive beliefs about yourself and your abilities. Repeating
						positive affirmations can help you shift negative thought patterns and
						promote a more positive mood.
					</Typography>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default GratitudeAffirmationsIntroSlide;
