import {
	AccountCircle,
	AccountTree,
	ArrowCircleUp,
	ConfirmationNumber,
	DateRange,
	Download,
	EmojiEmotions,
	ExpandLess,
	ExpandMore,
	Explore,
	Grade,
	Group,
	Groups,
	Lock,
	Person,
	Settings,
} from "@mui/icons-material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	SxProps,
	Tooltip,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../Theme";
import { apiGetReflectionStats } from "../../libs/apiLib";
import { useAppContext } from "../../libs/contextLib";
import { logger } from "../../libs/logLib";
import { useTypedAppContext } from "../../libs/typedContextLib";
import { generateReflectionPathName } from "../../model/reflection/reflection.Manager";
import { ReflectionModules } from "../../model/reflection/reflection.Model";
import { TelemetryAction } from "../../model/telemetry";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";
import SubscriptionPicker from "../common/SubscriptionPicker";

export enum NavBarState {
	FULL = "full-width",
	OVERLAY = "overlay",
	SHIFT = "shift-content",
}

export const NavBar: FC = () => {
	const {
		UIContext: { navBarVisible, setNavBarVisible, navBarState, appWidth },
	} = useTypedAppContext();
	const [lockValues, setLockValues] = useState(true);
	const [unlockNumber, setUnlockNumber] = useState(40);
	const [myInsightsOpen, setMyInsightsOpen] = useState(true);
	const [adminOpen, setAdminOpen] = useState(false);

	const navBarShift: SxProps = {
		backgroundColor: colors.white,
		paddingRight: "5px",
		paddingLeft: "5px",
		minWidth: "250px",
		maxWidth: "375px",
	};

	const navBarOverlay: SxProps = {
		backgroundColor: colors.white,
		paddingRight: "5px",
		paddingLeft: "5px",
		minWidth: "250px",
		maxWidth: "375px",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 1000,
		height: "100%",
		boxShadow: "4px 0px 4px rgba(0,0,0,0.1)",
	};

	const navBarFull: SxProps = {
		position: "absolute",
		backgroundColor: colors.white,
		paddingRight: "5px",
		paddingLeft: "5px",
		width: appWidth,
	};

	const location = useLocation();
	const history = useNavigate();
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		appState: { hasSubscription, selectedSubscriptionIsAdmin },
	} = useAppContext();
	const { pathname } = location;

	const handleMyInsightsClick = () => {
		setMyInsightsOpen(!myInsightsOpen);
		logger.telemetry({
			action: TelemetryAction.click,
			event: myInsightsOpen ? "my_insights_collapse" : "my_insights_expand",
		});
	};

	const handleAdminClick = () => {
		setAdminOpen(!adminOpen);
		logger.telemetry({
			action: TelemetryAction.click,
			event: adminOpen ? "team_admin_collapse" : "team_admin_expand",
		});
	};

	useEffect(() => {
		if (!lockValues) return;

		const getReflectionStats = async () => {
			if (!lockValues) return;
			const stats = await apiGetReflectionStats();
			const refCount = stats.reflections ?? 0;
			const refTshold = stats.reflectionDaysCounted ?? 20;
			setUnlockNumber(refTshold);
			if (refCount > refTshold * 2 - 1 && lockValues) {
				setLockValues(false);
			}
		};
		void getReflectionStats();
	}, []);

	const launchReflection = (modules: ReflectionModules) => {
		history(generateReflectionPathName(modules, ""));
	};

	useEffect(() => {
		if (pathname.search("user") !== -1) {
			setMyInsightsOpen(true);
		}
		if (pathname.search("admin") !== -1) {
			setAdminOpen(true);
		}
	}, [pathname]);

	const collapseMenuIfFull = () => {
		if (navBarState === NavBarState.FULL) {
			setNavBarVisible(false);
		}
	};

	return (
		<>
			{navBarVisible && (
				<Box
					sx={
						navBarState === NavBarState.FULL
							? navBarFull
							: navBarState === NavBarState.OVERLAY
								? navBarOverlay
								: navBarShift
					}
				>
					<List
						sx={{
							width: navBarState === NavBarState.FULL ? appWidth - 10 : "100%",
							bgcolor: colors.white,
							paddingTop: 0,
						}}
						component="nav"
						aria-labelledby="nested-list-subheader"
					>
						<ListItem sx={{ paddingTop: "4px", paddingBottom: "4px" }}>
							<SubscriptionPicker />
							<ListItemText></ListItemText>
							<div
								onClick={() => {
									setNavBarVisible(false);
									logger.telemetry({
										action: TelemetryAction.click,
										event: "navbar_collapse",
									});
								}}
								style={{ cursor: "pointer" }}
							>
								<KeyboardArrowLeftIcon />
							</div>
						</ListItem>
						<Divider />
						<Stack spacing={modulesTheme.spacing(1)} padding={modulesTheme.spacing(1)}>
							<QButton
								text="Morale check-in"
								onClick={() => {
									collapseMenuIfFull();
									launchReflection({ moraleCheckIn: true });
									logger.telemetry({
										action: TelemetryAction.click,
										event: "morale_check_in",
									});
								}}
								bold
								width="100%"
							/>
							<QButton
								text="Affirmations"
								variant="outlined"
								onClick={() => {
									collapseMenuIfFull();
									launchReflection({ affirmations: true });
									logger.telemetry({
										action: TelemetryAction.click,
										event: "affirmations",
									});
								}}
								bold
								width="100%"
							/>
							<QButton
								text="Gratitude"
								variant="outlined"
								onClick={() => {
									collapseMenuIfFull();
									launchReflection({ gratitude: true });
									logger.telemetry({
										action: TelemetryAction.click,
										event: "gratitude",
									});
								}}
								bold
								width="100%"
							/>
						</Stack>
						<Divider />
						<ListItemButton onClick={handleMyInsightsClick} disableGutters={true}>
							{myInsightsOpen ? <ExpandLess /> : <ExpandMore />}
							<ListItemIcon sx={{ minWidth: 30 }}>
								<Person />
							</ListItemIcon>
							<ListItemText primary="My insights" />
						</ListItemButton>
						<Collapse in={myInsightsOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItemButton
									onClick={() => {
										collapseMenuIfFull();
										logger.telemetry({
											action: TelemetryAction.click,
											event: "my_morale",
										});
									}}
									sx={{ pl: 5 }}
									to="/home/dashboard_user"
									component={Link}
									selected={pathname === "/home/dashboard_user"}
								>
									<ListItemIcon sx={{ minWidth: 30 }}>
										<EmojiEmotions />
									</ListItemIcon>
									<ListItemText primary="My Morale" />
								</ListItemButton>

								{lockValues ? (
									<Tooltip
										title={`My values are available after ${
											unlockNumber * 2
										} reflections. Continue checking in using Quimby to unlock more insights`}
									>
										<div>
											<ListItemButton
												onClick={() => {
													collapseMenuIfFull();
													logger.telemetry({
														action: TelemetryAction.click,
														event: "my_values_locked",
													});
												}}
												sx={{ pl: 5 }}
												selected={pathname === "/home/user/values"}
												disabled
											>
												<ListItemIcon sx={{ minWidth: 30 }}>
													<Lock />
												</ListItemIcon>
												<ListItemText primary="My Values" />
											</ListItemButton>
										</div>
									</Tooltip>
								) : (
									<ListItemButton
										onClick={() => {
											collapseMenuIfFull();
											logger.telemetry({
												action: TelemetryAction.click,
												event: "my_values",
											});
										}}
										sx={{ pl: 5 }}
										to="/home/user/values"
										selected={pathname === "/home/user/values"}
										component={Link}
									>
										<ListItemIcon sx={{ minWidth: 30 }}>
											<Grade />
										</ListItemIcon>
										<ListItemText primary="My Values" />
									</ListItemButton>
								)}
							</List>
						</Collapse>
						{!hasSubscription ? (
							<Tooltip title="Invite others to track aggregate insights and support each other. Requires upgrade to Plus plan.">
								<div>
									<ListItemButton
										sx={{ pl: 3 }}
										onClick={() => {
											collapseMenuIfFull();
											logger.telemetry({
												action: TelemetryAction.click,
												event: "shared_insights_trial",
											});
										}}
										to="/home/shared_insights_trial"
										selected={pathname === "/home/shared_insights_trial"}
										component={Link}
									>
										<ListItemIcon sx={{ minWidth: 30 }}>
											<Lock />
										</ListItemIcon>
										<ListItemText primary="Shared insights" />
										<ListItemIcon sx={{ minWidth: 30 }}>
											<ArrowCircleUp style={{ color: "#f09c88" }} />
										</ListItemIcon>
									</ListItemButton>
								</div>
							</Tooltip>
						) : (
							<ListItemButton
								sx={{ pl: 3 }}
								onClick={() => {
									collapseMenuIfFull();
									logger.telemetry({
										action: TelemetryAction.click,
										event: "shared_insights",
									});
								}}
								to="/home/shared_insights"
								selected={pathname === "/home/shared_insights"}
								component={Link}
							>
								<ListItemIcon sx={{ minWidth: 30 }}>
									<Groups />
								</ListItemIcon>
								<ListItemText primary="Shared insights" />
							</ListItemButton>
						)}
						{!hasSubscription ? (
							<Tooltip title="Give your mind a focus and direction. Requires upgrade to Plus plan.">
								<ListItemButton
									onClick={() => {
										collapseMenuIfFull();
										logger.telemetry({
											action: TelemetryAction.click,
											event: "Modules",
										});
									}}
									sx={{ pl: 3 }}
									to="/home/intentions"
									selected={pathname === "/home/intentions"}
									component={Link}
								>
									<ListItemIcon sx={{ minWidth: 30 }}>
										<Explore />
									</ListItemIcon>
									<ListItemText primary="Intentions" />
									<ListItemIcon sx={{ minWidth: 30 }}>
										<ArrowCircleUp style={{ color: "#f09c88" }} />
									</ListItemIcon>
								</ListItemButton>
							</Tooltip>
						) : (
							<ListItemButton
								onClick={() => {
									collapseMenuIfFull();
									logger.telemetry({
										action: TelemetryAction.click,
										event: "Modules",
									});
								}}
								sx={{ pl: 3 }}
								to="/home/intentions"
								selected={pathname === "/home/intentions"}
								component={Link}
							>
								<ListItemIcon sx={{ minWidth: 30 }}>
									<Explore />
								</ListItemIcon>
								<ListItemText primary="Intentions" />
							</ListItemButton>
						)}
						{!hasSubscription ? (
							<ListItemButton
								sx={{ pl: 3 }}
								onClick={() => {
									collapseMenuIfFull();
									logger.telemetry({
										action: TelemetryAction.click,
										event: "toolkit",
									});
								}}
								to="/home/toolkit"
								selected={pathname === "/home/toolkit"}
								component={Link}
							>
								<ListItemIcon sx={{ minWidth: 30 }}>
									<HomeRepairServiceIcon />
								</ListItemIcon>
								<ListItemText primary="My Toolkit" />
								<ListItemIcon sx={{ minWidth: 30 }}>
									<ArrowCircleUp style={{ color: "#f09c88" }} />
								</ListItemIcon>
							</ListItemButton>
						) : (
							<ListItemButton
								sx={{ pl: 3 }}
								onClick={() => {
									collapseMenuIfFull();
									logger.telemetry({
										action: TelemetryAction.click,
										event: "toolkit",
									});
								}}
								to="/home/toolkit"
								selected={pathname === "/home/toolkit"}
								component={Link}
							>
								<ListItemIcon sx={{ minWidth: 30 }}>
									<HomeRepairServiceIcon />
								</ListItemIcon>
								<ListItemText primary="My Toolkit" />
							</ListItemButton>
						)}
						<ListItemButton
							sx={{ pl: 3 }}
							onClick={() => {
								collapseMenuIfFull();
								logger.telemetry({
									action: TelemetryAction.click,
									event: "routine",
								});
							}}
							to="/home/routine"
							selected={pathname === "/home/routine"}
							component={Link}
						>
							<ListItemIcon sx={{ minWidth: 30 }}>
								<DateRange />
							</ListItemIcon>
							<ListItemText primary="My Routine" />
						</ListItemButton>
						<ListItemButton
							sx={{ pl: 3 }}
							onClick={() => {
								collapseMenuIfFull();
								logger.telemetry({
									action: TelemetryAction.click,
									event: "profile",
								});
							}}
							to="/home/profile"
							selected={pathname === "/home/profile"}
							component={Link}
						>
							<ListItemIcon sx={{ minWidth: 30 }}>
								<AccountCircle />
							</ListItemIcon>
							<ListItemText primary="Profile" />
						</ListItemButton>
						<ListItemButton
							sx={{ pl: 3 }}
							onClick={() => {
								collapseMenuIfFull();
								logger.telemetry({
									action: TelemetryAction.click,
									event: "download",
								});
							}}
							to="/home/download"
							selected={pathname === "/home/download"}
							component={Link}
						>
							<ListItemIcon sx={{ minWidth: 30 }}>
								<Download />
							</ListItemIcon>
							<ListItemText primary="Download" />
						</ListItemButton>
					</List>
					{selectedSubscriptionIsAdmin && (
						<>
							<Divider />
							<ListItemButton disableGutters={true} onClick={handleAdminClick}>
								{adminOpen ? <ExpandLess /> : <ExpandMore />}
								<ListItemIcon sx={{ minWidth: 30 }}>
									<Settings />
								</ListItemIcon>
								<ListItemText primary="Team admin" />
							</ListItemButton>
							<Collapse in={adminOpen} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<ListItemButton
										sx={{ pl: 3 }}
										onClick={() => {
											collapseMenuIfFull();
											logger.telemetry({
												action: TelemetryAction.click,
												event: "admin_members",
											});
										}}
										to="/home/admin/members"
										selected={pathname === "/home/admin/members"}
										component={Link}
									>
										<ListItemIcon sx={{ minWidth: 30 }}>
											<Group />
										</ListItemIcon>
										<ListItemText primary="Members" />
									</ListItemButton>
									<ListItemButton
										sx={{ pl: 3 }}
										onClick={() => {
											collapseMenuIfFull();
											logger.telemetry({
												action: TelemetryAction.click,
												event: "admin_group",
											});
										}}
										to="/home/admin/groups"
										selected={pathname === "/home/admin/groups"}
										component={Link}
									>
										<ListItemIcon sx={{ minWidth: 30 }}>
											<AccountTree />
										</ListItemIcon>
										<ListItemText primary="Groups" />
									</ListItemButton>
									<ListItemButton
										sx={{ pl: 3 }}
										onClick={() => {
											collapseMenuIfFull();
											logger.telemetry({
												action: TelemetryAction.click,
												event: "admin_subscriptions",
											});
										}}
										to="/home/admin/subscription"
										selected={pathname === "/home/admin/subscription"}
										component={Link}
									>
										<ListItemIcon sx={{ minWidth: 30 }}>
											<ConfirmationNumber />
										</ListItemIcon>
										<ListItemText primary="Subscription" />
									</ListItemButton>
								</List>
							</Collapse>
						</>
					)}
				</Box>
			)}
		</>
	);
};

export default NavBar;
