import { Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { QButtonProps } from "src/components/common/QButton";
import QButtonStack from "src/components/common/QButtonStack";
import { ModulesInputSurveyQuestion } from "src/model/modules/modules.Model";
import { ModulesData } from "../../../model/modulesData/modulesData.Model";

export interface SurveyQuestionProps {
	pageId: string;
	question: ModulesInputSurveyQuestion;
	modulesData: ModulesData;
}

interface SurveyState extends QButtonProps {
	index: number;
}

const SurveyQuestion: FC<SurveyQuestionProps> = ({ pageId, question, modulesData }) => {
	const getSurveyState = () => {
		const state = question.options.map((option, index) => {
			const saveFound = modulesData.getPageRecord(pageId, question.question);

			return {
				onClick: () => handleClick(index),
				text: option,
				variant: saveFound === option ? "contained" : "outlined",
				index: index,
			};
		});
		return state as SurveyState[];
	};

	const [questionState, setSurveyState] = useState(getSurveyState());

	useEffect(() => {
		setSurveyState(getSurveyState());
	}, [pageId]);

	const handleClick = (index: number) => {
		const updatedSurveyState = [...questionState];
		updatedSurveyState.forEach((option) => (option.variant = "outlined"));
		updatedSurveyState[index] = { ...updatedSurveyState[index], variant: "contained" };
		setSurveyState(updatedSurveyState);
		modulesData.setPageRecord(pageId, question.question, questionState[index].text);
	};
	return (
		<>
			<Typography variant="h6">{question.question}</Typography>
			<QButtonStack justifyContent="flex-start" buttons={questionState} />
		</>
	);
};

export default SurveyQuestion;
