// https://medium.com/@adostes/validating-a-form-in-react-cc29d47e140f
const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, //eslint-disable-line no-useless-escape
);

// https://www.regextester.com/
const validPhoneRegex = RegExp(
	/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, //eslint-disable-line no-useless-escape
);

// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s19.html
// The following code combines five password requirements:
//   -Length between 8 and 32 characters.
//   -One or more uppercase letters.
//   -One or more lowercase letters.
//   -One or more numbers.
//   -One or more special characters (ASCII punctuation or space characters).
function isPasswordValid(password) {
	var minMaxLength = /^[\s\S]{8,32}$/,
		upper = /[A-Z]/,
		lower = /[a-z]/,
		number = /[0-9]/,
		special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

	if (!minMaxLength.test(password)) {
		return "Password must be between 8 and 32 characters";
	}

	if (!upper.test(password)) {
		return "Password must contain one or more uppercase letters";
	}

	if (!lower.test(password)) {
		return "Password must contain one or more lowercase letters";
	}

	if (!number.test(password)) {
		return "Password must contain one or more numbers";
	}

	if (!special.test(password)) {
		return "Password must contain one or more special characters";
	}

	return "";
}

function requiredField(value) {
	let error = { error: false, errorText: "" };

	if (value.trim() === "") {
		error.error = true;
	}

	return error;
}

function isEmailValid(email) {
	return validEmailRegex.test(email);
}

function isPhoneValid(phone) {
	return validPhoneRegex.test(phone);
}

export function validateEmail(value) {
	let error = { error: false, errorText: "" };

	if (value.trim() === "") {
		error.error = true;
	} else {
		if (!isEmailValid(value)) {
			error.error = true;
			error.errorText = "Invalid email address";
		}
	}

	return error;
}

export function validatePassword(value) {
	let error = { error: false, errorText: "" };
	let passwordErrorText = isPasswordValid(value);
	if (value.trim() === "") {
		error.error = true;
	} else {
		if (passwordErrorText.length > 0) {
			error.error = true;
			error.errorText = passwordErrorText;
		}
	}

	return error;
}

export function validateVerifyPassword(value, password) {
	let error = { error: false, errorText: "" };

	if (value !== password) {
		error.error = true;
		error.errorText = "Password does not match";
	}
	return error;
}

export function validateFirstName(value) {
	return requiredField(value);
}

export function validateLastName(value) {
	return requiredField(value);
}

export function validateCompany(value) {
	return requiredField(value);
}

export function validatePhone(value) {
	let error = { error: false, errorText: "" };
	if (value.trim() !== "") {
		if (!isPhoneValid(value)) {
			error.error = true;
			error.errorText = "Invalid phone number. Example format: +xx (xxx) xxx-xxxx ext xxxx";
		}
	}

	return error;
}

export function validateVerificationCode(value) {
	return requiredField(value);
}

export const validationMap = {
	firstName: validateFirstName,
	lastName: validateLastName,
	company: validateCompany,
	email: validateEmail,
	// TODO: Disabling phone validation until we decide
	// TODO: how to support international phone numbers
	// phone: validatePhone,
	password: validatePassword,
	verifyPassword: validateVerifyPassword,
};
