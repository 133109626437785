import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import { parentNodeCandidateList } from "./groupManagementHelpers";

const useStyles = makeStyles((theme) => ({
	root: {},
	formControl: {
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

export default function ParentGroupPicker({
	groupsTree,
	selectedGroupId,
	parentGroupId,
	changeHandler,
	canEditGroupsSection,
}) {
	if (!groupsTree || groupsTree.length < 1) {
		return null;
	}

	const classes = useStyles();

	let parentNodeCandidates = parentNodeCandidateList(groupsTree[0], selectedGroupId);

	const handleChange = (e) => {
		changeHandler(e.target.value);
	};

	// Used to remove hierarchy styling from the selected item
	const renderSelectedItem = (value) => {
		return parentNodeCandidates.find((group) => group.id === value)?.name ?? "";
	};

	return parentNodeCandidates.length ? (
		<div className={classes.root}>
			<FormControl
				variant="outlined"
				className={classes.formControl}
				disabled={!canEditGroupsSection}
			>
				<InputLabel>Parent Group</InputLabel>
				<Select
					value={parentGroupId}
					onChange={handleChange}
					label="Parent Group"
					renderValue={renderSelectedItem}
				>
					{parentNodeCandidates.map((node, index) => {
						return (
							<MenuItem key={index} value={node.id}>
								{node.name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	) : (
		<div></div>
	);
}
