import { createContext, useContext } from "react";

export const AppContext = createContext(null);

export function useAppContext() {
	return useContext(AppContext);
}

export const SignUpContext = createContext(null);

export function useSignUpContext() {
	return useContext(SignUpContext);
}

export const StepperProcess = createContext(null);

export function useStepperProcess() {
	return useContext(StepperProcess);
}

export const SlideContext = createContext({
	setIsBackButtonVisible: () => {},
	setIsForwardButtonVisible: () => {},
	slideButtonDisabled: true,
	setSlideButtonDisabled: () => {},
	setIsSlideButtonVisible: () => {},
	setSlideButtonAction: () => {},
	setSlideButtonLabel: () => {
		//do nothing
	},
	setIsLoading: () => {},
	goForward: () => {
		//do nothing
	},
	replaceSlide: false,
	setReplaceSlide: () => {},
	isSummary: false,
	setIsSummary: () => {},
	skipTeam: false,
});

export function useSlideContext() {
	return useContext(SlideContext);
}
