import { ThumbDown, ThumbUp } from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";
import { colors } from "../../../Theme";
import { useReflectionModelContext } from "../../../libs/typedContextLib";

const THUMB_ICON_SIZE = "3rem";
const THUMB_ICON_HORIZONTAL_OFFSET = "15px";
const THUMB_ICON_VERTICAL_OFFSET = "20px";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	margin: {
		height: 3,
	},
	thumbsUp: {
		fontSize: THUMB_ICON_SIZE,
		marginLeft: THUMB_ICON_VERTICAL_OFFSET,
		marginBottom: THUMB_ICON_HORIZONTAL_OFFSET,
		color: colors.teal,
	},
	thumbsDown: {
		fontSize: THUMB_ICON_SIZE,
		marginRight: THUMB_ICON_VERTICAL_OFFSET,
		marginTop: THUMB_ICON_HORIZONTAL_OFFSET,
		color: colors.teal,
	},
	feelingSlider: {
		color: colors.teal,
		"& .MuiSlider-thumb": {
			height: 36,
			width: 36,
			backgroundColor: "#fff",
			border: "2px solid currentColor",
			"&:focus, &:hover, &$active": {
				boxShadow: "inherit",
			},
		},
		"& .MuiSlider-track": {
			height: 16,
			borderRadius: 8,
			color: colors.teal,
		},
		"& .MuiSlider-rail": {
			height: 16,
			borderRadius: 8,
			color: colors.teal,
		},
		thumb: {
			height: 36,
			width: 36,
			backgroundColor: "#fff",
			border: "2px solid currentColor",
			"&:focus, &:hover, &$active": {
				boxShadow: "inherit",
			},
		},
		valueLabel: {
			left: "calc(-50% + 16px)",
		},
	},
}));

const FeelingSlider: FC = () => {
	const classes = useStyles();
	const { feeling, setFeeling } = useReflectionModelContext();
	return (
		<div className={classes.root}>
			<ThumbDown className={classes.thumbsDown} />
			<Slider
				className={classes.feelingSlider}
				min={0}
				max={1000}
				scale={(num) => num / 100}
				valueLabelDisplay="off"
				aria-label="pretto slider"
				value={feeling}
				onChange={(_event, newValue) => {
					setFeeling(Array.isArray(newValue) ? newValue[0] : newValue);
				}}
				valueLabelFormat={(value) => Math.round(value)}
			/>
			<ThumbUp className={classes.thumbsUp} />
		</div>
	);
};
export default FeelingSlider;
