import { Reminder } from "../reminder/reminder.Model";

export interface Module extends ModuleData {
	moduleStatus: ModuleStatus;
}

export enum ModuleState {
	NEW = "new",
	ACTIVE = "active",
	PAUSED = "paused",
	COMPLETE = "complete",
	CANCELED = "canceled",
	LOCKED = "locked",
}

export enum ModuleType {
	MODULE = "module",
	TOOLKIT = "toolkit",
}

export interface ModuleStatus {
	/** uuid used to identify a unique session for a given module.*/
	moduleSessionId: string;
	/** module id from the module definition. */
	moduleState: ModuleState;
	/** string representing current step of the model the user is on as: section:activity:iteration:targetDate */
	moduleStep: string;
	/** ISO string representing the date/time when the module was started. */
	startDate: string;
	/** ISO string representing when some part of the module was last modified/completed. */
	lastUpdated: string;
}

export interface ModuleData {
	/** uuid representing the module id, used as PK */
	moduleId: string;
	/** version string in the form "major.minor" used as SK */
	version: string;
	title: string;
	description: string;
	type: ModuleType;
	thumbnailPath?: string;
	tags?: string[];
}

export interface ModuleMilestones extends Module {
	milestonePage: ModulesActivitySummaryPage;
}

export interface ModuleDetails extends Module {
	sections: ModuleSection[];
}

export interface ModuleSection {
	title: string;
	description: string;
	timeEstimate?: string;
	activities: ModuleActivity[];
}
export enum ActivityType {
	INTRO = "intro",
	CHECK_IN = "check-in",
	WRAP_UP = "wrap-up",
}

export interface ModuleActivity {
	title: string;
	description: string;
	timeEstimate?: string;
	iterations?: number;
	activityType: ActivityType;
	pages: ModulePage[];
	unlocked?: true;
}

export type ModulePage =
	| ModulesReminderSetupPage
	| ModulesExercisePage
	| ModulesInputPage
	| ModulesStaticPage
	| ModulesActivitySummaryPage
	| ModulesLegacyPage
	| ModulesNotificationSetupPage;

export interface ModulesPageBase {
	pageTitle: string;
	id: string;
	buttonConfig?: ModulesNavButtonConfig;
	title: string;
}

export interface ModulesNavButtonConfig {
	forwardButton?: boolean; // default: true
	forwardButtonText?: string; // default: Next
	backButton?: boolean; // default: true
	backButtonText?: string; // default: Back
}

export interface ModulesReminderSetupPage extends ModulesPageBase {
	reminderGroups: ModulesReminderGroup[];
	description?: string;
}

export const isModulesReminderSetupPage = (page: ModulePage): page is ModulesReminderSetupPage => {
	return page && "reminderGroups" in page;
};

export interface ModulesReminderGroup {
	groupTitle: string;
	reminders: ModulesReminder[];
}

export interface ModulesReminder extends Reminder {
	addToCalendar?: boolean;
	editable?: boolean;
	calRecurrenceInterval?: number;
	calRecurrenceCount?: number;
	calRecurrenceHours?: number;
	calRecurrenceMins?: number;
}

export enum MilestonesType {
	MODULE_NEXT = "module-next",
	MODULE_PREVIEW = "module-preview",
	ACTIVITY_PREVIEW = "activity-preview",
	FIXED = "fixed",
}

export interface ModulesActivitySummaryPage extends ModulesPageBase {
	description?: string;
	subtitle?: string;
	cumulativeTimeEstimate?: string;
	milestones: ModulesMilestone[];
	milestonesType?: MilestonesType;
	info?: string;
}

export const isModulesActivitySummaryPage = (
	page: ModulePage,
): page is ModulesActivitySummaryPage => {
	return page && ("milestones" in page || "milestonesType" in page);
};

export interface ModulesMilestone {
	title: string;
	step: number;
	description?: string;
	selected?: boolean;
	state?: ModulesMilestoneState;
	percentComplete?: number;
	timeEstimate?: string;
	faded?: boolean;
	progress?: ModulesMilestoneProgressItem[];
}

export interface ModulesMilestoneProgressItem {
	text: string;
	isLocked: boolean;
	iterations?: number;
	link?: string;
	isNext?: boolean;
}

export type ModulesMilestoneState = "locked" | "active" | "complete";

export interface ModulesStaticPage extends ModulesPageBase {
	description?: string;
	imagePath: string;
	imageAltText?: string;
	info?: string;
}

export const isModulesStaticPage = (page: ModulePage): page is ModulesStaticPage => {
	return page && "imagePath" in page;
};

export interface ModulesNotificationSetupPage extends ModulesPageBase {
	description?: string;
	notificationTarget: string;
	info?: string;
}

export const isModulesNotificationSetupPage = (
	page: ModulePage,
): page is ModulesNotificationSetupPage => {
	return page && "notificationTarget" in page;
};

export interface ModulesExercisePage extends ModulesPageBase {
	exercises: ModulesExercise[];
}

export const isModulesExercisePage = (page: ModulePage): page is ModulesExercisePage => {
	return page && "exercises" in page;
};

interface ModulesExercise {
	title: string;
	description?: string;
	filePath: string;
	infoBoxText: string;
	selectButtonText: string;
	transcript: string;
}

export type ModulesInputPage =
	| ModulesInputSurveyPage
	| ModulesInputTextPage
	| ModulesInputRadioPage;

export interface ModulesInputSurveyPage extends ModulesPageBase {
	description?: string;
	surveyQuestions: ModulesInputSurveyQuestion[];
}

export interface ModulesInputSurveyQuestion {
	question: string;
	options: string[];
}

export const isModulesInputSurveyPage = (page: ModulePage): page is ModulesInputSurveyPage => {
	return page && "surveyQuestions" in page;
};

export interface ModulesInputTextPage extends ModulesPageBase {
	description?: string;
	prompt: string;
	placeholder: string;
	info?: string;
}

export const isModulesInputTextPage = (page: ModulePage): page is ModulesInputTextPage => {
	return page && "prompt" in page && "placeholder" in page;
};

export interface ModulesInputRadioPage extends ModulesPageBase {
	description?: string;
	prompt: string;
	radioOptions: string[];
}

export const isModulesInputRadioPage = (page: ModulePage): page is ModulesInputRadioPage => {
	return page && "prompt" in page && "radioOptions" in page;
};

export interface ModulesLegacyPage extends ModulesPageBase {
	legacyPageType: ModulesLegacyPageType;
	description?: string;
	overrideDescription?: boolean;
}

export enum ModulesLegacyPageType {
	MORALE = "morale",
	EMOTION_STIMULUS = "emotion-stimulus",
	JOURNAL = "journal",
	LAST_SLIDE = "last-slide",
	EA_INTRO = "emotional-awareness-intro",
	INTERVENTION = "intervention",
}

export const isModulesLegacyPage = (page: ModulePage): page is ModulesLegacyPage => {
	return page && "legacyPageType" in page;
};

export interface ModuleStep {
	last: {
		sectionId: string | undefined;
		activityId: string | undefined;
		sectionIndex: number | undefined;
		activityIndex: number | undefined;
		iteration: number;
		triggerDate: number | undefined;
	};
	next: {
		sectionId: string | undefined;
		activityId: string | undefined;
		sectionIndex: number | undefined;
		activityIndex: number | undefined;
		iteration: number;
		triggerDate: number | undefined;
	};
}
