import { CheckCircle } from "@mui/icons-material";
import { Box, List, ListItem, Stack, ThemeProvider, Typography } from "@mui/material";
import React, { FC } from "react";
import { useSubscriptionContext } from "../../pages/Subscription";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";

export interface SubscriptionCardProps {
	title: string;
	description: string;
	priceMonthly: string | number;
	priceYearly: string | number;
	bullets: string[];
	selected: boolean;
	period: "monthly" | "yearly";
	togglePeriod: (period: "yearly" | "monthly") => void;
}

const SubscriptionCard: FC<SubscriptionCardProps> = (props) => {
	const classes = modulesStyles();
	const subscriptionContext = useSubscriptionContext();
	const price = props.period === "monthly" ? props.priceMonthly : props.priceYearly;
	const showPriceDescription =
		props.period === "yearly"
			? typeof props.priceYearly === "number"
				? true
				: false
			: typeof props.priceMonthly === "number"
				? true
				: false;
	const priceDescription = props.period === "yearly" ? "per year" : "per month";

	return (
		<ThemeProvider theme={modulesTheme}>
			<Box
				className={
					props.selected ? classes.subscriptionBoxSelected : classes.subscriptionBox
				}
			>
				<Stack spacing="20px">
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h1">{props.title}</Typography>
						<Stack direction="row" spacing="5px">
							{props.selected && (
								<>
									<CheckCircle />
									<Typography variant="body">Current Plan</Typography>
								</>
							)}
						</Stack>
					</Stack>
					<div
						className="subscriptionDescription"
						style={{ minHeight: subscriptionContext.descriptionHeight }}
					>
						<Typography variant="body">{props.description}</Typography>
					</div>
					<Stack
						spacing="10px"
						justifyContent="center"
						alignItems="center"
						className="subscriptionPrice"
						minHeight={subscriptionContext.priceHeight}
					>
						<Stack spacing="10px" direction="row">
							<Typography variant="h1">
								{typeof price === "number" ? `$${price}` : price}
							</Typography>
							{showPriceDescription && (
								<Stack>
									<Typography variant="body1">{"per member"}</Typography>
									<Typography variant="body1">{priceDescription}</Typography>
								</Stack>
							)}
						</Stack>
						{typeof price === "number" && (
							<>
								<Typography variant="h5">14 day free trial</Typography>
								<Stack spacing="10px" direction="row">
									<QButton
										onClick={() => {
											props.togglePeriod("monthly");
										}}
										text="Monthly"
										variant={
											props.period === "monthly" ? "contained" : "outlined"
										}
									/>
									<QButton
										onClick={() => {
											props.togglePeriod("yearly");
										}}
										text="Yearly"
										variant={
											props.period === "yearly" ? "contained" : "outlined"
										}
									/>
								</Stack>
							</>
						)}
					</Stack>
					<div>
						<Typography variant="body">Includes:</Typography>
						<List className={classes.bulletedList}>
							{props.bullets.map((bullet, index) => (
								<ListItem key={index} className={classes.bulletedItem}>
									<Typography variant="body">{bullet}</Typography>
								</ListItem>
							))}
						</List>
					</div>
				</Stack>
			</Box>
		</ThemeProvider>
	);
};

export default SubscriptionCard;
