import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { colors } from "src/Theme";
import ClickableLink from "../common/ClickableLink";

function descendingComparator(a, b, orderBy) {
	// HACK: If sorting by date , actually sort by timestamp
	orderBy = orderBy === "date" ? "timestamp" : orderBy;

	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "date",
		numeric: false,
		disablePadding: false,
		label: "Date",
		// minWidth: 100,
	},
	{
		id: "emotion",
		numeric: true,
		disablePadding: false,
		label: "Emotion",
		// minWidth: 100,
	},
	// {
	// 	id: "thoughts",
	// 	numeric: true,
	// 	disablePadding: false,
	// 	label: "Thoughts",
	// 	// minWidth: 400,
	// },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={headCell.disablePadding ? "none" : undefined}
						// style={{ minWidth: headCell.minWidth }}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ whiteSpace: "nowrap", width: "50%" }}
						colSpan={8}
					>
						{headCell.id === "thoughts" ? (
							<>{headCell.label}</>
						) : (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.mode === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	title: {
		flex: "1 1 100%",
	},
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography
					className={classes.title}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
					Nutrition
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton aria-label="delete" size="large">
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton aria-label="filter list" size="large">
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
		borderRadius: 15,
	},
	container: {
		// maxHeight: "300px",
	},
	table: {
		// minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	noThoughts: {
		textAlign: "center",
		padding: "4em",
		fontStyle: "italic",
	},
	bottomRow: {
		"& .MuiTablePagination-displayedRows": {
			margin: 0,
		},
		"& .MuiTablePagination-selectLabel": {
			margin: 0,
		},
		"& .MuiTablePagination-input": {
			marginRight: 5,
			marginLeft: 0,
		},
		"& .MuiTablePagination-toolbar": {
			paddingLeft: 8,
		},
		"& .MuiTablePagination-actions": {
			marginLeft: 0,
		},
		"& .MuiIconButton-root": {
			paddingRight: 2,
			paddingLeft: 2,
		},
	},
}));

EnhancedTable.propTypes = {
	data: PropTypes.array.isRequired,
};

const MAX_WORDS_TO_SHOW = 16;

export default function EnhancedTable({ data }) {
	const classes = useStyles();
	const [order, setOrder] = React.useState("desc");
	const [orderBy, setOrderBy] = React.useState("date");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [showAllMap, setShowAllMap] = React.useState({});

	const handleToggleShowAll = (index) => {
		setShowAllMap((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	useEffect(() => {
		setPage(0);
		setRowsPerPage(5);
	}, [data]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = data.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
	const hasThoughts = true;
	// const hasThoughts = data.find((item) => {
	//   return item.thoughts ? item.thoughts !== "" : false;
	// });
	return hasThoughts ? (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<TableContainer className={classes.container}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={"small"}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
						/>
						<TableBody>
							{stableSort(data, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									const limitedWords = row.thoughts
										.split(" ")
										.slice(0, MAX_WORDS_TO_SHOW)
										.join(" ");
									return (
										<>
											<TableRow
												hover
												tabIndex={-1}
												key={row.timestamp}
												style={{ backgroundColor: colors.lightTeal }}
											>
												<TableCell
													id={labelId}
													align="left"
													style={{ borderBottom: "none", width: "50%" }}
													colSpan={8}
												>
													{row.date}
												</TableCell>
												<TableCell
													align="left"
													style={{
														borderBottom: "none",
														width: "50%",
														whiteSpace: "nowrap",
													}}
													colSpan={8}
												>
													{row.emotion}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													scope="row"
													style={{
														overflowWrap: "anywhere",
													}}
													colSpan={12}
												>
													{showAllMap[index]
														? row.thoughts
														: `${limitedWords} ${
																row.thoughts.split(" ").length >
																MAX_WORDS_TO_SHOW
																	? "..."
																	: ""
															}`}
													{row.thoughts.split(" ").length >
														MAX_WORDS_TO_SHOW && (
														<ClickableLink
															onClick={() =>
																handleToggleShowAll(index)
															}
															text={
																showAllMap[index]
																	? "view less"
																	: "view more"
															}
															textAlign="right"
														/>
													)}
												</TableCell>
											</TableRow>
										</>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 33 * emptyRows }}>
									<TableCell colSpan={12} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					className={classes.bottomRow}
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	) : (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Typography className={classes.noThoughts}>
					Submit a reflection and enter your thoughts to start seeing your thought
					insights along with your emotion and morale.
				</Typography>
			</Paper>
		</div>
	);
}
