import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexFlow: "column",
		minHeight: "50%",
	},
	chipBox: {
		padding: theme.spacing(2),
		backgroundColor: "white",
		border: `2px solid ${theme.palette.primary.main}`,
		height: "100%",
		overflow: "auto",
		cursor: "text",
		borderRadius: "4px",
		flex: "1 0 100%",
	},
	chipRootValid: {
		margin: "0px 8px 8px 0px",
		float: "left",
		background: theme.palette.primary.main,
		color: "white",
		"&:hover": { background: theme.palette.emailChip.valid.light },
		"& .MuiChip-deleteIcon": {
			color: theme.palette.emailChip.valid.button,
			opacity: 0.4,
		},
		"& .MuiChip-deleteIcon:hover": {
			color: theme.palette.emailChip.valid.buttonHover,
			opacity: 0.8,
		},
	},
	chipRootInvalid: {
		border: `2px solid ${theme.palette.emailChip.invalid.main}`,
		background: "white",
		color: theme.palette.emailChip.invalid.main,
		"&:hover": {
			background: theme.palette.emailChip.invalid.light,
		},
		"& .MuiChip-deleteIcon": {
			color: theme.palette.emailChip.invalid.main,
			opacity: 0.5,
		},
		"& .MuiChip-deleteIcon:hover": {
			color: theme.palette.emailChip.invalid.main,
			opacity: 0.8,
		},
	},
	chipFocused: {
		background: theme.palette.secondary.main,
		color: "white",
		border: "none",
		"&:hover": { background: theme.palette.secondary.main },
		"& .MuiChip-deleteIcon": {
			color: theme.palette.emailChip.valid.button,
			opacity: 0.4,
		},
		"& .MuiChip-deleteIcon:hover": {
			color: theme.palette.emailChip.valid.buttonHover,
			opacity: 0.8,
		},
	},
	helperText: {
		paddingTop: theme.spacing(1),
	},
	error: {
		color: "red",
	},
}));
