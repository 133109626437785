import { Box, Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, MouseEventHandler, ReactNode } from "react";
import { modulesColors } from "../Modules/modulesTheme";

export interface QButtonProps {
	onClick?: MouseEventHandler;
	text: string;
	variant?: "outlined" | "contained" | "text";
	disabled?: boolean;
	icon?: ReactNode;
	width?: string;
	bold?: boolean;
	type?: "button" | "reset" | "submit" | undefined;
	loading?: boolean;
}

const buttonStyles = makeStyles(() => ({
	button: {
		height: "40px",
		borderRadius: "20px",
		textTransform: "none",
		fontSize: 15,
		fontWeight: "normal",
		boxShadow: "none",
		lineHeight: "1.2",
	},
	buttonBold: {
		height: "40px",
		borderRadius: "20px",
		textTransform: "none",
		fontSize: 16,
		fontWeight: "bold",
		boxShadow: "none",
		lineHeight: "1.2",
	},
	contained: {
		"&:focus": {
			backgroundColor: modulesColors.primary.dark,
			boxShadow: "none",
		},
		"&:hover": { boxShadow: "none" },
	},
	outlined: {
		borderColor: modulesColors.primary.main,
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
	},
	text: {
		borderColor: modulesColors.primary.main,
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
	},
}));

const QButton: FC<QButtonProps> = ({
	onClick = () => {},
	variant = "contained",
	text,
	icon,
	disabled = false,
	width = "auto",
	bold = false,
	type = "button",
	loading = false,
}) => {
	const classes = buttonStyles();
	return (
		<Box style={{ position: "relative", display: "inline-block", width: width }}>
			<Button
				variant={variant}
				onClick={onClick}
				className={`${bold ? classes.buttonBold : classes.button} ${
					variant === "contained"
						? classes.contained
						: variant === "outlined"
							? classes.outlined
							: classes.text
				}
			`}
				disableFocusRipple
				disableTouchRipple
				startIcon={icon}
				disabled={disabled || loading}
				sx={{ width: width }}
				type={type}
			>
				{text}
			</Button>
			<Box
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					zIndex: 1,
					height: "40px",
				}}
			>
				{loading && <CircularProgress sx={{ padding: "4px" }} />}
			</Box>
		</Box>
	);
};
export default QButton;
