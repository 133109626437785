import React from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: "4px",
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.text.light,
		background: theme.palette.group.main,
		width: "fit-content",
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	icon: {
		marginRight: theme.spacing(2),
		fontSize: "3em",
	},
	indicator: {
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export default function GroupsDataTile({ numberOfGroups }) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div>
				<AccountTreeIcon className={classes.icon} />
			</div>
			<div className={classes.indicator}>
				{/* <Typography variant='h3'>{numberOfGroups}</Typography>
        <Typography variant='body2'>Groups</Typography> */}
				<Typography variant="h4">{numberOfGroups}</Typography>
				<Typography variant="caption">Groups</Typography>
			</div>
		</div>
	);
}
