import { makeStyles } from "@mui/styles";
import { colors } from "../../Theme";
import { modulesColors, modulesTheme } from "./modulesTheme";

export const modulesStyles = makeStyles(() => ({
	hidden: {
		visibility: "hidden",
	},
	appBar: {
		backgroundColor: colors.teal,
		color: colors.white,
	},
	pageBase: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		paddingTop: "40px",
		paddingBottom: "40px",
		paddingLeft: "80px",
		paddingRight: "80px",
		backgroundColor: colors.lightGray,
	},
	pageBaseMobile: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		paddingTop: modulesTheme.spacing(5),
		paddingBottom: modulesTheme.spacing(5),
		paddingLeft: modulesTheme.spacing(2),
		paddingRight: modulesTheme.spacing(2),
		backgroundColor: colors.lightGray,
	},
	fullPage: {
		height: "100vh",
		paddingTop: modulesTheme.spacing(5),
		paddingBottom: modulesTheme.spacing(5),
		paddingLeft: modulesTheme.spacing(8),
		paddingRight: modulesTheme.spacing(8),
		display: "flex",
		margin: "auto",
		backgroundColor: modulesTheme.palette.background.default,
	},
	fullPageMobile: {
		height: "100vh",
		paddingTop: modulesTheme.spacing(2),
		paddingBottom: modulesTheme.spacing(2),
		paddingLeft: modulesTheme.spacing(2),
		paddingRight: modulesTheme.spacing(2),
		width: "100%",
		display: "flex",
		margin: "auto",
		backgroundColor: modulesTheme.palette.background.default,
	},
	loading: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		backgroundColor: colors.lightGray,
	},
	modulesDetailsPageImage: {
		alignSelf: "flex-start",
		maxHeight: "200px",
		maxWidth: "200px",
	},
	modulesNavTitle: {
		textAlign: "center",
		fontWeight: "bold",
	},
	modulesNavButtons: {
		position: "fixed",
		bottom: "0px",
		width: "100%",
		paddingBottom: "20px",
	},
	modulesStaticImage: { width: "190px" },
	modulesItemCentered: {
		display: "flex",
		justifyContent: "center",
	},
	modulesExerciseContainer: {
		maxWidth: "700px",
		width: "100%",
		backgroundColor: colors.white,
		borderRadius: "15px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "20px",
		boxShadow: "0 0 12px " + colors.lightTeal,
		gap: "2px",
	},
	modulesAudioPlayer: {
		maxWidth: "400px",
		width: "100%",
		marginTop: "40px",
		alignSelf: "center",
	},
	modulesVideoPlayer: {
		maxHeight: "300px",
		width: "100%",
		marginTop: "40px",
		alignSelf: "center",
	},
	modulesExerciseImage: {
		maxHeight: "300px",
		width: "100%",
		marginTop: "40px",
		alignSelf: "center",
	},
	infoBox: {
		backgroundColor: colors.lightTeal,
		borderRadius: "8px",
		paddingTop: "12px",
		paddingBottom: "12px",
		paddingRight: "16px",
		paddingLeft: "16px",
	},
	journalBox: {
		borderColor: colors.teal,
		borderStyle: "solid",
		borderRadius: "6px",
		borderWidth: "1px",
		display: "flex",
		marginBottom: 40,
	},
	journalBoxInput: {
		borderRadius: 9,
		borderColor: colors.teal,
		"&:after": { borderBottom: "none !important" },
		"&:before": { borderBottom: "none !important" },
		"&:hover:after": { borderBottom: "none !important" },
		"&:focus:after": { borderBottom: "none !important" },
		"&:hover:before": { borderBottom: "none !important" },
		"&:focus:before": { borderBottom: "none !important" },
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		"&:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.3)",
		},
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
		"&:hover:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
	},
	radioBoxRadioButton: {
		height: 22,
	},
	milestoneListItem: {
		backgroundColor: colors.lightGray,
		borderRadius: 8,
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingRight: "35px",
		paddingLeft: "35px",
		marginBottom: "10px",
	},
	milestoneListItemMobile: {
		backgroundColor: colors.lightGray,
		borderRadius: 8,
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingRight: "12px",
		paddingLeft: "12px",
		marginBottom: "10px",
	},
	modulesHomeMilestoneListItem: {
		backgroundColor: colors.white,
		borderRadius: 8,
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingRight: "35px",
		paddingLeft: "35px",
		marginBottom: "10px",
	},
	modulesHomeMilestoneListItemMobile: {
		backgroundColor: colors.white,
		borderRadius: 8,
		paddingTop: "5px",
		paddingBottom: "5px",
		paddingRight: "12px",
		paddingLeft: "12px",
		marginBottom: "10px",
	},
	milestoneListItemSelected: {
		backgroundColor: colors.white,
		borderColor: colors.teal,
		borderWidth: "1px",
		borderStyle: "solid",
	},
	milestoneListItemFaded: {
		maskImage: "linear-gradient(to bottom, black 0%, transparent 100%)",
	},
	milestoneListItemAvatar: {
		"&.MuiAvatar-root": {
			backgroundColor: colors.midTeal,
			fontWeight: "bold",
			width: "25px",
			height: "25px",
			fontSize: 18,
			marginRight: "10px",
		},
	},
	milestoneListItemAvatarTopAligned: {
		alignSelf: "flex-start",
	},
	milestoneListItemAvatarSelected: {
		"&.MuiAvatar-root": {
			backgroundColor: colors.teal,
		},
	},
	milestoneListItemProgressUnlocked: {
		"&.MuiSvgIcon-root": {
			color: colors.midTeal,
			fontWeight: "bold",
			width: "16px",
			height: "16px",
			marginRight: "5px",
			alignSelf: "center",
		},
	},
	milestoneListItemProgressLocked: {
		"&.MuiSvgIcon-root": {
			color: colors.darkGray,
			fontWeight: "bold",
			width: "16px",
			height: "16px",
			marginRight: "5px",
			alignSelf: "center",
		},
	},
	mileStoneListItemFlex: {
		display: "flex",
		flexDirection: "column",
	},
	milestoneListItemProgress: {
		paddingTop: "2px",
		display: "flex",
	},
	milestoneListItemTextTopAligned: {
		margin: "0px",
	},
	milestoneListItemIcon: {
		"&.MuiListItemIcon-root": {
			minWidth: "0px",
			display: "flex",
			justifyContent: "end",
		},
	},
	milestoneListItemIconLock: {
		width: "20px",
		height: "20px",
		color: colors.gray,
	},
	milestoneListItemIconCheck: {
		width: "20px",
		height: "20px",
		color: colors.teal,
	},
	milestoneListItemIconWatchLater: {
		width: "20px",
		height: "20px",
		color: colors.orange,
	},
	milestoneListItemIconText: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	modulePreviewBox: {
		marginTop: "20px",
		borderColor: colors.teal,
		borderWidth: "1px",
		borderStyle: "solid",
		padding: "40px",
		borderRadius: "20px",
	},
	modulePreviewImage: {
		alignSelf: "flex-start",
		width: "100px",
	},
	modulesLastPageContainer: {
		width: "100%",
		alignSelf: "center",
		paddingLeft: "110px",
	},
	thoughtsFullTextField: {
		borderRadius: 9,
		marginTop: 2,
		borderColor: colors.teal,
		"&:after": { borderBottom: "none !important" },
		"&:before": { borderBottom: "none !important" },
		"&:hover:after": { borderBottom: "none !important" },
		"&:focus:after": { borderBottom: "none !important" },
		"&:hover:before": { borderBottom: "none !important" },
		"&:focus:before": { borderBottom: "none !important" },
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		"&:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.3)",
		},
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
		"&:hover:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
	},
	thoughtsFullBox: {
		borderColor: colors.teal,
		borderStyle: "solid",
		borderRadius: 6,
		borderWidth: "1px",
		height: 280,
	},
	reflectionModalDragBar: {
		"-webkit-app-region": "drag",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		zIndex: 1000,
		position: "fixed",
		width: "100%",
		padding: "0px",
	},
	reflectionModalCloseButton: {
		"-webkit-app-region": "no-drag",
		color: colors.black,
		"&:focus": {
			outline: "none",
		},
		minWidth: "24px",
		padding: "10px",
		borderRadius: "20px",
	},
	reflectionModalSlidesRoot: {
		display: "flex",
		flex: 1,
		flexFlow: "row nowrap",
		height: "100%",
		width: "100%",
		justifyContent: "center",
	},
	reflectionModalLoading: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
	},
	notificationStatusBox: {
		backgroundColor: colors.white,
		borderRadius: "10px",
		borderColor: colors.midGray,
		borderStyle: "solid",
		borderWidth: "1px",
		padding: "16px",
		maxWidth: "800px",
		width: "100%",
	},
	webPushNotificationStatusBox: {
		backgroundColor: colors.white,
		borderRadius: "25px",
		borderColor: colors.midGray,
		borderStyle: "solid",
		borderWidth: "1px",
		padding: "16px",
		maxWidth: "800px",
		width: "100%",
	},
	webPushNotificationStatusEnabledIcon: {
		"&.MuiSvgIcon-root": {
			color: colors.teal,
			width: "20px",
			height: "20px",
			marginRight: "5px",
			alignSelf: "center",
		},
	},
	webPushNotificationStatusDisabledIcon: {
		"&.MuiSvgIcon-root": {
			color: colors.orange,
			width: "20px",
			height: "20px",
			marginRight: "5px",
			alignSelf: "center",
		},
	},
	webPushNotificationStatusErrorIcon: {
		"&.MuiSvgIcon-root": {
			color: colors.orange,
			width: "20px",
			height: "20px",
			marginRight: "5px",
			alignSelf: "center",
		},
	},
	confirmationDialogBox: {
		padding: "20px",
		backgroundColor: colors.white,
		maxWidth: "500px",
	},
	setupContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		maxWidth: "800px",
		height: "100%",
	},
	highlight: {
		color: colors.teal,
	},
	setupIcon: {
		height: 70,
		aspectRatio: 1,
		cursor: "pointer",
		marginRight: 25,
	},
	subscriptionBox: {
		maxWidth: "400px",
		minWidth: "300px",
		border: "solid",
		borderWeight: "2px",
		borderColor: colors.teal,
		borderRadius: "10px",
		padding: "20px",
	},
	subscriptionBoxSelected: {
		maxWidth: "400px",
		minWidth: "300px",
		border: "solid",
		borderWeight: "2px",
		borderColor: colors.teal,
		borderRadius: "10px",
		padding: "20px",
		backgroundColor: colors.lightTeal,
	},
	bulletedList: {
		"&.MuiList-root": {
			listStyle: "disc",
			paddingLeft: "20px",
		},
	},
	bulletedItem: {
		"&.MuiListItem-root": {
			display: "list-item",
			padding: "0px",
		},
	},
	interventionBox: {
		borderColor: colors.teal,
		borderStyle: "solid",
		borderRadius: "6px",
		borderWidth: "1px",
		display: "flex",
	},
	interventionImage: {
		height: 200,
		maxWidth: "100%",
	},
	modulesButtonsVerticalMargin: {
		marginTop: ".7rem !important",
	},
	toolkitGridItem: {
		"&:hover": {
			border: `2px solid ${modulesColors.primary.main}`,
			borderRadius: "1rem",
			cursor: "pointer",
		},
	},
}));
