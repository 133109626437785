import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactWordcloud from "react-wordcloud";

const WORD_COLORS = [
	"#f44336",
	"#e91e63",
	"#9c27b0",
	"#673ab7",
	"#3f51b5",
	"#2196f3",
	"#03a9f4",
	"#00bcd4",
	"#009688",
	"#4caf50",
	"#8bc34a",
	// "#cddc39",
	// "#ffeb3b",
	"#ffc107",
	"#ff9800",
	"#ff5722",
];

const minSize = [475, 475];
const options = {
	colors: WORD_COLORS,
	rotations: 1,
	rotationAngles: [0, 0],
	fontSizes: [12, 40],
	padding: 3,
	deterministic: true,
	randomSeed: "seed1",
	transitionDuration: 250,
	fontFamily: "roboto",
	enableOptimizations: true,
};

// TODO: May need this example of how to setup callbacks in the future
// TODO: https://github.com/chrisrzhou/react-wordcloud
// const callbacks = {
// getWordColor: (word) => (word.value > 10 ? "blue" : "red"),
// onWordClick: (word) => window.api.log(`onWordClick: ${word.text}`),
// onWordMouseOver: (word) => window.api.log(`onWordMouseOver: ${word.text}`),
// getWordTooltip: customTooltip,
// };

function groupInsightsTooltip(word) {
	return `${word.text} (Count: ${word.value}, Participants: ${word.users})`;
}
export default function GroupInsightsWordcloud({ words, getWordColor, onWordClick }) {
	const callbacks = useMemo(() => {
		return {
			getWordTooltip: groupInsightsTooltip,
			onWordClick: onWordClick,
			getWordColor: getWordColor,
		};
	}, [getWordColor, onWordClick]);

	return (
		<Paper style={{ borderRadius: 15 }}>
			<ReactWordcloud
				words={words}
				minSize={minSize}
				options={options}
				callbacks={callbacks}
			/>
		</Paper>
	);
}

export const MemoizedMyWordcloud = React.memo(GroupInsightsWordcloud);

GroupInsightsWordcloud.propTypes = {
	words: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	getWordColor: PropTypes.func.isRequired,
	onWordClick: PropTypes.func.isRequired,
};
