import { Grid, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useReflectionModelContext } from "../../../libs/typedContextLib";
import { ModulesLegacyPage } from "../../../model/modules/modules.Model";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

const MAX_CHARACTERS = 500;

interface ThoughtsSlideProps {
	page: ModulesLegacyPage;
}

const ThoughtsSlide: FC<ThoughtsSlideProps> = ({ page }) => {
	const { thoughts, setThoughts, emotionStimList } = useReflectionModelContext();
	const [charRemaining, setcharRemaining] = useState(MAX_CHARACTERS);

	const classes = modulesStyles();
	//@ts-expect-error e is not typed
	const onTextFieldChange = (e) => {
		const charCount = MAX_CHARACTERS - e.target.value.length;

		if (charCount >= 0) {
			setcharRemaining(charCount);
			setThoughts(e.target.value);
		}
	};

	const charRemainingString = () => {
		if (charRemaining < MAX_CHARACTERS) {
			return `Characters left: ${charRemaining}`;
		} else {
			return "";
		}
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container spacing="20px">
				<Grid item mobile={12}>
					{emotionStimList.length > 0 && (
						<div>
							<Typography variant="h5">You said you felt...</Typography>
							<Typography style={{ paddingLeft: 10 }}>
								{emotionStimList.map((x) => (
									<li key={x.emotion}>
										{x.emotion.toString() +
											x.stimuli.map(
												(stimulus, index, stimuli) =>
													(stimuli.length > 0 && index === 0
														? " by"
														: "") +
													(stimuli.length > 1 &&
													index === stimuli.length - 1
														? " and "
														: " ") +
													stimulus.title.toString(),
											)}
									</li>
								))}
							</Typography>
						</div>
					)}
				</Grid>
				<Grid item mobile={12}>
					<Typography variant="h5">Talk it out...</Typography>
					<TextField
						className={classes.thoughtsFullBox}
						variant="filled"
						fullWidth
						id="thoughts"
						name="thoughts"
						type="thoughts"
						multiline
						rows={11}
						maxRows={11}
						label="Your thoughts are not visible to anyone but you. Type away."
						InputProps={{ className: classes.thoughtsFullTextField }}
						value={thoughts}
						onChange={onTextFieldChange}
						helperText={charRemainingString()}
					/>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default ThoughtsSlide;
