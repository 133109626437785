import { Box, Stack, Typography } from "@mui/material";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import QButton from "src/components/common/QButton";
import { NavBarState } from "src/components/home/NavBar";
import { useReflectionModelContext, useTypedAppContext } from "../../../libs/typedContextLib";
import { ModulesNavButtonConfig } from "../../../model/modules/modules.Model";

export interface ModulesNavProps {
	children: ReactNode;
	pageTitle?: string;
	buttonConfig?: ModulesNavButtonConfig;
	backgroundImage?: string;
}

const NAV_BUTTON_DEFAULTS: ModulesNavButtonConfig = {
	backButton: true,
	backButtonText: "Back",
	forwardButton: true,
	forwardButtonText: "Next",
};

const ModulesNav: FC<ModulesNavProps> = ({
	children,
	pageTitle,
	buttonConfig,
	backgroundImage,
}) => {
	const { goBackward, goForward } = useReflectionModelContext();
	const {
		UIContext: { appHeight, appWidth, navBarState },
	} = useTypedAppContext();
	const navButtonConfig = { ...NAV_BUTTON_DEFAULTS, ...buttonConfig };
	const ref = useRef<HTMLDivElement | null>(null);
	const [padding, setPadding] = useState(0);
	const [visibility, setVisibility] = useState("visible");

	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			if (ref.current?.firstElementChild) {
				const contentHeight = ref.current.firstElementChild.getBoundingClientRect().height;
				const viewHeight = ref.current.getBoundingClientRect().height;
				const heightDifference = (viewHeight - contentHeight) / 2;
				const pad = heightDifference > 0 ? heightDifference : 0;
				setPadding(pad);
				setVisibility("visible");
			}
		});

		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				resizeObserver.unobserve(ref.current);
			}
		};
	}, [appHeight, appWidth]);

	const navChildrenStyle = {
		modulesNavChildren: {
			minWidth: "375px",
			width: "100%",
			height: "100%",
			display: "block",
			overflowY: "auto",
			paddingLeft: navBarState === NavBarState.FULL ? "16px" : "64px",
			paddingRight: navBarState === NavBarState.FULL ? "16px" : "64px",
			paddingTop: `${padding.toString()}px`,
			visibility: visibility,
		},
		modulesNaveChildrenBackgroundImage: {
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundImage: `url(${backgroundImage})`,
		},
	};

	return (
		<Stack
			spacing="20px"
			width="100%"
			sx={{
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundImage: `url(${backgroundImage})`,
				borderRadius: "20px",
			}}
		>
			{pageTitle && (
				<>
					<Typography variant="h6" textAlign="center" paddingTop="10px">
						{pageTitle}
					</Typography>
				</>
			)}

			<Box ref={ref} sx={navChildrenStyle.modulesNavChildren}>
				{children}
			</Box>

			<Stack direction="row" spacing="20px" justifyContent="center" paddingBottom="20px">
				{navButtonConfig.backButton ? (
					<QButton
						onClick={goBackward}
						text={navButtonConfig.backButtonText ?? ""}
						variant="outlined"
					/>
				) : (
					<></>
				)}
				{navButtonConfig.forwardButton ? (
					<QButton onClick={goForward} text={navButtonConfig.forwardButtonText ?? ""} />
				) : (
					<></>
				)}
			</Stack>
		</Stack>
	);
};

export default ModulesNav;
