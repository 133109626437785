import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import React, { FC } from "react";
import QButton from "src/components/common/QButton";
import ReminderEditControl from "../../reminder/ReminderEditControl";

export interface ModulesReminderEditDialogProps {
	open: boolean;
	setOpen: (x: boolean) => void;
	time: string;
	setTime: (x: string) => void;
	days: boolean[];
	setDays: (x: boolean[]) => void;
	save: () => void;
	cancel: () => void;
}

const ModulesReminderEditDialog: FC<ModulesReminderEditDialogProps> = ({
	open,
	setOpen,
	time,
	setTime,
	days,
	setDays,
	save,
	cancel,
}) => {
	const handleClose = () => {
		cancel();
		setOpen(false);
	};

	const handleSave = () => {
		save();
		setOpen(false);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">Configure Reminder</DialogTitle>
			<DialogContent>
				<ReminderEditControl days={days} setDays={setDays} time={time} setTime={setTime} />
			</DialogContent>
			<DialogActions>
				<Tooltip title="Save">
					<QButton text="Save" variant="text" onClick={handleSave} />
				</Tooltip>
				<Tooltip title="Cancel">
					<QButton text="Cancel" variant="text" onClick={handleClose} />
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
};

export default ModulesReminderEditDialog;
