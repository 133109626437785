import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { FC } from "react";
import { colors } from "../../Theme";
import { getAppStage } from "../../index";
import { modulesTheme } from "../Modules/modulesTheme";

const StageFlag: FC = () => {
	const stage = getAppStage();
	const isProd = stage === "prod";
	const text = ` [ ${getAppStage().toUpperCase()} ] `;
	return (
		<ThemeProvider theme={modulesTheme}>
			{!isProd && (
				<Box
					style={{
						backgroundColor: colors.white,
						position: "fixed",
						top: 0,
						left: "50%",
						padding: "1px 5px 1px 5px",
						transform: "translate(-50%)",
						border: "2px solid white",
						borderRadius: "0px 0px 10px 10px",
						borderTop: "none",
						borderColor: "#ff6700",
						zIndex: "10000",
					}}
				>
					<Typography color="#ff6700" variant="body" sx={{ fontWeight: "bold" }}>
						{text}
					</Typography>
				</Box>
			)}
		</ThemeProvider>
	);
};

export default StageFlag;
