import { ReflectionModules } from "./reflection.Model";

export const generateReflectionPathName = (
	reflectionModules: ReflectionModules,
	moduleId: string,
	increment = true,
) => {
	let pathName = "/home?";
	pathName += "showReflection=true";
	pathName += `&incrementReflection=${increment}`;
	pathName += `&moduleId=${moduleId}`;
	pathName += "&reflectionModules=" + encodeURIComponent(JSON.stringify(reflectionModules));

	return pathName;
};
