import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import StageFlag from "src/components/common/StageFlag";
import AppBar from "../components/home/AppBar";
import NavBar, { NavBarState } from "../components/home/NavBar";
import { useAppContext } from "../libs/contextLib";
import { useTypedAppContext } from "../libs/typedContextLib";
import { HOME_PAGE_ROUTES } from "../model/pages.Model";
import { isModulesOrToolkitReflection } from "../model/reflection/reflection.Model";
import DashboardSharedGroups from "./DashboardSharedGroups";
import DashboardUser from "./DashboardUser";
import DownloadPage from "./Download";
import GroupManagement from "./GroupManagement";
import Members from "./Members";
import Modules from "./Modules";
import ModuleDetails from "./ModulesDetails";
import MyRoutine from "./MyRoutine";
import MyToolkits from "./MyToolkits";
import MyValues from "./MyValues";
import Profile from "./Profile";
import ReflectionModal from "./ReflectionModal";
import { AccountUpgrade } from "./SharedInsights";
import Subscription from "./Subscription";

const Home = () => {
	const {
		appDispatch,
		appState: { invitations, selectedSubscriptionIsAdmin },
	} = useAppContext();
	const {
		UIContext: { navBarVisible, setNavBarVisible, navBarState, homeHidden, setHomeHidden },
	} = useTypedAppContext();
	const [toggleHome, setToggleHome] = useState(homeHidden);
	const [showReflectionModal, setShowReflectionModal] = useState(false);
	const [reflectionModules, setReflectionModules] = useState({});
	const history = useNavigate();
	const location = useLocation();

	const loadUrlQueryParams = () => {
		const queryParams = new URLSearchParams(location.search);
		// process showReflection
		const tempShowReflection = queryParams.get("showReflection");
		if (tempShowReflection === "true") {
			if (!showReflectionModal) {
				setShowReflectionModal(true);
			}
		} else {
			if (showReflectionModal) {
				setShowReflectionModal(false);
			}
		}
		//process reflectionModules
		const tempReflectionModulesURI = queryParams.get("reflectionModules");
		const tempReflectionActivityURI = queryParams.get("reflectionActivity");
		const tempReflectionModuleId = queryParams.get("moduleId");
		const tempReflectionNoIncrement =
			queryParams.get("incrementReflection") === "false" ? false : true;
		const tempReflectionModulesJSON = decodeURIComponent(tempReflectionModulesURI);
		const tempReflectionActivityJSON = decodeURIComponent(tempReflectionActivityURI);
		const newReflectionModules = {
			...JSON.parse(tempReflectionModulesJSON, tempReflectionActivityJSON),
			...{ incrementReflection: tempReflectionNoIncrement, moduleId: tempReflectionModuleId },
		};
		if (JSON.stringify(newReflectionModules) !== JSON.stringify(reflectionModules)) {
			setReflectionModules(newReflectionModules);
		}
	};
	loadUrlQueryParams();

	const hideReflectionModal = () => {
		setShowReflectionModal(false);
		appDispatch({ type: "refreshReflectionData", refreshReflectionData: true });
		if (isModulesOrToolkitReflection(reflectionModules)) {
			if (reflectionModules.moduleId) {
				history(`intentions/${reflectionModules.moduleId}`);
			} else {
				if (reflectionModules?.toolkit) {
					history("toolkit");
				} else {
					history("intentions");
				}
			}
		} else {
			history("dashboard_user");
		}
	};

	React.useEffect(() => {
		if (
			!!invitations &&
			invitations.length > 0 &&
			invitations.find((invitation) => !invitation.seen)
		) {
			history("/home/profile");
		}
	}, [invitations]);

	const styles = {
		container: {
			flex: 1,
			display: "flex",
			flexDirection: "row",
			height: "100%",
		},
		contentContainer: {
			display: "flex",
			flex: 1,
			alignSelf: "stretch",
			paddingTop: navBarVisible ? "0px" : "56px",
		},
		hidden: {
			display: "none",
		},
	};
	useEffect(() => {
		if (navBarState === NavBarState.SHIFT) {
			setNavBarVisible(true);
		} else {
			setNavBarVisible(false);
		}
	}, [navBarState]);

	useEffect(() => {
		if (
			(navBarState === NavBarState.FULL && navBarVisible) ||
			(navBarState !== NavBarState.SHIFT && showReflectionModal)
		) {
			setHomeHidden(true);
			setToggleHome(true);
		} else {
			setHomeHidden(false);
			setToggleHome(false);
		}
	}, [navBarState, navBarVisible, showReflectionModal]);

	return (
		<>
			<ReflectionModal
				showReflectionModal={showReflectionModal}
				hideReflectionModal={hideReflectionModal}
				reflectionModules={reflectionModules}
			/>
			<StageFlag />
			<div
				style={{
					...styles.container,
					flexWrap: navBarState === NavBarState.OVERLAY ? "wrap" : "nowrap",
				}}
			>
				<AppBar />
				<NavBar />

				<div style={toggleHome ? styles.hidden : styles.contentContainer}>
					<Routes>
						<Route
							path={HOME_PAGE_ROUTES.dashboardUser.route}
							element={<DashboardUser />}
						/>
						<Route
							path={HOME_PAGE_ROUTES.sharedInsights.route}
							element={<DashboardSharedGroups />}
						/>
						<Route
							path={HOME_PAGE_ROUTES.sharedInsightsTrial.route}
							element={<AccountUpgrade />}
						/>
						<Route path={HOME_PAGE_ROUTES.values.route} element={<MyValues />} />
						<Route path={HOME_PAGE_ROUTES.intentions.route} element={<Modules />} />
						<Route
							path={HOME_PAGE_ROUTES.intention_details.route}
							element={<ModuleDetails />}
						/>
						<Route path={HOME_PAGE_ROUTES.toolkit.route} element={<MyToolkits />} />
						<Route path={HOME_PAGE_ROUTES.notification.route} element={<MyRoutine />} />
						<Route path={HOME_PAGE_ROUTES.profile.route} element={<Profile />} />
						<Route path={HOME_PAGE_ROUTES.download.route} element={<DownloadPage />} />
						{selectedSubscriptionIsAdmin && (
							<>
								<Route
									path={HOME_PAGE_ROUTES.adminMembers.route}
									element={<Members />}
								/>
								<Route
									path={HOME_PAGE_ROUTES.adminGroups.route}
									element={<GroupManagement />}
								/>
								<Route
									path={HOME_PAGE_ROUTES.adminSubscription.route}
									element={<Subscription />}
								/>
							</>
						)}
						<Route path="*" element={<DashboardUser />} />
					</Routes>
				</div>
			</div>
		</>
	);
};

export default Home;
