import { Stack, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { modulesStyles } from "../components/Modules/modulesStyles";
import { modulesTheme } from "../components/Modules/modulesTheme";
import { NavBarState } from "../components/home/NavBar";
import NotificationTargetList from "../components/notification/NotificationTargetList";
import RemindersList from "../components/reminder/RemindersList";
import { useTypedAppContext } from "../libs/typedContextLib";

const MyRoutine = () => {
	const {
		UIContext: { navBarState },
	} = useTypedAppContext();
	const classes = modulesStyles();
	return (
		<ThemeProvider theme={modulesTheme}>
			<div
				className={
					navBarState === NavBarState.SHIFT ? classes.pageBase : classes.pageBaseMobile
				}
			>
				<Stack
					direction="column"
					spacing="20px"
					alignSelf="center"
					maxWidth="800px"
					width="100%"
					height="100%"
					justifyContent="center"
				>
					<Typography variant="h1">My Routine</Typography>
					<Typography variant="body">
						Receive notifications from the following apps
					</Typography>
					<NotificationTargetList returnPath="/home/routine" />
					<RemindersList />
				</Stack>
			</div>
		</ThemeProvider>
	);
};

export default MyRoutine;
