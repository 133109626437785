import { MIN_GROUP_PARTICIPATION, MY_INSIGHTS, dataType, dateRange } from "../shared/constants";
import {
	addLeadingZero,
	amOrPm,
	convertGroupInsightDateToNumber,
	convertTo12Hour,
	getWeek,
	twoDigitYear,
	weeksDiff,
} from "./HelperFunctions";

import WeekUtils from "week-utils";

const weekUtils = new WeekUtils();

const dateRangeNumbers = {
	ONE_WEEK: 7,
	ONE_MONTH: 30,
	SIX_MONTHS: 180,
	ONE_YEAR: 365,
	MAX: "MAX",
};

const dateRangeMultiplier = 24 * 60 * 60 * 1000;
const weekMS = 7 * dateRangeMultiplier;

function mapGroupMoodIndexData(data, index) {
	// TODO: Cap this at 1 to avoid weird scenarios where people
	// TODO: leave a group midweek after submitting a reflection
	let rawParticipationRate = data.participation / data.groupSize;
	rawParticipationRate = rawParticipationRate > 1 ? 1 : rawParticipationRate;

	let participationRate = (rawParticipationRate * 100).toFixed(2);

	return {
		date: data.timestamp,
		// Only display data points when there is sufficient participation
		feeling: data.participation >= MIN_GROUP_PARTICIPATION ? data.groupMoodIndex : NaN,
		participation: `${participationRate}%`,
		fakeIndex: index,
	};
}

function mapGroupEmotionWordsData(data, emotionWords) {
	if (data.participation >= MIN_GROUP_PARTICIPATION) {
		if (data.groupEmotionWordsData) {
			Object.keys(data.groupEmotionWordsData).forEach((key) => {
				const lowerCaseKey = key.toLowerCase();
				let wordInfo = data.groupEmotionWordsData[key];
				let emotionEntry = emotionWords.find(
					(element) => element.text.toLowerCase() === lowerCaseKey,
				);

				if (emotionEntry) {
					emotionEntry.value += wordInfo.count;
					emotionEntry.users = { ...emotionEntry.users, ...wordInfo.users };
				} else {
					emotionWords.push({
						text: capitalize(lowerCaseKey),
						value: wordInfo.count,
						users: wordInfo.users,
					});
				}
			});
		}
	}

	return emotionWords;
}

function mapGroupStimulusData(data, stimulusWords) {
	if (data.participation >= MIN_GROUP_PARTICIPATION) {
		if (data.groupStimulusData) {
			Object.entries(data.groupStimulusData).forEach((entry) => {
				const stimulusKey = entry[1].stimulus.toLowerCase();
				const emotionKey = entry[1].emotion.toLowerCase();
				let stimulusEntry = stimulusWords.find(
					(element) =>
						element.stimulus.toLowerCase() === stimulusKey &&
						element.emotion.toLowerCase() === emotionKey,
				);

				if (stimulusEntry) {
					stimulusEntry.value += parseInt(entry[1].count);
				} else {
					stimulusWords.push({
						emotion: capitalize(emotionKey),
						stimulus: capitalize(stimulusKey),
						value: parseInt(entry[1].count),
					});
				}
			});
		}
	}

	return stimulusWords;
}

function capitalize(s) {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
}

function calculateUniqueUsersForGroupEmotionWords(emotionWords) {
	Object.keys(emotionWords).forEach((key) => {
		let users = emotionWords[key].users;
		let numUsers = Object.keys(users).length;

		emotionWords[key].users = numUsers;
	});

	return emotionWords;
}

function mapMyInsightEmotionWordData(data, emotionWords) {
	const lowerCaseWord = data.emotionWord.toLowerCase();
	const capitalizedWord = capitalize(lowerCaseWord);

	let emotionEntry = emotionWords.find((element) => element.text === capitalizedWord);

	if (emotionEntry) {
		emotionEntry.value++;
	} else {
		if (capitalizedWord !== " ") {
			emotionWords.push({
				text: capitalizedWord,
				value: 1,
			});
		}
	}

	return emotionWords;
}

function createMyInsightsTableData(
	timestamp,
	date,
	emotion,
	thoughts,
	feeling,
	stimulus,
	stimulusCount,
	value,
	multiEmotion,
	multiEmotionIndex,
) {
	return {
		timestamp,
		date,
		emotion,
		thoughts,
		feeling,
		stimulus,
		stimulusCount,
		value,
		multiEmotion,
		multiEmotionIndex,
	};
}

function mapMyInsightsTableData(data, tableData) {
	const lowerCaseWord = data.emotionWord.toLowerCase();
	const capitalizedWord = capitalize(lowerCaseWord);
	const date = `${formatDate(data.timestamp)}, ${formatTime(data.timestamp)}`;
	let stimulusCount = 1;
	if (!data.stimulus || typeof data.stimulus === "string") {
		tableData.push(
			createMyInsightsTableData(
				data.timestamp,
				date,
				capitalizedWord,
				data.thoughts,
				data.moodIndex,
				data.stimulus ? capitalize(data.stimulus.toLowerCase()) : undefined,
				stimulusCount,
				data.value ? capitalize(data.value.toLowerCase()) : undefined,
				data.multiEmotion ? data.multiEmotion : false,
				data.multiEmotionIndex ? data.multiEmotionIndex : 0,
			),
		);
	} else {
		stimulusCount = data.stimulus.length;
		let stimulusList = [];
		for (let stim of data.stimulus) {
			stimulusList.push(capitalize(stim.toLowerCase()));
		}
		tableData.push(
			createMyInsightsTableData(
				data.timestamp,
				date,
				capitalizedWord,
				data.thoughts,
				data.moodIndex,
				stimulusList,
				stimulusCount,
				data.value ? capitalize(data.value.toLowerCase()) : undefined,
				data.multiEmotion ? data.multiEmotion : false,
				data.multiEmotionIndex ? data.multiEmotionIndex : 0,
			),
		);
	}
	return tableData;
}
/* dataType: {
    MOOD: "moodIndexData",
    EMOTION: "emotionWordData",
    TABLE: "tableData",
    KUDOS: "kudosData",
  },*/
function mapMyInsightData(rawData) {
	let mappedData = {
		[dataType.MOOD]: {},
		[dataType.EMOTION]: {},
		[dataType.TABLE]: {},
		domains: {},
		stimulus: {},
	};

	mappedData.moodIndexData[dateRange.MAX] = [];
	mappedData.emotionWordData[dateRange.MAX] = [];
	mappedData.tableData[dateRange.MAX] = [];
	mappedData.stimulus[dateRange.MAX] = [];

	let index = 0;
	for (let data of rawData) {
		// moodIndex Data
		mappedData.moodIndexData[dateRange.MAX].push({
			date: data.timestamp,
			feeling: data.moodIndex,
			fakeIndex: index,
			multiEmotionIndex: data.multiEmotionIndex,
		});

		//emotionWord Data
		mapMyInsightEmotionWordData(data, mappedData.emotionWordData[dateRange.MAX]);

		//table Data
		mapMyInsightsTableData(data, mappedData.tableData[dateRange.MAX]);

		// increment index
		index++;
	}
	// Calculate domains
	mappedData.domains = calculateDomainsMyInsights(mappedData.moodIndexData[dateRange.MAX]);
	// Create filtered data for moodIndexData and tableData
	createFilteredData(mappedData);

	//create filtered data for emotionWordData
	createFilteredEmotionData(rawData, mappedData);

	return mappedData;
}

// Creates moodIndexData and tableDate for the the values in dateRange
function createFilteredData(mappedData) {
	Object.values(dateRange).forEach((range) => {
		if (range !== dateRange.MAX) {
			// get domain
			const [startIndex, lastIndex] = mappedData.domains[range];

			// moodIndexData
			mappedData.moodIndexData[range] = mappedData.moodIndexData[dateRange.MAX].slice(
				startIndex,
				lastIndex + 1,
			);

			// tableData
			mappedData.tableData[range] = mappedData.tableData[dateRange.MAX].slice(
				startIndex,
				lastIndex + 1,
			);
		}
	});
}

// Creates moodIndexData and tableDate for the the values in dateRange
function createFilteredEmotionData(rawData, mappedData) {
	Object.values(dateRange).forEach((range) => {
		if (range !== dateRange.MAX) {
			// intialize array
			mappedData.emotionWordData[range] = [];

			// get domain
			const [startIndex, lastIndex] = mappedData.domains[range];

			// get filtered raw data
			let filteredData = rawData.slice(startIndex, lastIndex + 1);

			for (let data of filteredData) {
				//emotionWord Data
				mapMyInsightEmotionWordData(data, mappedData.emotionWordData[range]);
			}
		}
	});
}

// Creates moodIndexData and tableDate for the the values in dateRange
function createFilteredDataGroupInsights(mappedData) {
	Object.values(dateRange).forEach((range) => {
		if (range !== dateRange.MAX) {
			// get domain
			const [startIndex, lastIndex] = mappedData.domains[range];

			// moodIndexData
			mappedData.moodIndexData[range] = mappedData.moodIndexData[dateRange.MAX].slice(
				startIndex,
				lastIndex + 1,
			);
		}
	});
}

// Creates moodIndexData and tableDate for the the values in dateRange
function createFilteredEmotionDataGroupInsights(rawData, mappedData) {
	Object.values(dateRange).forEach((range) => {
		if (range !== dateRange.MAX) {
			// intialize array
			mappedData.emotionWordData[range] = [];
			mappedData.stimulusData[range] = [];

			// get domain
			const [startIndex, lastIndex] = mappedData.domains[range];

			// get filtered raw data
			let filteredData = rawData.slice(startIndex, lastIndex + 1);

			for (let data of filteredData) {
				//emotionWord Data
				mapGroupEmotionWordsData(data, mappedData.emotionWordData[range]);
				//stimulusData
				mapGroupStimulusData(data, mappedData.stimulusData[range]);
			}

			// After collating all of the groupEmotionWord data
			// Figure out how many unique users used the word
			calculateUniqueUsersForGroupEmotionWords(mappedData.emotionWordData[range]);
		}
	});
}

// Return number of weeks between date1 and date2 (inclusive)
function weeksBetween(date1, date2) {
	let date1Parts = date1.split("_");
	let date2Parts = date2.split("_");

	let monday1 = weekUtils.getWeekDate(date1Parts[0], date1Parts[1]).weekStart;
	let monday2 = weekUtils.getWeekDate(date2Parts[0], date2Parts[1]).weekStart;

	return weeksDiff(monday1, monday2);
}

// Creates an array of consecutive timestamps of the form YEAR_WEEKNUM
// starting at start date
function createConsecutiveTimestamps(startDate, length) {
	let startDateParts = startDate.split("_");

	let date = weekUtils.getWeekDate(startDateParts[0], startDateParts[1]).weekStart;

	// explicitly set time to 1p to avoid problems with daylight savings time
	date.setHours(13);

	let result = [];
	for (let i = 0; i < length; i++) {
		result.push(`${date.getFullYear()}_${addLeadingZero(weekUtils.curWeek(date))}`);
		date = new Date(date.getTime() + weekMS);
	}

	return result;
}

// Returns YEAR_WEEKNUM for week after timestamp
function getNextTimestamp(timestamp) {
	const timestampParts = timestamp.split("_");

	const monday = weekUtils.getWeekDate(timestampParts[0], timestampParts[1]).weekStart;

	// explicitly set time to 1p to avoid problems with daylight savings time
	monday.setHours(13);

	const nextMonday = new Date(monday.getTime() + weekMS);
	return `${nextMonday.getFullYear()}_${weekUtils.curWeek(nextMonday)}`;
}

// Returns YEAR_WEEKNUM for current week
function getCurrentWeekTimestamp() {
	const today = new Date();
	return `${today.getFullYear()}_${weekUtils.curWeek(today)}`;
}

function createPlaceholderData(timestamp) {
	return {
		// TODO: I don't believe groupEmotionWords is used anywhere
		groupEmotionWords: [],

		groupEmotionWordsData: {},
		groupMoodIndex: 0,
		lastUpdated: 0,
		participation: 0,
		timestamp,
	};
}

// Recursive algorithm used to fill in missing data with placeholders
function fillInTheBlanks(data = [], s = 0, e = 0) {
	const goalLength = weeksBetween(s, e);

	let actualLength = data.length;

	// BASE CASE
	if (data.length === 0) {
		return [createPlaceholderData(s)];
	}

	// BASE CASE
	if (data.length === 1) {
		const numberOfWeeks = weeksBetween(s, e);
		let timestamps = createConsecutiveTimestamps(s, numberOfWeeks);
		let blanksArray = [];

		//for (i=s; s <= e, i++)
		for (let i = 0; i < numberOfWeeks; i++) {
			if (data[0].timestamp === timestamps[i]) {
				blanksArray.push(data[0]);
			} else {
				blanksArray.push(createPlaceholderData(timestamps[i]));
			}
		}

		return blanksArray;
	}

	// BASE CASE
	if (actualLength === goalLength) {
		return data;
	}

	// Cut array in half
	const m = Math.floor(data.length / 2);
	let arrayA = data.slice(0, m);
	let arrayB = data.slice(m, data.length);

	// Determine S's and E's
	let sA;
	let eA;

	if (arrayA.length > 0) {
		sA = s;
		eA = arrayA[arrayA.length - 1].timestamp;
	} else {
		sA = s;
		eA = s;
	}

	let sB = getNextTimestamp(eA);
	let eB = e;

	let result = [
		...fillInTheBlanks(arrayA, sA, eA),
		...fillInTheBlanks(arrayB, sB, eB), //eslint-disable-line
	];

	return result;
}

function mapGroupInsightData(dataSource, rawData) {
	let mappedData = {
		[dataType.MOOD]: {},
		[dataType.EMOTION]: {},
		[dataType.STIMULUS]: {},
		domains: {},
	};

	// Add placeholders for missing weeks in data
	const rawDataWithBlanksFilled = fillInTheBlanks(
		rawData,
		rawData[0].timestamp,
		getCurrentWeekTimestamp(),
	);

	mappedData.moodIndexData[dateRange.MAX] = [];
	mappedData.emotionWordData[dateRange.MAX] = [];
	mappedData.stimulusData[dateRange.MAX] = [];

	let index = 0;
	for (let data of rawDataWithBlanksFilled) {
		// moodIndex Data
		mappedData.moodIndexData[dateRange.MAX].push(mapGroupMoodIndexData(data, index));

		//emotionWord Data
		mapGroupEmotionWordsData(data, mappedData.emotionWordData[dateRange.MAX]);

		//stimulus Data
		mapGroupStimulusData(data, mappedData.stimulusData[dateRange.MAX]);

		// increment index
		index++;
	}

	// After collating all of the groupEmotionWord data
	// Figure out how many unique users used the word
	calculateUniqueUsersForGroupEmotionWords(mappedData.emotionWordData[dateRange.MAX]);

	// Calculate domains
	mappedData.domains = calculateDomainsGroupInsights(mappedData.moodIndexData[dateRange.MAX]);

	// Create filtered data for moodIndexData and tableData
	createFilteredDataGroupInsights(mappedData);

	//create filtered data for emotionWordData
	createFilteredEmotionDataGroupInsights(rawDataWithBlanksFilled, mappedData);

	//create filtered data for stimulusData
	return mappedData;
}

export function mapData(dataSource, data) {
	let mappedData;
	if (dataSource === MY_INSIGHTS) {
		mappedData = mapMyInsightData(data);
	} else {
		mappedData = mapGroupInsightData(dataSource, data);
	}

	return mappedData;
}

export function getTimeStamp(dataSource, data) {
	if (data.length > 0) {
		const lastDataItem = data[data.length - 1];

		return dataSource === MY_INSIGHTS ? +lastDataItem.timestamp : lastDataItem.lastUpdated;
	}

	return "";
}

export function updateCache(dataSource, data, reflectionCache) {
	return { ...reflectionCache, [dataSource]: data };
}

export function hasDataChanged(dataSource, newData, reflectionCache) {
	if (newData.length > 0) {
		// No cache exists
		if (!reflectionCache[dataSource]) {
			return true;
		}

		const newDataTimeStamp = getTimeStamp(dataSource, newData);

		// Cache exists but is stale
		if (reflectionCache[dataSource].timestamp !== newDataTimeStamp) {
			return true;
		}
	}

	return false;
}

export function getDomainIndices(dataSource, reflectionCache, range = dateRange.MAX) {
	if (reflectionCache[dataSource]) {
		return reflectionCache[dataSource].data.domains[range];
	}

	return [];
}

export function getData(dataSource, reflectionCache, dataType, range = dateRange.MAX) {
	if (reflectionCache[dataSource]) {
		let result = reflectionCache[dataSource].data[dataType][range];
		return result;
	}

	return [];
}

export function getFilteredStimulusData(
	dataSource,
	reflectionCache,
	dataType,
	range = dateRange.MAX,
	emotion,
) {
	if (reflectionCache[dataSource]) {
		let result = reflectionCache[dataSource].data[dataType][range];
		if (emotion) {
			return result.filter((value) => value.emotion === emotion);
		}
		return result;
	}

	return [];
}

export function getDataDomains(dataSource, reflectionCache) {
	if (reflectionCache[dataSource]) {
		return reflectionCache[dataSource].data.domains;
	}

	return {};
}

export function randomNumberInRange(max) {
	return Math.floor(Math.random() * max + 1) - 1;
}

// d=data, t=target, s=start, e=end, m=middle
function binarySearchMyInsights(d, t, s, e) {
	// If there is only one data point, return it.
	if (d.length === 1) return d[0].fakeIndex;

	const m = Math.floor((s + e) / 2);
	if (t === d[m].date) return d[m].fakeIndex;
	if (e - 1 === s)
		return Math.abs(d[s].date - t) > Math.abs(d[e].date - t) ? d[e].fakeIndex : d[s].fakeIndex;
	if (t > d[m].date) {
		return binarySearchMyInsights(d, t, m, e);
	}
	if (t < d[m].date) {
		return binarySearchMyInsights(d, t, s, m); //eslint-disable-line
	}
}

function binarySearchGroupInsights(d, t, s, e) {
	// If there is only one data point, return it.
	if (d.length === 1) return d[0].fakeIndex;

	const m = Math.floor((s + e) / 2);
	// Need to convert timestamps to numbers so they can be compared
	const mDateNum = convertGroupInsightDateToNumber(d[m].date);
	const sDateNum = convertGroupInsightDateToNumber(d[s].date);
	const eDateNum = convertGroupInsightDateToNumber(d[e].date);

	if (t === mDateNum) return d[m].fakeIndex;
	if (e - 1 === s)
		return Math.abs(sDateNum - t) > Math.abs(eDateNum - t) ? d[e].fakeIndex : d[s].fakeIndex;
	if (t > mDateNum) {
		return binarySearchGroupInsights(d, t, m, e);
	}
	if (t < mDateNum) {
		return binarySearchGroupInsights(d, t, s, m); //eslint-disable-line
	}
}

// Returns time in milliseconds for date created
function createPastDate(dateRange) {
	let now = new Date(Date.now());
	now.setHours(0, 0, 0, 0);

	let pastDate = new Date(now.getTime() - dateRangeNumbers[dateRange] * dateRangeMultiplier);

	return pastDate.getTime();
}

export function calculateDomainsMyInsights(data) {
	const lastIndex = data.length - 1;
	const domains = {};

	// MAX
	if (data.length > 0) {
		domains[dateRange.MAX] = [0, lastIndex];
	}

	// Calculate domains for other ranges
	Object.values(dateRange).forEach((dateRangeValue) => {
		if (dateRangeValue !== dateRange.MAX) {
			let pastDate = createPastDate(dateRangeValue);
			let firstIndex = binarySearchMyInsights(data, pastDate, 0, data.length - 1);
			if (data[firstIndex].multiEmotionIndex) {
				firstIndex = firstIndex - data[firstIndex].multiEmotionIndex;
			}
			// If the last data point is after the pastDate, mark range as empty
			// Because the range values are used as arguments to Array.slice()
			// setting the firstIndex value to greater than the array length
			// ensures an empty array will be returned for the range
			if (pastDate > data[lastIndex].date) {
				domains[dateRangeValue] = [lastIndex + 1, lastIndex];
			} else {
				domains[dateRangeValue] = [firstIndex, lastIndex];
			}
		}
	});

	return domains;
}

export function calculateDomainsGroupInsights(data) {
	const lastIndex = data.length - 1;
	const domains = {};

	// MAX
	if (data.length > 0) {
		domains[dateRange.MAX] = [0, lastIndex];
	}

	// Calculate domains for other ranges
	Object.values(dateRange).forEach((dateRangeValue) => {
		if (dateRangeValue !== dateRange.MAX) {
			let pastDate = new Date(createPastDate(dateRangeValue));
			let pastDateWeekNum = getWeek(pastDate, 1);

			// Create target date
			const targetDate = `${pastDate.getFullYear()}_${pastDateWeekNum}`;
			const targetDateNum = convertGroupInsightDateToNumber(targetDate);

			// Search data for index closest to targetDate
			let firstIndex = binarySearchGroupInsights(data, targetDateNum, 0, data.length - 1);

			// If the last data point is after the pastDate, mark range as empty
			// Because the range values are used as arguments to Array.slice()
			// setting the firstIndex value to greater than the array length
			// ensures an empty array will be returned for the range
			if (targetDateNum > convertGroupInsightDateToNumber(data[lastIndex].date)) {
				domains[dateRangeValue] = [lastIndex + 1, lastIndex];
			} else {
				domains[dateRangeValue] = [firstIndex, lastIndex];
			}
		}
	});

	return domains;
}

function formatDate(rawTime) {
	const date = new Date(+rawTime);
	const year = twoDigitYear(date.getFullYear());
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${month}/${day}/${year}`;
}

function formatTime(rawTime) {
	const date = new Date(+rawTime);
	const hours = convertTo12Hour(date.getHours());
	const minutes = addLeadingZero(date.getMinutes());
	const meridiem = amOrPm(date.getHours());

	return `${hours}:${minutes}${meridiem}`;
}
