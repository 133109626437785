import { Grid } from "@mui/material";
import React, { FC, useState } from "react";
import LinkButton from "../Modules/Shared/LinkButton";
import GoogleCalendarNTI from "../notification/GoogleCalendarNTI";
import BrowserNotificationNTI from "./BrowserNotificationNTI";

export interface NotificationTargetListProps {
	expanded?: boolean;
	returnPath: string;
}

const NotificationTargetList: FC<NotificationTargetListProps> = ({ expanded, returnPath }) => {
	const [showMore, setShowMore] = useState(expanded);
	return (
		<Grid container>
			<Grid item mobile={12} style={{ paddingTop: "1rem" }}>
				<GoogleCalendarNTI returnPath={returnPath} />
			</Grid>
			<Grid item mobile={12} style={{ paddingTop: "1rem" }}>
				{showMore && (
					<>
						<BrowserNotificationNTI />
					</>
				)}
			</Grid>
			<Grid item mobile={12} style={{ paddingTop: "1rem" }}>
				{!expanded && (
					<div>
						<LinkButton
							text={showMore ? "fewer settings" : "more settings"}
							onClick={() => {
								setShowMore(!showMore);
							}}
						/>
					</div>
				)}
			</Grid>
		</Grid>
	);
};

export default NotificationTargetList;
