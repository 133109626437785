import { Grid, Paper, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useTypedAppContext } from "src/libs/typedContextLib";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";

interface NotificationTargetItemProps {
	notificationTargetName: string;
	enabled: boolean;
	statusText: string;
	info: string;
	children: ReactNode;
}

const NotificationTargetItem: FC<NotificationTargetItemProps> = ({
	notificationTargetName,
	enabled,
	statusText,
	info,
	children,
}) => {
	const classes = modulesStyles();

	const {
		UIContext: { appWidth },
	} = useTypedAppContext();
	return (
		<Paper className={classes.notificationStatusBox}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item mobile={12} tablet={6}>
					<Grid direction="row" spacing="10px">
						<Typography variant="h6">{notificationTargetName}</Typography>
						<Typography
							variant="body"
							color={enabled ? "green" : "red"}
							fontStyle="italic"
						>
							{statusText}
						</Typography>
					</Grid>
					<Typography variant="body">{info} </Typography>
				</Grid>
				<Grid
					item
					mobile={12}
					tablet={6}
					style={{
						textAlign:
							appWidth > modulesTheme.breakpoints.values.tablet ? "end" : "start",
						paddingTop:
							appWidth < modulesTheme.breakpoints.values.tablet ? "1rem" : "0",
					}}
				>
					{children}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default NotificationTargetItem;
