import { ReflectionModules } from "@model/reflection/reflection.Model";
import { Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useCarouselContext } from "../../libs/typedContextLib";
import ReflectionModal from "../../pages/ReflectionModal";
import { modulesStyles } from "../Modules/modulesStyles";
import { modulesTheme } from "../Modules/modulesTheme";
import QButton from "../common/QButton";

interface SetupReflectionProps {}

const SetupReflection: FC<SetupReflectionProps> = () => {
	const [showReflectionModal, setShowReflectionModal] = useState(false);
	const [reflectionModules, setReflectionModules] = useState({
		moraleCheckIn: true,
	} as ReflectionModules);
	const { goForward } = useCarouselContext();
	const classes = modulesStyles();

	const hideReflectionModal = () => {
		setShowReflectionModal(false);
		goForward();
	};

	return (
		<>
			<ReflectionModal
				showReflectionModal={showReflectionModal}
				hideReflectionModal={hideReflectionModal}
				reflectionModules={reflectionModules}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				setReflectionModules={setReflectionModules}
			/>
			<Stack
				spacing={modulesTheme.spacing(2)}
				justifyContent="center"
				alignItems="center"
				paddingBottom={modulesTheme.spacing(2)}
			>
				<Typography variant="h5">Submit your first check-in</Typography>
				<Typography variant="body1" paragraph>
					<span className={classes.highlight}>Emotional resilience</span> is a key skill
					for high performers. Resilience in a physical material means the ability to
					bounce back to its normal state of elasticity. Emotional resilience is similar.
					Prolonged stress can lead to physical and emotional breakdown, absenteeism, low
					morale and a feeling of dissatisfaction.
					<br />
					<br />
					Pausing and reflecting a few times a day can help you build a greater emotional
					awareness and resilience so that you can more easily manage and bounce back from
					stressful events and live a healthier life.
				</Typography>
				<QButton
					onClick={() => {
						setShowReflectionModal(true);
					}}
					text="Morale check-in"
				/>
			</Stack>
		</>
	);
};

export default SetupReflection;
