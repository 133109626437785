import { ModulesLegacyPage } from "@model/modules/modules.Model";
import { ArrowDropDown } from "@mui/icons-material";
import { Grid, Menu, MenuItem, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";
import { colors } from "../../../../Theme";
import { useReflectionModelContext } from "../../../../libs/typedContextLib";
import ModulesNav from "../../Shared/ModulesNav";
import { modulesStyles } from "../../modulesStyles";

const MAX_CHARACTERS = 500;

const useTextFieldStyles = makeStyles(() => ({
	root: {
		borderRadius: 9,
		borderColor: colors.teal,
		"&:after": { borderBottom: "none !important" },
		"&:before": { borderBottom: "none !important" },
		"&:hover:after": { borderBottom: "none !important" },
		"&:focus:after": { borderBottom: "none !important" },
		"&:hover:before": { borderBottom: "none !important" },
		"&:focus:before": { borderBottom: "none !important" },
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		"&:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.3)",
		},
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
		"&:hover:focus": {
			backgroundColor: "rgba(255, 255, 255, 0.5)",
		},
	},
	arrow: {
		color: colors.teal,
		alignSelf: "center",
	},
}));

export const PROMPTLIST = {
	TIME_FRAME:
		"Holding this emotion in your mind, would this feeling matter in a week, a month, or a year?",
	WORKED_IN_THE_PAST:
		"When have I felt this feeling before? What contributed to the resolution of this feeling in the past?",
	ASSUMPTIONS:
		"What might be my assumptions about what is happening right now? How are those assumptions contributing to my particular emotion?",
	SUPPORTING_OTHERS:
		"If someone I cared about felt the same way, what might I say to them to express support?",
	SHARE: "What might be contributing to this feeling? Consider sharing how you feel with a colleague or friend, and allow them to see more of you by providing additional context you don't usually include.",
	MEETING:
		"What are some factors specifics about the meeting(s) that make you anxious? Is it lack of time/ predictability associated with an agenda or may be a particular individual? If you had unlimited power in the situation, what would help improve how you feel towards this meeting(s)?",
	CAREER: "What are the obstacles that  may contribute to you moving forward in your career?” What makes you feel engaged and fulfilled in your role? What makes you feel challenged?",
	MENTAL_HEALTH:
		"Identify 3 things that have helped you improve your mental health and contribute positively to your mental health",
};

interface JournalInterventionSlideProps {
	page: ModulesLegacyPage;
	initialPrompt?: string;
}

const JournalInterventionSlide: FC<JournalInterventionSlideProps> = ({ initialPrompt, page }) => {
	const { thoughts, setThoughts, prompt, setPrompt } = useReflectionModelContext();
	const [charRemaining, setcharRemaining] = React.useState(MAX_CHARACTERS);
	const [anchorEl, setAnchorEl] = React.useState();
	const open = Boolean(anchorEl);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const handleClose = (text) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setPrompt(text);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setAnchorEl(null);
	};
	if (prompt === "" && initialPrompt !== "") {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setPrompt(initialPrompt);
	}

	const classes = modulesStyles();

	const textFieldClass = useTextFieldStyles();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const onTextFieldChange = (e) => {
		const charCount = MAX_CHARACTERS - e.target.value.length;

		if (charCount >= 0) {
			setcharRemaining(charCount);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			setThoughts(e.target.value);
		}
	};

	const charRemainingString = () => {
		if (charRemaining < MAX_CHARACTERS) {
			return `Characters left: ${charRemaining}`;
		} else {
			return "";
		}
	};

	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid container justifyContent="center" alignItems="center" spacing="20px">
				<Grid item>
					<Typography variant="h5">Practice journaling</Typography>
				</Grid>
				<Grid item mobile={12}>
					<div
						className={classes.interventionBox}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						//@ts-ignore
						pointerEvents="auto"
						cursor="pointer"
						id="basic-button"
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClick}
					>
						<Typography width={"850px"} minHeight={"30px"} padding={"10px"}>
							{prompt === "" ? <i>Select a prompt...</i> : <strong>{prompt}</strong>}
						</Typography>
						<ArrowDropDown className={textFieldClass.arrow} />
					</div>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => handleClose("")}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{Object.values(PROMPTLIST).map((x, index) => (
							<MenuItem key={index} onClick={() => handleClose(x)}>
								{x}
							</MenuItem>
						))}
					</Menu>
				</Grid>
				<Grid item mobile={12}>
					<TextField
						className={classes.interventionBox}
						variant="filled"
						fullWidth
						id="thoughts"
						name="thoughts"
						type="thoughts"
						multiline
						minRows={11}
						maxRows={11}
						label="Your thoughts are not visible to anyone but you. Type away."
						InputProps={{ className: textFieldClass.root }}
						value={thoughts}
						onChange={onTextFieldChange}
						helperText={charRemainingString()}
					/>
				</Grid>
			</Grid>
		</ModulesNav>
	);
};

export default JournalInterventionSlide;
