import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, MouseEventHandler, ReactNode } from "react";
import { modulesColors } from "../Modules/modulesTheme";

export interface QRoundButtonProps {
	onClick?: MouseEventHandler;
	text: string;
	variant?: "outlined" | "contained" | "text";
	disabled?: boolean;
	icon?: ReactNode;
	diameter?: string;
}

const buttonStyles = makeStyles(() => ({
	button: {
		borderRadius: "50%",
		textTransform: "none",
		fontSize: 14,
		fontWeight: "normal",
		boxShadow: "none",
		minWidth: "0px",
		minHeight: "0px",
		padding: "0px",
		paddingTop: ".2rem",
	},
	contained: {
		"&:focus": {
			backgroundColor: modulesColors.primary.dark,
			boxShadow: "none",
		},
		"&:hover": { boxShadow: "none" },
	},
	outlined: {
		borderColor: modulesColors.primary.main,
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
	},
	text: {
		borderColor: modulesColors.primary.main,
		"&:focus": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:hover": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: modulesColors.primary.mid,
			borderColor: modulesColors.primary.main,
			color: modulesColors.neutrals._1000,
		},
	},
}));

const QRoundButton: FC<QRoundButtonProps> = ({
	onClick = () => {},
	variant = "contained",
	text,
	icon,
	disabled = false,
	diameter = "24px",
}) => {
	const classes = buttonStyles();
	return (
		<Button
			variant={variant}
			onClick={onClick}
			className={`${classes.button} ${
				variant === "contained"
					? classes.contained
					: variant === "outlined"
						? classes.outlined
						: classes.text
			}
			`}
			disableFocusRipple
			disableTouchRipple
			startIcon={icon}
			disabled={disabled}
			sx={{ width: diameter, height: diameter }}
		>
			{text}
		</Button>
	);
};
export default QRoundButton;
