import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import makeStyles from "@mui/styles/makeStyles";
import { TreeView } from "@mui/x-tree-view";
import React, { useEffect } from "react";
import TreeItems from "./TreeItems";
import { getDescendentIds } from "./groupManagementHelpers";

const useStyles = makeStyles({
	root: {
		// height: 216,
		height: "100%",
		flexGrow: 1,
		maxWidth: 400,
	},
});

export default function GroupsTreeControl({
	selectedGroupId,
	setSelectedGroupId,
	groupsTree,
	disableToggle = false,
}) {
	const classes = useStyles();

	const rootNode = !!groupsTree && groupsTree.length > 0 ? groupsTree[0] : undefined;

	const [expanded, setExpanded] = React.useState(getDescendentIds(rootNode));
	const [selected, setSelected] = React.useState(selectedGroupId);

	useEffect(() => {
		setSelected(selectedGroupId);
		setExpanded(getDescendentIds(rootNode));
	}, [selectedGroupId]);

	useEffect(() => {
		setExpanded(getDescendentIds(rootNode));
	}, [groupsTree]);

	const handleToggle = (event, nodeIds) => {
		if (!disableToggle) setExpanded(nodeIds);
	};

	const handleSelect = (event, nodeIds) => {
		setSelectedGroupId(nodeIds);
	};

	return (
		<TreeView
			className={classes.root}
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			expanded={expanded}
			selected={selected}
			onNodeToggle={handleToggle}
			onNodeSelect={handleSelect}
		>
			<TreeItems groupsTree={groupsTree} />
		</TreeView>
	);
}
