import { List } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { ModulesMilestone } from "src/model/modules/modules.Model";
import MilestoneStaticListItem from "./MilestoneStaticListItem";

export interface MilestoneStaticListProps {
	milestones: ModulesMilestone[];
	moduleId: string;
	listItemClassName?: string;
	listItemSelectedClassName?: string;
}

const MilestoneStaticList: FC<MilestoneStaticListProps> = ({
	milestones,
	listItemClassName,
	listItemSelectedClassName,
	moduleId,
}) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [updatedMilestones, setUpdatedMilestones] = useState(milestones);

	useEffect(() => {
		const activeMileStone = milestones.filter((m) => m.state === "active");
		if (activeMileStone[0]) {
			setActiveIndex(activeMileStone[0].step - 1);
		}

		const modifiedMileStones = milestones.map((item) => {
			if (item.state === "active" && item.progress && item.progress.length > 0) {
				const indexWithIsLocked = item.progress.findIndex(
					(progressItem) => progressItem.isLocked,
				);

				if (indexWithIsLocked !== -1) {
					item.progress[indexWithIsLocked].isNext = true;
				}
			}

			return item;
		});
		setUpdatedMilestones(modifiedMileStones);
	}, [milestones]);

	return (
		<List>
			{updatedMilestones.map((milestone, index) => (
				<MilestoneStaticListItem
					key={milestone.step}
					milestone={milestone}
					listItemClassName={listItemClassName}
					listItemSelectedClassName={listItemSelectedClassName}
					isActiveMileStone={index === activeIndex}
					setActiveIndex={setActiveIndex}
					milestoneIndex={index}
					activeIndex={activeIndex}
					moduleId={moduleId}
				/>
			))}
		</List>
	);
};

export default MilestoneStaticList;
