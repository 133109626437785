import { createTheme } from "@mui/material";
import React from "react";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		body: React.CSSProperties;
		body3: React.CSSProperties;
		info: React.CSSProperties;
		info1: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		body?: React.CSSProperties;
		body3?: React.CSSProperties;
		info?: React.CSSProperties;
		info1?: React.CSSProperties;
	}
	interface PaletteColorOptions {
		main: string;
		mid: string;
		light: string;
		dark: string;
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		body: true;
		body3: true;
		info: true;
		info1: true;
	}
}

declare module "@mui/system" {
	interface BreakpointOverrides {
		// Your custom breakpoints
		navBar: true;
		mobile: true;
		tablet: true;
		desktop: true;
		// Remove default breakpoints
		xs: false;
		sm: false;
		md: false;
		lg: false;
		xl: false;
	}
}

export const modulesColors = {
	primary: {
		main: "#41948C",
		light: "#E3FCFA",
		mid: "#AAE0DB",
		dark: "#14746A",
	},
	secondary: {
		main: "#E5A08C",
		light: "#FFEAE5",
		mid: "#FFC5B7",
		dark: "#B26655",
	},
	neutrals: {
		_1000: "#000000",
		_900: "#1C1F1F",
		_800: "#2F3333",
		_700: "#6D7372",
		_600: "#848A89",
		_500: "#B3BAB9",
		_400: "#D0D6D5",
		_300: "#E4EBEA",
		_200: "#F0F7F6",
		_100: "#FFFFFF",
	},
};

export const modulesTheme = createTheme({
	palette: {
		primary: {
			main: modulesColors.primary.main,
			light: modulesColors.primary.light,
			mid: modulesColors.primary.mid,
			dark: modulesColors.primary.dark,
		},
		secondary: {
			main: modulesColors.secondary.main,
			light: modulesColors.secondary.light,
			mid: modulesColors.secondary.mid,
			dark: modulesColors.secondary.dark,
		},
	},
	typography: {
		fontFamily: `"Nunito", "Helvetica", "Arial", sans-serif !important`,
		h1: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
			fontSize: 32,
			fontWeight: "400",
		},
		h2: {
			fontFamily: '"Merriweather", Georgia, Garamond, Times, serif',
			fontSize: 28,
			fontWeight: "400",
		},
		h3: {
			fontSize: 24,
			fontWeight: "bold",
		},

		h5: {
			fontSize: 18,
			fontWeight: "bold",
		},
		h6: {
			fontSize: 16,
			fontWeight: "bold",
		},
		body: { fontSize: 16 },
		body1: { fontSize: 15 },
		body2: { fontSize: 14 },
		body3: { fontSize: 12 },
		info: { fontSize: 15, opacity: 0.75 },
		info1: { fontSize: 14, opacity: 0.75 },
	},
	breakpoints: {
		values: {
			mobile: 0,
			navBar: 430,
			tablet: 768,
			desktop: 1024,
		},
	},
});
