import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "src/Theme";

interface ProgressBarProps {
	step: number;
	totalSteps: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ step, totalSteps }) => {
	const ref = useRef<HTMLDivElement | null>();
	const [maxWidth, setMaxWidth] = useState(780);
	const barHeight = 10;
	const barMaxWidth = 150;
	const barPadding = 5;
	const totalStepsMax = 10;
	const [stepMultiplier, setStepMultiplier] = useState(1);
	const [barWidth, setBarWidth] = useState(barMaxWidth);
	const [barCount, setBarCount] = useState(Array.from({ length: totalSteps }, () => 1));

	useEffect(() => {
		const getParentWidth = () => {
			if (ref.current?.parentElement) {
				const width = ref.current.parentElement.getBoundingClientRect().width;
				setMaxWidth(width);
			}
		};
		getParentWidth();

		window.addEventListener("resize", getParentWidth);

		return () => {
			window.removeEventListener("resize", getParentWidth);
		};
	}, []);

	useEffect(() => {
		if (totalSteps >= totalStepsMax) {
			setStepMultiplier(Math.floor(totalSteps / totalStepsMax));
			setBarWidth((maxWidth - barPadding * totalStepsMax) / totalStepsMax);
			setBarCount(Array.from({ length: totalSteps }, () => 1));
		} else {
			const barCalcWidth = (maxWidth - barPadding * totalSteps) / totalSteps;
			setBarWidth(barCalcWidth > barMaxWidth ? barMaxWidth : barCalcWidth);
			setBarCount(Array.from({ length: totalSteps }, () => 1));
		}
	}, [totalSteps, maxWidth]);

	return (
		//@ts-expect-error type error on ref
		<div ref={ref}>
			{barCount.map((x, index) => (
				<svg
					key={x + index}
					xmlns="http://www.w3.org/2000/svg"
					width={barWidth + barPadding}
					height={barHeight}
				>
					<rect
						x={barPadding}
						width={barWidth}
						height={barHeight}
						fill={index > step / stepMultiplier ? colors.darkerGray : colors.teal}
					/>
				</svg>
			))}
		</div>
	);
};

export default ProgressBar;
