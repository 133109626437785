import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	member: { fontStyle: "italic" },
}));

function isMemberName(name) {
	return !name.startsWith("[");
}

export default function GroupChipToolTipSection({ title, people }) {
	const classes = useStyles();

	return (
		<div>
			<Typography variant="button">{title}</Typography>
			{people.length > 0 ? (
				people.map((name, index) => (
					<Typography
						variant="body2"
						key={index}
						className={clsx(isMemberName(name) && classes.member)}
					>
						{name}
					</Typography>
				))
			) : (
				<Typography variant="body2">None</Typography>
			)}
		</div>
	);
}
