import React, { useEffect, useState } from "react";
import { apiCreateGroup, apiDeleteGroup, apiUpdateGroup } from "../libs/apiLib";
import { findNode, generateSelectedGroupInfo } from "../components/group/groupManagementHelpers";

import GroupDetails from "../components/group/GroupDetails";
import GroupSidebar from "../components/group/GroupSidebar";
import { KILL_EDIT_MODE_ACTION } from "../shared/constants";
import SaveChangesDialog from "../components/group/SaveChangesDialog";
import makeStyles from "@mui/styles/makeStyles";
import { useAppContext } from "../libs/contextLib";
import { logger } from "../libs/logLib";
import { refreshSubscriptionInfo } from "../utility/App.util";

const EMPTY_GROUP_EDIT_RECORD = {};

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
		width: "100%",
	},
	content: {
		display: "grid",
		gridTemplateColumns: "minmax(300px, auto) 1fr",
		height: "100%",
		width: "100%",
	},
}));

function isGroupModified(groupEditRecord) {
	return Object.values(groupEditRecord).reduce((result, record) => {
		return result || record.isModified;
	}, false);
}

// TODO: this is a bit of a hack
function getEditedGroupName(groupEditRecord, groups) {
	let editRecords = Object.values(groupEditRecord);

	return editRecords.length > 0
		? groups[editRecords[0].id]
			? groups[editRecords[0].id].name
			: ""
		: "";
}

export default function GroupManagement() {
	const {
		appState: { subscriptionMembers, groups, groupsTree, selectedSubscriptionId },
		appDispatch,
	} = useAppContext();
	const subscriptionId = selectedSubscriptionId;
	const [selectedGroupId, setSelectedGroupId] = useState(groupsTree[0].SK ?? "");
	const [selectedGroupInfo, setSelectedGroupInfo] = useState(
		generateSelectedGroupInfo(selectedGroupId, groups, subscriptionMembers),
	);

	const [killEditMode, setKillEditMode] = useState(KILL_EDIT_MODE_ACTION.CLEAR);
	const [groupEditRecord, setGroupEditRecord] = useState(EMPTY_GROUP_EDIT_RECORD);
	const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);
	const classes = useStyles();

	// When this is flipped to true it propgates once, then goes back to false
	useEffect(() => {
		if (killEditMode !== KILL_EDIT_MODE_ACTION.CLEAR) {
			setKillEditMode(KILL_EDIT_MODE_ACTION.CLEAR);
			updateSelectedGroupInfo();
		}
	}, [killEditMode]);

	useEffect(() => {
		if (isGroupModified(groupEditRecord)) {
			setShowSaveChangesDialog(true);
		} else {
			setKillEditMode(KILL_EDIT_MODE_ACTION.CLEAR);
			updateSelectedGroupInfo();
		}
	}, [selectedGroupId]);

	useEffect(() => {
		setSelectedGroupInfo(
			generateSelectedGroupInfo(selectedGroupId, groups, subscriptionMembers),
		);
	}, [subscriptionMembers, groups]);

	function updateGroupEditRecord(editRecord) {
		let groupEditRecordCopy = { ...groupEditRecord };
		groupEditRecordCopy[editRecord.key] = editRecord;
		setGroupEditRecord(groupEditRecordCopy);
	}

	function updateSelectedGroupInfo() {
		setSelectedGroupInfo(
			generateSelectedGroupInfo(selectedGroupId, groups, subscriptionMembers),
		);
	}

	async function editGroup(groupId, key, value) {
		try {
			await apiUpdateGroup(subscriptionId, groupId, { [key]: value });
			await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
		} catch (e) {
			logger.error(e);
		}
	}

	async function createNewGroup(name, parentGroupId) {
		try {
			let companyId = selectedSubscriptionId;
			await apiCreateGroup(subscriptionId, companyId, name, parentGroupId);

			await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
		} catch (e) {
			logger.error(e);
		}
	}

	async function deleteGroup(deletedGroupId) {
		try {
			findNode(groupsTree[0], deletedGroupId);
			await apiDeleteGroup(subscriptionId, deletedGroupId);

			await refreshSubscriptionInfo(selectedSubscriptionId, appDispatch);
		} catch (e) {
			logger.error(e);
		}
	}

	function killEditModeActions(action) {
		setKillEditMode(action);
		setGroupEditRecord(EMPTY_GROUP_EDIT_RECORD);
		setShowSaveChangesDialog(false);
	}

	function handleDontSave() {
		killEditModeActions(KILL_EDIT_MODE_ACTION.DONTSAVE);
	}

	function handleSave() {
		killEditModeActions(KILL_EDIT_MODE_ACTION.SAVE);
	}

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<GroupSidebar
					selectedGroupId={selectedGroupId}
					groups={groups}
					groupsTree={groupsTree}
					createNewGroup={createNewGroup}
					canEditGroupsSection={true}
					setSelectedGroupId={setSelectedGroupId}
				/>
				<GroupDetails
					groupsTree={groupsTree}
					selectedGroup={selectedGroupInfo}
					editGroup={editGroup}
					updateGroupEditRecord={updateGroupEditRecord}
					deleteGroup={() => deleteGroup(selectedGroupId)}
					killEditMode={killEditMode}
					canEditGroupsSection={true}
				/>
			</div>
			<SaveChangesDialog
				open={showSaveChangesDialog}
				groupName={getEditedGroupName(groupEditRecord, groups)}
				handleDontSave={handleDontSave}
				handleSave={handleSave}
			/>
		</div>
	);
}
