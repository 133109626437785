import React from "react";
import DeleteMemberButton from "./DeleteMemberButton";
import EditMemberButton from "./EditMemberButton";
import EditButtons from "../group/EditButtons";
import { MEMBER_STATUS } from "../../shared/constants";

export default function PeopletableRowButtons({
	row,
	hoverRowId,
	setHoverRowId,
	editRowId,
	setEditRowId,
	saveAction,
	cancelAction,
	isModified,
	setEditRowInfo,
}) {
	if (editRowId === row.userId) {
		return (
			<div>
				<EditButtons
					isEditMode={true}
					isModified={isModified}
					saveAction={saveAction}
					cancelAction={cancelAction}
				/>
			</div>
		);
	}

	if (hoverRowId === row.userId) {
		return (
			<div>
				<EditMemberButton
					action={() => {
						setEditRowId(row.userId);
						setEditRowInfo(row);
					}}
				/>
				<DeleteMemberButton
					userId={row.userId}
					name={row.status === MEMBER_STATUS.MEMBER ? row.name : row.email}
					clearHoverRow={() => setHoverRowId("")}
				/>
			</div>
		);
	}

	return <div></div>;
}
