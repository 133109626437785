import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { ModulesStaticPage } from "src/model/modules/modules.Model";
import ModulesNav from "../Shared/ModulesNav";
import { modulesStyles } from "../modulesStyles";

interface StaticContentTemplateProps {
	page: ModulesStaticPage;
}

const StaticContentTemplate: FC<StaticContentTemplateProps> = ({ page }) => {
	const classes = modulesStyles();
	return (
		<ModulesNav pageTitle={page.pageTitle} buttonConfig={page.buttonConfig}>
			<Grid
				container
				direction="row"
				spacing="20px"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item mobile={12} tablet={3}>
					<div className={classes.modulesItemCentered}>
						<img
							className={classes.modulesStaticImage}
							src={page.imagePath}
							alt={page.imageAltText}
						/>
					</div>
				</Grid>
				<Grid item mobile={12} tablet={9}>
					<Typography variant="h5">{page.title}</Typography>
					{page.description && (
						<Typography
							variant="body"
							dangerouslySetInnerHTML={{ __html: page.description }}
						/>
					)}
				</Grid>

				{page.info && (
					<Grid item mobile={12}>
						<Typography variant="info1">{page.info}</Typography>
					</Grid>
				)}
			</Grid>
		</ModulesNav>
	);
};

export default StaticContentTemplate;
